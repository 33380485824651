import React from "react";

import axios from "axios";

// Copy data to clipboard
import { CopyToClipboard } from 'react-copy-to-clipboard';

import * as Constants from "./Constants.js";
import { RecommenderV3SearchButtonDefaultLabel } from "./components/RecommenderSearchButton";
import { RecommenderSearchLinkDefaultLabel } from "./components/RecommenderSearchLink";
import { RecommenderExpandLinkDefaultLabel } from "./components/RecommenderExpandLink";

import { FaClipboard } from 'react-icons/fa';

export const log10 = (val) => {
  return Math.log(val) / Math.LN10;
}

export const zeroPad = (n, width, z) => {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export const getJsonFromSpecifiedUrl = (urlStr) => {
  const url = new URL(urlStr);
  let query = url.search.substr(1);
  let result = {};
  query.split("&").forEach(function(part) {
      var item = part.split("=");
      if (item[0].length > 0)
        result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export const getJsonFromUrl = () => {
  return getJsonFromSpecifiedUrl(window.location);
  // let query = window.location.search.substr(1);
  // let result = {};
  // query.split("&").forEach(function(part) {
  //     var item = part.split("=");
  //     if (item[0].length > 0)
  //       result[item[0]] = decodeURIComponent(item[1]);
  // });
  // return result;
}

export const stripQueryStringAndHashFromPath = (url) => { 
  return url.split("?")[0].split("#")[0]; 
}

export const isValidChromosome = (assembly, chromosomeName) => {
  // console.log(`isValidChromosome | ${assembly} | ${chromosomeName}`);
  const chromosomeBounds = Constants.assemblyBounds[assembly];
  if (!chromosomeBounds) {
    // console.log("isValidChromosome: bad or unknown assembly");
    return false; // bad or unknown assembly
  }
  const chromosomeNames = Object.keys(chromosomeBounds).map((n) => n.toLowerCase());
  if (!chromosomeNames) {
    // console.log("isValidChromosome: no chromosomes");
    return false; // no chromosomes? that would be weird
  }
  const chromosomeNamesContainsNameOfInterest = (chromosomeNames.indexOf(chromosomeName.toLowerCase()) > -1);
  return chromosomeNamesContainsNameOfInterest;
}

export const getRangeFromString = (str, applyPadding, applyApplicationBinShift, assembly) => {
  // console.log(`Helpers.getRangeFromString ${str} ${applyPadding} ${applyApplicationBinShift} ${assembly}`);
  if (!applyApplicationBinShift) applyApplicationBinShift = false;
  /*
    Test if the new location passes as a chrN:X-Y pattern, 
    where "chrN" is an allowed chromosome name, and X and Y 
    are integers, and X < Y. 
    
    We allow chromosome positions X and Y to contain commas, 
    to allow cut-and-paste from the UCSC genome browser.
  */
  let matches = str.replace(/,/g, '').split(/[:-\s]+/g).filter( i => i );
  let chrom = "";
  let start = -1;
  let stop = -1;
  // console.log("matches", matches);
  if (matches.length === 3) {
    chrom = matches[0];
    if (!isValidChromosome(assembly, chrom)) {
      return null;
    }
    chrom = getTrueChromosomeName(assembly, chrom);
    start = parseInt(matches[1].replace(',',''));
    stop = parseInt(matches[2].replace(',',''));
    if (applyPadding) {
      start -= parseInt(Constants.defaultHgViewRegionUpstreamPadding);
      stop += parseInt(Constants.defaultHgViewRegionDownstreamPadding);
    }
  }
  else if (matches.length === 2) {
    chrom = matches[0];
    if (!isValidChromosome(assembly, chrom)) {
      return null;
    }
    chrom = getTrueChromosomeName(assembly, chrom);
    let midpoint = parseInt(matches[1].replace(',',''));
    start = midpoint - parseInt(Constants.defaultHgViewRegionUpstreamPadding);
    stop = midpoint + parseInt(Constants.defaultHgViewRegionDownstreamPadding);
  }
  else if (matches.length === 1) {
    chrom = matches[0];
    if (!isValidChromosome(assembly, chrom)) {
      return null;
    }
    chrom = getTrueChromosomeName(assembly, chrom);
    if (Constants.assemblyChromosomes[assembly].includes(chrom)) {
      start = 1
      stop = Constants.assemblyBounds[assembly][chrom]['ub'] - 1;
    }
  }
  else {
    return null;
  }
  //console.log("chrom, start, stop", chrom, start, stop);
  //let padding = (applyPadding) ? parseInt(Constants.defaultHgViewGenePaddingFraction * (stop - start)) : 0;
  //let assembly = this.state.hgViewParams.genome;
  //let chrLimit = parseInt(Constants.assemblyBounds[assembly][chrom].ub) - 10;
  //
  // Constants.applicationBinShift applies a single-bin correction to the padding 
  // applied to the specified range (exemplar, etc.). It is not perfect but helps 
  // when applying a vertical line on selected exemplars.
  //
  //start = ((start - padding + (applyApplicationBinShift ? Constants.applicationBinShift : 0)) > 0) ? (start - padding + (applyApplicationBinShift ? Constants.applicationBinShift : 0)) : 0;
  //stop = ((stop + padding + (applyApplicationBinShift ? Constants.applicationBinShift : 0)) < chrLimit) ? (stop + padding + (applyApplicationBinShift ? Constants.applicationBinShift : 0)) : stop;
  if (start < 0) {
    start = 0;
  }
  if (stop >= Constants.assemblyBounds[assembly][chrom]['ub']) {
    stop = Constants.assemblyBounds[assembly][chrom]['ub'];
  }
  const range = [chrom, start, stop];
  // console.log("range", range);
  return range;
}

export const getTrueChromosomeName = (assembly, chromosomeName) => {
  let chromosomeBounds = Constants.assemblyBounds[assembly];
  if (!chromosomeBounds) {
    // console.log("fixChromosomeName: bad or unknown assembly");
    return null; // bad or unknown assembly
  }
  const chromosomeNamesOriginal = Object.keys(chromosomeBounds);
  const chromosomeNamesLC = chromosomeNamesOriginal.map((n) => n.toLowerCase());
  if (!chromosomeNamesOriginal) {
    // console.log("fixChromosomeName: no chromosomes");
    return null; // no chromosomes? that would be weird
  }
  let indexOfChromosomeNameOfInterest = chromosomeNamesLC.indexOf(chromosomeName.toLowerCase());
  // console.log(`chromosomeNamesLC.indexOf(chromosomeName.toLowerCase()) ${chromosomeNamesLC.indexOf(chromosomeName.toLowerCase())}`);
  return chromosomeNamesOriginal[indexOfChromosomeNameOfInterest];
}

export const positionSummaryElement = (showClipboard, showScale, self) => {
  if (showClipboard == null) showClipboard = true;
  if ((typeof self.state.currentPosition === "undefined") || (typeof self.state.currentPosition.chrLeft === "undefined") || (typeof self.state.currentPosition.chrRight === "undefined") || (typeof self.state.currentPosition.startLeft === "undefined") || (typeof self.state.currentPosition.stopRight === "undefined")) {
    return <div />
  }
  //console.log("self.state.currentPosition", self.state.currentPosition);

  let positionSummary = (self.state.currentPosition.chrLeft === self.state.currentPosition.chrRight) ? `${self.state.currentPosition.chrLeft}:${self.state.currentPosition.startLeft}-${self.state.currentPosition.stopLeft}` : `${self.state.currentPosition.chrLeft}:${self.state.currentPosition.startLeft} - ${self.state.currentPosition.chrRight}:${self.state.currentPosition.stopRight}`;

  let scaleSummary = (self.state.chromsAreIdentical) ? self.state.currentViewScaleAsString : "";
  
  if (showClipboard) {
    if (parseInt(self.state.width)>750) {
      return <div style={(parseInt(self.state.width)<1300)?{"letterSpacing":"0.005em"}:{}}><span title={"Viewer genomic position"}>{positionSummary} {(showScale) ? scaleSummary : ""}</span> <CopyToClipboard text={positionSummary}><span className="navigation-summary-position-clipboard-parent" title={"Copy genomic position to clipboard"}><FaClipboard className="navigation-summary-position-clipboard" /></span></CopyToClipboard></div>
    }
    else {
      return <div />
    }
  }
  else {
    return <div className="navigation-summary-position-mobile-landscape"><span title={"Viewer genomic position and assembly"}>{positionSummary} {(!showScale) ? scaleSummary : ""} • {self.state.hgViewParams.genome}</span></div>
  }
}

export const calculateScale = (leftChr, rightChr, start, stop, self, includeAssembly) => {
  //
  // get current scale difference
  //
  let diff = 0;
  let log10Diff = 0;
  let scaleAsStr = "";
  let chromsAreIdentical = (leftChr === rightChr);
  if (leftChr === rightChr) {
    diff = parseInt(stop) - parseInt(start);
  }
  else {
    //console.log(`updateScale > chromosomes are different`);
    const leftDiff = parseInt(Constants.assemblyBounds[self.state.hgViewParams.genome][leftChr]['ub']) - parseInt(start);
    const rightDiff = parseInt(stop);
    const allChrs = Object.keys(Constants.assemblyBounds[self.state.hgViewParams.genome]).sort((a, b) => { return parseInt(a.replace("chr", "")) - parseInt(b.replace("chr", "")); });
    //console.log(`leftChr ${leftChr} | rightChr ${rightChr} | start ${start} | stop ${stop} | leftDiff ${leftDiff} | rightDiff ${rightDiff} | allChrs ${allChrs}`);
    let log10DiffFlag = false;
    for (let i = 0; i < allChrs.length; i++) {
      const currentChr = allChrs[i];
      if (currentChr === leftChr) {
        //console.log(`adding ${leftDiff} for chromosome ${currentChr}`);
        diff += (leftDiff > 0) ? leftDiff : 1;
        log10DiffFlag = true;
      }
      else if (currentChr === rightChr) {
        //console.log(`adding ${rightDiff} for chromosome ${currentChr}`);
        diff += (rightDiff > 0) ? rightDiff : 1;
        log10DiffFlag = false;
        break;
      }
      else if (log10DiffFlag) {
        //console.log(`adding ${Constants.assemblyBounds[this.state.hgViewParams.genome][currentChr]['ub']} for chromosome ${currentChr}`);
        diff += Constants.assemblyBounds[self.state.hgViewParams.genome][currentChr]['ub'];
      }
    }
  }
  //console.log(`calculateScale ${diff}`);
  log10Diff = log10(diff);
  scaleAsStr = (log10Diff < 3) ? `${Math.ceil(diff/100)*100}nt` :
               (log10Diff < 4) ? `${Math.floor(diff/1000)}kb` :
               (log10Diff < 5) ? `${Math.floor(diff/1000)}kb` :
               (log10Diff < 6) ? `${Math.floor(diff/1000)}kb` :
               (log10Diff < 7) ? `${Math.floor(diff/1000000)}Mb` :
               (log10Diff < 8) ? `${Math.floor(diff/1000000)}Mb` :
               (log10Diff < 9) ? `${Math.floor(diff/1000000)}Mb` :
                                 `${Math.floor(diff/1000000000)}Gb`;
  // scaleAsStr = `(~${scaleAsStr})`;
  scaleAsStr = (includeAssembly) ? `(~${scaleAsStr} | ${self.state.hgViewParams.genome})` : `(~${scaleAsStr})`;
  return { 
    diff: diff, 
    scaleAsStr: scaleAsStr,
    chromsAreIdentical: chromsAreIdentical
  };
}

export const hgViewconfDownloadURL = (url, id, self) => {
  return url + self.state.hgViewParams.hgViewconfEndpointURLSuffix + id; 
}

export const uuidQueryPromise = (fn, endpoint, self) => {
  const hgUUIDQueryURL = `${endpoint}/tilesets?ac=${fn}`;
  return axios.get(hgUUIDQueryURL).then((res) => {
    if (res.data && res.data.results && res.data.results[0]) {
      return {'fn': fn, 'uuid': res.data.results[0].uuid};
    }
    else {
      let err = {};
      err.response = {};
      err.response.status = "404";
      err.response.statusText = "No tileset data found for specified UUID";
      // throw {response:{status:"404", statusText:"No tileset data found for specified UUID"}};
      throw err;
    }
  })
  .catch((err) => {
    // console.log(JSON.stringify(err));
    let msg = self.errorMessage(err, `Could not retrieve UUID for track query (${fn})`, hgUUIDQueryURL);
    self.setState({
      overlayMessage: msg,
      hgViewconf: {}
    }, () => {
      self.fadeInOverlay();
    });
  });
}

export const getChromSizesURL = (genome, self) => {
  let chromSizesURL = self.state.hgViewParams.hgGenomeURLs[genome];
  if (self.state.hgViewParams.itt === "ht") {
    chromSizesURL = self.state.hgViewParams.hgFixedBinGenomeURLs[genome];
  }
  // console.log(`chromSizesURL ${chromSizesURL}`);
  if (self.isProductionSite) {
    chromSizesURL = chromSizesURL.replace(Constants.applicationDevelopmentPort, Constants.applicationProductionPort);
  }
  else if (self.isProductionProxySite) {
    chromSizesURL = chromSizesURL.replace(Constants.applicationDevelopmentPort, Constants.applicationProductionProxyPort);
    chromSizesURL = chromSizesURL.replace(/^https/, "http");
  }
  else {
    let port = parseInt(self.currentURL.port);
    if (isNaN(port)) { port = Constants.applicationProductionPort; }
    chromSizesURL = chromSizesURL.replace(":" + Constants.applicationDevelopmentPort, `:${port}`);
  }
  return chromSizesURL;
}

export const adjustHgViewParamsForNewGenome = (oldHgViewParams, newGenome) => {}

export const recommenderV3QueryPromise = (qChr, qStart, qEnd, qWindowSizeBp, self) => {
  // let params = self.state.tempHgViewParams;
  let datasetAltname = 'dhsIndex';
  let assembly = 'hg38';
  let stateModel = '';
  let groupAltname = 'all';
  let groupEncoded = encodeURIComponent(Constants.groupsForRecommenderV1OptionGroup[datasetAltname][assembly][groupAltname]);
  let saliencyLevel = ''; // Constants.complexitiesForRecommenderV1OptionSaliencyLevel[params.complexity];
  let chromosome = qChr;
  let start = qStart;
  let end = qEnd;
  let windowSizeBp = parseInt(qWindowSizeBp);
  // let rawBp = (windowSizeBp < 1250) ? 500 :
  //             (windowSizeBp < 3750) ? 1000 :
  //             (windowSizeBp < 6250) ? 2500 :
  //             (windowSizeBp < 8750) ? 5000 :
  //             (windowSizeBp < 12500) ? 7500 : 10000;
  let rawBp = (windowSizeBp < 3750) ? 1000 :
              (windowSizeBp < 6250) ? 2500 :
              (windowSizeBp < 8750) ? 5000 :
              (windowSizeBp < 12500) ? 7500 : 10000;
  // let scaleLevel = parseInt(windowSize / 5);
  let tabixUrlEncoded = encodeURIComponent(Constants.applicationTabixRootURL);
  let outputFormat = Constants.defaultApplicationRecommenderV3OutputFormat;
  
  // let recommenderV3URL = `${Constants.recommenderProxyURL}/v2?datasetAltname=${datasetAltname}&assembly=${assembly}&stateModel=${stateModel}&groupEncoded=${groupEncoded}&saliencyLevel=${saliencyLevel}&chromosome=${chromosome}&start=${start}&end=${end}&tabixUrlEncoded=${tabixUrlEncoded}&outputFormat=${outputFormat}&windowSize=${windowSize}&scaleLevel=${scaleLevel}`;

  let recommenderV3URL = `${Constants.recommenderProxyURL}/v2?datasetAltname=${datasetAltname}&assembly=${assembly}&stateModel=${stateModel}&groupEncoded=${groupEncoded}&saliencyLevel=${saliencyLevel}&chromosome=${chromosome}&start=${start}&end=${end}&tabixUrlEncoded=${tabixUrlEncoded}&outputFormat=${outputFormat}&rawBp=${rawBp}`;
  
  console.log(`[recommenderV3SearchOnClick] recommenderV3URL ${recommenderV3URL}`);
  
  return axios.get(recommenderV3URL).then((res) => {
    if (res.data) {
      // console.log(`[recommenderV3SearchOnClick] res.data ${JSON.stringify(res.data)}`);
      if (res.data.hits && res.data.hits.length == 1) {
        return res.data;
      }
      else
        throw new Error("No recommendations found");
    }
    else {
      throw new Error("No recommendations found");
    }
  })
  .catch((err) => {
    err.response = {};
    err.response.title = "Please try again";
    err.response.status = "404";
    err.response.statusText = `Could not retrieve recommendations for region query. Please try another region.`;
    // console.log(`[recommenderV1SearchOnClick] err ${JSON.stringify(err)}`);
    let msg = self.errorMessage(err, err.response.statusText, null);
    self.setState({
      overlayMessage: msg,
    }, () => {
      self.fadeInOverlay(() => {
        self.setState({
          selectedExemplarRowIdx: Constants.defaultApplicationSerIdx,
          recommenderV3SearchIsVisible: self.recommenderV3SearchCanBeVisible(),
          recommenderV3SearchInProgress: false,
          recommenderV3SearchButtonLabel: RecommenderV3SearchButtonDefaultLabel,
          recommenderV3SearchLinkLabel: RecommenderSearchLinkDefaultLabel,
          recommenderV3ExpandIsEnabled: false,
          recommenderV3ExpandLinkLabel: RecommenderExpandLinkDefaultLabel,
          autocompleteInputDisabled: false,
        })
      });
    });
  })
}

export const debounce = (fn, time) => {
  let timeoutId
  return wrapper
  function wrapper (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      timeoutId = null
      fn(...args)
    }, time)
  }
}

export const simSearchQueryPromise = (qChr, qStart, qEnd, qWindowSizeBp, self, ignoreNoHits) => {
  let params = self.state.tempHgViewParams;
  let datasetAltname = 'dhsIndex';
  let assembly = 'hg38';
  let stateModel = '';
  let groupAltname = 'all';
  // console.log(`simSearchQueryPromise | ${JSON.stringify(params)} | ${datasetAltname} | ${assembly} | ${groupAltname}`);
  let groupEncoded = encodeURIComponent(Constants.groupsForRecommenderV1OptionGroup[datasetAltname][assembly][groupAltname]);
  let saliencyLevel = ''; // Constants.complexitiesForRecommenderV1OptionSaliencyLevel[params.complexity];
  let chromosome = qChr;
  let start = qStart;
  let end = qEnd;
  let windowSizeBp = parseInt(qWindowSizeBp);
  // let windowSizeKb = parseInt(qWindowSizeKb);
  // let windowSize = (windowSizeKb < 10 + 8) ? 5 :
  //                  (windowSizeKb < 25 + 13) ? 10 :
  //                  (windowSizeKb < 50 + 13) ? 25 :
  //                  (windowSizeKb < 75 + 13) ? 50 :
  //                  (windowSizeKb < 100 + 50) ? 75 : 
  //                  (windowSizeKb < 150 + 50) ? 100 : null;
  let rawBp = (windowSizeBp < 3750) ? 1000 :
              (windowSizeBp < 6250) ? 2500 :
              (windowSizeBp < 8750) ? 5000 :
              (windowSizeBp < 12500) ? 7500 : 10000;
  
  // if (!windowSize) {
  //   self.setState({
  //     simsearchQueryCount: -1,
  //     simsearchQueryCountIsVisible: false,
  //   });
  //   return Promise.resolve(null);
  // }

  // let scaleLevel = parseInt(windowSize / 5);
  let tabixUrlEncoded = encodeURIComponent(Constants.applicationTabixRootURL);
  let outputFormat = Constants.defaultApplicationRecommenderV3OutputFormat;
  
  // let recommenderV3URL = `${Constants.recommenderProxyURL}/v2?datasetAltname=${datasetAltname}&assembly=${assembly}&stateModel=${stateModel}&groupEncoded=${groupEncoded}&saliencyLevel=${saliencyLevel}&chromosome=${chromosome}&start=${start}&end=${end}&tabixUrlEncoded=${tabixUrlEncoded}&outputFormat=${outputFormat}&windowSize=${windowSize}&scaleLevel=${scaleLevel}`;

  let recommenderV3URL = `${Constants.recommenderProxyURL}/v2?datasetAltname=${datasetAltname}&assembly=${assembly}&stateModel=${stateModel}&groupEncoded=${groupEncoded}&saliencyLevel=${saliencyLevel}&chromosome=${chromosome}&start=${start}&end=${end}&tabixUrlEncoded=${tabixUrlEncoded}&outputFormat=${outputFormat}&rawBp=${rawBp}`;
  
  // console.log(`[simSearchQueryPromise] simSearchQueryPromiseURL ${JSON.stringify(recommenderV3URL)}`); 
  
  return axios.get(recommenderV3URL).then((res) => {
    if (res.data) {
      // console.log(`[simSearchQueryPromise] res.data ${JSON.stringify(res.data)}`);
      if (res.data.hits && res.data.hits.length > 0 && res.data.hits[0].length > 0) {
        return res.data;
      }
      else {
        // console.log(`res ${JSON.stringify(res)}`);
        if (!ignoreNoHits) throw new Error("No recommendations found");
      }
    }
    else {
      if (!ignoreNoHits) throw new Error("No recommendations found");
    }
  })
  .catch((err) => {
    err.response = {};
    err.response.title = "Please try again";
    err.response.status = "404";
    err.response.statusText = `Could not retrieve recommendations for region query. Please try another region.`;
    // console.log(`[recommenderV1SearchOnClick] err ${JSON.stringify(err)}`);
    let msg = self.errorMessage(err, err.response.statusText, null);
    self.setState({
      overlayMessage: msg,
    }, () => {
      self.fadeInOverlay(() => {
        self.setState({
          selectedExemplarRowIdx: Constants.defaultApplicationSerIdx,
          recommenderV3SearchIsVisible: self.recommenderV3SearchCanBeVisible(),
          recommenderV3SearchInProgress: false,
          recommenderV3SearchButtonLabel: RecommenderV3SearchButtonDefaultLabel,
          recommenderV3SearchLinkLabel: RecommenderSearchLinkDefaultLabel,
          recommenderV3ExpandIsEnabled: false,
          recommenderV3ExpandLinkLabel: RecommenderExpandLinkDefaultLabel,
          genomeSelectIsActive: true,
          autocompleteInputDisabled: false,
          simsearchQueryCount: -1,
          simsearchQueryCountIsVisible: false,
        })
      });
    });
  })
}