import React, { Component } from "react";

import PropTypes from 'prop-types';

import {
  Dropdown, 
  DropdownToggle, 
  DropdownMenu, 
  DropdownItem,
  InputGroup,
  Input,
  Button,
} from "reactstrap";

import axios from "axios";

// higlass-multivec
// cf. https://www.npmjs.com/package/higlass-multivec
import "higlass-multivec/dist/higlass-multivec.js";

// higlass-transcripts
// cf. https://github.com/higlass/higlass-transcripts
import "higlass-transcripts/dist/higlass-transcripts.js";

// higlass
// cf. https://www.npmjs.com/package/higlass
import "higlass/dist/hglib.css";
import { 
  HiGlassComponent, 
  ChromosomeInfo 
} from "higlass";

// Mobile device detection
import { isMobile } from "react-device-detect";

// Application constants
import * as Constants from "../Constants.js";
import * as Helpers from "../Helpers.js";

// Deep clone an object 
import update from 'immutability-helper';

// Application autocomplete
import Autocomplete from "./Autocomplete/Autocomplete";

// Query JSON objects (to build dropdowns and other inputs)
// cf. https://www.npmjs.com/package/jsonpath-lite
export const jp = require("jsonpath");

// Generate UUIDs
export const uuid4 = require("uuid4");

class Portal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: props.height, 
      width: props.width,
      contactEmail: "info@altius.org",
      twitterHref: "https://twitter.com/AltiusInst",
      linkedInHref: "https://www.linkedin.com/company/altius-institute-for-biomedical-sciences",
      altiusHref: "https://www.altius.org",
      higlassHref: "http://higlass.io",
      singleGroupGenomeDropdownOpen: false,
      singleGroupGenomeDropdownSelection: Constants.defaultSingleGroupGenomeKey,
      singleGroupDropdownOpen: Constants.defaultSingleGroupDropdownOpen,
      singleGroupDropdownSelection: Constants.defaultSingleGroupKeys[Constants.defaultSingleGroupGenomeKey],
      singleGroupSearchInputPlaceholder: Constants.defaultSingleGroupSearchInputPlaceholder,
      singleGroupSearchInputValue: Constants.defaultSingleGroupSearchInputValue,
      hgViewKey: 0,
      hgViewLoopEnabled: true,
      hgViewHeight: 0,
      epilogosContentHeight: window.innerHeight + "px",
      epilogosContentPadding: 115,
      genes: Constants.portalGenes,
      hgViewconf: null,
      hgViewParams: Constants.portalHgViewParameters,
      hgViewRefreshTimerActive: true,
      hgViewClickPageX: Constants.defaultHgViewClickPageX,
      hgViewClickTimePrevious: Constants.defaultHgViewClickTimePrevious,
      hgViewClickTimeCurrent: Constants.defaultHgViewClickTimeCurrent,
      hgViewClickInstance: 0,
      hgViewParentVisibilitySensorIsActive: true,
      hgViewParentIsVisible: true,
      goJumpActive: false,
      exemplarJumpActive: false,
      exemplarRegions: [],
      portalRefreshInterval: null,
      overlayVisible: false,
      showOverlayNotice: true,
      overlayMessage: "Placeholder",
      previousWidth: 0,
      previousHeight: 0,
      orientationIsPortrait: true,
    };
    
    this.hgView = React.createRef();
    this.singleGroupSearchInputComponent = React.createRef();
    this.offscreenContent = React.createRef();
    this.epilogosPortalContainerOverlay = React.createRef();
    this.epilogosPortalOverlayNotice = React.createRef();
    this.portalCenteredContent = React.createRef();
    
    // read exemplars into memory
    let exemplarURL = this.stripQueryStringAndHashFromPath(document.location.href) + `/assets/exemplars/${this.state.hgViewParams.genome}/proteinCodings/top15PerSystem.txt`;
    //console.log("exemplarURL", exemplarURL);
    if (exemplarURL) {
      axios.get(exemplarURL)
        .then((res) => {
          let data = res.data;
          if (!data) {
            throw Error("Error: Exemplars not returned from query to " + exemplarURL);
          }
          else if (typeof data === "string") {
            let regions = data.split('\n');
            this.setState({
              exemplarJumpActive: true,
              exemplarRegions: regions
            });
          } 
          else {
            throw Error("Exemplar data invalid");
          }         
        })
        .catch((err) => {
          //console.log(err.response);
          let msg = <div className="portal-overlay-notice"><div className="portal-overlay-notice-header">{err.response.status} Error</div><div className="portal-overlay-notice-body"><div>Error retrieving exemplar data!</div><div>{err.response.statusText}: {exemplarURL}</div><div className="portal-overlay-notice-body-controls"><Button title={"Dismiss"} color="primary" size="sm" onClick={() => { this.fadeOutOverlay() }}>Dismiss</Button></div></div></div>;
          this.setState({
            overlayMessage: msg
          }, () => {
            this.fadeInOverlay();
          });
        });
    }
    
    // get current URL attributes (protocol, port, etc.)
    this.currentURL = document.createElement('a');
    this.currentURL.setAttribute('href', window.location.href);

    // is this site production or development?
    let sitePort = parseInt(this.currentURL.port);
    if (isNaN(sitePort)) sitePort = 443;
    this.isProductionSite = ((sitePort === "") || (sitePort === 443)); // || (sitePort !== 3000 && sitePort !== 3001));
    this.isProductionProxySite = (sitePort === Constants.applicationProductionProxyPort); // || (sitePort !== 3000 && sitePort !== 3001));
    // console.log("this.isProductionSite", this.isProductionSite);
    // console.log("this.isProductionProxySite", this.isProductionProxySite);

    // cache of ChromosomeInfo response
    this.chromInfoCache = {};
  }

  // pickExemplarAtRandom() {
  //   return {"chr" : "chrX", "start" : "48781554", "stop" : "48795308" }; // GATA1, for now
  // }

  debounce = (callback, wait, immediate = false) => {
    let timeout = null;
    return function() {
      const callNow = immediate && !timeout;
      const next = () => callback.apply(this, arguments);
      clearTimeout(timeout)
      timeout = setTimeout(next, wait);
      if (callNow) {
        next();
      }
    }
  }

  resize = (time) => {
    setTimeout(() => {
      this.updateViewportDimensions();
    }, time)
  }
  
  componentDidMount() {
    let self = this;
    this.resize(100);
    window.addEventListener("resize", () => {
      self.resize(0)
    });
    let supportsOrientationChange = "onorientationchange" in window, orientationEvent = supportsOrientationChange ? "orientationchange" : "resize";
    window.addEventListener(orientationEvent, () => {
      self.resize(0)
    });
    window.addEventListener("visibilitychange", () => {
      self.resize(500)
    });
    /* 
      Push the hgView refresh to a separate function to clear intervals between
      updates and reduce the chance of Chrome getting a memory leak that causes 
      the user to intervene and reload the page.
    */
    this.initHgViewRefresh();
  }
  
  componentDidUpdate() {}
  
  componentWillUnmount() {
    let supportsOrientationChange = "onorientationchange" in window, orientationEvent = supportsOrientationChange ? "orientationchange" : "resize";
    window.removeEventListener("resize", this.resize());
    if (orientationEvent === "orientationchange") window.removeEventListener("orientationchange", this.resize());
    window.removeEventListener("visibilitychange", this.resize());
  }
  
  initHgViewRefresh = () => {
    // console.log("this.initHgViewRefresh()");
    let self = this;    
    window.ref = window.setInterval(function() { 
      // console.log("attempting update...", document.hasFocus());
      if (self.state.hgViewconf && self.state.hgViewconf.views && self.state.hgViewLoopEnabled && document.hasFocus() && self.state.hgViewRefreshTimerActive && self.state.hgViewParentIsVisible) {
        self.updateHgViewWithRandomGene();
      } 
    }, this.state.hgViewParams.hgViewGeneSelectionTime);
  }
  
  reinitHgViewRefresh = () => {
    // console.log("this.reinitHgViewRefresh()");
    clearInterval(window.ref);
    this.updateHgViewWithRandomGene();
    this.initHgViewRefresh();
  }
  
  updateHgViewWithRandomGene = () => {
    // console.log("this.updateHgViewWithRandomGene()");
    const randomGene = this.hgRandomGene();
    axios.get(randomGene.url)
      .then((res) => {
        if (res.data.hits) {
          // console.log("(portal) res.data.hits", res.data.hits);
          // console.log("(portal) randomGene.name", randomGene.name);
          let match = res.data.hits[randomGene.name][0];
          if (!match) {
            return;
          }
          // console.log("(portal) match", res.data.hits[randomGene.name][0]);
          let chr = match["chrom"];
          let txStart = match["start"];
          let txEnd = match["stop"];
          let strand = match["strand"];
          let tssStart = (strand === "+") ? txStart : txEnd;
          let assembly = this.state.hgViewParams.genome;
          let chrLimit = parseInt(Constants.assemblyBounds[assembly][chr].ub);
          // let geneLength = parseInt(txEnd) - parseInt(txStart);
          // console.log("updateHgViewWithRandomGene - ", assembly, chr, txStart, txEnd, chrLimit, geneLength);
          // let padding = parseInt(Constants.defaultHgViewGenePaddingFraction * geneLength);
          let padding = Constants.defaultHgViewRegionPadding;
          // txStart = ((txStart - padding) > 0) ? (txStart - padding) : 0;
          // txEnd = ((txEnd + padding) < chrLimit) ? (txEnd + padding) : txEnd;
          txStart = ((tssStart - padding) > 0) ? (tssStart - padding) : 0;
          txEnd = ((tssStart + padding) < chrLimit) ? (tssStart + padding) : chrLimit;
          this.hgViewUpdatePosition(assembly, chr, txStart, txEnd, chr, txStart, txEnd, 0);
          clearInterval(window.ref);
          this.initHgViewRefresh();
          //setTimeout(() => { this.updateViewportDimensions(); }, 1000);
        }
      })
      .catch((err) => {
        throw String(`Error: ${err}`);
      });
  }
  
  updateViewportDimensions = () => {
    //console.log("updateViewportDimensions()");
    
    const angle = window.screen.orientation ? window.screen.orientation.angle : window.orientation;
    let orientationIsPortrait = (angle === 0) || (angle === 180);
    
    let windowOuterHeight = `${parseInt(window.outerHeight)}px`;
    let windowInnerHeight = `${parseInt(window.innerHeight)}px`;
    // let windowOuterWidth = `${parseInt(window.outerWidth)}px`;
    let windowInnerWidth = `${parseInt(window.innerWidth)}px`;
    let windowHeightDiff = parseInt(window.outerHeight) - parseInt(windowInnerHeight) + 'px'; 
    if (orientationIsPortrait) {
      if (parseInt(windowInnerWidth) > parseInt(windowInnerHeight)) {
        windowInnerHeight = `${parseInt(window.innerWidth)}px`;
        windowInnerWidth = `${parseInt(window.innerHeight)}px`;
        orientationIsPortrait = false;
      }
    }
    else {
      if (parseInt(windowInnerWidth) < parseInt(windowInnerHeight)) {
        windowInnerHeight = `${parseInt(window.innerWidth)}px`;
        windowInnerWidth = `${parseInt(window.innerHeight)}px`;
        orientationIsPortrait = true;
      }
    }
    // console.log(`angle ${angle} windowInnerWidth ${windowInnerWidth} windowOuterWidth ${windowOuterWidth} windowInnerHeight ${windowInnerHeight} windowOuterHeight ${windowOuterHeight} orientationIsPortrait ${orientationIsPortrait}`);

    const viewWidth = parseInt(windowInnerWidth);
    const viewHeight = (orientationIsPortrait && isMobile) ? parseInt(windowInnerHeight)/2 : (isMobile) ? parseInt(windowInnerHeight) : parseInt(windowOuterHeight) - parseInt(windowHeightDiff);
    const chromosomeTrackHeight = Constants.defaultChromosomeTrackHeight;
    const indexDHSTrackMaxRows = (orientationIsPortrait && isMobile) ? Constants.defaultIndexDHSHiglassTranscriptsMobileTrackPortraitMaxRows : (isMobile) ? Constants.defaultIndexDHSHiglassTranscriptsMobileTrackLandscapeMaxRows : Constants.defaultIndexDHSHiglassTranscriptsMobileTrackPortraitMaxRows;
    const indexDHSTrackHeight = (orientationIsPortrait && isMobile) ? Constants.defaultIndexDHSHiglassTranscriptsMobileTrackPortraitHeight : (isMobile) ? Constants.defaultIndexDHSHiglassTranscriptsMobileTrackLandscapeHeight : Constants.defaultIndexDHSHiglassTranscriptsMobileTrackPortraitHeight;
    const geneAnnotationTrackMaxRows = (orientationIsPortrait && isMobile) ? Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackPortraitMaxRows : (isMobile) ? Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackLandscapeMaxRows : Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackPortraitMaxRows;
    const geneAnnotationTrackHeight = (orientationIsPortrait && isMobile) ? Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackPortraitHeight : (isMobile) ? Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackLandscapeHeight : Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackPortraitHeight;
    const spacerTrackHeight = Constants.defaultSpacerMobileTrackHeight;
    // dynamic component track height
    const componentTrackHeight = viewHeight - chromosomeTrackHeight - indexDHSTrackHeight - geneAnnotationTrackHeight - spacerTrackHeight;
    // console.log(`componentTrackHeight ${componentTrackHeight}`);
    // console.log(`viewHeight ${viewHeight}`);
    // console.log(`chromosomeTrackHeight ${chromosomeTrackHeight}`);
    // console.log(`indexDHSTrackHeight ${indexDHSTrackHeight}`);
    // console.log(`geneAnnotationTrackHeight ${geneAnnotationTrackHeight}`);
    // console.log(`spacerTrackHeight ${spacerTrackHeight}`);
    // exemplar padding
    const regionPadding = Constants.defaultHgViewRegionPadding;

    function initializeViewerState(chromInfo, self) {
      const genome = self.state.hgViewParams.genome;
      const exemplar = self.hgRandomGene();
      axios.get(exemplar.url)
        .then((res) => {
          if (res.data.hits) {
            let match = res.data.hits[exemplar.name][0];
            if (!match) {
              match = {"chrom" : "chrX", "start" : "48781554", "stop" : "48795308", "strand" : "+" }; // GATA1
            }
            // console.log(`match ${JSON.stringify(match, null, 2)}`);
            let tssStart = (match.strand === "+") ? match.start : match.stop
            let absLeft = chromInfo.chrToAbs([match.chrom, parseInt(tssStart) - regionPadding]);
            let absRight = chromInfo.chrToAbs([match.chrom, parseInt(tssStart) + regionPadding]);
            const view = newHgViewconf.views[0];
            view.uid = uuid4();
            view.layout.i = view.uid;
            view.initialXDomain = [absLeft, absRight];
            view.initialYDomain = [absLeft, absRight];
            let topClone = update(view.tracks.top, {$push: []});
            let newTop = [];
            let newTopIdx = 0;
            // component stack delta track
            newTop[newTopIdx] = update(topClone[0], {
              server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
              name: {$set: "16ComponentStackedDelta"},
              type: {$set: "horizontal-stacked-delta-bar"},
              tilesetUid: {$set: "HlXADoAsQt6tTm-NkrhWgw"},
              uid: {$set: uuid4()},
              resolutions: {$set: [13107200, 6553600, 3276800, 1638400, 819200, 409600, 204800, 102400, 51200, 25600, 12800, 6400, 3200, 1600, 800, 400, 200]},
              width: {$set: viewWidth},
              height: {$set: componentTrackHeight},
              options: {
                //minHeight: {$set: 0},
                backgroundColor: {$set: "black"},
                colorScale: {$set: Constants.systemColorPalettesAsHex[genome]['16']},
                labelPosition: {$set: "topLeft"},
                labelTextOpacity: {$set: 0.0},
                labelBackgroundOpacity: {$set: 0.0},
                labelColor: {$set: "black"},
                valueScaling: {$set: "linear"},
                valueScaleMin: {$set: 0.0},
                valueScaleMax: {$set: 4.0},
                name: {$set: "16ComponentStackedDelta"},
                showMousePosition: {$set: true},
                colorbarPosition: {$set: null},
                trackBorderWidth: {$set: 0},
                trackBorderColor: {$set: "black"},
                barBorder: {$set: false},
                sortLargestOnTop: {$set: true},
                fillOpacityMin: {$set: 0.2},
                fillOpacityMax: {$set: 0.8},
              }
            });
            newTopIdx += 1;
            // chromosome track
            newTop[newTopIdx] = update(topClone[0], {
              server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
              name: {$set: "ChromosomeAxis"},
              type: {$set: "horizontal-chromosome-labels"},
              tilesetUid: {$set: "e7yehSFuSvWu0_9uEK1Apw"},
              uid: {$set: uuid4()},
              width: {$set: viewWidth},
              height: {$set: chromosomeTrackHeight},
              options: {
                backgroundColor: {$set: "white"},
                color: {$set: "#777777"},
                stroke: {$set: "#ffffff"},
                fontSize: {$set: 12},
                fontIsAligned: {$set: false},
                showMousePosition: {$set: true},
              }
            });
            newTopIdx += 1;
            // index DHS track
            newTop[newTopIdx] = update(topClone[0], {
              server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
              // server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
              height: {$set: indexDHSTrackHeight},
              type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
              tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
              uid: {$set: uuid4()},
              options: {
                minHeight: {$set: 0},
                backgroundColor: {$set: "white"},
                isBarPlotLike: {$set: true},
                itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                showMousePosition: {$set: true},
              }
            });
            // newTop[newTopIdx] = update(topClone[0], {
            //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
            //   name: {$set: "IndexDHS"},
            //   type: {$set: "horizontal-transcripts"},
            //   tilesetUid: {$set: "D5k7ajwfT9mzwbybaSS0VA"},
            //   uid: {$set: uuid4()},
            //   width: {$set: viewWidth},
            //   height: {$set: indexDHSTrackHeight},
            //   options: {
            //     fontSize: {$set: 11},
            //     labelColor: {$set: "black"},
            //     labelPosition: {$set: "hidden"},
            //     labelLeftMargin: {$set: 0},
            //     labelRightMargin: {$set: 0},
            //     labelTopMargin: {$set: 0},
            //     labelBottomMargin: {$set: 0},
            //     plusStrandColor: {$set: "blue"},
            //     minusStrandColor: {$set: "red"},
            //     trackBorderWidth: {$set: 0},
            //     trackBorderColor: {$set: "black"},
            //     showMousePosition: {$set: false},
            //     geneAnnotationHeight: {$set: 10},
            //     geneLabelPosition: {$set: "outside"},
            //     geneStrandSpacing: {$set: 4},
            //     name: {$set: "IndexDHS"},
            //     blockStyle: {$set: "boxplot"},
            //     showToggleTranscriptsButton: {$set: false},
            //     labelFontSize: {$set: 11},
            //     labelFontWeight: {$set: 500},
            //     maxTexts: {$set: 75},
            //     maxRows: {$set: indexDHSTrackMaxRows},
            //     minHeight: {$set: indexDHSTrackHeight},
            //     itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
            //     startCollapsed: {$set: false},
            //     trackMargin: {$set: {
            //       top: 0,
            //       bottom: 0,
            //       left: 0,
            //       right: 0
            //     }},
            //     transcriptHeight: {$set: 20},
            //     transcriptSpacing: {$set: 5},
            //     isVisible: {$set: true}
            //   }
            // });
            newTopIdx += 1;
            // gene annotation track
            const genesUUID = Constants.viewerHgViewconfGenomeAnnotationUUIDs[genome]['genes_fixed_bin'];
            newTop[newTopIdx] = update(topClone[0], {
              server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
              type: {$set: "gene-annotations"},
              height: {$set: geneAnnotationTrackHeight},
              tilesetUid: {$set: genesUUID},
              uid: {$set: uuid4()},
              options: {
                backgroundColor: {$set: "white"},
                plusStrandColor: {$set: "black"},
                minusStrandColor: {$set: "black"},
                showMousePosition: {$set: true},
              }
            });
            // newTop[newTopIdx] = update(topClone[0], {
            //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
            //   name: {$set: "GeneAnnotations"},
            //   type: {$set: "horizontal-transcripts"},
            //   tilesetUid: {$set: "KPNgc7HhS4ilhcv0Cr95vg"},
            //   uid: {$set: uuid4()},
            //   width: {$set: viewWidth},
            //   height: {$set: geneAnnotationTrackHeight},
            //   options: {
            //     fontSize: {$set: 11},
            //     labelColor: {$set: "black"},
            //     labelPosition: {$set: "hidden"},
            //     labelLeftMargin: {$set: 0},
            //     labelRightMargin: {$set: 0},
            //     labelTopMargin: {$set: 0},
            //     labelBottomMargin: {$set: 0},
            //     plusStrandColor: {$set: "#666666"},
            //     minusStrandColor: {$set: "#666666"},
            //     utrColor: {$set: "#afafaf"},
            //     trackBorderWidth: {$set: 0},
            //     trackBorderColor: {$set: "black"},
            //     showMousePosition: {$set: false},
            //     geneAnnotationHeight: {$set: 10},
            //     geneLabelPosition: {$set: "outside"},
            //     geneStrandSpacing: {$set: 4},
            //     name: {$set: "GeneAnnotations"},
            //     blockStyle: {$set: "directional"},
            //     showToggleTranscriptsButton: {$set: false},
            //     maxRows: {$set: geneAnnotationTrackMaxRows},
            //     minHeight: {$set: geneAnnotationTrackHeight},
            //     startCollapsed: {$set: false},
            //     trackMargin: {$set: {
            //       top: 0,
            //       bottom: 0,
            //       left: 0,
            //       right: 0
            //     }},
            //     transcriptHeight: {$set: 12},
            //     transcriptSpacing: {$set: 5},
            //     isVisible: {$set: true}
            //   }
            // });
            newTopIdx += 1;
            // spacer track
            newTop[newTopIdx] = update(topClone[0], {
              server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
              name: {$set: "Spacer"},
              type: {$set: "empty"},
              uid: {$set: uuid4()},
              width: {$set: viewWidth},
              height: {$set: spacerTrackHeight},
              options: {
                backgroundColor: {$set: "white"}
              }
            });
            newTopIdx += 1;
            view.tracks.top = newTop;
            self.setState({
              hgViewHeight: viewHeight,
              hgViewKey: self.state.hgViewKey + 1,
              hgViewconf: newHgViewconf
            });
          }
        })
      .catch((err) => {
        throw String(`initializeViewerState error: ${err}`);
      })
      .finally(() => {
        
      });
    }
    
    function updateViewerState(chromInfo, self) {
      const view = newHgViewconf.views[0];
      const componentTrack = view.tracks.top[0];
      componentTrack.width = viewWidth;
      componentTrack.height = componentTrackHeight;
      const indexDHSTrack = view.tracks.top[2];
      indexDHSTrack.height = indexDHSTrackHeight;
      indexDHSTrack.options.maxRows = indexDHSTrackMaxRows;
      indexDHSTrack.options.minHeight = indexDHSTrackHeight;
      const geneAnnotationTrack = view.tracks.top[3];
      geneAnnotationTrack.height = geneAnnotationTrackHeight;
      geneAnnotationTrack.options.maxRows = geneAnnotationTrackMaxRows;
      geneAnnotationTrack.options.minHeight = geneAnnotationTrackHeight;
      self.setState({
        hgViewHeight: viewHeight,
        hgViewKey: self.state.hgViewKey + 1,
        hgViewconf: newHgViewconf
      });
    }

    let newHgViewconf = this.state.hgViewconf;
    const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, this.state.hgViewParams.genome);
    if (chromInfoCacheExists) {
      if (!newHgViewconf) {
        newHgViewconf = JSON.parse(JSON.stringify(Constants.hgViewconfTemplate));
        initializeViewerState(this.chromInfoCache[this.state.hgViewParams.genome], this);
      }
      else {
        updateViewerState(this.chromInfoCache[this.state.hgViewParams.genome], this);
      }
    }
    else {
      let chromSizesURL = Helpers.getChromSizesURL(this.state.hgViewParams.genome, this);
      ChromosomeInfo(chromSizesURL)
        .then((chromInfo) => {
          this.chromInfoCache[this.state.hgViewParams.genome] = Object.assign({}, chromInfo);
          if (!newHgViewconf) {
            newHgViewconf = JSON.parse(JSON.stringify(Constants.hgViewconfTemplate));
            initializeViewerState(chromInfo, this);
            //console.log(`newHgViewconf ${JSON.stringify(newHgViewconf, null, 2)}`)
          }
          else {
            updateViewerState(chromInfo, this);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          throw new Error(`Could not retrieve chromosome information ${chromSizesURL}`);
        });
    }

    //console.log(`orientationIsPortrait ${orientationIsPortrait} (W ${windowInnerWidth} H ${windowInnerHeight})`);
    if (isMobile) {
      // portalHeaderRef
      this.portalHeaderRef.style.position = "absolute";
      this.portalHeaderRef.style.left = `calc(50vw - 150px)`;
      this.portalHeaderRef.style.top = (orientationIsPortrait) ? "calc(8vh)" : "calc(5vh)";
      this.portalHeaderRef.style.zIndex = 1;
      // portalHgViewRef
      this.portalHgViewRef.style.position = "absolute";
      this.portalHgViewRef.style.top = (orientationIsPortrait) ? `${parseInt(parseInt(windowInnerHeight) / 2) + 'px'}` : "10px";
      this.portalHgViewRef.style.zIndex = 0;
    }
    else {
      // console.log(`desktop`);
      // portalHeaderRef
      this.portalHeaderRef.style.position = "absolute";
      this.portalHeaderRef.style.left = "calc(50vw - 150px)";
      this.portalHeaderRef.style.top = "calc(25vh)";
      this.portalHeaderRef.style.zIndex = 1;
      // portalHgViewRef
      this.portalHgViewRef.style.position = "absolute";
      this.portalHgViewRef.style.top = "10px";//`${parseInt(parseInt(windowOuterHeight) / 2) + 'px'}`;
      //this.portalHgViewRef.style.bottom = `${parseInt(windowOuterHeight) + 'px'}`;
      this.portalHgViewRef.style.zIndex = 0;
    }
    
    // let epilogosContentQuery = document.getElementById("epilogos-content-query-parent");
    // let epilogosContentQueryHeight = epilogosContentQuery; //(parseInt(windowInnerHeight) > parseInt(windowInnerWidth)) ? windowInnerHeight : windowInnerWidth;

    this.setState({
      previousHeight: this.state.height,
      previousWidth: this.state.width,
    }, () => {
      this.setState({
        height: windowInnerHeight,
        width: windowInnerWidth,
        orientationIsPortrait: orientationIsPortrait,
      })
    });
  }

  hgRandomGene = () => {
    const gene = this.state.genes[Math.floor(Math.random() * this.state.genes.length)];
    const annotationUrl = Constants.annotationScheme + "://" + Constants.annotationHost + ":" + Constants.annotationPort + "/sets?q=" + gene + "&assembly=" + this.state.hgViewParams.genome;
    return {
      'name': gene, 
      'url': annotationUrl
    };
  }
  
  hgViewUpdatePosition = (genome, chrLeft, startLeft, stopLeft, chrRight, startRight, stopRight, padding) => {
    let chromSizesURL = this.state.hgViewParams.hgGenomeURLs[genome];
    if (this.currentURL.port === "" || parseInt(this.currentURL.port) !== 3000) {
      chromSizesURL = chromSizesURL.replace(":3000", "");
    }
    ChromosomeInfo(chromSizesURL)
      .then((chromInfo) => {
        if (padding === 0) {
          //console.log("hgViewUpdatePosition - ", build, chrLeft, startLeft, stopLeft, chrRight, startRight, stopRight, padding);
          this.hgView.zoomTo(
            this.state.hgViewconf.views[0].uid,
            chromInfo.chrToAbs([chrLeft, startLeft]),
            chromInfo.chrToAbs([chrLeft, stopLeft]),
            chromInfo.chrToAbs([chrRight, startRight]),
            chromInfo.chrToAbs([chrRight, stopRight]),
            this.state.hgViewParams.hgViewAnimationTime
          );
        }
        else {
          var midpointLeft = startLeft + parseInt((stopLeft - startLeft)/2);
          var midpointRight = startRight + parseInt((stopRight - startRight)/2);
          this.hgView.zoomTo(
            this.state.hgViewconf.views[0].uid,
            chromInfo.chrToAbs([chrLeft, parseInt(midpointLeft - padding)]),
            chromInfo.chrToAbs([chrLeft, parseInt(midpointLeft + padding)]),
            chromInfo.chrToAbs([chrRight, parseInt(midpointRight - padding)]),
            chromInfo.chrToAbs([chrRight, parseInt(midpointRight + padding)]),
            this.state.hgViewParams.hgViewAnimationTime
          );
        }
      })
      .catch((err) => {
        throw String(`Error: ${err}`);
      });
  }
  
  hgViewconfDownloadURL = (url, id) => { 
    return url + this.state.hgViewParams.hgViewconfEndpointURLSuffix + id; 
  }
  
  exemplarDownloadURL = (assembly, model, complexity, group) => {
    let downloadURL = this.stripQueryStringAndHashFromPath(document.location.href) + "/assets/epilogos/" + assembly + "/" + model + "/" + group + "/" + complexity + "/exemplar/top100.txt";
    return downloadURL;
  }
  
  singleGroupGenomeMenu = () => {
    return (
      <Dropdown name="singleGroupGenomeMenu" className="epilogos-content-dropdown-menu epilogos-content-ero-block" size="sm" isOpen={this.state.singleGroupGenomeDropdownOpen} toggle={()=>{ 
        this.setState(prevState => ({ singleGroupGenomeDropdownOpen: !prevState.singleGroupGenomeDropdownOpen }));
      }}>
        <DropdownToggle caret>
          {this.state.singleGroupGenomeDropdownSelection}
        </DropdownToggle>
        <DropdownMenu> 
          {this.singleGroupGenomeMenuItems()}
        </DropdownMenu> 
      </Dropdown>)
  }
    
  singleGroupGenomeMenuItems = () => {
    let ks = Object.keys(Constants.genomes);
    ks.sort((a, b) => {
      var auc = a[1].toUpperCase();
      var buc = b[1].toUpperCase();
      return (auc < buc) ? -1 : (auc > buc) ? 1 : 0;
    });
    let self = this;
    return ks.map((s) => {
      return <DropdownItem key={s} value={s} onClick={(e)=>{ 
        //console.log("singleGroupGenomeMenuItems e.target.value", e.target.value);
        self.setState({
          singleGroupGenomeDropdownSelection: e.target.value,
          singleGroupDropdownSelection: Constants.defaultSingleGroupKeys[e.target.value]
        })
      }}>{Constants.genomes[s]}</DropdownItem>;
    });
  }
  
  singleGroupMenu = () => {
    return (
      <Dropdown name="singleGroupMenu" className="epilogos-content-dropdown-menu epilogos-content-ero-block" size="sm" isOpen={this.state.singleGroupDropdownOpen} toggle={()=>{ 
        this.setState(prevState => ({ singleGroupDropdownOpen: !prevState.singleGroupDropdownOpen }));
      }}>
        <DropdownToggle caret>
          {this.singleGroupMenuItemSelectionToText(this.state.singleGroupDropdownSelection)}
        </DropdownToggle>
        <DropdownMenu> 
          {this.singleGroupMenuItems()}
        </DropdownMenu> 
      </Dropdown>)
  }
    
  singleGroupMenuItems = () => {
    let md = Constants.groupsByGenome[this.state.singleGroupGenomeDropdownSelection];
    let singles = jp.query(md, '$..[?(@.subtype=="single")]');
    let toObj = (ks, vs) => ks.reduce((o,k,i)=> {o[k] = vs[i]; return o;}, {});
    let groupItems = toObj(jp.query(singles, "$..value"), jp.query(singles, "$..text"));
    let ks = Object.keys(groupItems);
    let self = this;
    return ks.map((s) => {
      return <DropdownItem key={s} value={s} onClick={(e)=>{ 
        //console.log("singleGroupMenuItems e.target.value", e.target.value);
        self.setState({
          singleGroupDropdownSelection: e.target.value
        })
      }}>{groupItems[s]}</DropdownItem>;
    });
  }
  
  singleGroupMenuItemSelectionToText = (s) => {
    let md = Constants.groupsByGenome[this.state.singleGroupGenomeDropdownSelection];
    let singles = jp.query(md, '$..[?(@.subtype=="single")]');
    let toObj = (ks, vs) => ks.reduce((o,k,i)=> {o[k] = vs[i]; return o;}, {});
    let groupItems = toObj(jp.query(singles, "$..value"), jp.query(singles, "$..text"));
    return groupItems[s];
  }
  
  singleGroupSearchInput = (p) => {
    return (
      <div className="epilogos-content-ero-block">
        <InputGroup className="epilogos-content-search-input-group">
          <Input innerRef={(input) => this.singleGroupSearchInputComponent = input} placeholder={p} className="epilogos-content-search-input js-focus-visible" size="sm" onChange={(e) => {
            this.setState({ singleGroupSearchInputValue: e.target.value }, function() {/*console.log("singleGroupSearchInputValue", this.state.singleGroupSearchInputValue)*/})
          }} />
        </InputGroup>
      </div>);
  }
  
  // singleGroupJump = () => {
  //   return (
  //     <div className="epilogos-content-ero-block">
  //       <Button color="primary" className="btn-custom btn-sm" onClick={this.onClickPortalGo} disabled={!this.state.goJumpActive} title="Jump to the specified gene or genomic interval">Go</Button>
  //     </div>
  //   );
  // }
  
  // singleGroupExemplarJump = () => {
  //   return (
  //     <div className="epilogos-content-ero-block">
  //       <Button color="primary" className="btn-custom btn-sm" onClick={this.onClickPortalIFL} disabled={!this.state.exemplarJumpActive} title="Jump to an interesting Index DHS">{Constants.portalExemplarJumpButtonLabel}</Button>
  //     </div>
  //   );
  // }
  
  onClick = (event) => { 
    event.preventDefault();
    if (event.currentTarget.dataset.id) {
      window.open(event.currentTarget.dataset.id, event.currentTarget.dataset.target || "_blank");
    }
  }
  
  onClickScrollOffscreenContentIntoView = () => {
    //
    // We temporarily disable the hgView visibility sensor as it
    // will otherwise interrupt scrolling the HIW section into view.
    // It is reenabled after a reasonable time, i.e. once the scroll
    // event is finished.
    //
    this.setState({ hgViewParentVisibilitySensorIsActive: false }, 
      () => {
/*
        this.refs.offscreenContent.scrollIntoView({
          behavior: 'smooth',
          block: 'start', 
          inline: 'nearest'
        });
*/
        var element = document.getElementById("epilogos-content-hiw-peek-parent");
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start', 
          inline: 'nearest'
        });
        
        setTimeout(() => {
          this.setState({ hgViewParentVisibilitySensorIsActive: true });
        }, 500)
      })
  }
  
  // onClickScrollOffscreenToolsContentIntoView = (event) => {
  //   this.offscreenToolsContent.scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'start', 
  //     inline: 'nearest'
  //   });
  // }
  
  onDoubleClickHgView = (event) => {
    event.stopImmediatePropagation();
  }
  
  onMouseUpHgViewParent = () => { /*console.log("onMouseUpHgViewParent!");*/ }
  
  onClickHgViewParent = (event) => {
    //
    // The parent div of the hgView subscribes to click events, which we can handle here. 
    //
    // We do this because the HiGlass view container does not make single-click events available 
    // for subscription. 
    //
    // A future version of their React component may enable this, but for now we must handle this 
    // in our own parent container.
    //
    // As another complication, three click events are fired in serial when a single-click
    // occurs on the HiGlass container parent. According to one developer via Slack this is 
    // due to how the child hgView handles those events, on its own.
    //
    // We have two choices:
    //
    // 1. Handle the first click event immediately, ignoring a double-click event on the HiGlass
    //    container. We jump immediately to the viewer, with the current domain. Or:
    //
    // 2. Preserve the ability to handle double-click events on the HiGlass container, staying
    //    in the portal unless a single-click event occurs.
    //
    // In the case of option 2, I measure the time delta between certain consecutive 
    // single-click events.
    //
    // If that time is greater than a constant threshold, I trigger loading a new page at
    // the coordinates of the x-position of the mouse, at the time of the single click.
    //
    // This allows the HiGlass container to continue to handle click-and-drag and double-click 
    // events, while giving me control over the single-click event.
    //
    let pageX = event.pageX;
    //
    // On review with Wouter, for now, we select option 1. If option 2 is useful down the road, we 
    // just remove or comment out this block, including the return statement.
    //
    // Option 1
    //
    this.setState(() => ({
      hgViewClickPageX: pageX
    }), () => {
      this.onClickHgViewParentClickImmediate();
    });
    return;
    //
    // Option 2
    //
/*
    let currentTime = performance.now();
    let clickInstance = (this.state.hgViewClickInstance + 1) % 3;
    switch (clickInstance) {
      case 0:
        this.setState((prevState) => ({
          hgViewClickPageX: pageX,
          hgViewClickTimePrevious: prevState.hgViewClickTimeCurrent,
          hgViewClickTimeCurrent: currentTime,
          hgViewClickInstance: clickInstance
        }), () => {
*/
          // 
          // I start a timer that tests the delta of previous and current click 
          // timestamps, when that timer expires.
          //
          // If (when that timer expires) the delta is less than some threshold,
          // then I treat the event as a double-click. Otherwise, it is treated
          // as a single-click event and that behavior is triggered.
          //
/*
          this.setState({
            hgViewClickTimer: setTimeout(() => { this.onClickHgViewParentClickDeltaTest(); }, Constants.applicationPortalClickDeltaTimer)
          });
        });
        break;
      case 1:
      case 2:
        // we do not adjust the time settings, but we do increment the click instance        
        this.setState({
          hgViewClickInstance: clickInstance
        });
        break;
      default:
        break;
    }
*/
  }
  
  onClickHgViewParentClickImmediate = () => {
    const uid = this.state.hgViewconf.views[0].uid;
    const absLocation = this.hgView.api.getLocation(uid);
    const absLocationXDomain = absLocation.xDomain;
    // let chromSizesURL = this.state.hgViewParams.hgGenomeURLs[this.state.hgViewParams.genome];
    // if (this.currentURL.port === "" || parseInt(this.currentURL.port !== 3000)) {
    //   chromSizesURL = chromSizesURL.replace(":3000", "");
    // }

    function openViewerAtVisibleRegion(chromInfo, absLocationXDomain, self) {
      const chrStartPos = chromInfo.absToChr(absLocationXDomain[0]);
      const chrStopPos = chromInfo.absToChr(absLocationXDomain[1]);
      const chrLeft = chrStartPos[0];
      const start = chrStartPos[1];
      const stop = chrStopPos[1];
      const chrRange = [chrLeft, start, stop];
      self.openViewerAtChrRange(chrRange);
    }

    const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, this.state.hgViewParams.genome);
    if (chromInfoCacheExists) {
      openViewerAtVisibleRegion(this.chromInfoCache[this.state.hgViewParams.genome], absLocationXDomain, this);
    }
    else {
      let chromSizesURL = Helpers.getChromSizesURL(this.state.hgViewParams.genome, this);
      ChromosomeInfo(chromSizesURL)
        .then((chromInfo) => {
          this.chromInfoCache[this.state.hgViewParams.genome] = Object.assign({}, chromInfo);
          openViewerAtVisibleRegion(chromInfo, absLocationXDomain, this);
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          throw new Error(`Could not retrieve chromosome information ${chromSizesURL}`);
        });
    }
  }
  
  onClickHgViewParentClickDeltaTest = () => {
    let hgViewClickTimeDelta = this.state.hgViewClickTimeCurrent - this.state.hgViewClickTimePrevious;
    if ((this.state.hgViewClickTimePrevious === Constants.defaultHgViewClickTimePrevious) || (hgViewClickTimeDelta >= Constants.applicationPortalClickDeltaThreshold)) {
      let uid = this.state.hgViewconf.views[0].uid;
      let absLocation = this.hgView.api.getLocation(uid);
      let absLocationXDomain = absLocation.xDomain;
      let windowWidthFraction = this.state.hgViewClickPageX / window.innerWidth;
      let absLocationXDomainByWindowWidthFraction = absLocationXDomain[0] + (absLocationXDomain[1] - absLocationXDomain[0]) * windowWidthFraction;
      let chromSizesURL = this.state.hgViewParams.hgGenomeURLs[this.state.hgViewParams.genome];
      if (this.currentURL.port === "" || parseInt(this.currentURL.port !== 3000)) {
        chromSizesURL = chromSizesURL.replace(":3000", "");
      }
      ChromosomeInfo(chromSizesURL)
        .then((chromInfo) => {
          let chrPosition = chromInfo.absToChr(absLocationXDomainByWindowWidthFraction);
          this.openViewerAtChrPosition(chrPosition, this.state.hgViewParams.paddingMidpoint);
        })
        .catch((err) => {
          throw String(`Error: Portal.onClickHgViewParentClickDeltaTest() failed to translate absolute coordinates to chromosomal coordinates: ${err}`);
        })
    }
  }
  
  stripQueryStringAndHashFromPath = (url) => { return url.split("?")[0].split("#")[0]; }
  
  openViewerAtChrRange = (range) => {
    let chrLeft = range[0];
    let chrRight = range[0];
    let start = parseInt(range[1]);
    let stop = parseInt(range[2]);
    if (range.length === 4) {
      chrLeft = range[0];
      chrRight = range[1];
      start = parseInt(range[2]);
      stop = parseInt(range[3]);
    }
    let viewerUrl = this.stripQueryStringAndHashFromPath(document.location.href) + "?application=viewer";
    viewerUrl += "&mode=" + Constants.portalHgViewParameters.mode;
/*
    viewerUrl += "&genome=" + Constants.portalHgViewParameters.genome;
    viewerUrl += "&model=" + Constants.portalHgViewParameters.model;
    viewerUrl += "&complexity=" + Constants.portalHgViewParameters.complexity;
    viewerUrl += "&group=" + Constants.portalHgViewParameters.group;
*/
    viewerUrl += "&chrLeft=" + chrLeft;
    viewerUrl += "&chrRight=" + chrRight;
    viewerUrl += "&start=" + start;
    viewerUrl += "&stop=" + stop;
    // viewerUrl += "&dtt=" + Constants.defaultApplicationDttThreshold;
    // viewerUrl += "&itt=" + Constants.defaultApplicationIttCategory;
    // console.log(`viewerUrl ${viewerUrl}`);
    window.location.href = viewerUrl;
  }
  
  openViewerAtChrPosition = (pos, padding) => {
    let chrLeft = pos[0];
    let chrRight = pos[0];
    let start = parseInt(pos[1]) - padding;
    let stop = parseInt(pos[1]) + padding;
    let viewerUrl = this.stripQueryStringAndHashFromPath(document.location.href) + "?application=viewer";
    viewerUrl += "&mode=" + Constants.portalHgViewParameters.mode;
/*
    viewerUrl += "&genome=" + Constants.portalHgViewParameters.genome;
    viewerUrl += "&model=" + Constants.portalHgViewParameters.model;
    viewerUrl += "&complexity=" + Constants.portalHgViewParameters.complexity;
    viewerUrl += "&group=" + Constants.portalHgViewParameters.group;
*/
    viewerUrl += "&chrLeft=" + chrLeft;
    viewerUrl += "&chrRight=" + chrRight;
    viewerUrl += "&start=" + start;
    viewerUrl += "&stop=" + stop;
    
    // viewerUrl += "&dtt=" + Constants.defaultApplicationDttThreshold;
    // viewerUrl += "&itt=" + Constants.defaultApplicationIttCategory;

    window.location.href = viewerUrl;
  }
  
  onMouseEnterHgViewParent = () => {
    document.body.style.overflow = "hidden";
    this.setState({
      hgViewRefreshTimerActive: false
    });
  }
  
  onMouseLeaveHgViewParent = () => {
    document.body.style.overflow = "auto";
    this.setState({
      hgViewRefreshTimerActive: true
    });
  }
  
  onChangeHgViewParentVisibility = (isVisible) => {
    this.setState({
      hgViewParentIsVisible: isVisible
    });
  }
  
  onChangePortalInput = (value) => {
    // console.log("onChangePortalInput", value);
    const exemplarJumpActive = (value.length === 0);
    const goJumpActive = (value.length >= Constants.applicationAutocompleteInputMinimumLength);
    // console.log(`goJumpActive ${goJumpActive} exemplarJumpActive ${exemplarJumpActive}`);
    this.setState({
      singleGroupSearchInputValue: value,
      exemplarJumpActive: exemplarJumpActive,
      goJumpActive: goJumpActive
    }, () => {
      // console.log(`singleGroupSearchInputValue ${this.state.singleGroupSearchInputValue}`);
    });
  }
  
  onClickPortalGo = () => {
    // console.log("onClickPortalGo");
    let indexDHSIdentifierTest = /^([XY0-9]{1,2}.[0-9]{1,})$/.test(this.state.singleGroupSearchInputValue);
    if (!indexDHSIdentifierTest) {
      const range = Helpers.getRangeFromString(this.state.singleGroupSearchInputValue, false, false, this.state.hgViewParams.genome);
      // console.log(`range ${range}`); 
      if (range) {
        this.openViewerAtChrRange(range);
      }
    }
    else {
      const mapUrl = Constants.mapIndexDHSScheme + "://" + Constants.mapIndexDHSHost + ":" + Constants.mapIndexDHSPort + "/annotation?set=" + Constants.mapIndexDHSSetName + "&identifier=" + this.state.singleGroupSearchInputValue.trim();
      axios.get(mapUrl)
        .then((res) => {
          if (res.data.data) {
            // console.log(`res.data.hits ${JSON.stringify(res.data.hits)}`);
            const hit = res.data.data;
            const indexDHSLocation = `${hit.seqname}:${hit.start - Constants.defaultHgViewIndexDHSPadding}-${hit.end + Constants.defaultHgViewIndexDHSPadding}`;
            // console.log(`indexDHSLocation ${indexDHSLocation}`);
            const indexDHSRange = Helpers.getRangeFromString(indexDHSLocation, false, false, this.state.hgViewParams.genome);
            if (indexDHSRange) {
              // console.log(`indexDHSRange ${indexDHSRange}`);
              this.openViewerAtChrRange(indexDHSRange);
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          // do nothing
        });
    }
  }
  
  onClickPortalIFL = () => {
    //console.log("onClickPortalIFL");
    let range = this.getRandomRangeFromExemplarRegions();
    if (range) {
      this.openViewerAtChrRange(range);
    }
  }
  
  getRandomRangeFromExemplarRegions = () => {
    let randomRegion = this.state.exemplarRegions[this.state.exemplarRegions.length * Math.random() | 0];
    let regionFields = randomRegion.split('\t');
    let chr = regionFields[0];
    let start = parseInt(regionFields[1].replace(',',''));
    let stop = parseInt(regionFields[2].replace(',',''));
    let strand = regionFields[5];
    if (!Helpers.isValidChromosome(this.state.hgViewParams.genome, chr)) {
      return null;
    }
    //let padding = parseInt(Constants.defaultHgViewRegionPadding);
    //let range = [chrLeft, start - padding, stop + padding];
    let range = [chr, start - (strand === "+" ? Constants.defaultHgViewRegionUpstreamPadding : Constants.defaultHgViewRegionDownstreamPadding), stop + (strand === "+" ? Constants.defaultHgViewRegionDownstreamPadding : Constants.defaultHgViewRegionUpstreamPadding)];
    return range;
  }
  
  // getRangeFromString = (str) => {
  //   /*
  //     Test if the new location passes as a chrN:X-Y pattern, 
  //     where "chrN" is an allowed chromosome name, and X and Y 
  //     are integers, and X < Y. 
      
  //     We allow chromosome positions X and Y to contain commas, 
  //     to allow cut-and-paste from the UCSC genome browser.
  //   */
  //   let matches = str.replace(/,/g, '').split(/[:-\s\t]/g).filter( i => i );
  //   if (matches.length !== 3) {
  //     //console.log("matches failed", matches);
  //     return;
  //   }
  //   //console.log("matches", matches);
  //   let chrom = matches[0];
  //   let start = parseInt(matches[1].replace(',',''));
  //   let stop = parseInt(matches[2].replace(',',''));
  //   //console.log("chrom, start, stop", chrom, start, stop);
  //   if (!this.isValidChromosome(this.state.hgViewParams.genome, chrom)) {
  //     return null;
  //   }
  //   let padding = parseInt(Constants.defaultHgViewGenePaddingFraction * (stop - start));
  //   let assembly = this.state.hgViewParams.genome;
  //   let chrLimit = parseInt(Constants.assemblyBounds[assembly][chrom].ub);
  //   start = ((start - padding) > 0) ? (start - padding) : 0;
  //   stop = ((stop + padding) < chrLimit) ? (stop + padding) : stop;
  //   let range = [chrom, chrom, start, stop];
  //   //console.log("range", range);
  //   return range;
  // }
  
  onChangePortalLocation = (location, applyPadding) => {
    // console.log("onChangePortalLocation", location);
    let range = Helpers.getRangeFromString(location, applyPadding, false, this.state.hgViewParams.genome);
    if (range) {
      this.openViewerAtChrRange(range);
    }
  }
  
  // isValidChromosome(assembly, chromosomeName) {
  //   let chromosomeBounds = Constants.assemblyBounds[assembly];
  //   if (!chromosomeBounds) {
  //     return false; // bad or unknown assembly
  //   }
  //   let chromosomeNames = Object.keys(chromosomeBounds);
  //   if (!chromosomeNames) {
  //     return false; // no chromosomes? that would be weird
  //   }
  //   let chromosomeNamesContainsNameOfInterest = (chromosomeNames.indexOf(chromosomeName) > -1);
  //   return chromosomeNamesContainsNameOfInterest;
  // }
  
  portalOverlayNotice = () => {
    return <div>{this.state.overlayMessage}</div>
  }
  
  fadeOutOverlay = (cb) => {
    this.epilogosPortalContainerOverlay.style.opacity = 0;
    this.epilogosPortalContainerOverlay.style.transition = "opacity 0.5s 0.5s";
    setTimeout(() => {
      this.epilogosPortalContainerOverlay.style.pointerEvents = "none";
      if (cb) { cb(); }
    }, 500);
  }
  
  fadeInOverlay = (cb) => {
    this.epilogosPortalContainerOverlay.style.opacity = 1;
    this.epilogosPortalContainerOverlay.style.transition = "opacity 0.5s 0.5s";
    this.epilogosPortalContainerOverlay.style.pointerEvents = "auto";
    setTimeout(() => {
      if (cb) { cb(); }
    }, 500);
  }
    
  render() {

    const devNonce = (this.isProductionSite) ? "" : <div style={{fontSize:"12px", margin:"0", padding:"0", textAlign:"center", color:"grey"}}>development</div>
    
    return (
      
      <div className="index-content-parent">
        <div className="index-content-header-parent" id="index-content-header-parent" ref={(component) => this.portalHeaderRef = component}>
          {devNonce}
          <div className="epilogos-content-header text-center" style={{"userSelect":"text"}} onClick={() => { this.reinitHgViewRefresh() }}>
            index
          </div>          
          <div className="epilogos-content-query-autocomplete" id="epilogos-content-query-autocomplete" style={{"userSelect":"text"}}>              
            <div className="epilogos-content-placeholder-text epilogos-content-ero-search">
              <div className="epilogos-content-ero-search">
                <Autocomplete
                  title="Search for a gene of interest or jump to a genomic interval"
                  className="epilogos-content-search-input"
                  placeholder={this.state.singleGroupSearchInputPlaceholder}
                  annotationScheme={Constants.annotationScheme}
                  annotationHost={Constants.annotationHost}
                  annotationPort={Constants.annotationPort}
                  annotationAssemblyRaw={this.state.hgViewParams.genome}
                  annotationAssembly={`${this.state.hgViewParams.genome}_GENCODE_v38`}
                  mapIndexDHSScheme={Constants.mapIndexDHSScheme}
                  mapIndexDHSHost={Constants.mapIndexDHSHost}
                  mapIndexDHSPort={Constants.mapIndexDHSPort}
                  mapIndexDHSSetName={Constants.mapIndexDHSSetName}
                  onChangeLocation={this.onChangePortalLocation}
                  onChangeInput={this.onChangePortalInput}
                  suggestionsClassName="portal-suggestions suggestions"
                />
                <p />
                <div className="epilogos-content-ero-block">
                  <Button color="primary" className="btn-custom btn-sm" onClick={this.onClickPortalGo} disabled={!this.state.goJumpActive} title="Jump to the specified gene or genomic interval">Go</Button>
                </div> 
                {" "}
                <div className="epilogos-content-ero-block">
                  <Button color="primary" className="btn-custom btn-sm" onClick={this.onClickPortalIFL} disabled={(this.state.singleGroupSearchInputValue.length > 0)} title="Jump to an interesting Index DHS">{Constants.portalExemplarJumpButtonLabel}</Button>
                </div>
                <div className="epilogos-content-ero-search epilogos-content-ero-search-text">
                  <em>e.g.</em>, use query terms like HGNC symbols (<strong>HOXA1</strong>, <strong>NFKB1</strong>, etc.) or genomic regions (<strong>chr17:41155790-41317987</strong>, etc.)
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div className="portal-higlass-content" ref={(component) => this.portalHgViewRef = component} style={{"height": this.state.hgViewHeight}} onClick={this.onClickHgViewParent}>
        {(this.state.hgViewconf) ? <HiGlassComponent
          key={this.state.hgViewKey}
          ref={(component) => this.hgView = component}
          options={{ 
            bounded: true,
            pixelPreciseMarginPadding: false,
            containerPaddingX: 0,
            containerPaddingY: 0,
            viewMarginTop: 0,
            viewMarginBottom: 0,
            viewMarginLeft: 0,
            viewMarginRight: 0,
            viewPaddingTop: 0,
            viewPaddingBottom: 0,
            viewPaddingLeft: 0,
            viewPaddingRight: 0
          }}
          viewConfig={this.state.hgViewconf}
          /> : ""}
        </div>
      </div>
    );
  }
}

export default Portal;

Portal.propTypes = { 
  height: PropTypes.string,
  width: PropTypes.string,
};