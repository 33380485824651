import React, { Component } from "react";

// Application constants and helpers
import * as Constants from "../Constants.js";
import * as Helpers from "../Helpers.js";

import {
  Navbar,
  NavbarBrand,
  NavItem,
  Nav,
} from "reactstrap";

import axios from "axios";

// higlass
// cf. https://www.npmjs.com/package/higlass
import "higlass/dist/hglib.css";
import { 
  HiGlassComponent, 
  ChromosomeInfo 
} from "higlass";

// Application autocomplete
import Autocomplete from "./Autocomplete/Autocomplete";

// Icons
import { FaBars, FaTimes } from 'react-icons/fa';

// Drawer content
import DrawerContent from "./DrawerContent";
import { slide as Drawer } from 'react-burger-menu';

// Deep clone an object 
import update from 'immutability-helper';

// Validate strings (URLs etc.)
import validator from 'validator';

// Generate UUIDs
export const uuid4 = require("uuid4");

// -------------------------------------------------------------------------------------------------------------------

class ViewerMobile extends Component {
  constructor(props) {
    
    super(props);
    
    this.state = {
      width: "0px",
      height: "0px",
      showUpdateNotice: false,
      hideDrawerOverlay: true,
      drawerWidth: Constants.defaultMinimumDrawerWidth,
      drawerHeight: 0,
      drawerIsOpen: false,
      drawerTitle: "Title",
      drawerContentKey: 0,
      drawerSelection: Constants.defaultDrawerType,
      drawerActiveTabOnOpen: null,
      advancedOptionsVisible: false,
      activeTab: null,
      hgViewKey: 0,
      hgViewconf: {},
      hgViewHeight: Constants.viewerHgViewParameters.hgViewTrackEpilogosHeight + Constants.viewerHgViewParameters.hgViewTrackChromatinMarksHeight + Constants.viewerHgViewParameters.hgViewTrackChromosomeHeight + Constants.viewerHgViewParameters.hgViewTrackGeneAnnotationsHeight + Constants.viewerHgViewParameters.epilogosHeaderNavbarMobileDeviceHeight,
      hgViewParams: Constants.viewerHgViewParameters,
      tempHgViewParams: {...Constants.viewerHgViewParameters},
      trackLabelsVisible: false,
      selectedNonRoiRowIdxOnLoad: -1,
      proteinCodingsEnabled: false,
      proteinCodingsJumpActive: false,
      proteinCodingsRegions: [],
      proteinCodingsTableData: [],
      proteinCodingsTableDataCopy: [],
      proteinCodingsTableDataIdxBySort: [],
      proteinCodingsSystems: [],
      selectedProteinCodingsRowIdx: -1,
      selectedProteinCodingsChrLeft: "",
      selectedProteinCodingsChrRight: "",
      selectedProteinCodingsStart: -1,
      selectedProteinCodingsStop: -1,
      selectedProteinCodingsBeingUpdated: false,
      transcriptionFactorsEnabled: false,
      transcriptionFactorsJumpActive: false,
      transcriptionFactorsRegions: [],
      transcriptionFactorsTableData: [],
      transcriptionFactorsTableDataCopy: [],
      transcriptionFactorsTableDataIdxBySort: [],
      transcriptionFactorsSystems: [],
      selectedTranscriptionFactorsRowIdx: -1,
      selectedTranscriptionFactorsChrLeft: "",
      selectedTranscriptionFactorsChrRight: "",
      selectedTranscriptionFactorsStart: -1,
      selectedTranscriptionFactorsStop: -1,
      selectedTranscriptionFactorsBeingUpdated: false,
      overlayVisible: false,
      showOverlayNotice: false,
      overlayMessage: "Placeholder",
      roiEnabled: false,
      roiJumpActive: false,
      roiRegions: [],
      roiTableData: [],
      roiTableDataCopy: [],
      roiTableDataIdxBySort: [],
      roiTableDataLongestNameLength: Constants.defaultRoiTableDataLongestNameLength,
      roiTableDataLongestAllowedNameLength: Constants.defaultRoiTableDataLongestAllowedNameLength,
      roiEncodedSet: "",
      roiRawSet: "",
      roiEncodedURL: "",
      roiRawURL: "",
      roiMode: Constants.defaultApplicationRoiMode,
      roiPaddingFractional: Constants.defaultApplicationRoiPaddingFraction,
      roiPaddingAbsolute: Constants.defaultApplicationRoiPaddingAbsolute,
      roiMaxColumns: 0,
      selectedRoiRowIdxOnLoad: Constants.defaultApplicationSrrIdx,
      selectedRoiRowIdx: Constants.defaultApplicationSrrIdx,
      selectedRoiChrLeft: "",
      selectedRoiChrRight: "",
      selectedRoiStart: -1,
      selectedRoiStop: -1,
      selectedRoiBeingUpdated: false,
      searchInputLocationBeingChanged: false,
      autocompleteIsVisible: false,
      orientationIsPortrait: true,
      epilogosViewerDataAvailableSpace: 0,
      maxAutocompleteSuggestionHeight: 0,
      currentPosition: { chrLeft: "", chrRight: "", startLeft: 0, stopLeft: 0, startRight: 0, stopRight: 0 },
      currentPositionKey: Math.random(),
      currentViewScale: -1,
      previousViewScale: -1,
      currentViewScaleAsString: "",
      chromsAreIdentical: false,
      regionIndicatorData: {},
      regionIndicatorOuterWidth: 0,
      highlightEncodedRows: "",
      highlightRawRows: [],
      highlightBehavior: "",
      highlightBehaviorAlpha: 0.0,
      indexDHSTrackHeight: Constants.defaultIndexDHSHiglassTranscriptsMobileTrackPortraitHeight,
      searchInputText: null,
    };

    this.updateViewerHistory = this.debounce((viewerUrlStr) => {
      // console.log(`updateViewerHistory - ${viewerUrlStr}`);
      const previousUrlStr = window.history.state;
      const previousUrlQuery = previousUrlStr && Helpers.getJsonFromSpecifiedUrl(previousUrlStr);
      const currentUrlQuery = Helpers.getJsonFromSpecifiedUrl(viewerUrlStr);
      //
      // allow up to ten bases of slippage
      //
      const baseSlippage = 10;
      const previousCurrentDiffWithinBounds = (previousUrlQuery) ? 
        (previousUrlQuery.mode === this.state.hgViewParams.mode) && 
        // (previousUrlQuery.genome === this.state.hgViewParams.genome) && 
        // (previousUrlQuery.model === this.state.hgViewParams.model) && 
        // (previousUrlQuery.complexity === this.state.hgViewParams.complexity) && 
        // (previousUrlQuery.group === this.state.hgViewParams.group) && 
        // (previousUrlQuery.sampleSet === this.state.hgViewParams.sampleSet) && 
        (previousUrlQuery.chrLeft === this.state.currentPosition.chrLeft) && 
        (previousUrlQuery.chrRight === this.state.currentPosition.chrRight) && 
        (Math.abs(this.state.currentPosition.startLeft - previousUrlQuery.start) < baseSlippage) && (Math.abs(this.state.currentPosition.stopRight - previousUrlQuery.stop) < baseSlippage) 
        : 
        false;
        const exemplarRowSelectionChanged = (currentUrlQuery.serIdx && (currentUrlQuery.serIdx !== Constants.defaultApplicationSerIdx)) || (previousUrlQuery && previousUrlQuery.serIdx && (previousUrlQuery.serIdx !== currentUrlQuery.serIdx) && (currentUrlQuery.serIdx !== Constants.defaultApplicationSerIdx));
        const roiRowSelectionChanged = ((previousUrlQuery) && (!previousUrlQuery.srrIdx) && ((currentUrlQuery.srrIdx) && (currentUrlQuery.srrIdx !== Constants.defaultApplicationSrrIdx))) || ((previousUrlQuery) && (previousUrlQuery.srrIdx) && (currentUrlQuery.srrIdx) && (previousUrlQuery.srrIdx !== currentUrlQuery.srrIdx) && (currentUrlQuery.srrIdx !== Constants.defaultApplicationSrrIdx)); 
      if (!previousUrlStr || !previousCurrentDiffWithinBounds || roiRowSelectionChanged || exemplarRowSelectionChanged) {
        let stub = "";
        if (this.state.searchInputText) {
          stub = `- ${this.state.searchInputText}`;
        }
        else if ((this.state.currentPosition.chrLeft === this.state.currentPosition.chrRight) && (this.state.currentPosition.startLeft !== this.state.currentPosition.stopRight)) {
          stub = `- ${this.state.currentPosition.chrLeft}:${this.state.currentPosition.startLeft}-${this.state.currentPosition.stopRight}`;
        }
        else if (this.state.currentPosition.chrLeft !== this.state.currentPosition.chrRight) {
          stub = `- ${this.state.currentPosition.chrLeft}:${this.state.currentPosition.startLeft}-${this.state.currentPosition.chrRight}:${this.state.currentPosition.stopRight}`;
        }
        const historyTitle = `Altius Index ${stub}`;
        // some versions of window.history.pushState do not provide title update functionality
        window.history.pushState(viewerUrlStr, historyTitle, viewerUrlStr);
        document.title = historyTitle;
        
      }
    }, Constants.defaultViewerHistoryChangeEventDebounceTimeout);

    // cache of ChromosomeInfo response
    this.chromInfoCache = {};
    
    // references
    this.hgView = React.createRef();
    this.epilogosViewerHamburgerButton = React.createRef();
    this.epilogosViewerContainerParent = React.createRef();
    this.epilogosViewerTrackLabelParent = React.createRef();
    this.epilogosViewerBrandText = React.createRef();
    
    // timeout for location change
    this.viewerLocationChangeEventTimer = null;
    this.viewerZoomPastExtentTimer = null;
    this.viewerHistoryChangeEventTimer = null;
    
    // get current URL attributes (protocol, port, etc.)
    this.currentURL = document.createElement('a');
    this.currentURL.setAttribute('href', window.location.href);
    
    // is this site production or development?
    let sitePort = parseInt(this.currentURL.port);
    if (isNaN(sitePort)) sitePort = 443;
    this.isProductionSite = ((sitePort === "") || (sitePort === 443)); // || (sitePort !== 3000 && sitePort !== 3001));
    this.isProductionProxySite = (sitePort === Constants.applicationProductionProxyPort); // || (sitePort !== 3000 && sitePort !== 3001));
    // console.log("this.isProductionSite", this.isProductionSite);
    // console.log("this.isProductionProxySite", this.isProductionProxySite);
    
    // setup
    const queryObj = Helpers.getJsonFromUrl();
    let newTempHgViewParams = {...this.state.tempHgViewParams};
    newTempHgViewParams.genome = queryObj.genome || Constants.defaultApplicationGenome;
    newTempHgViewParams.model = queryObj.model || Constants.defaultApplicationModel;
    newTempHgViewParams.complexity = queryObj.complexity || Constants.defaultApplicationComplexity;
    newTempHgViewParams.group = queryObj.group || Constants.defaultApplicationGroup;
    newTempHgViewParams.chrLeft = queryObj.chrLeft || Constants.defaultApplicationChr;
    newTempHgViewParams.chrRight = queryObj.chrRight || Constants.defaultApplicationChr;
    newTempHgViewParams.start = parseInt(queryObj.start || Constants.defaultApplicationStart);
    newTempHgViewParams.stop = parseInt(queryObj.stop || Constants.defaultApplicationStop);
    newTempHgViewParams.mode = queryObj.mode || Constants.defaultApplicationMode;
    newTempHgViewParams.itt = queryObj.itt || Constants.defaultApplicationIttCategory;
    //newTempHgViewParams.numSamples = queryObj.numSamples || Constants.defaultApplicationNumSamples;
    //newTempHgViewParams.dtt = parseInt(queryObj.dtt || Constants.defaultApplicationDttThreshold);
    //newTempHgViewParams.dtc = queryObj.dtc || Constants.defaultApplicationDtcCategory;
    
    newTempHgViewParams.gmax = queryObj.gmax || Constants.defaultApplicationSignalGlobalMax;

    this.state.selectedExemplarRowIdx = queryObj.serIdx || Constants.defaultApplicationSerIdx;
    this.state.selectedRoiRowIdx = queryObj.srrIdx || Constants.defaultApplicationSrrIdx;
    this.state.selectedRoiRowIdxOnLoad = queryObj.srrIdx || Constants.defaultApplicationSrrIdx;
    this.state.roiMode = queryObj.roiMode || Constants.defaultApplicationRoiMode;
    this.state.roiPaddingFractional = queryObj.roiPaddingFractional || Constants.defaultApplicationRoiPaddingFraction;
    this.state.roiPaddingAbsolute = queryObj.roiPaddingAbsolute || Constants.defaultApplicationRoiPaddingAbsolute;
    this.state.drawerActiveTabOnOpen = queryObj.activeTab || Constants.defaultDrawerTabOnOpen;
    
    if (Number.isNaN(newTempHgViewParams.start) || Number.isNaN(newTempHgViewParams.stop)) {
      newTempHgViewParams.chrLeft = Constants.defaultApplicationChr;
      newTempHgViewParams.chrRight = Constants.defaultApplicationChr;
      newTempHgViewParams.start = Constants.defaultApplicationStart;
      newTempHgViewParams.stop = Constants.defaultApplicationStop;
    }
/*
    if (newTempHgViewParams.numSamples === "undefined") {
      newTempHgViewParams.numSamples = Constants.defaultApplicationNumSamples;
    }
*/
    this.state.currentPosition = {
      chrLeft : newTempHgViewParams.chrLeft,
      chrRight : newTempHgViewParams.chrRight,
      startLeft : newTempHgViewParams.start,
      startRight : newTempHgViewParams.start,
      stopLeft : newTempHgViewParams.stop,
      stopRight : newTempHgViewParams.stop
    };
    if (newTempHgViewParams.start === newTempHgViewParams.stop) {
      //console.log("Coordinates are identical!")
      newTempHgViewParams.chrLeft = Constants.defaultApplicationPositions[newTempHgViewParams.genome].chr;
      newTempHgViewParams.chrRight = Constants.defaultApplicationPositions[newTempHgViewParams.genome].chr;
      newTempHgViewParams.start = Constants.defaultApplicationPositions[newTempHgViewParams.genome].start;
      newTempHgViewParams.stop = Constants.defaultApplicationPositions[newTempHgViewParams.genome].stop;
      //console.log("calling [updateViewerURL] from [constructor]");
      this.state.currentPosition = {
        chrLeft : newTempHgViewParams.chrLeft,
        chrRight : newTempHgViewParams.chrRight,
        startLeft : newTempHgViewParams.start,
        startRight : newTempHgViewParams.start,
        stopLeft : newTempHgViewParams.stop,
        stopRight : newTempHgViewParams.stop
      };
    }
    this.updateViewerURL(newTempHgViewParams.mode,
                         this.state.currentPosition.chrLeft,
                         this.state.currentPosition.chrRight,
                         this.state.currentPosition.startLeft,
                         this.state.currentPosition.stopRight,
                         newTempHgViewParams.numSamples);
    
    function updateWithRoisInMemory(self) {
      //console.log("ROI table data updated!");
      const queryObj = Helpers.getJsonFromUrl();
      //console.log("queryObj", JSON.stringify(queryObj, null, 2));
      const firstROI = self.state.roiTableData[0];
      //console.log("firstROI", JSON.stringify(firstROI, null, 2));
      const intervalPaddingFraction = (queryObj.roiPaddingFractional) ? parseFloat(queryObj.roiPaddingFractional) : Constants.defaultApplicationRoiPaddingFraction;
      //console.log("intervalPaddingFraction", intervalPaddingAbsolute);
      const intervalPaddingAbsolute = (queryObj.roiSet) ? Constants.defaultApplicationRoiSetPaddingAbsolute : ((queryObj.roiPaddingAbsolute) ? parseInt(queryObj.roiPaddingAbsolute) : Constants.defaultApplicationRoiPaddingAbsolute);
      //console.log("intervalPaddingAbsolute", intervalPaddingAbsolute);
      if (queryObj.roiSet && !queryObj.roiPaddingAbsolute) {
        self.state.roiPaddingAbsolute = Constants.defaultApplicationRoiSetPaddingAbsolute;
      }
      const rowIndex = (self.state.selectedRoiRowIdxOnLoad !== Constants.defaultApplicationSrrIdx) ? self.state.selectedRoiRowIdxOnLoad : 1;
      const roiStart = parseInt(firstROI.chromStart);
      const roiStop = parseInt(firstROI.chromEnd);
      let roiPadding = (queryObj.roiPaddingFractional) ? parseInt(intervalPaddingFraction * (roiStop - roiStart)) : intervalPaddingAbsolute;
      //console.log("roiPadding", roiPadding);
      newTempHgViewParams.chrLeft = firstROI.chrom;
      newTempHgViewParams.chrRight = firstROI.chrom;
      newTempHgViewParams.start = roiStart - roiPadding;
      newTempHgViewParams.stop = roiStop + roiPadding;
      let scale = Helpers.calculateScale(newTempHgViewParams.chrLeft, newTempHgViewParams.chrRight, newTempHgViewParams.start, newTempHgViewParams.stop, self);
      self.state.previousViewScale = scale.diff;
      self.state.currentViewScale = scale.diff;
      self.state.currentViewScaleAsString = scale.scaleAsStr;
      self.state.chromsAreIdentical = scale.chromsAreIdentical;
      self.state.hgViewParams = newTempHgViewParams;
      self.state.tempHgViewParams = newTempHgViewParams;
      self.state.currentPosition.chrLeft = firstROI.chrom;
      self.state.currentPosition.chrRight = firstROI.chrom;
      self.state.currentPosition.startLeft = roiStart - roiPadding;
      self.state.currentPosition.stopLeft = roiStop + roiPadding;
      self.state.currentPosition.startRight = roiStart - roiPadding;
      self.state.currentPosition.stopRight = roiStop + roiPadding;
      self.triggerUpdate("update");
      setTimeout(() => {
        self.setState({
          drawerIsOpen: true
        }, () => {
          self.jumpToRegion(firstROI.position, Constants.applicationRegionTypes.roi, rowIndex, firstROI.strand);
        });
      }, 2000); 
    }
    
    function updateWithDefaults(self) {
      const scale = Helpers.calculateScale(newTempHgViewParams.chrLeft, newTempHgViewParams.chrRight, newTempHgViewParams.start, newTempHgViewParams.stop, self);
      self.state.previousViewScale = scale.diff;
      self.state.currentViewScale = scale.diff;
      self.state.currentViewScaleAsString = scale.scaleAsStr;
      self.state.chromsAreIdentical = scale.chromsAreIdentical;
      self.state.hgViewParams = newTempHgViewParams;
      self.state.tempHgViewParams = newTempHgViewParams;
      self.triggerUpdate("update");
      if (self.state.activeTab !== null) {
        setTimeout(() => {
          self.setState({
            drawerIsOpen: true,
            drawerActiveTabOnOpen: self.state.activeTab,
            drawerContentKey: self.state.drawerContentKey + 1,
          }, () => {
            const nonROIRowIndex = self.state.selectedNonRoiRowIdxOnLoad;
            const nonROIRegion = (self.state.activeTab === "proteinCodings") ? self.state.proteinCodingsTableData[(nonROIRowIndex - 1)] : self.state.transcriptionFactorsTableData[(nonROIRowIndex - 1)];
            const nonROIRegionType = (self.state.activeTab === "proteinCodings") ? Constants.applicationRegionTypes.proteinCodings : Constants.applicationRegionTypes.transcriptionFactors;
            setTimeout(() => {
              // console.log(`${nonROIRegion.position}, ${nonROIRegionType}, ${nonROIRowIndex}, ${nonROIRegion.strand}`);
              self.jumpToRegion(nonROIRegion.position, nonROIRegionType, nonROIRowIndex, nonROIRegion.strand);
            }, 0);
          });
        }, 2000);
      }
    }
    
    setTimeout(() => { 
      this.updateTranscriptionFactors(newTempHgViewParams.genome);
      this.updateProteinCodings(newTempHgViewParams.genome);
    }, 0);  
    
    //
    // If the roiSet parameter is defined, we check if there is a string defining intervals
    // for the url-decoded key.
    //
    if (queryObj.roiSet) {
      this.state.roiEncodedSet = queryObj.roiSet;
      this.state.roiRawSet = decodeURIComponent(this.state.roiEncodedSet);
      if (this.state.roiRawSet in Constants.roiSets) {
        // console.log("queryObj.roiSet (decoded)", this.state.roiRawSet);
        // const updateViaConstructor = true;
        this.roiRegionsUpdate(Constants.roiSets[this.state.roiRawSet], updateWithRoisInMemory, this);
      }
      else {
        //console.log("queryObj.roiSet (decoded) not in Constants.roiSets", this.state.roiRawSet);
        updateWithDefaults(this);
      }
    }
    //
    // If the roiURL parameter is defined, we fire a callback once the URL is loaded 
    // to get the first row, to set position before the HiGlass container is rendered
    //
    else if (queryObj.roiURL) {
      setTimeout(() => {
        // console.log("queryObj.roiURL", queryObj.roiURL);
        this.updateRois(queryObj.roiURL, updateWithRoisInMemory);
      }, 0);
    }
    else {
      if ((typeof queryObj.spcIdx !== "undefined") && (queryObj.spcIdx !== Constants.defaultApplicationSerIdx)) {
        this.state.selectedNonRoiRowIdxOnLoad = parseInt(queryObj.spcIdx);
        this.state.activeTab = "proteinCodings";
      }
      else if ((typeof queryObj.stfIdx !== "undefined") && (queryObj.stfIdx !== Constants.defaultApplicationSerIdx)) {
        this.state.selectedNonRoiRowIdxOnLoad = parseInt(queryObj.stfIdx);
        this.state.activeTab = "transcriptionFactors";
      }
      updateWithDefaults(this);
    }

    this.updateViewerIndexDHSTrackHeight = this.debounce((newHeight, trackObj) => {
      this.setState({
        indexDHSTrackHeight: newHeight,
      }, () => {
        console.log(`indexDHSTrackObj trackDimensionsModified event sent ${this.state.indexDHSTrackHeight}px`);
        const updateViewconfTrackHeights = true;
        this.updateViewportDimensions(updateViewconfTrackHeights);
        trackObj.pubSub.unsubscribe("trackDimensionsModified");
      });
    }, 1500);
  }
  
  componentDidMount() {
    let self = this;
    let supportsOrientationChange = "onorientationchange" in window, orientationEvent = supportsOrientationChange ? "orientationchange" : "resize";
    window.addEventListener(orientationEvent, function() {
      //document.body.style.width = window.innerWidth;
      setTimeout(() => {
        self.updateViewportDimensions();
        setTimeout(() => {
          self.updateViewportDimensions();
          self.updateScale();
        }, 1000);
      }, 0);
    });
    window.addEventListener("visibilitychange", function() {
      //console.log("visibilitychange called...");
      setTimeout(() => {
        self.updateViewportDimensions();
        setTimeout(() => {
          self.updateViewportDimensions();
          self.updateScale();
        }, 1000);
      }, 0);
    });
    setTimeout(() => {
      this.updateViewportDimensions();
    }, 500);
  }
  
  componentWillUnmount() {}

  debounce = (callback, wait, immediate = false) => {
    let timeout = null;
    return function() {
      const callNow = immediate && !timeout;
      const next = () => callback.apply(this, arguments);
      clearTimeout(timeout)
      timeout = setTimeout(next, wait);
      if (callNow) {
        next();
      }
    }
  }
  
  // eslint-disable-next-line no-unused-vars
  updateViewportDimensions = (updateViewconfTrackHeights) => {
    const angle = window.screen.orientation ? window.screen.orientation.angle : window.orientation;
    let orientationIsPortrait = (angle === 0) || (angle === 180);
    let windowInnerHeight = `${parseInt(window.innerHeight)}px`;
    let windowInnerWidth = `${parseInt(window.innerWidth)}px`;
    if (orientationIsPortrait) {
      if (parseInt(windowInnerWidth) > parseInt(windowInnerHeight)) {
        windowInnerHeight = `${parseInt(window.innerWidth)}px`;
        windowInnerWidth = `${parseInt(window.innerHeight)}px`;
        orientationIsPortrait = false;
      }
    }
    else {
      if (parseInt(windowInnerWidth) < parseInt(windowInnerHeight)) {
        windowInnerHeight = `${parseInt(window.innerWidth)}px`;
        windowInnerWidth = `${parseInt(window.innerHeight)}px`;
        orientationIsPortrait = true;
      }
    }
    // console.log(`orientationIsPortrait ${orientationIsPortrait} (W ${windowInnerWidth} H ${windowInnerHeight})`);
    
    let hamburgerButton = document.getElementById("epilogos-viewer-hamburger-button");
    let hamburgerButtonOffsetWidth = (hamburgerButton) ? hamburgerButton.offsetWidth : 0;
    let epilogosViewerHeaderNavbarHamburgerFullWidth = (parseInt(hamburgerButtonOffsetWidth) + 15) + "px";
    
    let brand = document.getElementById("epilogos-viewer-brand");
    let brandOffsetWidth = (brand) ? brand.offsetWidth : 0;
    let epilogosViewerHeaderNavbarBrandFullWidth = (parseInt(brandOffsetWidth) + 15) + "px";
    
    let rightHalfNavWidth = document.getElementById("navbar-righthalf").offsetWidth;

    let epilogosViewerHeaderNavbarAvailableSpace = parseInt(windowInnerWidth) - parseInt(epilogosViewerHeaderNavbarHamburgerFullWidth) - parseInt(epilogosViewerHeaderNavbarBrandFullWidth) - parseInt(rightHalfNavWidth);
    
    // console.log(`epilogosViewerHeaderNavbarAvailableSpace ${epilogosViewerHeaderNavbarAvailableSpace} => ${windowInnerWidth} - ${epilogosViewerHeaderNavbarHamburgerFullWidth} - ${epilogosViewerHeaderNavbarBrandFullWidth} - ${rightHalfNavWidth}`);
    
    let autocompleteIsVisible = (epilogosViewerHeaderNavbarAvailableSpace > 220);
    
    let epilogosViewerHeaderNavbarHeight = "38px";
    let epilogosViewerDataAvailableSpace = (parseInt(windowInnerHeight) - parseInt(epilogosViewerHeaderNavbarHeight)) + "px";
    //console.log(`epilogosViewerDataAvailableSpace ${epilogosViewerDataAvailableSpace} => ${windowInnerHeight} - ${epilogosViewerHeaderNavbarHeight}`);
    
    let maxAutocompleteSuggestionHeight = parseInt(epilogosViewerDataAvailableSpace) - 10;
    
    // customize track heights -- requires preknowledge of track order
    // if (!this.state.hgViewconf.views) return;
    // let deepCopyHgViewconf = null;
    // if (updateViewconfTrackHeights) {
    //   deepCopyHgViewconf = JSON.parse(JSON.stringify(this.state.hgViewconf));
    //   let itt = this.state.hgViewParams.itt;
    //   let mode = this.state.hgViewParams.mode;
    //   let newHgViewTrackIndexDHSHeight = this.state.indexDHSTrackHeight;
    //   let newHgViewTrackChromosomeHeight = Constants.defaultChromosomeTrackHeight;
    //   let newHgViewTrackGeneAnnotationsHeight = this.state.hgViewParams.hgViewTrackGeneAnnotationsHeight;

    //   if (mode == "pd") {
    //     if (itt == "ht") {
    //       newHgViewTrackIndexDHSHeight = this.state.indexDHSTrackHeight;
    //       newHgViewTrackGeneAnnotationsHeight = Constants.defaultGeneAnnotationsHiglassTranscriptsTrackHeight;
    //     }
    //     else {
    //       newHgViewTrackIndexDHSHeight = Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight;
    //       newHgViewTrackGeneAnnotationsHeight = this.state.hgViewParams.hgViewTrackGeneAnnotationsHeight;
    //     }
    //     console.log(`old windowInnerHeight ${parseInt(windowInnerHeight)}`);
    //     deepCopyHgViewconf.views[0].tracks.top.forEach((t, ti) => {
    //       console.log(`old ${ti} ${t.height}`);
    //     })
    //     let pdAvailableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(epilogosViewerHeaderNavbarHeight) + "px";
    //     deepCopyHgViewconf.views[0].tracks.top[0].height = parseInt(pdAvailableWindowInnerHeight);
    //     // deepCopyHgViewconf.views[0].tracks.top[1].height = parseInt(newHgViewTrackChromosomeHeight);
    //     // deepCopyHgViewconf.views[0].tracks.top[2].height = parseInt(newHgViewTrackIndexDHSHeight);
    //     // deepCopyHgViewconf.views[0].tracks.top[3].height = parseInt(newHgViewTrackGeneAnnotationsHeight);
    //     deepCopyHgViewconf.views[0].tracks.top.forEach((t, ti) => {
    //       console.log(`new ${ti} ${t.height}`);
    //     });
    //     console.log(`new windowInnerHeight ${parseInt(windowInnerHeight)}`);
    //     // updatedState.hgViewconf = deepCopyHgViewconf;
    //     //this.hgView.api.setViewConfig(deepCopyHgViewconf, true);
    //   }
    // }

    this.setState({
      width: windowInnerWidth,
      height: windowInnerHeight,
      autocompleteIsVisible: autocompleteIsVisible,
      orientationIsPortrait: orientationIsPortrait,
      epilogosViewerDataAvailableSpace: epilogosViewerDataAvailableSpace,
      maxAutocompleteSuggestionHeight: maxAutocompleteSuggestionHeight
    }, () => {
      let searchParent = document.getElementById("epilogos-viewer-search-input-parent");
      let searchParentOffsetWidth = (searchParent) ? searchParent.offsetWidth : 0;
      let drawerWidth = parseInt(windowInnerWidth) - parseInt(epilogosViewerHeaderNavbarAvailableSpace) + parseInt(searchParentOffsetWidth) + "px";
      if (parseInt(drawerWidth) < Constants.defaultMinimumDrawerWidth) drawerWidth = Constants.defaultMinimumDrawerWidth;
      let drawerHeight = parseInt(epilogosViewerDataAvailableSpace) + "px";
      // if ROI table width is wider, use it, instead
      let roiTableWidth = 0;
      let isDrawerWidthWider = false;
      if (document.getElementById("drawer-content-roi-table")) {
        roiTableWidth = parseInt(document.getElementById("drawer-content-roi-table").offsetWidth);
        //console.log("roiTableWidth", roiTableWidth);
        if (roiTableWidth > parseInt(drawerWidth)) {
          drawerWidth = (roiTableWidth + 50) + "px";
        }
      }
      //console.log(`drawerWidth ${drawerWidth} X drawerHeight ${drawerHeight}`);
      this.setState({
        drawerWidth: drawerWidth,
        drawerHeight: drawerHeight,
        drawerContentKey: this.state.drawerContentKey + (isDrawerWidthWider ? 1 : 0),
      }, () => {
        this.triggerUpdate("update");
        // if (deepCopyHgViewconf) {
        //   this.setState({
        //     hgViewconf: deepCopyHgViewconf
        //   }, () => {
        //     this.triggerUpdate("update");
        //   })
        // }
        // else {
        //   this.triggerUpdate("update");
        // }
      })
    });
  }
  
  changeViewParams = (isDirty, tempHgViewParams) => {
    //let hideOverlay = !isDirty; /* false = overlay; true = hide overlay */
    //console.log("tempHgViewParams", tempHgViewParams);
    this.setState({
      tempHgViewParams: {...tempHgViewParams}
    }, () => {
      if (isDirty) {
        this.triggerUpdate("update");
      }
    });
  }
  
  updateActiveTab = (newTab) => {
    this.setState({
      drawerActiveTabOnOpen: newTab
    });
  }
  
  onClick = (event) => { 
    if (event.currentTarget.dataset.id) {
      event.preventDefault();
      let target = event.currentTarget.dataset.target || "_blank";
      window.open(event.currentTarget.dataset.id, target);
    }
  }
  
    roiRawURL = (param) => {
    return decodeURIComponent(param);
  }

  roiRegionsUpdate = (data, cb, self) => {
    //console.log("roiRegionsUpdate", data);
    // regions represent raw lines from the incoming data
    // table data represent processed lines from regions, organized into fields
    const dataRegions = data.split(/\r\n|\r|\n/);
    // we set up a template object to hold a row of BED6 data (with placeholders)
    const roiTableRow = {
      'idx' : 0,
      'chrom' : '.',
      'chromStart' : 0,
      'chromEnd' : 0,
      'name' : '.',
      'score' : 0.0,
      'strand' : '.',
      'element' : {
        'position' : '.',
        'paddedPosition' : '.'
      }
    };
    let roiTableRows = [];
    let roiTableRowsCopy = [];
    let roiTableRowsIdxBySort = [];
    //
    // input should contain chromosomes that match the selected genome
    //
    const validChroms = Object.keys(Constants.assemblyBounds[this.state.hgViewParams.genome]);
    //
    // it is possible that the lines will not contain BED data, or will not have all fields
    // we validate input to try to ensure that the ROI drawer content will not contain garbage regions
    //
    function isNormalInteger(str) {
      return /^\+?(0|[1-9]\d*)$/.test(str);
    }    
    let newRoiMaxColumns = this.state.roiMaxColumns;
    let newRoiTableDataLongestNameLength = this.state.roiTableDataLongestNameLength;
    let lineCount = 0;
    //
    // parse data
    //
    for (const line of dataRegions) {
      //console.log("line", line);
      if (line.length === 0) { continue; }
      lineCount += 1;
      // we only add up to maximum number of elements
      if (lineCount > Constants.defaultApplicationRoiLineLimit) break;
      const elems = line.split(/\t/);
      let columns = elems.length;
      //      
      // not enough columns to make up a minimal BED file
      //
      if (columns < 3) {
        //console.log(`Input regions are missing columns (line ${lineCount})`);
        const err = {
          "response" : {
            "status" : 400,
            "statusText" : "Malformed input"
          }
        };
        const msg = this.errorMessage(err, `Input regions are missing columns (line ${lineCount})`, this.state.roiRawURL);
        this.setState({
          overlayMessage: msg
        }, () => {
          this.fadeInOverlay();
        });      
        return;
      }
      //
      // if the line does not have start or stop coordinates, then we send an error and return early
      //
      if (!isNormalInteger(elems[1]) || !isNormalInteger(elems[2])) {
        //console.log(`Input regions have non-coordinate data (line ${lineCount})`);
        const err = {
          "response" : {
            "status" : 400,
            "statusText" : "Malformed input"
          }
        };
        const msg = this.errorMessage(err, `Input regions have non-coordinate data (line ${lineCount})`, this.state.roiRawURL);
        this.setState({
          overlayMessage: msg
        }, () => {
          this.fadeInOverlay();
        });       
        return;
      }
      //
      // if the first element in elems is not a valid chromosome name from selected genome, report error and return early
      //
      if (!validChroms.includes(elems[0])) {
        // console.log(`Input regions have bad chromosome names (line ${lineCount})`);
        const err = {
          "response" : {
            "status" : 400,
            "statusText" : "Malformed input"
          }
        };
        const msg = this.errorMessage(err, `Input regions have bad chromosome names (line ${lineCount})`, this.state.roiRawURL);
        this.setState({
          overlayMessage: msg
        }, () => {
          this.fadeInOverlay();
        }); 
        return;
      }
      //
      // update maximum number of columns
      //
      if (columns > newRoiMaxColumns) {
        newRoiMaxColumns = columns;
      }
      //
      // clone a row object from template
      //
      const row = Object.assign({}, roiTableRow);
      //
      // populate row with content from elems
      //
      row.idx = lineCount;
      row.chrom = elems[0];
      row.chromStart = parseInt(elems[1]);
      row.chromEnd = parseInt(elems[2]);
      row.position = row.chrom + ':' + row.chromStart + '-' + row.chromEnd;
      //
      let paddedPosition = Helpers.zeroPad(row.chrom.replace(/chr/, ''), 3) + ':' + Helpers.zeroPad(row.chromStart, 12) + '-' + Helpers.zeroPad(row.chromEnd, 12);
      if (isNaN(row.chrom.replace(/chr/, ''))) {
        paddedPosition = row.chrom.replace(/chr/, '') + ':' + Helpers.zeroPad(row.chromStart, 12) + '-' + Helpers.zeroPad(row.chromEnd, 12);
      }
      //
      row.paddedPosition = paddedPosition;
      row.element = {
        "position" : row.position.slice(),
        "paddedPosition" : row.paddedPosition.slice()
      };
      row.name = (columns > 3) ? elems[3] : '.';
      row.score = (columns > 4) ? elems[4] : 0.0;
      row.strand = (columns > 5) ? elems[5] : '.';
      // console.log("row", row);
      //
      // add row object to table data array
      //
      roiTableRows.push(row);     
      roiTableRowsCopy.push(row);
      roiTableRowsIdxBySort.push(row.idx);
      //
      //
      //
      if (row.name.length > newRoiTableDataLongestNameLength) newRoiTableDataLongestNameLength = row.name.length; 
    }
    //
    // update state
    //
    //console.log("roiTableRows", roiTableRows);
    if (self) {
      self.state.roiEnabled = true;
      self.state.roiRegions = dataRegions;
      self.state.roiTableData = roiTableRows;
      self.state.roiTableDataCopy = roiTableRowsCopy;
      self.state.roiTableDataIdxBySort = roiTableRowsIdxBySort;
      self.state.roiMaxColumns = newRoiMaxColumns;
      self.state.roiTableDataLongestNameLength = newRoiTableDataLongestNameLength;
      const queryObj = Helpers.getJsonFromUrl();
      const activeTab = (queryObj.activeTab) ? queryObj.activeTab : "roi";
      // const firstRoi = roiTableRows[(self.state.selectedRoiRowIdxOnLoad !== Constants.defaultApplicationSrrIdx) ? self.state.selectedRoiRowIdxOnLoad - 1 : 0];
      // const region = firstRoi.position;
      // const regionType = Constants.applicationRegionTypes.roi;
      const rowIndex = (self.state.selectedRoiRowIdxOnLoad !== Constants.defaultApplicationSrrIdx) ? self.state.selectedRoiRowIdxOnLoad : 1;
      // const strand = firstRoi.strand;
      self.state.drawerActiveTabOnOpen = activeTab;
      self.state.selectedRoiRowIdx = rowIndex;
      // this.jumpToRegion(region, regionType, rowIndex, strand);
      // this.updateViewportDimensions();
      // self.state.drawerIsOpen = true;
      setTimeout(() => {
        if (cb) {
          cb(self);
/*
          setTimeout(() => { 
            self.jumpToRegion(region, regionType, rowIndex, strand);
          }, 1000);
*/
        }
      }, 1000);
    }
    else {
      this.setState({
        roiEnabled: true,
        roiRegions: dataRegions,
        roiTableData: roiTableRows,
        roiTableDataCopy: roiTableRowsCopy,
        roiTableDataIdxBySort: roiTableRowsIdxBySort,
        roiMaxColumns: newRoiMaxColumns,
        roiTableDataLongestNameLength: newRoiTableDataLongestNameLength,
      }, () => {
        //
        // let the callback know that ROI data is available
        //
        if (cb) {
          cb(this);
        }
        //console.log("this.state.roiTableData", this.state.roiTableData);
        //console.log("this.state.roiTableDataIdxBySort", this.state.roiTableDataIdxBySort);
  /*
        if (document.getElementById("drawer-content-roi-table")) {
          console.log("drawer-content-roi-table", parseInt(document.getElementById("drawer-content-roi-table")));
        }
  */
        const queryObj = Helpers.getJsonFromUrl();
        //console.log("queryObj", JSON.stringify(queryObj));
        const activeTab = (queryObj.activeTab) ? queryObj.activeTab : "roi";
        setTimeout(() => {
          const firstRoi = roiTableRows[(this.state.selectedRoiRowIdxOnLoad !== Constants.defaultApplicationSrrIdx) ? this.state.selectedRoiRowIdxOnLoad - 1 : 0];
          try {
            const region = firstRoi.position;
            const regionType = Constants.applicationRegionTypes.roi;
            const rowIndex = (this.state.selectedRoiRowIdxOnLoad !== Constants.defaultApplicationSrrIdx) ? this.state.selectedRoiRowIdxOnLoad : 1;
            //console.log("rowIndex", rowIndex);
            const strand = firstRoi.strand;
            this.setState({
              //drawerIsOpen: true,
              drawerActiveTabOnOpen: activeTab,
              drawerContentKey: this.state.drawerContentKey + 1,
              selectedRoiRowIdx: rowIndex
            }, () => {
              //console.log("state", JSON.stringify(this.state));
              setTimeout(() => {
                this.jumpToRegion(region, regionType, rowIndex, strand);
              }, 0);
              this.updateViewportDimensions();
/*
              setTimeout(() => {
                this.setState({
                  drawerIsOpen: true
                });
              }, 1000); 
*/           
            });
          }
          catch (e) {
            if (e instanceof TypeError) {
              throw new Error(`ROI parsing error ${JSON.stringify(roiTableRows)}`);
            }
          }
        }, 2000);
      });
    }
  }  

  updateRois = (roiEncodedURL, cb) => {
    // decode to test validity, re-encode to submit to proxy
    let roiRawURL = this.roiRawURL(roiEncodedURL);
    if (validator.isURL(roiRawURL)) {
      let reencodedRoiURL = encodeURIComponent(roiRawURL);
      this.setState({
        roiEncodedURL: reencodedRoiURL,
        roiRawURL: roiRawURL
      }, () => {
        //console.log("this.state.roiEncodedURL", this.state.roiEncodedURL);
        let proxyRoiURL = `${Constants.urlProxyURL}/${this.state.roiEncodedURL}`;
        //console.log("proxyRoiURL", proxyRoiURL);
        axios.get(proxyRoiURL)
          .then((res) => {
            if (res.data) { 
              this.roiRegionsUpdate(res.data, cb);
            }
            
          })
          .catch((err) => {
            const msg = this.errorMessage(err, `Regions-of-interest URL is invalid`, roiRawURL);
            this.setState({
              roiEnabled: false,
              overlayMessage: msg
            }, () => {
              this.fadeInOverlay();
            });
            return;
          });
      })
    }
    else {
      const err = {
        "response" : {
          "status" : 400,
          "statusText" : "Malformed URL"
        }
      };
      const msg = this.errorMessage(err, `Regions-of-interest URL is invalid`, roiRawURL);
      this.setState({
        roiEnabled: false,
        overlayMessage: msg
      }, () => {
        this.fadeInOverlay();
      });
      return;
    }
  }
  
  proteinCodingsDownloadURL = (genome) => {
    let downloadURL = Helpers.stripQueryStringAndHashFromPath(document.location.href) + `/assets/exemplars/${genome}/proteinCodings/top5PerSystem.txt`;
    //console.log("proteinCodingsDownloadURL", downloadURL);
    return downloadURL;
  }
  
  updateProteinCodings = (genome) => {
    // read exemplars into memory
    let proteinCodingsURL = this.proteinCodingsDownloadURL(genome);
    
    if (proteinCodingsURL) {
      axios.get(proteinCodingsURL)
        .then((res) => {
          if (!res.data) {
            throw String("Error: Protein-coding genes not returned from query to " + proteinCodingsURL);
          }
          this.setState({
            proteinCodingsJumpActive: true,
            proteinCodingsRegions: res.data.split('\n')
          }, () => { 
            //console.log("exemplarRegions", this.state.exemplarRegions); 
            let data = [];
            let dataCopy = [];
            let dataIdxBySort = [];
            let systems = {};
            this.state.proteinCodingsRegions.forEach((val, idx) => {
              let elem = val.split('\t');
              let chrom = elem[0];
              let start = elem[1];
              let stop = elem[2];
              let gene = elem[3];
              let system = elem[4];
              let rank = elem[5];
              let strand = elem[6];
              if (!chrom) return;
              //console.log("chrom, start, stop, state", chrom, start, stop, state);
              let paddedPosition = Helpers.zeroPad(chrom.replace(/chr/, ''), 3) + ':' + Helpers.zeroPad(parseInt(start), 12) + '-' + Helpers.zeroPad(parseInt(stop), 12);
              if (isNaN(chrom.replace(/chr/, ''))) {
                paddedPosition = chrom.replace(/chr/, '') + ':' + Helpers.zeroPad(parseInt(start), 12) + '-' + Helpers.zeroPad(parseInt(stop), 12);
              }
              let paddedNumerical = Helpers.zeroPad(parseInt(rank), 3);
              data.push({ 
                'idx' : idx + 1,
                'position' : chrom + ':' + start + '-' + stop,
                'gene' : gene,
                'system' : system,
                'rank' : {
                  'numerical' : rank,
                  'paddedNumerical' : paddedNumerical
                },
                'element' : {
                  'paddedPosition' : paddedPosition,
                  'position' : chrom + ':' + start + '-' + stop,
                  'system' : system,
                  'rank' : rank,
                  'strand' : strand
                }
              });
              dataCopy.push({
                'idx' : idx + 1,
                'element' : paddedPosition,
                'gene' : gene,
                'system' : system,
                'rank' : paddedNumerical,
                'strand' : strand
              });
              dataIdxBySort.push(idx + 1);
              systems[system] = 0;
            });
            this.setState({
              proteinCodingsTableData: data,
              proteinCodingsTableDataCopy: dataCopy,
              proteinCodingsTableDataIdxBySort: dataIdxBySort,
              proteinCodingsSystems: Object.keys(systems)
            }, () => {
              //console.log("this.state.transcriptionFactorsTableData", this.state.transcriptionFactorsTableData);
              //console.log("this.state.transcriptionFactorsTableDataCopy", this.state.transcriptionFactorsTableDataCopy);
              //console.log("this.state.transcriptionFactorsTableDataIdxBySort", this.state.transcriptionFactorsTableDataIdxBySort);
              //console.log("this.state.transcriptionFactorsSystems", this.state.transcriptionFactorsSystems);
            });
          });
        })
        .catch((err) => {
          //console.log(err.response);
          let msg = this.errorMessage(err, `Could not retrieve protein-coding gene data`, proteinCodingsURL);
          this.setState({
            overlayMessage: msg
          }, () => {
            this.fadeInOverlay();
          });
        });
    }
  }
  
  transcriptionFactorsDownloadURL = (genome) => {
    let downloadURL = Helpers.stripQueryStringAndHashFromPath(document.location.href) + `/assets/exemplars/${genome}/transcriptionFactors/top5PerSystem.txt`;
    //console.log("transcriptionFactorsDownloadURL", downloadURL);
    return downloadURL;
  }
  
  updateTranscriptionFactors = (genome) => {
    // read exemplars into memory
    let transcriptionFactorsURL = this.transcriptionFactorsDownloadURL(genome);
    
    if (transcriptionFactorsURL) {
      axios.get(transcriptionFactorsURL)
        .then((res) => {
          if (!res.data) {
            throw String("Error: TFs not returned from query to " + transcriptionFactorsURL);
          }
          this.setState({
            transcriptionFactorsJumpActive: true,
            transcriptionFactorsRegions: res.data.split('\n')
          }, () => { 
            //console.log("exemplarRegions", this.state.exemplarRegions); 
            let data = [];
            let dataCopy = [];
            let dataIdxBySort = [];
            let systems = {};
            this.state.transcriptionFactorsRegions.forEach((val, idx) => {
              let elem = val.split('\t');
              let chrom = elem[0];
              let start = elem[1];
              let stop = elem[2];
              let gene = elem[3];
              let system = elem[4];
              let rank = elem[5];
              let strand = elem[6];
              if (!chrom) return;
              //console.log("chrom, start, stop, state", chrom, start, stop, state);
              let paddedPosition = Helpers.zeroPad(chrom.replace(/chr/, ''), 3) + ':' + Helpers.zeroPad(parseInt(start), 12) + '-' + Helpers.zeroPad(parseInt(stop), 12);
              if (isNaN(chrom.replace(/chr/, ''))) {
                paddedPosition = chrom.replace(/chr/, '') + ':' + Helpers.zeroPad(parseInt(start), 12) + '-' + Helpers.zeroPad(parseInt(stop), 12);
              }
              let paddedNumerical = Helpers.zeroPad(parseInt(rank), 3);
              data.push({ 
                'idx' : idx + 1,
                'position' : chrom + ':' + start + '-' + stop,
                'gene' : gene,
                'system' : system,
                'rank' : {
                  'numerical' : rank,
                  'paddedNumerical' : paddedNumerical
                },
                'element' : {
                  'paddedPosition' : paddedPosition,
                  'position' : chrom + ':' + start + '-' + stop,
                  'system' : system,
                  'rank' : rank,
                  'strand' : strand
                }
              });
              dataCopy.push({
                'idx' : idx + 1,
                'element' : paddedPosition,
                'gene' : gene,
                'system' : system,
                'rank' : paddedNumerical,
                'strand' : strand
              });
              dataIdxBySort.push(idx + 1);
              systems[system] = 0;
            });
            this.setState({
              transcriptionFactorsTableData: data,
              transcriptionFactorsTableDataCopy: dataCopy,
              transcriptionFactorsTableDataIdxBySort: dataIdxBySort,
              transcriptionFactorsSystems: Object.keys(systems)
            }, () => {
              //console.log("this.state.transcriptionFactorsTableData", this.state.transcriptionFactorsTableData);
              //console.log("this.state.transcriptionFactorsTableDataCopy", this.state.transcriptionFactorsTableDataCopy);
              //console.log("this.state.transcriptionFactorsTableDataIdxBySort", this.state.transcriptionFactorsTableDataIdxBySort);
              //console.log("this.state.transcriptionFactorsSystems", this.state.transcriptionFactorsSystems);
            });
          });
        })
        .catch((err) => {
          //console.log(err.response);
          let msg = this.errorMessage(err, `Could not retrieve TF data`, transcriptionFactorsURL);
          this.setState({
            overlayMessage: msg
          }, () => {
            this.fadeInOverlay();
          });
        });
    }
  }
  
  closeDrawer = (cb) => { this.setState({ drawerIsOpen: false }, () => { if (cb) cb(); }); }
  
  toggleDrawer = (name) => {
    const angle = window.screen.orientation ? window.screen.orientation.angle : window.orientation;
    let orientationIsPortrait = (angle === 0) || (angle === 180);
    let windowInnerHeight = `${parseInt(window.innerHeight)}px`;
    let windowInnerWidth = `${parseInt(window.innerWidth)}px`;
    if (orientationIsPortrait) {
      if (parseInt(windowInnerWidth) > parseInt(windowInnerHeight)) {
        windowInnerHeight = `${parseInt(window.innerWidth)}px`;
        windowInnerWidth = `${parseInt(window.innerHeight)}px`;
        orientationIsPortrait = false;
      }
    }
    else {
      if (parseInt(windowInnerWidth) < parseInt(windowInnerHeight)) {
        windowInnerHeight = `${parseInt(window.innerWidth)}px`;
        windowInnerWidth = `${parseInt(window.innerHeight)}px`;
        orientationIsPortrait = true;
      }
    }
    let hamburgerButton = document.getElementById("epilogos-viewer-hamburger-button");
    let hamburgerButtonOffsetWidth = (hamburgerButton) ? hamburgerButton.offsetWidth : 0;
    let epilogosViewerHeaderNavbarHamburgerFullWidth = (parseInt(hamburgerButtonOffsetWidth) + 15) + "px";
    let brand = document.getElementById("epilogos-viewer-brand");
    let brandOffsetWidth = (brand) ? brand.offsetWidth : 0;
    let epilogosViewerHeaderNavbarBrandFullWidth = (parseInt(brandOffsetWidth) + 15) + "px";
    let epilogosViewerHeaderNavbarAvailableSpace = parseInt(windowInnerWidth) - parseInt(epilogosViewerHeaderNavbarHamburgerFullWidth) - parseInt(epilogosViewerHeaderNavbarBrandFullWidth);
    let epilogosViewerHeaderNavbarHeight = "38px";
    let epilogosViewerDataAvailableSpace = (parseInt(windowInnerHeight) - parseInt(epilogosViewerHeaderNavbarHeight)) + "px";
    let searchParent = document.getElementById("epilogos-viewer-search-input-parent");
    let searchParentOffsetWidth = (searchParent) ? searchParent.offsetWidth : 0;
    let drawerWidth = parseInt(windowInnerWidth) - parseInt(epilogosViewerHeaderNavbarAvailableSpace) + parseInt(searchParentOffsetWidth) + "px";
    if (parseInt(drawerWidth) < Constants.defaultMinimumDrawerWidth) drawerWidth = Constants.defaultMinimumDrawerWidth;
    let drawerHeight = parseInt(epilogosViewerDataAvailableSpace) + "px";
    // if ROI table width is wider, use it, instead
    let roiTableWidth = 0;
    let isDrawerWidthWider = false;
    if (document.getElementById("drawer-content-roi-table")) {
      roiTableWidth = parseInt(document.getElementById("drawer-content-roi-table").offsetWidth);
      //console.log("roiTableWidth", roiTableWidth);
      if (roiTableWidth > parseInt(drawerWidth)) {
        drawerWidth = (roiTableWidth + 50) + "px";
      }
    }
    //console.log(`drawerWidth ${drawerWidth} X drawerHeight ${drawerHeight}`);
    const drawerType = (name) ? Constants.drawerTypeByName[name] : Constants.defaultDrawerType;
    this.setState({
      orientationIsPortrait: orientationIsPortrait,
      drawerWidth: drawerWidth,
      drawerHeight: drawerHeight,
      drawerContentKey: this.state.drawerContentKey + (isDrawerWidthWider ? 1 : 0),
      drawerSelection: drawerType,
    }, () => {
      this.handleDrawerStateChange({
        isOpen: !this.state.drawerIsOpen
      });
    })
  }
  
  handleDrawerStateChange = (state) => {     
    if (state.isOpen) {
      let windowInnerHeight = this.state.height;
      let epilogosViewerHeaderNavbarHeight = parseInt(document.getElementById("epilogos-viewer-container-navbar").clientHeight) + "px";
      let epilogosViewerDrawerHeight = parseInt(parseInt(windowInnerHeight) - parseInt(epilogosViewerHeaderNavbarHeight) - 70) + "px";
      this.setState({
        drawerSelection: Constants.defaultDrawerType,
        drawerHeight: epilogosViewerDrawerHeight
      }, () => {
        this.setState({ 
          drawerIsOpen: state.isOpen
        });
      })
    }
    else {
      this.setState({ 
        drawerIsOpen: state.isOpen
      });
    }
  }
  
  onChangeSearchInputLocation = (location, applyPadding, userInput) => {
    let range = Helpers.getRangeFromString(location, applyPadding, null, this.state.hgViewParams.genome);
    if (range) {
      this.setState({
        searchInputLocationBeingChanged: true
      }, () => {
        const applyPadding = true;
        this.openViewerAtChrRange(range, applyPadding, this.state.hgViewParams);
        setTimeout(() => {
          this.setState({
            searchInputText: userInput,
            searchInputLocationBeingChanged: false
          });
          this.updateScale();
        }, 1000);
      })
    }
  }
  
  onChangeSearchInput = (value) => {
    this.setState({
      searchInputValue: value
    });
  }
  
  onFocusSearchInput = () => {
    if (this.state.drawerIsOpen) {
      this.closeDrawer(()=>{document.getElementById("autocomplete-input").focus()});
    }
  }
  
  openViewerAtChrRange = (range, applyPadding, params) => {
    let chrLeft = range[0];
    let chrRight = range[0];
    let start = parseInt(range[1]);
    let stop = parseInt(range[2]);
    if (applyPadding) {
      const padding = parseInt(Constants.defaultHgViewGenePaddingFraction * (stop - start));
      const ub = Constants.assemblyBounds[params.genome][chrRight]['ub'];
      start = ((start - padding) > 0) ? (start - padding) : 0;
      stop = ((stop + padding) < ub) ? (stop + padding) : ub;
    }
    //console.log("calling [updateViewerURL] from [openViewerAtChrRange]");
    this.updateViewerURL(params.mode,
                         chrLeft,
                         chrRight,
                         start,
                         stop,
                         params.numSamples);
    this.hgViewUpdatePosition(params, chrLeft, start, stop, chrRight, start, stop);
  }
  
  hgViewUpdatePosition = (params, chrLeft, startLeft, stopLeft, chrRight, startRight, stopRight) => {
    //console.log("hgViewUpdatePosition", params, chrLeft, startLeft, stopLeft, chrRight, startRight, stopRight);
    startLeft = parseInt(startLeft);
    stopLeft = parseInt(stopLeft);
    startRight = parseInt(startRight);
    stopRight = parseInt(stopRight);
    if ((typeof startLeft === "undefined") || (typeof stopLeft === "undefined") || (typeof startRight === "undefined") || (typeof stopRight === "undefined")) {
      return;
    }

    //let chromSizesURL = this.getChromSizesURL(params.genome);
    const genome = params.genome;
    const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, genome);

    function updateHgViewUpdatePositionForChromInfo(chromInfo, self) {
      if (params.paddingMidpoint === 0) {
        self.hgView.zoomTo(
          self.state.hgViewconf.views[0].uid,
          chromInfo.chrToAbs([chrLeft, startLeft]),
          chromInfo.chrToAbs([chrLeft, stopLeft]),
          chromInfo.chrToAbs([chrRight, startRight]),
          chromInfo.chrToAbs([chrRight, stopRight]),
          params.hgViewAnimationTime
        );
      }
      else {
        let midpointLeft = parseInt(startLeft) + parseInt((parseInt(stopLeft) - parseInt(startLeft)) / 2);
        let midpointRight = parseInt(startRight) + parseInt((parseInt(stopRight) - parseInt(startRight)) / 2);
        
        // adjust position
        startLeft = parseInt(midpointLeft - params.paddingMidpoint);
        stopLeft = parseInt(midpointLeft + params.paddingMidpoint);
        startRight = parseInt(midpointRight - params.paddingMidpoint);
        stopRight = parseInt(midpointRight + params.paddingMidpoint);

        self.hgView.zoomTo(
          self.state.hgViewconf.views[0].uid,
          chromInfo.chrToAbs([chrLeft, startLeft]),
          chromInfo.chrToAbs([chrLeft, stopLeft]),
          chromInfo.chrToAbs([chrRight, startRight]),
          chromInfo.chrToAbs([chrRight, stopRight]),
          params.hgViewAnimationTime
        );
      }  
      setTimeout(() => {
        self.setState({
          currentPositionKey: Math.random(),
          currentPosition : {
            chrLeft : chrLeft,
            chrRight : chrRight,
            startLeft : startLeft,
            stopLeft : stopLeft,
            startRight : startRight,
            stopRight : stopRight
          }
        }, () => {
          //console.log("calling [updateViewerURL] from [hgViewUpdatePosition]");
          self.updateViewerURL(
            params.mode,
            self.state.currentPosition.chrLeft,
            self.state.currentPosition.chrRight,
            self.state.currentPosition.startLeft,
            self.state.currentPosition.stopRight,
            params.numSamples);
        })
      }, Constants.defaultHgViewRegionPositionRefreshTimer);
    }

    if (chromInfoCacheExists) {
      updateHgViewUpdatePositionForChromInfo(this.chromInfoCache[genome], this);
    }
    else {
      let chromSizesURL = this.getChromSizesURL(genome);
      ChromosomeInfo(chromSizesURL)
        .then((chromInfo) => {
          this.chromInfoCache[genome] = Object.assign({}, chromInfo);
          updateHgViewUpdatePositionForChromInfo(chromInfo, this);
        })
        .catch((err) => {
          throw new Error(`Error - [hgViewUpdatePosition] failed to fetch chromosome information - ${JSON.stringify(err)}`);
        });
    }
  }
  
  updateScale = () => {
    const scale = Helpers.calculateScale(
      this.state.currentPosition.chrLeft, 
      this.state.currentPosition.chrRight, 
      this.state.currentPosition.startLeft, 
      this.state.currentPosition.stopLeft, 
      this);
    this.setState({
      chromsAreIdentical: scale.chromsAreIdentical,
      currentViewScaleAsString: scale.scaleAsStr,
    });
  }
  
  updateViewerURL = (mode, chrLeft, chrRight, start, stop) => {
    // console.log(`updateViewerURL(${mode}, ${chrLeft}, ${chrRight}, ${start}, ${stop})`);
    let viewerUrl = Helpers.stripQueryStringAndHashFromPath(document.location.href) + "?application=viewer";
    viewerUrl += "&mode=" + mode;
    viewerUrl += "&chrLeft=" + chrLeft;
    viewerUrl += "&chrRight=" + chrRight;
    viewerUrl += "&start=" + parseInt(start);
    viewerUrl += "&stop=" + parseInt(stop);
    if (parseInt(this.state.selectedProteinCodingsRowIdx) > 0) {
      viewerUrl += "&spcIdx=" + parseInt(this.state.selectedProteinCodingsRowIdx);
    }
    if (parseInt(this.state.selectedTranscriptionFactorsRowIdx) > 0) {
      viewerUrl += "&stfIdx=" + parseInt(this.state.selectedTranscriptionFactorsRowIdx);
    }
    if (this.state.roiEncodedURL.length > 0) {
      viewerUrl += `&roiURL=${this.state.roiEncodedURL}`;
    }
    if (this.state.roiEncodedSet.length > 0) {
      viewerUrl += `&roiSet=${this.state.roiEncodedSet}`;
    }
    if (parseInt(this.state.selectedRoiRowIdx) > 0) {
      viewerUrl += "&srrIdx=" + parseInt(this.state.selectedRoiRowIdx);
    }
    if (this.state.roiMode && (this.state.roiMode.length > 0) && (this.state.roiMode !== Constants.defaultApplicationRoiMode)) {
      viewerUrl += `&roiMode=${this.state.roiMode}`;
    }
    if (this.state.roiPaddingAbsolute && (parseInt(this.state.roiPaddingAbsolute) > 0) && (parseInt(this.state.roiPaddingAbsolute) !== Constants.defaultApplicationRoiPaddingAbsolute)) {
      viewerUrl += `&roiPaddingAbsolute=${this.state.roiPaddingAbsolute}`;
    }
    if (this.state.roiPaddingFractional && ((parseFloat(this.state.roiPaddingFractional) > 0) && (parseFloat(this.state.roiPaddingFractional) < 1)) && (parseFloat(this.state.roiPaddingFractional) !== Constants.defaultApplicationRoiPaddingFraction)) {
      viewerUrl += `&roiPaddingFractional=${this.state.roiPaddingFractional}`;
    }
    if (this.state.hgViewParams.itt && (this.state.hgViewParams.itt.length > 0) && (this.state.hgViewParams.itt !== Constants.defaultApplicationIttCategory)) {
      viewerUrl += `&itt=${this.state.hgViewParams.itt}`;
    }
    console.log(`this.state.hgViewParams.gmax ${this.state.hgViewParams.gmax}`);
    if (this.state.hgViewParams.gmax && (this.state.hgViewParams.gmax > 0.0) && (this.state.hgViewParams.gmax !== Constants.defaultApplicationSignalGlobalMax)) {
      viewerUrl += `&gmax=${this.state.hgViewParams.gmax}`;
    }
/*
    if (parseInt(this.state.hgViewParams.dtt) >= 0) {
      viewerUrl += "&dtt=" + parseInt(this.state.hgViewParams.dtt);
    }
    if (this.state.hgViewParams.dtc.length > 0) {
      viewerUrl += `&dtc=${this.state.hgViewParams.dtc}`;
    }
    else {
      viewerUrl += `&dtc=${Constants.defaultApplicationDtcCategory}`;
    }
*/
    // console.warn(`viewerUrl: ${viewerUrl}`);
    this.updateViewerHistory(viewerUrl);
  }
  
  // updateViewerHistory = (viewerUrl) => {
  //   window.history.pushState(viewerUrl, null, viewerUrl);
  // }
  
  jumpToRegion = (region, regionType, rowIndex, strand) => {
    //console.log(`jumpToRegion(${region}, ${regionType}, ${rowIndex}, ${strand})`);
    const pos = Helpers.getRangeFromString(region, false, ((regionType === Constants.applicationRegionTypes.roi) ? false : true), this.state.hgViewParams.genome);
    //console.log(`pos ${pos}`);
    let regionLabel = null;
    let exemplarTableData = null;
    if (regionType === Constants.applicationRegionTypes.proteinCodings) {
      exemplarTableData = this.state.proteinCodingsTableData;
    }
    else if (regionType === Constants.applicationRegionTypes.transcriptionFactors) {
      exemplarTableData = this.state.transcriptionFactorsTableData;
    }
    //console.log(`exemplarTableData ${JSON.stringify(exemplarTableData[0])}`);
    let regionSystem = (regionType === Constants.applicationRegionTypes.roi) ? null : exemplarTableData[(rowIndex - 1)].system;
    let regionSystemLabel = (regionType === Constants.applicationRegionTypes.roi) ? null : Constants.components[regionSystem].longName;
    let regionSystemColor = (regionType === Constants.applicationRegionTypes.roi) ? null : Constants.components[regionSystem].color;
    let regionIndicatorData = {
      chromosome: pos[0],
      start: parseInt(pos[1]),
      stop: parseInt(pos[2]),
      midpoint: parseInt(parseInt(pos[1]) + ((parseInt(pos[2]) - parseInt(pos[1])) / 2)),
      regionLabel: region,
      regionSystem: { 
        system: regionSystem, 
        label: regionSystemLabel, 
        color: regionSystemColor 
      },
      msg: null
    };
    switch (regionType) {
      case Constants.applicationRegionTypes.roi: {
        switch (this.state.roiMode) {
          case Constants.applicationRoiModes.default: {
            regionLabel = `${String.fromCharCode(8676)} ${region} ${String.fromCharCode(8677)}`;
            break;
          }
          case Constants.applicationRoiModes.midpoint: {
            const start = parseInt(pos[1]);
            const stop = parseInt(pos[2]);
            const midpoint = parseInt(start + ((stop - start) / 2));
            const midpointLabel = `${pos[0]}:${midpoint}-${(midpoint + 1)}`;
            regionLabel = midpointLabel;
            regionIndicatorData.regionLabel = regionLabel;
            break;
          }
          default:
            throw new Error('Unknown ROI mode', this.state.roiMode);  
        }
        break;
      }
      case Constants.applicationRegionTypes.proteinCodings:
      case Constants.applicationRegionTypes.transcriptionFactors: {
        regionLabel = region;
        break;
      }
      default:
        throw new Error('Unknown application region type', regionType);
    }
    // this.setState({
    //   // verticalDropLabel: regionLabel,
    //   regionIndicatorData: regionIndicatorData,
    // });

    this.setState({
      regionIndicatorData: regionIndicatorData,
      searchInputText: null,
    }, () => {
      this.openViewerAtChrPosition(pos,
        Constants.defaultHgViewRegionUpstreamPadding,
        Constants.defaultHgViewRegionDownstreamPadding,
        regionType,
        rowIndex,
        strand);
    });
  }
  
  openViewerAtChrPosition = (pos, upstreamPadding, downstreamPadding, regionType, rowIndex, strand) => {
    //console.log(`openViewerAtChrPosition > ${pos} | ${upstreamPadding} | ${downstreamPadding} | ${regionType} | ${rowIndex} | ${strand}`);
    let chrLeft = pos[0];
    let chrRight = pos[0];
    let posnInt = parseInt(pos[1]);
    let start = posnInt;
    let stop = posnInt;
    let unpaddedStart = start;
    let unpaddedStop = stop;

    switch (regionType) {
      case Constants.applicationRegionTypes.proteinCodings:
      case Constants.applicationRegionTypes.transcriptionFactors: {
//         const exemplarPaddingFraction = Constants.defaultApplicationRoiPaddingFraction;
        stop = parseInt(pos[2]);
        unpaddedStop = stop;
/*
        let exemplarPadding = parseInt(exemplarPaddingFraction * (stop - start))
        start -= exemplarPadding;
        stop += exemplarPadding;
*/
        if (strand === "+") {
          start -= upstreamPadding;
          stop += downstreamPadding;
        }
        else if (strand === "-") {
          start -= downstreamPadding;
          stop += upstreamPadding;
        }
        else {
          start -= upstreamPadding;
          stop += downstreamPadding;
        }
        break;
      }
      case Constants.applicationRegionTypes.roi: {
        if (this.state.roiMode === "default") {
          const queryObj = Helpers.getJsonFromUrl();
          const roiPaddingFraction = (queryObj.roiPaddingFractional) ? parseFloat(queryObj.roiPaddingFractional) : Constants.defaultApplicationRoiPaddingFraction;
          const roiPaddingAbsolute = (queryObj.roiPaddingAbsolute) ? parseInt(queryObj.roiPaddingAbsolute) : Constants.defaultApplicationRoiPaddingAbsolute;
          stop = parseInt(pos[2]);
          unpaddedStop = stop;
          let roiPadding = (queryObj.roiPaddingFractional) ? parseInt(roiPaddingFraction * (stop - start)) : roiPaddingAbsolute;
          start -= roiPadding;
          stop += roiPadding;
        }
        else if (this.state.roiMode === "midpoint") {
          stop = parseInt(pos[2]);
          unpaddedStop = stop;
          let roiMidpoint = parseInt(start + ((stop - start) / 2));
          start = roiMidpoint - parseInt(this.state.roiPaddingAbsolute);
          stop = roiMidpoint + parseInt(this.state.roiPaddingAbsolute);
        }
        else {
          throw new URIError("Unknown ROI mode");
        }
        break;
      }
      default:
        break;
    }
    
    //console.warn(`hgViewUpdatePosition > ${this.state.hgViewParams.genome} | ${chrLeft} | ${start} | ${stop} | ${chrRight} | ${start} | ${stop} | ${regionType} | ${rowIndex}`);
    
    this.hgViewUpdatePosition(this.state.hgViewParams, chrLeft, start, stop, chrRight, start, stop);
    
    if (rowIndex < 0) return;
    
    setTimeout(() => {
      switch (regionType) {
        case Constants.applicationRegionTypes.proteinCodings: {
          this.setState({
            selectedProteinCodingsBeingUpdated: true,
            selectedProteinCodingsRowIdx: parseInt(rowIndex),
            selectedProteinCodingsChrLeft: chrLeft,
            selectedProteinCodingsChrRight: chrRight,
            selectedProteinCodingsStart: parseInt(start),
            selectedProteinCodingsStop: parseInt(stop)
          }, () => {
            //console.log("calling [updateViewerURL] from [openViewerAtChrPosition] for [proteinCodings]");
            this.updateViewerURL(this.state.hgViewParams.mode,
                                 chrLeft,
                                 chrRight,
                                 start,
                                 stop,
                                 this.state.hgViewParams.numSamples);
            if (this.state.selectedProteinCodingsRowIdx !== -1) {
              this.fadeOutIntervalDrop();
              //this.fadeInVerticalDrop();
              this.fadeOutVerticalDrop();
              this.fadeInIntervalDrop(chrLeft, chrRight, unpaddedStart, unpaddedStop, start, stop);
            }
          });
          const proteinCodingEl = document.getElementById(`protein_coding_idx_${(rowIndex - 1)}`);
          if (proteinCodingEl) proteinCodingEl.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" }); 
          break;
        }
        case Constants.applicationRegionTypes.transcriptionFactors: {
          this.setState({
            selectedTranscriptionFactorsBeingUpdated: true,
            selectedTranscriptionFactorsRowIdx: parseInt(rowIndex),
            selectedTranscriptionFactorsChrLeft: chrLeft,
            selectedTranscriptionFactorsChrRight: chrRight,
            selectedTranscriptionFactorsStart: parseInt(start),
            selectedTranscriptionFactorsStop: parseInt(stop)
          }, () => {
            //console.log("calling [updateViewerURL] from [openViewerAtChrPosition] for [transcriptionFactors]");
            this.updateViewerURL(this.state.hgViewParams.mode,
                                 chrLeft,
                                 chrRight,
                                 start,
                                 stop,
                                 this.state.hgViewParams.numSamples);
            if (this.state.selectedTranscriptionFactorsRowIdx !== -1) {
              this.fadeOutIntervalDrop();
              //this.fadeInVerticalDrop();
              this.fadeOutVerticalDrop();
              this.fadeInIntervalDrop(chrLeft, chrRight, unpaddedStart, unpaddedStop, start, stop);
            }
          });
          const transcriptionFactorEl = document.getElementById(`transcription_factor_idx_${(rowIndex - 1)}`);
          if (transcriptionFactorEl) transcriptionFactorEl.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
          break;
        }
        case Constants.applicationRegionTypes.roi: {
          this.setState({
            selectedRoiBeingUpdated: true,
            selectedRoiRowIdx: parseInt(rowIndex),
            selectedRoiChrLeft: chrLeft,
            selectedRoiChrRight: chrRight,
            selectedRoiStart: parseInt(start),
            selectedRoiStop: parseInt(stop),
            //drawerContentKey: this.state.drawerContentKey + 1
          }, () => {
            //console.log("calling [updateViewerURL] from [openViewerAtChrPosition] for [roi]");
            //console.log("this.state.selectedRoiRowIdx", this.state.selectedRoiRowIdx);
            this.updateViewerURL(this.state.hgViewParams.mode,
                                 chrLeft,
                                 chrRight,
                                 start,
                                 stop,
                                 this.state.hgViewParams.numSamples);
            if (this.state.selectedRoiRowIdx !== -1) {
              //this.fadeInVerticalDrop();
              //this.fadeInIntervalDrop(chrLeft, chrRight, unpaddedStart, unpaddedStop, start, stop);
              switch (this.state.roiMode) {
                case "default": {
                  this.fadeOutVerticalDrop();
                  this.fadeInIntervalDrop(chrLeft, chrRight, unpaddedStart, unpaddedStop, start, stop);
                  break;
                }
                case "midpoint": {
                  this.fadeOutIntervalDrop();
                  this.fadeInVerticalDrop();
                  break;
                }
                default:
                  throw new URIError("Unknown ROI mode");
              }
            }
            const roiEl = document.getElementById(`roi_idx_${(rowIndex - 1)}`);
            if (roiEl) roiEl.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" }); 
          });
          break;
        }
        default:
          break;
      } 
    }, 1000);
  }
  
  updateViewerLocation = (event) => {
    //console.log("this.updateViewerLocation()");
    const newMode = this.state.hgViewParams.mode;
    if (newMode !== 'co') {
      this.updateViewerURLWithLocation(event);
      setTimeout(() => {
        this.updateScale();
      }, 2000);
    }
    else {
      if (!this.viewerLocationChangeEventTimer) {
        clearTimeout(this.viewerLocationChangeEventTimer);
        //console.log("this.viewerLocationChangeEventTimer *unset*");
        this.viewerLocationChangeEventTimer = setTimeout(() => {
          if (!this.state.searchInputLocationBeingChanged) {
            //console.log("calling [updateViewerURLWithLocation] from [updateViewerLocation]");
            this.updateViewerURLWithLocation(event);
          }
          setTimeout(() => { 
            this.viewerLocationChangeEventTimer = null;
            setTimeout(() => {
              this.updateScale();
            }, 2000);
          }, 0);
          //console.log("this.viewerLocationChangeEventTimer set");
        }, 500);
      }
    }    
  }
  
  updateViewerURLWithLocation = (event) => {
    const genome = this.state.hgViewParams.genome;
    const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, genome);

    function updateViewerURLWithLocationForChromInfo(chromInfo, self) {
      //console.log(`self.state ${JSON.stringify(self.state)}`);
      let chrStartPos = chromInfo.absToChr(event.xDomain[0]);
      let chrStopPos = chromInfo.absToChr(event.xDomain[1]);
      let chrLeft = chrStartPos[0];
      let start = chrStartPos[1];
      let chrRight = chrStopPos[0];
      let stop = chrStopPos[1];
      let selectedProteinCodingsRowIdx = self.state.selectedProteinCodingsRowIdx;
      let selectedTranscriptionFactorsRowIdx = self.state.selectedTranscriptionFactorsRowIdx;
      let selectedRoiRowIdx = self.state.selectedRoiRowIdx;
      if ((chrLeft !== self.state.selectedProteinCodingsChrLeft) || (chrRight !== self.state.selectedProteinCodingsChrRight) || (start !== self.state.selectedProteinCodingsStart) || (stop !== self.state.selectedProteinCodingsStop) || (chrLeft !== self.state.selectedTranscriptionFactorsChrLeft) || (chrRight !== self.state.selectedTranscriptionFactorsChrRight) || (start !== self.state.selectedTranscriptionFactorsStart) || (stop !== self.state.selectedTranscriptionFactorsStop) || (chrLeft !== self.state.selectedRoiChrLeft) || (chrRight !== self.state.selectedRoiChrRight) || (start !== self.state.selectedRoiStart) || (stop !== self.state.selectedRoiStop)) {
        const queryObj = Helpers.getJsonFromUrl();
        if (!self.state.selectedProteinCodingsBeingUpdated && !self.state.selectedTranscriptionFactorsBeingUpdated && !queryObj.roiURL && !queryObj.roiSet) {
          //console.log("resetting selected row idx for protein coding or TF category", this.state.selectedProteinCodingsBeingUpdated, this.state.selectedTranscriptionFactorsBeingUpdated);
          selectedProteinCodingsRowIdx = Constants.defaultApplicationSerIdx;
          selectedTranscriptionFactorsRowIdx = Constants.defaultApplicationSerIdx;
          //console.log("calling fadeOutVerticalDrop from updateViewerURL because this.state.selectedExemplarBeingUpdated is false");
          self.fadeOutVerticalDrop();
          self.fadeOutIntervalDrop();
        }
        if (!self.state.selectedRoiBeingUpdated && (queryObj.roiURL || queryObj.roiSet)) {
          //console.log("resetting selected row idx for ROI category");
          selectedRoiRowIdx = Constants.defaultApplicationSrrIdx;
          //console.log("calling fadeOutVerticalDrop and fadeOutIntervalDrop from updateViewerURL because this.state.selectedRoiBeingUpdated is false");
          self.fadeOutVerticalDrop();
          self.fadeOutIntervalDrop();
        }
      }
      self.setState({
        //positionSummaryElementKey: Math.random(),
        currentPositionKey: Math.random(),
        currentPosition : {
          chrLeft : chrLeft,
          chrRight : chrRight,
          startLeft : start,
          stopLeft : stop,
          startRight : start,
          stopRight : stop
        },
        selectedProteinCodingsRowIdx: selectedProteinCodingsRowIdx,
        selectedTranscriptionFactorsRowIdx: selectedTranscriptionFactorsRowIdx,
        selectedRoiRowIdx: selectedRoiRowIdx,
      }, () => {
        // console.log("calling [updateViewerURL] from [updateViewerURLWithLocation]");
        self.setState({
          positionSummaryElementKey: Math.random(),
        }, () => {
          self.updateViewerURL(self.state.hgViewParams.mode,
                                self.state.currentPosition.chrLeft,
                                self.state.currentPosition.chrRight,
                                self.state.currentPosition.startLeft,
                                self.state.currentPosition.stopRight,
                                self.state.hgViewParams.numSamples);
        })

        let boundsLeft = 20;
        let boundsRight = Constants.assemblyBounds[self.state.hgViewParams.genome].chrY.ub - boundsLeft;
        if (((chrLeft === "chr1") && (start < boundsLeft)) && ((chrRight === "chrY") && (stop > boundsRight))) {
          // console.log("handleZoomPastExtent() called");
          self.handleZoomPastExtent();
        } 
        // console.log("updateViewerURLWithLocation() finished");
      });
    }

    if (chromInfoCacheExists) {
      updateViewerURLWithLocationForChromInfo(this.chromInfoCache[genome], this);
    }
    else {
      let chromSizesURL = this.getChromSizesURL(genome);
      ChromosomeInfo(chromSizesURL)
        .then((chromInfo) => {
          this.chromInfoCache[genome] = Object.assign({}, chromInfo);
          updateViewerURLWithLocationForChromInfo(chromInfo, this);
        })
        .catch((err) => {
          throw new Error(`Error - [updateViewerURLWithLocation] could not retrieve chromosome information - ${JSON.stringify(err)}`);
        });
    }
  }
  
  getChromSizesURL = (genome) => {
    let chromSizesURL = this.state.hgViewParams.hgGenomeURLs[genome];
    if (this.state.hgViewParams.itt === "ht") {
      chromSizesURL = this.state.hgViewParams.hgFixedBinGenomeURLs[genome];
    }
    // console.log(`chromSizesURL ${chromSizesURL}`);
    if (this.isProductionSite) {
      chromSizesURL = chromSizesURL.replace(Constants.applicationDevelopmentPort, Constants.applicationProductionPort);
    }
    else if (this.isProductionProxySite) {
      chromSizesURL = chromSizesURL.replace(Constants.applicationDevelopmentPort, Constants.applicationProductionProxyPort);
      chromSizesURL = chromSizesURL.replace(/^https/, "http");
    }
    else {
      let port = parseInt(this.currentURL.port);
      if (isNaN(port)) { port = Constants.applicationProductionPort; }
      chromSizesURL = chromSizesURL.replace(":" + Constants.applicationDevelopmentPort, `:${port}`);
    }
    return chromSizesURL;
  }
  
  handleZoomPastExtent = () => {
    //console.log("this.handleZoomPastExtent()");
    if (this.state.searchInputLocationBeingChanged) return;
    
    if (!this.viewerZoomPastExtentTimer) {
      clearTimeout(this.viewerZoomPastExtentTimer);
      this.viewerZoomPastExtentTimer = setTimeout(() => {
        let genome = this.state.hgViewParams.genome;
        let boundsLeft = 20;
        let boundsRight = Constants.assemblyBounds[genome].chrY.ub - boundsLeft;

        const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, genome);

        function handleZoomPastExtentForChromInfo(chromInfo, self) {
          setTimeout(() => {
            self.hgView.zoomTo(
              self.state.hgViewconf.views[0].uid,
              chromInfo.chrToAbs(["chr1", boundsLeft]),
              chromInfo.chrToAbs(["chrY", boundsRight]),
              chromInfo.chrToAbs(["chr1", boundsLeft]),
              chromInfo.chrToAbs(["chrY", boundsRight]),
              100
            );
          }, 0);
          setTimeout(() => { 
            self.viewerZoomPastExtentTimer = null; 
            // console.log("this.viewerZoomPastExtentTimer *unset*"); 
            // console.log("calling [updateViewerURL] from [handleZoomPastExtent]");
            self.updateViewerURL(self.state.tempHgViewParams.mode,
                                 "chr1",
                                 "chrY",
                                 boundsLeft,
                                 boundsRight,
                                 self.state.tempHgViewParams.numSamples);
            setTimeout(() => {
              self.updateScale();
            }, 2500);
          }, 2000);
        }

        if (chromInfoCacheExists) {
          // console.log(`this.state ${JSON.stringify(this.state)}`);
          handleZoomPastExtentForChromInfo(this.chromInfoCache[genome], this);
        }
        else {
          let chromSizesURL = this.getChromSizesURL(genome);
          ChromosomeInfo(chromSizesURL)
            .then((chromInfo) => {
              this.chromInfoCache[genome] = Object.assign({}, chromInfo);
              handleZoomPastExtentForChromInfo(chromInfo, this);
            })
            .catch((err) => {
              throw new Error(`Error - [handleZoomPastExtent] could not retrieve chromosome information - ${JSON.stringify(err)}`);
            });
        }

      }, 2000);
    }
  }
  
  fadeInVerticalDrop = () => {}
  fadeOutVerticalDrop = () => {}
  fadeInIntervalDrop = () => {}
  fadeOutIntervalDrop = () => {}
  fadeInOverlay = () => {}
  fadeOutOverlay = () => {}
  
  // eslint-disable-next-line no-unused-vars
  errorMessage = (err, errorMsg, errorURL) => { 
    //console.log("errorMessage", errorMsg, JSON.stringify(err), errorURL); 
  }
  
  triggerUpdate = (updateMode) => {
    // console.log("triggerUpdate", updateMode);
    if (updateMode === "cancel") {
      this.closeDrawer();
      this.setState({
        showUpdateNotice: false,
        hideDrawerOverlay: true,
        drawerIsOpen: true,
        tempHgViewParams: {...this.state.hgViewParams},
        drawerContentKey: this.state.drawerContentKey + 1
      });
    }
    else if (updateMode === "update") {
      //
      // get parameters from tempHgViewParams
      //
      let newGenome = this.state.tempHgViewParams.genome;
      let newMode = this.state.tempHgViewParams.mode;
      // let newNumSamples = this.state.tempHgViewParams.numSamples;

      const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, newGenome);

      //
      // query object
      //
      const queryObj = Helpers.getJsonFromUrl();
      //
      // clear out indicator markers
      //
      if (this.epilogosViewerContainerVerticalDrop && this.epilogosViewerContainerVerticalDrop.style) this.fadeOutVerticalDrop();
      if (this.epilogosViewerContainerIntervalDrop && this.epilogosViewerContainerIntervalDrop.style) this.fadeOutIntervalDrop();
      //
      // return a Promise to request a UUID from a filename pattern
      //
      // const uuidQueryPromise = function(fn, endpoint, self) {
      //   let hgUUIDQueryURL = `${endpoint}/tilesets?ac=${fn}`;
      //   return axios.get(hgUUIDQueryURL).then((res) => {
      //     if (res.data && res.data.results && res.data.results[0]) {
      //       return {'fn': fn, 'uuid': res.data.results[0].uuid};
      //     }
      //     else {
      //       let err = {};
      //       err.response = {};
      //       err.response.status = "404";
      //       err.response.statusText = "No tileset data found for specified UUID";
      //       // throw {response:{status:"404", statusText:"No tileset data found for specified UUID"}};
      //       throw err;
      //     }
      //   })
      //   .catch((err) => {
      //     // console.log(JSON.stringify(err));
      //     let msg = self.errorMessage(err, `Could not retrieve UUID for track query (${fn})`, hgUUIDQueryURL);
      //     self.setState({
      //       overlayMessage: msg,
      //       hgViewconf: {}
      //     }, () => {
      //       self.fadeInOverlay();
      //     });
      //   });
      // }
      //
      // build new viewconf
      //
   
      // 
      // we start with a template that is specific to the 'mode'
      //   
      let newViewconfUUID = Constants.viewerHgViewconfTemplates[newMode];
      //
      // try to fix bad URL parameters
      //
      // if (newGroup.includes("_vs_")) { newMode = "paired"; newViewconfUUID = Constants.viewerHgViewconfTemplates.paired; }
      // 
      // we also need the UUID of the chromsizes and gene annotations track, which is 'genome'-specific
      //
      let newChromsizesUUID = Constants.viewerHgViewconfGenomeAnnotationUUIDs[newGenome]['chromsizes'];
      let newGenesUUID = Constants.viewerHgViewconfGenomeAnnotationUUIDs[newGenome]['genes'];
      // let newCTCFBindingSitesUUID = Constants.viewerHgViewconfGenomeAnnotationUUIDs[newGenome]['CTCF_binding_sites'];

      if ((this.state.hgViewParams.itt === "ht") || (this.state.hgViewParams.mode === "pm") || (this.state.hgViewParams.mode === "pd")) {
        newChromsizesUUID = Constants.viewerHgViewconfGenomeAnnotationUUIDs[newGenome]['chromsizes_fixed_bin'];
        // newCTCFBindingSitesUUID = Constants.viewerHgViewconfGenomeAnnotationUUIDs[newGenome]['CTCF_binding_sites_fixed_bin'];
        newGenesUUID = Constants.viewerHgViewconfGenomeAnnotationUUIDs[newGenome]['genes_fixed_bin'];
      }

      //
      // we also need the colormap, which is 'genome' and 'model' specific
      //
      // let newColormap = Constants.viewerHgViewconfColormaps[newGenome][newModel];
      
      let newHgViewconfURL = Helpers.hgViewconfDownloadURL(this.state.hgViewParams.hgViewconfEndpointURL, newViewconfUUID, this);
      // console.log("newHgViewconfURL", newHgViewconfURL);
      
      //
      // scale adjustments
      //
      let newHgViewParams = {...this.state.hgViewParams};
      let newHgViewTrackChromosomeHeight = Constants.defaultChromosomeTrackHeight; // parseInt(newHgViewParams.hgViewTrackChromosomeHeight);
      let newHgViewTrackGeneAnnotationsHeight = parseInt(Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackHeight);
/*
      let newHgViewTrackIndexAnnotationsHeight = ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite))
        ?
          Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight
        :
          Constants.viewerHgViewParameters.hgViewTrackDHSComponentsHeight;
*/
      // let newHgViewTrackIndexAnnotationsHeight = Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight;
      // let newHgViewTrackNumSamplesHeight = (this.state.isMobile && (this.state.isPortrait === false)) ? 0 : parseInt(newHgViewParams.hgViewTrackGeneAnnotationsHeight / 3.0);
/*
      let newHgViewTrackNumSamplesHeight = ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite))
        ?
          Constants.viewerHgViewParameters.hgViewTrackSamplesWithDHSHeight
        :
          0.001;
*/
      // let newHgViewTrackIndexDHSHeight = ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight : 0.001;
      let newHgViewTrackIndexDHSHeight = Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight;
      // let newHgViewTrackDHSComponentsHeight = ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? 0.001 : Constants.viewerHgViewParameters.hgViewTrackDHSComponentsHeight;
      let newHgViewTrackDHSComponentsHeight = Constants.viewerHgViewParameters.hgViewTrackDHSComponentsHeight;
      
      //
      // modes
      //
      switch (newMode) {
        case "ac":
        case "pc": {
          //
          // get biosamples
          //
          let samplesToRenderAsTracks = [];
          let samplePromises = [];
          Object.keys(Constants.allComponent).forEach((d) => {
            if (Constants.allComponent[d].active) {
              let o = Constants.allComponent[d];
              o['name'] = d;
              o['filename'] = `${d}.multires.mv5`
              samplesToRenderAsTracks.push(o);
              let endpoint = Constants.applicationHiGlassServerEndpointRootURL;
              samplePromises.push(Helpers.uuidQueryPromise(o['filename'], endpoint, this));
            }
          });
        
          //console.log("samplesToRenderAsTracks", samplesToRenderAsTracks);
          Promise.all(samplePromises).then((v) => {
            let uuid = {};
            v.forEach((d) => {
              uuid[d.fn] = d.uuid;
            });
            samplesToRenderAsTracks.forEach((d) => {
              d['uuid'] = uuid[d['filename']];
            });
            //console.log(samplesToRenderAsTracks);
            let samplesToBeRendered = samplesToRenderAsTracks.reduce((d, c) => { return parseInt(d + c.samples) }, 0);
            //console.log("samplesToBeRendered", samplesToBeRendered);
            //console.log("newHgViewconfURL", newHgViewconfURL);
            axios.get(newHgViewconfURL)
              .then((res) => {
                if (!res.data) {
                  throw String("Error: New viewconf not returned from query to " + newHgViewconfURL);
                }
                
                //console.log("old res.data", res.data);
                
                // ensure the template is not editable
                res.data.editable = false;
                
                // update hgview parameters
                newHgViewParams.genome = newGenome;
                newHgViewParams.mode = newMode;
                
                let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
                let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
                let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
                let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
                //console.log("position: ", chrLeft, chrRight, start, stop);
                
                function updateViewerStateForAcPcModeAndChromInfo(chromInfo, self) {
                  if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                    chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                    chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                    start = Constants.defaultApplicationPositions[newGenome].start;
                    stop = Constants.defaultApplicationPositions[newGenome].stop;
                  }
                  if (start > chromInfo.chromLengths[chrLeft]) {
                    start = chromInfo.chromLengths[chrLeft] - 10000;
                  }
                  if (stop > chromInfo.chromLengths[chrRight]) {
                    stop = chromInfo.chromLengths[chrRight] - 1000;
                  }
                  let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
                  let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
                  //console.log(chrLeft, start, absLeft);
                  //console.log(chrRight, stop, absRight);
                  res.data.views[0].initialXDomain = [absLeft, absRight];
                  res.data.views[0].initialYDomain = [absLeft, absRight];
                  // update track heights -- requires preknowledge of track order from template
                  let windowInnerHeight = document.documentElement.clientHeight + "px";
                  //console.log("windowInnerHeight", windowInnerHeight);
                  //console.log("newHgViewTrackNumSamplesHeight", newHgViewTrackNumSamplesHeight);
                  //let availableWindowInnerHeight = parseInt(windowInnerHeight) - newHgViewTrackChromosomeHeight - newHgViewTrackIndexAnnotationsHeight - newHgViewTrackNumSamplesHeight - newHgViewTrackGeneAnnotationsHeight - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarMobileDeviceHeight) + "px";
                  let availableWindowInnerHeight = parseInt(windowInnerHeight) - 
                    parseInt(newHgViewTrackChromosomeHeight) - 
                    //parseInt(newHgViewTrackNumSamplesHeight) - 
                    parseInt(newHgViewTrackIndexDHSHeight) - 
                    //parseInt(newHgViewTrackDHSComponentsHeight) - 
                    parseInt(newHgViewTrackGeneAnnotationsHeight) - 
                    parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarMobileDeviceHeight) + "px";
                  //console.log("availableWindowInnerHeight", availableWindowInnerHeight);
                  let heightPerSample = parseFloat(parseInt(availableWindowInnerHeight) / samplesToBeRendered);
                  //console.log("heightPerSample", heightPerSample);
                  // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
                  let topClone = update(res.data.views[0].tracks.top, {$push: []});
                  //console.log("topClone", topClone);
                  // build a new view from pieces of this clone
                  let newTop = [];
                  let newTopIdx = 0;
                  //
                  // add chromosome track
                  //
                  newTop[newTopIdx] = update(topClone[1], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    height: {$set: newHgViewTrackChromosomeHeight},
                    tilesetUid: {$set: newChromsizesUUID},
                    uid: {$set: uuid4()},
                    options: {
                      backgroundColor: {$set: "white"},
                      showMousePosition: {$set: true},
                    }
                  });
                  newTopIdx += 1;
                  //
                  // heatmap
                  //
                  samplesToRenderAsTracks.forEach((sampleToRender) => {
                    //console.log("sampleToRender", sampleToRender);
                    newTop[newTopIdx] = update(topClone[0], {
                      // server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      server: {$set: Constants.applicationEndpointRootURL},
                      height: {$set: parseInt(sampleToRender.samples * heightPerSample)},
                      name: {$set: sampleToRender.longName},
                      type: {$set: "horizontal-multivec"},
                      minHeight: {$set: 1},
                      resolutions: {$set: Constants.componentResolutions},
                      options: {
                        axisLabelFormatting: {$set: "normal"},
                        axisPositionHorizontal: {$set: "outsideRight"},
                        labelPosition: {$set: "topLeft"},
                        labelTextOpacity: {$set: 0.0},
                        labelBackgroundOpacity: {$set: 0.0},
                        labelColor: {$set: "white"},
                        valueScaleMin: {$set: 0.0},
                        valueScaleMax: {$set: (self.state.hgViewParams.gmax > 0) ? self.state.hgViewParams.gmax : 1.0},
                        name: {$set: sampleToRender.longName},
                        valueScaling: {$set: "linear"},
                        // colorRange: {$set: Constants.componentColorMapGreyscale},
                        colorRange: {$set: Constants.componentColorMapViridisV1},
                        showMousePosition: {$set: true},
                        colorbarPosition: {$set: null},
                      },
                      tilesetUid: {$set: sampleToRender.uuid},
                      uid: {$set: uuid4()},
                    });
                    newTopIdx += 1;
                  })
                  
                  //
                  // add numSamples track
                  //
/*
                  let numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamples];
                  let numSamplesTrackType = Constants.viewerHgViewconfNumSamplesTrackType;
                  let numSamplesValueScaleMin = Constants.viewerHgViewconfNumSamplesValueScaleMin[newNumSamples];
                  let numSamplesValueScaleMax = Constants.viewerHgViewconfNumSamplesValueScaleMax[newNumSamples];
                  newTop[newTopIdx] = update(topClone[2], {
                    server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                    height: {$set: newHgViewTrackNumSamplesHeight},
                    type: {$set: numSamplesTrackType},
                    tilesetUid: {$set: numSamplesUUID},
                    uid: {$set: uuid4()},
                    options: {
                      minHeight: {$set: 0},
                      backgroundColor: {$set: "rgb(242,242,242)"},
                      axisLabelFormatting: {$set: "normal"},
                      axisPositionHorizontal: {$set: "outsideRight"},
                      barFillColor: {$set: "black"},
                      pointColor: {$set: "black"},
                      pointSize: {$set: 4},
                      zeroLineVisible: {$set: true},
                      zeroLineColor: {$set: "black"},
                      zeroLineOpacity: {$set: 0.0},
                      valueScaling: {$set: "log"},
                      valueScaleMin: {$set: numSamplesValueScaleMin},
                      valueScaleMax: {$set: numSamplesValueScaleMax},
                      valueFormat: {$set: "d"},
                      aggregationMode: {$set: "median"},
                      showTooltip: {$set: true},
                      showMousePosition: {$set: true}
                    }
                  });
                  newTopIdx += 1;
*/
                  //
                  // add Index DHS track
                  //
                  switch (self.state.hgViewParams.itt) {
                    case Constants.applicationIttModes.bb:
                      // bigBed
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                        // server: {$set: Constants.applicationEndpointRootURL},
                        height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                        type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                        tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                        uid: {$set: uuid4()},
                        options: {
                          minHeight: {$set: 0},
                          backgroundColor: {$set: "white"},
                          isBarPlotLike: {$set: true},
                          itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                          showMousePosition: {$set: true},
                        }
                      });
                      break;
                    case 'ht':
                      // higlass-transcripts
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                        type: {$set: "horizontal-transcripts"},
                        tilesetUid: {$set: "D5k7ajwfT9mzwbybaSS0VA"},
                        uid: {$set: uuid4()},
                        options: {
                          showMousePosition: {$set: true},
                          blockStyle: {$set: "boxplot"},
                          showToggleTranscriptsButton: {$set: false},
                          labelFontSize: {$set: "11"},
                          labelFontWeight: {$set: "500"},
                          maxTexts: {$set: 100},
                          itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                          startCollapsed: {$set: false},
                          trackMargin: {$set: {
                            top: 10,
                            bottom: 10,
                            left: 0,
                            right: 0,
                          }},
                          transcriptHeight: {$set: 16},
                          transcriptSpacing: {$set: 5},
                          isVisible: {$set: false},
                        }
                      });
                      break;
                    default:
                      throw new URIError(`Unknown Index DHS track type (itt) mode ${self.state.hgViewParams.itt}`);
                  }
                  newTopIdx += 1;
                  //console.warn(`Adding DHS index track... ${this.state.currentViewScale} vs ${this.state.hgViewParams.dtt}`);
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackIndexDHSHeight},
                  //   type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                  //   tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     minHeight: {$set: 0},
                  //     backgroundColor: {$set: "white"},
                  //     isBarPlotLike: {$set: true},
                  //     itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                  //     showMousePosition: {$set: true},
                  //   }
                  // });
                  // newTopIdx += 1;
                  //
                  // add all-DHS Component track
                  //
                  //console.warn(`all-DHS track height ${newHgViewTrackDHSComponentsHeight}`);
/*
                  newTop[newTopIdx] = update(topClone[2], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    height: {$set: newHgViewTrackDHSComponentsHeight},
                    type: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[this.state.hgViewParams.dtc]['type']},
                    tilesetUid: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[this.state.hgViewParams.dtc]['uuid']},
                    uid: {$set: uuid4()},
                    options: {
                      minHeight: {$set: 0},
                      backgroundColor: {$set: "white"},
                      colorRange: {$set: Constants.dhsComponentColorsAsRgb},
                      colorLabels: {$set: Constants.dhsComponentLabels},
                      showMousePosition: {$set: true},
                      valueScaling: {$set: null},
                      valueScaleMin: {$set: 1},
                      valueScaleMax: {$set: 17},
                      valueScaleNaN: {$set: 17},
                      zeroValueColor: {$set: "white"},
                      heatmapValueScaling: {$set: "categorical"},
                      colorbarPosition: {$set: null}
                    }
                  });
                  newTopIdx += 1;       
*/             
                  //
                  // add gene annotation track
                  //
                  newTop[newTopIdx] = update(topClone[2], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    height: {$set: newHgViewTrackGeneAnnotationsHeight},
                    tilesetUid: {$set: newGenesUUID},
                    uid: {$set: uuid4()},
                    options: {
                      backgroundColor: {$set: "white"},
                      plusStrandColor: {$set: "black"},
                      minusStrandColor: {$set: "black"},
                      showMousePosition: {$set: true},
                    }
                  });
                  // replace the top view tracks with the newly build track layout
                  //console.log("updated newTop", JSON.stringify(newTop, null, 2));
                  res.data.views[0].tracks.top = newTop;
                  // confirm layout
                  //console.log("updated res.data", JSON.stringify(res.data, null, 2));
                  // get child view heights
                  const childViews = res.data.views[0].tracks.top;
                  let childViewHeightTotal = 0;
                  childViews.forEach((cv) => { childViewHeightTotal += cv.height });
                  childViewHeightTotal += 10;
                  let childViewHeightTotalPx = childViewHeightTotal + "px";
                  //console.log("childViewHeightTotalPx", childViewHeightTotalPx);
                  
                  // update Viewer state
                  //console.log("Updating hgViewconf from res.data");
                  self.setState({
                    hgViewParams: newHgViewParams,
                    hgViewHeight: childViewHeightTotalPx,
                    hgViewconf: res.data,
                    currentPositionKey: Math.random(),
                    currentPosition : {
                      chrLeft : chrLeft,
                      chrRight : chrRight,
                      startLeft : parseInt(start),
                      stopLeft : parseInt(stop),
                      startRight : parseInt(start),
                      stopRight : parseInt(stop)
                    },
                    selectedProteinCodingsRowIdx: -1,
                    selectedTranscriptionFactorsRowIdx: -1,
                    selectedRoiRowIdx: -1,
                    trackLabelsVisible: false,
                  }, () => {
                    self.setState({
                      hgViewKey: self.state.hgViewKey + 1,
                      drawerContentKey: self.state.drawerContentKey + 1,
                    }, () => {
                      // update browser history (address bar URL)
                      //console.log("calling [updateViewerURL] from [triggerUpdate]");
                      self.updateViewerURL(
                        self.state.hgViewParams.mode,
                        self.state.currentPosition.chrLeft,
                        self.state.currentPosition.chrRight,
                        self.state.currentPosition.startLeft,
                        self.state.currentPosition.stopRight,
                        self.state.hgViewParams.numSamples);
                      // add location event handler
                      self.hgView.api.on("location", (event) => { 
                        self.updateViewerLocation(event);
                      });
                    })
                  })
                }
                
                if (chromInfoCacheExists) {
                  updateViewerStateForAcPcModeAndChromInfo(this.chromInfoCache[newGenome], this);
                }
                else {
                  let chromSizesURL = this.getChromSizesURL(newGenome);
                  ChromosomeInfo(chromSizesURL)
                    .then((chromInfo) => {
                      this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                      updateViewerStateForAcPcModeAndChromInfo(chromInfo, this);
                    })
                    .catch((err) => {
                      let msg = this.errorMessage(err, `Could not retrieve chromosome information`, chromSizesURL);
                      this.setState({
                        overlayMessage: msg,
                        hgViewconf: {}
                      }, () => {
                        this.fadeInOverlay();
                      });
                    });
                }
              })
              .catch((err) => {
                //console.log(err.response);
                let msg = this.errorMessage(err, `Could not retrieve view configuration`, newHgViewconfURL);
                this.setState({
                  overlayMessage: msg,
                  hgViewconf: {}
                }, () => {
                  this.fadeInOverlay();
                });
              });
          })
          .catch((err) => {
            throw new Error(`Could not retrieve UUIDs for all specified components - ${JSON.stringify(err)}`);
          });
          break;
        }
        case "pw": {
          //
          // per-component (bigWig) mode
          // 
          if (this.state.orientationIsPortrait) {
            //
            // normal (portrait) display
            //
            //console.log("showing per-component view in Portrait mode");
            // get all active per-component items
            let samplesToRenderAsTracks = [];
            let samplePromises = [];
            Object.keys(Constants.components).forEach((d) => {
              if (Constants.components[d].active) {
                let o = Constants.components[d];
                let i = o.componentIndex;
                o['name'] = d;
                o['filename'] = `WM20181113_signal_track_per_component.comp${i}.bw`
                samplesToRenderAsTracks.push(o);
                let endpoint = Constants.applicationHiGlassDevServerEndpointRootURL;
                // let endpoint = Constants.applicationEndpointRootURL;                
                samplePromises.push(Helpers.uuidQueryPromise(o['filename'], endpoint, this));
              }
            });
            //console.log("samplesToRenderAsTracks", samplesToRenderAsTracks);
            Promise.all(samplePromises).then((v) => {
                let uuid = {};
                v.forEach((d) => {
                  uuid[d.fn] = d.uuid;
                });
                samplesToRenderAsTracks.forEach((d) => {
                  d['uuid'] = uuid[d['filename']];
                });
                //console.log(samplesToRenderAsTracks);
                let samplesToBeRendered = samplesToRenderAsTracks.reduce((d, c) => { return parseInt(d + c.samples) }, 0);
                let componentsToBeRendered = samplePromises.length;
                //console.log("samplesToBeRendered", samplesToBeRendered);
                //console.log("componentsToBeRendered", componentsToBeRendered);
                //console.log("newHgViewconfURL", newHgViewconfURL);
                axios.get(newHgViewconfURL)
                  .then((res) => {
                    if (!res.data) {
                      throw String("Error: New viewconf not returned from query to " + newHgViewconfURL);
                    }
                    
                    //console.log("old res.data", res.data);
                    
                    // ensure the template is not editable
                    res.data.editable = false;
                    
                    // update hgview parameters
                    newHgViewParams.genome = newGenome;
                    newHgViewParams.mode = newMode;
                    
                    let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
                    let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
                    let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
                    let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
                    //console.log("position: ", chrLeft, chrRight, start, stop);

                    function updateViewerStateForPwModeAndChromInfoInPortraitOrientation(chromInfo, self) {
                      //console.log("chromInfo", chromInfo);
                      if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                        chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                        chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                        start = Constants.defaultApplicationPositions[newGenome].start;
                        stop = Constants.defaultApplicationPositions[newGenome].stop;
                      }
                      if (start > chromInfo.chromLengths[chrLeft]) {
                        start = chromInfo.chromLengths[chrLeft] - 10000;
                      }
                      if (stop > chromInfo.chromLengths[chrRight]) {
                        stop = chromInfo.chromLengths[chrRight] - 1000;
                      }
                      let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
                      let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
                      //console.log(chrLeft, start, absLeft);
                      //console.log(chrRight, stop, absRight);
                      res.data.views[0].initialXDomain = [absLeft, absRight];
                      res.data.views[0].initialYDomain = [absLeft, absRight];
                      // update track heights -- requires preknowledge of track order from template
                      let windowInnerHeight = document.documentElement.clientHeight + "px";
                      //console.log("windowInnerHeight", windowInnerHeight);
                      //newHgViewTrackNumSamplesHeight = (this.state.currentViewScale <= this.state.hgViewParams.dtt) ? newHgViewTrackNumSamplesHeight : 0;
                      let availableWindowInnerHeight = parseInt(windowInnerHeight) - 
                        parseInt(newHgViewTrackChromosomeHeight) - 
                        //parseInt(newHgViewTrackNumSamplesHeight) - 
                        parseInt(newHgViewTrackIndexDHSHeight) - 
                        //parseInt(newHgViewTrackDHSComponentsHeight) - 
                        parseInt(newHgViewTrackGeneAnnotationsHeight) - 
                        parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarMobileDeviceHeight) + "px";
                      //console.log("availableWindowInnerHeight", availableWindowInnerHeight);
                      let heightPerSample = parseFloat(parseInt(availableWindowInnerHeight) / samplesToBeRendered);
                      //console.log("heightPerSample", heightPerSample);
                      let heightPerTrack = samplesToBeRendered / componentsToBeRendered * heightPerSample;
                      //console.log("heightPerTrack", heightPerTrack);
                      // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
                      let topClone = update(res.data.views[0].tracks.top, {$push: []});
                      //console.log("topClone", topClone);
                      // build a new view from pieces of this clone
                      let newTop = [];
                      let newTopIdx = 0;
                      samplesToRenderAsTracks.forEach((sampleToRender) => {
                        //console.log("sampleToRender", sampleToRender);
                        newTop[newTopIdx] = update(topClone[0], {
                          server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                          // server: {$set: Constants.applicationEndpointRootURL},
                          height: {$set: heightPerTrack},
                          minHeight: {$set: 1},
                          name: {$set: sampleToRender.longName},
                          type: {$set: "horizontal-bar"},
                          resolutions: {$set: Constants.componentResolutions},
                          options: {
                            backgroundColor: {$set: "black"},
                            labelPosition: {$set: "topLeft"},
                            labelTextOpacity: {$set: 0.0},
                            labelBackgroundColor: {$set: "black"},
                            labelBackgroundOpacity: {$set: 0.0},
                            labelColor: {$set: "white"},
                            name: {$set: sampleToRender.longName},
                            valueScaling: {$set: "linear"},
                            //lineStrokeColor: {$set: sampleToRender.color},
                            barFillColor: {$set: sampleToRender.color},
                            showTooltip: {$set: true},
                            showMousePosition: {$set: true},
                          },
                          tilesetUid: {$set: sampleToRender.uuid},
                          uid: {$set: uuid4()},
                        });
                        newTopIdx += 1;
                      })
                      //
                      // add chromosome track
                      //
                      newTop[newTopIdx] = update(topClone[1], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: newHgViewTrackChromosomeHeight},
                        tilesetUid: {$set: newChromsizesUUID},
                        uid: {$set: uuid4()},
                        options: {
                          backgroundColor: {$set: "white"},
                          showMousePosition: {$set: true},
                        }
                      });
                      newTopIdx += 1;
                      //
                      // add numSamples track
                      //
/*
                      let numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamples];
                      let numSamplesTrackType = Constants.viewerHgViewconfNumSamplesTrackType;
                      let numSamplesValueScaleMin = Constants.viewerHgViewconfNumSamplesValueScaleMin[newNumSamples];
                      let numSamplesValueScaleMax = Constants.viewerHgViewconfNumSamplesValueScaleMax[newNumSamples];
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                        height: {$set: newHgViewTrackNumSamplesHeight},
                        type: {$set: numSamplesTrackType},
                        tilesetUid: {$set: numSamplesUUID},
                        uid: {$set: uuid4()},
                        options: {
                          minHeight: {$set: 0},
                          backgroundColor: {$set: "rgb(242,242,242)"},
                          axisLabelFormatting: {$set: "normal"},
                          axisPositionHorizontal: {$set: "outsideRight"},
                          barFillColor: {$set: "black"},
                          pointColor: {$set: "black"},
                          pointSize: {$set: 4},
                          zeroLineVisible: {$set: true},
                          zeroLineColor: {$set: "black"},
                          zeroLineOpacity: {$set: 0.0},
                          valueScaling: {$set: "log"},
                          valueScaleMin: {$set: numSamplesValueScaleMin},
                          valueScaleMax: {$set: numSamplesValueScaleMax},
                          valueFormat: {$set: "d"},
                          aggregationMode: {$set: "median"},
                          showTooltip: {$set: true},
                          showMousePosition: {$set: true}
                        }
                      });
                      newTopIdx += 1;
*/
                      //
                      // add Index DHS track
                      //
                      switch (self.state.hgViewParams.itt) {
                        case Constants.applicationIttModes.bb:
                          // bigBed
                          newTop[newTopIdx] = update(topClone[2], {
                            server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                            // server: {$set: Constants.applicationEndpointRootURL},
                            height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                            type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                            tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                            uid: {$set: uuid4()},
                            options: {
                              minHeight: {$set: 0},
                              backgroundColor: {$set: "white"},
                              isBarPlotLike: {$set: true},
                              itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                              showMousePosition: {$set: true},
                            }
                          });
                          break;
                        case 'ht':
                          // higlass-transcripts
                          newTop[newTopIdx] = update(topClone[2], {
                            server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                            height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                            type: {$set: "horizontal-transcripts"},
                            tilesetUid: {$set: "D5k7ajwfT9mzwbybaSS0VA"},
                            uid: {$set: uuid4()},
                            options: {
                              showMousePosition: {$set: true},
                              blockStyle: {$set: "boxplot"},
                              showToggleTranscriptsButton: {$set: false},
                              labelFontSize: {$set: "11"},
                              labelFontWeight: {$set: "500"},
                              maxTexts: {$set: 100},
                              itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                              startCollapsed: {$set: false},
                              trackMargin: {$set: {
                                top: 10,
                                bottom: 10,
                                left: 0,
                                right: 0,
                              }},
                              transcriptHeight: {$set: 16},
                              transcriptSpacing: {$set: 5},
                              isVisible: {$set: true},
                            }
                          });
                          break;
                        default:
                          throw new URIError(`Unknown Index DHS track type (itt) mode ${self.state.hgViewParams.itt}`);
                      }
                      newTopIdx += 1;
                      //console.warn(`Adding DHS index track... ${this.state.currentViewScale} vs ${this.state.hgViewParams.dtt}`);
                      // newTop[newTopIdx] = update(topClone[2], {
                      //   server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                      //   height: {$set: newHgViewTrackIndexDHSHeight},
                      //   type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                      //   tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                      //   uid: {$set: uuid4()},
                      //   options: {
                      //     minHeight: {$set: 0},
                      //     backgroundColor: {$set: "white"},
                      //     isBarPlotLike: {$set: true},
                      //     itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                      //     showMousePosition: {$set: true},
                      //   }
                      // });
                      // newTopIdx += 1;
                      //
                      // add all-DHS Component track
                      //
                      //console.warn(`all-DHS track height ${newHgViewTrackDHSComponentsHeight}`);
/*
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: newHgViewTrackDHSComponentsHeight},
                        type: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[this.state.hgViewParams.dtc]['type']},
                        tilesetUid: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[this.state.hgViewParams.dtc]['uuid']},
                        uid: {$set: uuid4()},
                        options: {
                          minHeight: {$set: 0},
                          backgroundColor: {$set: "white"},
                          colorRange: {$set: Constants.dhsComponentColorsAsRgb},
                          colorLabels: {$set: Constants.dhsComponentLabels},
                          showMousePosition: {$set: true},
                          valueScaling: {$set: null},
                          valueScaleMin: {$set: 1},
                          valueScaleMax: {$set: 17},
                          valueScaleNaN: {$set: 17},
                          zeroValueColor: {$set: "white"},
                          heatmapValueScaling: {$set: "categorical"},
                          colorbarPosition: {$set: null}
                        }
                      });
                      newTopIdx += 1;
*/
                      //
                      // add gene annotation track
                      //
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: newHgViewTrackGeneAnnotationsHeight},
                        tilesetUid: {$set: newGenesUUID},
                        uid: {$set: uuid4()},
                        options: {
                          backgroundColor: {$set: "white"},
                          plusStrandColor: {$set: "black"},
                          minusStrandColor: {$set: "black"},
                          showMousePosition: {$set: true},
                        }
                      });
                      // replace the top view tracks with the newly build track layout
                      //console.log("updated newTop", JSON.stringify(newTop, null, 2));
                      res.data.views[0].tracks.top = newTop;
                      // confirm layout
                      //console.log("updated res.data", JSON.stringify(res.data, null, 2));
                      // lock vertical scale of per-component signals
                      if (Constants.lockPwModeVerticalScale) {
                        // locksByViewUid
                        const viewUid = res.data.views[0].uid;
                        const newLockUid = uuid4();
                        let newLocksByViewUid = {};
                        Object.keys(Constants.components).forEach((c, ci) => {
                          let newLockViewKey = `${viewUid}.${res.data.views[0].tracks.top[ci].uid}`;
                          newLocksByViewUid[newLockViewKey] = newLockUid;
                        });
                        res.data.valueScaleLocks.locksByViewUid = newLocksByViewUid;
                        // locksDict
                        let newLocksDict = {};
                        //newLocksDict.ignoreOffScreenValues = true;
                        newLocksDict[newLockUid] = {};
                        newLocksDict[newLockUid].uid = newLockUid;
                        newLocksDict[newLockUid].ignoreOffScreenValues = true;
                        Object.keys(Constants.components).forEach((c, ci) => {
                          let newLockDictKey = `${viewUid}.${res.data.views[0].tracks.top[ci].uid}`;
                          newLocksDict[newLockUid][newLockDictKey] = {};
                          newLocksDict[newLockUid][newLockDictKey].view = viewUid;
                          newLocksDict[newLockUid][newLockDictKey].track = res.data.views[0].tracks.top[ci].uid;
                          //newLocksDict[newLockUid][newLockDictKey].ignoreOffScreenValues = true;
                        });
                        res.data.valueScaleLocks.locksDict = newLocksDict;
                      }
                      // get child view heights
                      const childViews = res.data.views[0].tracks.top;
                      let childViewHeightTotal = 0;
                      childViews.forEach((cv) => { childViewHeightTotal += cv.height });
                      childViewHeightTotal += 10;
                      let childViewHeightTotalPx = childViewHeightTotal + "px";
                      //console.log("childViewHeightTotalPx", childViewHeightTotalPx);
                      
                      // update Viewer state
                      //console.log("Updating hgViewconf from new res.data", JSON.stringify(res.data));
                      self.setState({
                        hgViewParams: newHgViewParams,
                        hgViewHeight: childViewHeightTotalPx,
                        hgViewconf: res.data,
                        currentPositionKey: Math.random(),
                        currentPosition : {
                          chrLeft : chrLeft,
                          chrRight : chrRight,
                          startLeft : parseInt(start),
                          stopLeft : parseInt(stop),
                          startRight : parseInt(start),
                          stopRight : parseInt(stop)
                        },
                        selectedProteinCodingsRowIdx: -1,
                        selectedTranscriptionFactorsRowIdx: -1,
                        selectedRoiRowIdx: -1,
                        trackLabelsVisible: true,
                      }, () => {
                        self.setState({
                          hgViewKey: self.state.hgViewKey + 1,
                          //drawerContentKey: this.state.drawerContentKey + 1,
                          positionSummaryElementKey: self.state.positionSummaryElementKey + 1,
                        }, () => {
                          // update browser history (address bar URL)
                          //console.log("calling [updateViewerURL] from [triggerUpdate (pw, portrait)]");
                          self.updateViewerURL(
                            self.state.hgViewParams.mode,
                            self.state.currentPosition.chrLeft,
                            self.state.currentPosition.chrRight,
                            self.state.currentPosition.startLeft,
                            self.state.currentPosition.stopRight,
                            self.state.hgViewParams.numSamples);
                          // add location event handler
                          self.hgView.api.on("location", (event) => { 
                            self.updateViewerLocation(event);
                          });
                        })
                      })
                    }
                      
                    if (chromInfoCacheExists) {
                      updateViewerStateForPwModeAndChromInfoInPortraitOrientation(this.chromInfoCache[newGenome], this);
                    }
                    else {
                      let chromSizesURL = this.getChromSizesURL(newGenome);
                      //console.log(`Viewer > updateViewerStateForPwModeAndChromInfoInPortraitOrientation > chromSizesURL ${chromSizesURL}`);
                      ChromosomeInfo(chromSizesURL)
                        .then((chromInfo) => {
                          this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                          updateViewerStateForPwModeAndChromInfoInPortraitOrientation(chromInfo, this);
                        })
                        .catch((err) => {
                          let msg = this.errorMessage(err, `Viewer > updateViewerStateForPwModeAndChromInfoInPortraitOrientation > Could not retrieve chromosome information`, chromSizesURL);
                          this.setState({
                            overlayMessage: msg,
                            hgViewconf: {}
                          }, () => {
                            this.fadeInOverlay();
                          });
                        });
                    }
                  })
                  .catch((err) => {
                    //console.log(err.response);
                    let msg = this.errorMessage(err, `Could not retrieve view configuration`, newHgViewconfURL);
                    this.setState({
                      overlayMessage: msg,
                      hgViewconf: {}
                    }, () => {
                      this.fadeInOverlay();
                    });
                  });
              })
              .catch((err) => {
                throw new Error(`Could not retrieve UUIDs for all specified components - ${JSON.stringify(err)}`);
              });
          }
          else {
            //
            // condensed (landscape) display
            //
            // console.log("showing per-component view in Landscape mode");
            //
            // get all active per-component items
            //
            let samplesToRenderAsTracks = [];
            let samplePromises = [];
            Object.keys(Constants.components).forEach((d) => {
              if (Constants.components[d].active) {
                let o = Constants.components[d];
                let i = o.componentIndex;
                o['name'] = d;
                o['filename'] = `WM20181113_signal_track_per_component.comp${i}.bw`
                samplesToRenderAsTracks.push(o);
                let endpoint = Constants.applicationHiGlassDevServerEndpointRootURL;
                // let endpoint = Constants.applicationEndpointRootURL;
                samplePromises.push(Helpers.uuidQueryPromise(o['filename'], endpoint, this));
              }
            });
            // console.log("samplesToRenderAsTracks", samplesToRenderAsTracks);
            Promise.all(samplePromises).then((v) => {
                let uuid = {};
                v.forEach((d) => {
                  uuid[d.fn] = d.uuid;
                });
                samplesToRenderAsTracks.forEach((d) => {
                  d['uuid'] = uuid[d['filename']];
                });
                // console.log(samplesToRenderAsTracks);
                // let samplesToBeRendered = samplesToRenderAsTracks.reduce((d, c) => { return parseInt(d + c.samples) }, 0);
                // let componentsToBeRendered = samplePromises.length;
                // console.log("samplesToBeRendered", samplesToBeRendered);
                // console.log("componentsToBeRendered", componentsToBeRendered);
                // console.log("newHgViewconfURL", newHgViewconfURL);
                axios.get(newHgViewconfURL)
                  .then((res) => {
                    if (!res.data) {
                      throw String("Error: New viewconf not returned from query to " + newHgViewconfURL);
                    }
                    
                    //console.log("old res.data", res.data);
                    
                    // ensure the template is not editable
                    res.data.editable = false;
                    
                    // update hgview parameters
                    newHgViewParams.genome = newGenome;
                    newHgViewParams.mode = newMode;
                    
                    let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
                    let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
                    let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
                    let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
                    // console.log("position: ", chrLeft, chrRight, start, stop);

                    function updateViewerStateForPwModeAndChromInfoInLandscapeOrientation(chromInfo, self) {
                      // console.log("chromInfo", chromInfo);
                      if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                        chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                        chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                        start = Constants.defaultApplicationPositions[newGenome].start;
                        stop = Constants.defaultApplicationPositions[newGenome].stop;
                      }
                      if (start > chromInfo.chromLengths[chrLeft]) {
                        start = chromInfo.chromLengths[chrLeft] - 10000;
                      }
                      if (stop > chromInfo.chromLengths[chrRight]) {
                        stop = chromInfo.chromLengths[chrRight] - 1000;
                      }
                      let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
                      let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
                      // console.log(chrLeft, start, absLeft);
                      // console.log(chrRight, stop, absRight);
                      res.data.views[0].initialXDomain = [absLeft, absRight];
                      res.data.views[0].initialYDomain = [absLeft, absRight];
                      // update track heights -- requires preknowledge of track order from template
                      let windowInnerHeight = document.documentElement.clientHeight + "px";
                      // console.log("windowInnerHeight", windowInnerHeight);
                      // newHgViewTrackNumSamplesHeight = (this.state.currentViewScale <= this.state.hgViewParams.dtt) ? newHgViewTrackNumSamplesHeight : 0;
                      let availableWindowInnerHeight = parseInt(windowInnerHeight) - 
                        parseInt(newHgViewTrackChromosomeHeight) - 
                        // parseInt(newHgViewTrackNumSamplesHeight) - 
                        parseInt(newHgViewTrackIndexDHSHeight) - 
                        // parseInt(newHgViewTrackDHSComponentsHeight) - 
                        parseInt(newHgViewTrackGeneAnnotationsHeight) - 
                        parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarMobileDeviceHeight) + "px";
                      // console.log("availableWindowInnerHeight", availableWindowInnerHeight);
                      // let heightPerSample = parseFloat(parseInt(availableWindowInnerHeight) / samplesToBeRendered);
                      // console.log("heightPerSample", heightPerSample);
                      // let heightPerTrack = samplesToBeRendered / componentsToBeRendered * heightPerSample;
                      // console.log("heightPerTrack", heightPerTrack);
                      // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
                      let topClone = update(res.data.views[0].tracks.top, {$push: []});
                      // console.log("topClone", topClone);
                      // build a new view from pieces of this clone
                      let newCombinedTop = [];
                      let newCombinedTopIdx = 0;
                      samplesToRenderAsTracks.forEach((sampleToRender) => {
                        newCombinedTop[newCombinedTopIdx] = update(topClone[0], {
                          server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                          // server: {$set: Constants.applicationEndpointRootURL},
                          height: {$set: parseInt(availableWindowInnerHeight)},
                          width: {$set: parseInt(self.state.width)},
                          //minHeight: {$set: 1},
                          name: {$set: sampleToRender.longName},
                          type: {$set: "horizontal-bar"},
                          //resolutions: {$set: Constants.componentResolutions},
                          options: {
                            //align: {$set: "bottom"},
                            axisLabelFormatting: {$set: "normal"},
                            axisPositionHorizontal: {$set: "outsideRight"},
                            name: {$set: sampleToRender.longName},
                            backgroundColor: {$set: "transparent"},
                            labelPosition: {$set: "topLeft"},
                            labelTextOpacity: {$set: 0.0},
                            labelBackgroundColor: {$set: "black"},
                            labelBackgroundOpacity: {$set: 0.0},
                            labelColor: {$set: "white"},
                            valueScaling: {$set: "linear"},
                            //lineStrokeColor: {$set: sampleToRender.color},
                            barFillColor: {$set: sampleToRender.color},
                            barOpacity: {$set: 0.65},
                            showTooltip: {$set: true},
                            showMousePosition: {$set: true},
                          },
                          tilesetUid: {$set: sampleToRender.uuid},
                          uid: {$set: uuid4()},
                        });
                        newCombinedTopIdx += 1;
                      });
                      let newTop = [];
                      let newTopIdx = 0;
                      //
                      // add chromosome track
                      //
                      newTop[newTopIdx] = update(topClone[1], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: newHgViewTrackChromosomeHeight},
                        tilesetUid: {$set: newChromsizesUUID},
                        uid: {$set: uuid4()},
                        options: {
                          name: {$set: "chromosome"},
                          backgroundColor: {$set: "white"},
                          showMousePosition: {$set: true},
                        }
                      });
                      newTopIdx += 1;
                      //
                      // add combined track
                      //
                      newTop[newTopIdx] = update(topClone[0], {
                        name: {$set: "combined-components"},
                        type: {$set: "combined"},
                        uid: {$set: uuid4()},
                        height: {$set: parseInt(availableWindowInnerHeight)},
                        width: {$set: parseInt(self.state.width)},
                        contents: {$set: newCombinedTop},
                        options: {$set: {}}
                      });
                      let combinedTrackIdx = newTopIdx;
                      newTopIdx += 1;
                      //
                      // add numSamples track
                      //
/*
                      let numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamples];
                      let numSamplesTrackType = Constants.viewerHgViewconfNumSamplesTrackType;
                      let numSamplesValueScaleMin = Constants.viewerHgViewconfNumSamplesValueScaleMin[newNumSamples];
                      let numSamplesValueScaleMax = Constants.viewerHgViewconfNumSamplesValueScaleMax[newNumSamples];
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                        height: {$set: newHgViewTrackNumSamplesHeight},
                        type: {$set: numSamplesTrackType},
                        tilesetUid: {$set: numSamplesUUID},
                        uid: {$set: uuid4()},
                        options: {
                          name: {$set: "number-of-samples-per-DHS"},
                          minHeight: {$set: 0},
                          backgroundColor: {$set: "rgb(242,242,242)"},
                          axisLabelFormatting: {$set: "normal"},
                          axisPositionHorizontal: {$set: "outsideRight"},
                          barFillColor: {$set: "black"},
                          pointColor: {$set: "black"},
                          pointSize: {$set: 4},
                          zeroLineVisible: {$set: true},
                          zeroLineColor: {$set: "black"},
                          zeroLineOpacity: {$set: 0.0},
                          valueScaling: {$set: "log"},
                          valueScaleMin: {$set: numSamplesValueScaleMin},
                          valueScaleMax: {$set: numSamplesValueScaleMax},
                          valueFormat: {$set: "d"},
                          aggregationMode: {$set: "median"},
                          showTooltip: {$set: true},
                          showMousePosition: {$set: true}
                        }
                      });
                      newTopIdx += 1;
*/
                      //
                      // add Index DHS track
                      //
                      switch (self.state.hgViewParams.itt) {
                        case Constants.applicationIttModes.bb:
                          // bigBed
                          newTop[newTopIdx] = update(topClone[2], {
                            server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                            // server: {$set: Constants.applicationEndpointRootURL},
                            height: {$set: newHgViewTrackIndexDHSHeight},
                            type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                            tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                            uid: {$set: uuid4()},
                            options: {
                              minHeight: {$set: 0},
                              backgroundColor: {$set: "white"},
                              isBarPlotLike: {$set: true},
                              itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                              showMousePosition: {$set: true},
                            }
                          });
                          break;
                        case Constants.applicationIttModes.ht:
                          // higlass-transcripts
                          newTop[newTopIdx] = update(topClone[2], {
                            server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                            height: {$set: newHgViewTrackIndexDHSHeight},
                            type: {$set: "horizontal-transcripts"},
                            tilesetUid: {$set: "D5k7ajwfT9mzwbybaSS0VA"},
                            uid: {$set: uuid4()},
                            options: {
                              showMousePosition: {$set: true},
                              blockStyle: {$set: "boxplot"},
                              showToggleTranscriptsButton: {$set: false},
                              labelFontSize: {$set: "11"},
                              labelFontWeight: {$set: "500"},
                              maxTexts: {$set: 100},
                              itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                              startCollapsed: {$set: false},
                              trackMargin: {$set: {
                                top: 10,
                                bottom: 10,
                                left: 0,
                                right: 0,
                              }},
                              transcriptHeight: {$set: 16},
                              transcriptSpacing: {$set: 5},
                              isVisible: {$set: true},
                            }
                          });
                          break;
                        default:
                          throw new URIError(`Unknown Index DHS track type (itt) mode ${self.state.hgViewParams.itt}`);
                      }
                      newTopIdx += 1;

                      //console.warn(`Adding DHS index track... ${this.state.currentViewScale} vs ${this.state.hgViewParams.dtt}`);
                      // newTop[newTopIdx] = update(topClone[2], {
                      //   server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                      //   height: {$set: newHgViewTrackIndexDHSHeight},
                      //   type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                      //   tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                      //   uid: {$set: uuid4()},
                      //   options: {
                      //     name: {$set: "Index-DHS"},
                      //     minHeight: {$set: 0},
                      //     backgroundColor: {$set: "white"},
                      //     isBarPlotLike: {$set: true},
                      //     itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                      //     showMousePosition: {$set: true},
                      //   }
                      // });
                      // newTopIdx += 1;
                      //
                      // add all-DHS Component track
                      //
                      //console.warn(`all-DHS track height ${newHgViewTrackDHSComponentsHeight}`);
/*
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: newHgViewTrackDHSComponentsHeight},
                        type: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[this.state.hgViewParams.dtc]['type']},
                        tilesetUid: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[this.state.hgViewParams.dtc]['uuid']},
                        uid: {$set: uuid4()},
                        options: {
                          name: {$set: "DHS-domain"},
                          minHeight: {$set: 0},
                          backgroundColor: {$set: "white"},
                          colorRange: {$set: Constants.dhsComponentColorsAsRgb},
                          colorLabels: {$set: Constants.dhsComponentLabels},
                          showMousePosition: {$set: true},
                          valueScaling: {$set: null},
                          valueScaleMin: {$set: 1},
                          valueScaleMax: {$set: 17},
                          valueScaleNaN: {$set: 17},
                          zeroValueColor: {$set: "white"},
                          heatmapValueScaling: {$set: "categorical"},
                          colorbarPosition: {$set: null}
                        }
                      });
                      newTopIdx += 1;
*/
                      //
                      // add gene annotation track
                      //
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: newHgViewTrackGeneAnnotationsHeight},
                        tilesetUid: {$set: newGenesUUID},
                        uid: {$set: uuid4()},
                        options: {
                          name: {$set: "gene-annotations"},
                          backgroundColor: {$set: "white"},
                          plusStrandColor: {$set: "black"},
                          minusStrandColor: {$set: "black"},
                          showMousePosition: {$set: true},
                        }
                      });
                      // replace the top view tracks with the newly build track layout
                      // console.log("updated newTop", JSON.stringify(newTop, null, 2));
                      res.data.views[0].tracks.top = newTop;
                      // confirm layout
                      // console.log("updated res.data", JSON.stringify(res.data, null, 2));

                      // lock vertical scale of per-component signals
                      if (Constants.lockPwModeVerticalScale) {
                        // locksByViewUid
                        const viewUid = res.data.views[0].uid;
                        const newLockUid = uuid4();
                        let newLocksByViewUid = {};
                        Object.keys(Constants.components).forEach((c, ci) => {
                          try {
                            if (res.data.views[0].tracks.top[combinedTrackIdx].contents[ci]) {
                              let newLockViewKey = `${viewUid}.${res.data.views[0].tracks.top[combinedTrackIdx].contents[ci].uid}`;
                              newLocksByViewUid[newLockViewKey] = newLockUid;
                            }
                          }
                          catch(err) {
                            console.log(`${err}`);
                            throw new Error(`Could not set locks dictionary for lock uid ${newLockUid} view uid ${viewUid} track index ${ci}`);
                          }
                        });
                        res.data.valueScaleLocks.locksByViewUid = newLocksByViewUid;
                        // locksDict
                        let newLocksDict = {};
                        //newLocksDict.ignoreOffScreenValues = true;
                        newLocksDict[newLockUid] = {};
                        newLocksDict[newLockUid].uid = newLockUid;
                        newLocksDict[newLockUid].ignoreOffScreenValues = true;
                        Object.keys(Constants.components).forEach((c, ci) => {
                          try {
                            if (res.data.views[0].tracks.top[combinedTrackIdx].contents[ci]) {
                              let newLockDictKey = `${viewUid}.${res.data.views[0].tracks.top[combinedTrackIdx].contents[ci].uid}`;
                              newLocksDict[newLockUid][newLockDictKey] = {};
                              newLocksDict[newLockUid][newLockDictKey].view = viewUid;
                              newLocksDict[newLockUid][newLockDictKey].track = res.data.views[0].tracks.top[combinedTrackIdx].contents[ci].uid;
                              // newLocksDict[newLockUid][newLockDictKey].ignoreOffScreenValues = true;
                            }
                          }
                          catch(err) {
                            throw new Error(`Could not set locks dictionary for lock uid ${newLockUid} view uid ${viewUid} track index ${ci}`);
                          }
                        });
                        res.data.valueScaleLocks.locksDict = newLocksDict;
                      }

                      // get child view heights
                      const childViews = res.data.views[0].tracks.top;
                      let childViewHeightTotal = 0;
                      childViews.forEach((cv) => { childViewHeightTotal += cv.height });
                      childViewHeightTotal += 10;
                      let childViewHeightTotalPx = childViewHeightTotal + "px";
                      // console.log("childViewHeightTotalPx", childViewHeightTotalPx);

                      // update Viewer state
                      // console.log("Updating hgViewconf from new res.data", JSON.stringify(res.data));
                      self.setState({
                        hgViewParams: newHgViewParams,
                        hgViewHeight: childViewHeightTotalPx,
                        hgViewconf: res.data,
                        currentPositionKey: Math.random(),
                        currentPosition : {
                          chrLeft : chrLeft,
                          chrRight : chrRight,
                          startLeft : parseInt(start),
                          stopLeft : parseInt(stop),
                          startRight : parseInt(start),
                          stopRight : parseInt(stop)
                        },
                        selectedProteinCodingsRowIdx: -1,
                        selectedTranscriptionFactorsRowIdx: -1,
                        selectedRoiRowIdx: -1,
                        trackLabelsVisible: false,
                      }, () => {
                        self.setState({
                          hgViewKey: self.state.hgViewKey + 1,
                          //drawerContentKey: self.state.drawerContentKey + 1,
                          positionSummaryElementKey: self.state.positionSummaryElementKey + 1,
                        }, () => {
                          // update browser history (address bar URL)
                          //console.log("calling [updateViewerURL] from [triggerUpdate]");
                          self.updateViewerURL(
                            self.state.hgViewParams.mode,
                            self.state.currentPosition.chrLeft,
                            self.state.currentPosition.chrRight,
                            self.state.currentPosition.startLeft,
                            self.state.currentPosition.stopRight,
                            self.state.hgViewParams.numSamples);
                          // add location event handler
                          self.hgView.api.on("location", (event) => { 
                            self.updateViewerLocation(event);
                          });
                        })
                      })
                    }
                    
                    if (chromInfoCacheExists) {
                      updateViewerStateForPwModeAndChromInfoInLandscapeOrientation(this.chromInfoCache[newGenome], this);
                    }
                    else {
                      let chromSizesURL = this.getChromSizesURL(newGenome);
                      //console.log(`Viewer > updateViewerStateForPwModeAndChromInfoInLandscapeOrientation > chromSizesURL ${chromSizesURL}`);
                      ChromosomeInfo(chromSizesURL)
                        .then((chromInfo) => {
                          this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                          updateViewerStateForPwModeAndChromInfoInLandscapeOrientation(chromInfo, this);
                        })
                        .catch((err) => {
                          let msg = this.errorMessage(err, `Viewer > updateViewerStateForPwModeAndChromInfoInLandscapeOrientation > Could not retrieve chromosome information`, chromSizesURL);
                          this.setState({
                            overlayMessage: msg,
                            hgViewconf: {}
                          }, () => {
                            this.fadeInOverlay();
                          });
                        });
                    }
                  })
                  .catch((err) => {
                    //console.log(err.response);
                    let msg = this.errorMessage(err, `Could not retrieve view configuration`, newHgViewconfURL);
                    this.setState({
                      overlayMessage: msg,
                      hgViewconf: {}
                    }, () => {
                      this.fadeInOverlay();
                    });
                  });
              })
              .catch((err) => {
                throw new Error(`Could not retrieve UUIDs for all specified components - ${JSON.stringify(err)}`);
              });
          }
          break;
        }
        case "co": {
          //console.log("newHgViewconfURL", newHgViewconfURL);
          axios.get(newHgViewconfURL)
            .then((res) => {
              if (!res.data) {
                throw String("Error: New viewconf not returned from query to " + newHgViewconfURL);
              }
              
              //console.log("old res.data", res.data);
              
              // ensure the template is not editable
              res.data.editable = false;
              
              // update hgview parameters
              newHgViewParams.genome = newGenome;
              newHgViewParams.mode = newMode;
              
              let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
              let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
              let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
              let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
              //console.log("position: ", chrLeft, chrRight, start, stop);

              function updateViewerStateForCoModeAndChromInfo(chromInfo, self) {
                //console.log("chromInfo", chromInfo);
                if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                  chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                  chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                  start = Constants.defaultApplicationPositions[newGenome].start;
                  stop = Constants.defaultApplicationPositions[newGenome].stop;
                }
                if (start > chromInfo.chromLengths[chrLeft]) {
                  start = chromInfo.chromLengths[chrLeft] - 10000;
                }
                if (stop > chromInfo.chromLengths[chrRight]) {
                  stop = chromInfo.chromLengths[chrRight] - 1000;
                }
                let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
                let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
                //console.log(chrLeft, start, absLeft);
                //console.log(chrRight, stop, absRight);
                res.data.views[0].initialXDomain = [absLeft, absRight];
                res.data.views[0].initialYDomain = [absLeft, absRight];
                // update track heights -- requires preknowledge of track order from template
                let windowInnerHeight = `${parseInt(window.innerHeight)}px`;
                //console.log("windowInnerHeight", windowInnerHeight);
                //newHgViewTrackNumSamplesHeight = (this.state.currentViewScale <= this.state.hgViewParams.dtt) ? newHgViewTrackNumSamplesHeight : 0;
                let availableWindowInnerHeight = parseInt(windowInnerHeight) - 
                  parseInt(newHgViewTrackChromosomeHeight) - 
                  parseInt(newHgViewTrackGeneAnnotationsHeight) - 
                  parseInt(newHgViewTrackChromosomeHeight) - 
                  parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarMobileDeviceHeight) + "px";
                //console.log("availableWindowInnerHeight", availableWindowInnerHeight);
                newHgViewTrackDHSComponentsHeight = parseInt(availableWindowInnerHeight);
                //console.log("heightPerTrack", heightPerTrack);
                // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
                let topClone = update(res.data.views[0].tracks.top, {$push: []});
                //console.log("topClone", topClone);
                // build a new view from pieces of this clone
                let newTop = [];
                let newTopIdx = 0;
                //
                // add chromosome track
                //
                newTop[newTopIdx] = update(topClone[1], {
                  server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  height: {$set: newHgViewTrackChromosomeHeight},
                  tilesetUid: {$set: newChromsizesUUID},
                  uid: {$set: uuid4()},
                  options: {
                    backgroundColor: {$set: "white"},
                    showMousePosition: {$set: true},
                  }
                });
                newTopIdx += 1;
                //
                // add all-DHS Component track
                //
                //console.warn(`all-DHS track height ${newHgViewTrackDHSComponentsHeight}`);
                newTop[newTopIdx] = update(topClone[2], {
                  name: {$set: "DHS Components"},
                  server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  height: {$set: newHgViewTrackDHSComponentsHeight},
                  type: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['type']},
                  tilesetUid: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['uuid']},
                  uid: {$set: uuid4()},
                  options: {
                    minHeight: {$set: 0},
                    backgroundColor: {$set: "white"},
                    colorRange: {$set: Constants.dhsComponentColorsAsRgb},
                    colorLabels: {$set: Constants.dhsComponentLabels},
                    showMousePosition: {$set: true},
                    valueScaling: {$set: null},
                    valueScaleMin: {$set: 1},
                    valueScaleMax: {$set: 17},
                    valueScaleNaN: {$set: 17},
                    zeroValueColor: {$set: "white"},
                    heatmapValueScaling: {$set: "categorical"},
                    colorbarPosition: {$set: null}
                  }
                });
                newTopIdx += 1;
                //
                // add gene annotation track
                //
                newTop[newTopIdx] = update(topClone[2], {
                  name: {$set: "Gene annotations"},
                  server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  height: {$set: newHgViewTrackGeneAnnotationsHeight - 30},
                  tilesetUid: {$set: newGenesUUID},
                  uid: {$set: uuid4()},
                  options: {
                    backgroundColor: {$set: "white"},
                    plusStrandColor: {$set: "black"},
                    minusStrandColor: {$set: "black"},
                    showMousePosition: {$set: true},
                  }
                });
                newTopIdx += 1;
                //
                // add chromosome track
                //
                newTop[newTopIdx] = update(topClone[1], {
                  server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  height: {$set: newHgViewTrackChromosomeHeight},
                  tilesetUid: {$set: newChromsizesUUID},
                  uid: {$set: uuid4()},
                  options: {
                    backgroundColor: {$set: "white"},
                    showMousePosition: {$set: true},
                  }
                });
                newTopIdx += 1;
                // replace the top view tracks with the newly build track layout
                //console.log("updated newTop", JSON.stringify(newTop, null, 2));
                res.data.views[0].tracks.top = newTop;
                // confirm layout
                //console.log("updated res.data", JSON.stringify(res.data, null, 2));

                // get child view heights
                const childViews = res.data.views[0].tracks.top;
                let childViewHeightTotal = 0;
                childViews.forEach((cv) => { childViewHeightTotal += cv.height });
                childViewHeightTotal += 10;
                let childViewHeightTotalPx = childViewHeightTotal + "px";
                //console.log("childViewHeightTotalPx", childViewHeightTotalPx);
                
                // update Viewer state
                //console.log("Updating hgViewconf from new res.data");
                self.setState({
                  hgViewParams: newHgViewParams,
                  hgViewHeight: childViewHeightTotalPx,
                  hgViewconf: res.data,
                  currentPositionKey: Math.random(),
                  currentPosition : {
                    chrLeft : chrLeft,
                    chrRight : chrRight,
                    startLeft : parseInt(start),
                    stopLeft : parseInt(stop),
                    startRight : parseInt(start),
                    stopRight : parseInt(stop)
                  },
                  selectedProteinCodingsRowIdx: -1,
                  selectedTranscriptionFactorsRowIdx: -1,
                  selectedRoiRowIdx: -1,
                  trackLabelsVisible: false,
                }, () => {
                  self.setState({
                    hgViewKey: self.state.hgViewKey + 1,
                    //drawerContentKey: self.state.drawerContentKey + 1,
                    positionSummaryElementKey: self.state.positionSummaryElementKey + 1,
                  }, () => {
                    // update browser history (address bar URL)
                    //console.log("calling [updateViewerURL] from [triggerUpdate]");
                    self.updateViewerURL(
                      self.state.hgViewParams.mode,
                      self.state.currentPosition.chrLeft,
                      self.state.currentPosition.chrRight,
                      self.state.currentPosition.startLeft,
                      self.state.currentPosition.stopRight,
                      self.state.hgViewParams.numSamples);
                    // add location event handler
                    self.hgView.api.on("location", (event) => { 
                      self.updateViewerLocation(event);
                    });
                  })
                })
              }
              
              if (chromInfoCacheExists) {
                updateViewerStateForCoModeAndChromInfo(this.chromInfoCache[newGenome], this);
              }
              else {
                let chromSizesURL = this.getChromSizesURL(newGenome);
                //console.log(`Viewer > updateViewerStateForCoModeAndChromInfo > chromSizesURL ${chromSizesURL}`);
                ChromosomeInfo(chromSizesURL)
                  .then((chromInfo) => {
                    this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                    updateViewerStateForCoModeAndChromInfo(chromInfo, this);
                  })
                  .catch((err) => {
                    let msg = this.errorMessage(err, `Viewer > updateViewerStateForCoModeAndChromInfo > Could not retrieve chromosome information`, chromSizesURL);
                    this.setState({
                      overlayMessage: msg,
                      hgViewconf: {}
                    }, () => {
                      this.fadeInOverlay();
                    });
                  });
              }
            })
            .catch((err) => {
              //console.log(err.response);
              let msg = this.errorMessage(err, `Could not retrieve view configuration`, newHgViewconfURL);
              this.setState({
                overlayMessage: msg,
                hgViewconf: {}
              }, () => {
                this.fadeInOverlay();
              });
            });
          break;
        }
        case "pm": {
          let newHgViewTrackNumSamplesHeightForPmMode = (this.state.orientationIsPortrait) ? Constants.viewerHgViewParameters.hgViewTrackSamplesWithDHSHeight : 0.001;
          // let newNumSamplesForPmMode = this.state.tempHgViewParams.numSamples;
          // get per-component multivec item
          let samplesToRenderAsTracks = [];
          let samplePromises = [];
          let pmSample = {
            "name" : "WM20181113_signal_track_per_component.hg38.multires.mv5",
            "filename" : "WM20181113_signal_track_per_component.hg38.multires.mv5",
            "componentIndex": 1,
            "samples": 1,
            "active": true,
            "longName": "All samples",
            "uuid": "HlXADoAsQt6tTm-NkrhWgw",
          };
          samplesToRenderAsTracks.push(pmSample);
          let endpoint = Constants.applicationHiGlassServerEndpointRootURL;
          samplePromises.push(Helpers.uuidQueryPromise(pmSample["filename"], endpoint, this));
          Promise.all(samplePromises).then(() => {
              let samplesToBeRendered = samplesToRenderAsTracks.reduce((d, c) => { return parseInt(d + c.samples) }, 0);
              axios.get(newHgViewconfURL)
              .then((res) => {
                if (!res.data) {
                  throw new Error("Error: New viewconf not returned from query to " + newHgViewconfURL);
                }
                
                // console.log("old res.data", res.data);
                
                // ensure the template is not editable
                res.data.editable = false;
                
                // update hgview parameters
                newHgViewParams.genome = newGenome;
                newHgViewParams.mode = newMode;
                
                let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
                let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
                let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
                let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
                //console.log("position: ", chrLeft, chrRight, start, stop);

                function updateViewerStateForPmModeAndChromInfo(chromInfo, self) {
                  if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                    chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                    chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                    start = Constants.defaultApplicationPositions[newGenome].start;
                    stop = Constants.defaultApplicationPositions[newGenome].stop;
                  }
                  if (start > chromInfo.chromLengths[chrLeft]) {
                    start = chromInfo.chromLengths[chrLeft] - 10000;
                  }
                  if (stop > chromInfo.chromLengths[chrRight]) {
                    stop = chromInfo.chromLengths[chrRight] - 1000;
                  }
                  let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
                  let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
                  // console.log("chrLeft, start, absLeft", chrLeft, start, absLeft);
                  // console.log("chrRight, stop, absRight", chrRight, stop, absRight);
                  res.data.views[0].initialXDomain = [absLeft, absRight];
                  res.data.views[0].initialYDomain = [absLeft, absRight];
                  // update track heights -- requires preknowledge of track order from template
                  let windowInnerHeight = document.documentElement.clientHeight + "px";
                  // console.log("windowInnerHeight", windowInnerHeight);
                  // console.log("newHgViewTrackNumSamplesHeight", newHgViewTrackNumSamplesHeight);
                  //let availableWindowInnerHeight = parseInt(windowInnerHeight) - newHgViewTrackChromosomeHeight - newHgViewTrackIndexAnnotationsHeight - newHgViewTrackNumSamplesHeight - newHgViewTrackGeneAnnotationsHeight - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                  //let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackNumSamplesHeightForPmMode) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackDHSComponentsHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                  let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackNumSamplesHeightForPmMode) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                  // console.log("availableWindowInnerHeight", availableWindowInnerHeight);
                  let heightPerSample = parseFloat(parseInt(availableWindowInnerHeight) / samplesToBeRendered);
                  // console.log("heightPerSample", heightPerSample);
                  
                  // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
                  let topClone = update(res.data.views[0].tracks.top, {$push: []});
                  // console.log("topClone", topClone);

                  // build a new view from pieces of this clone
                  let newTop = [];
                  let newTopIdx = 0;

                  samplesToRenderAsTracks.forEach((sampleToRender) => {
                    // console.log("sampleToRender", sampleToRender);
                    newTop[newTopIdx] = update(topClone[0], {
                      server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      height: {$set: parseInt(sampleToRender.samples * heightPerSample)},
                      name: {$set: "16ComponentStackedDelta"},
                      /* type: {$set: "horizontal-multivec"}, */
                      /* type: {$set: "basic-multiple-bar-chart"}, */
                      type: {$set: "horizontal-stacked-delta-bar"},
                      /* type: {$set: "horizontal-stacked-bar"}, */
                      // minHeight: {$set: 1},
                      // resolutions: {$set: Constants.componentResolutions},
                      options: {
                        minHeight: {$set: 0},
                        backgroundColor: {$set: "white"},
                        // colorRange: {$set: Constants.componentColorMapGreyscale},
                        colorRange: {$set: Constants.componentColorMapViridisV1},
                        colorScale: {$set: Constants.systemColorPalettesAsHex['hg38']['16']},
                        labelPosition: {$set: "topLeft"},
                        labelTextOpacity: {$set: 0.0},
                        labelBackgroundOpacity: {$set: 0.0},
                        labelColor: {$set: "white"},
                        valueScaling: {$set: "linear"},
                        valueScaleMin: {$set: 0.0},
                        valueScaleMax: {$set: parseFloat(self.state.hgViewParams.gmax)},
                        name: {$set: sampleToRender.longName},
                        showMousePosition: {$set: true},
                        colorbarPosition: {$set: null},
                        trackBorderWidth: {$set: 0},
                        trackBorderColor: {$set: "black"},
                        barBorder: {$set: false},
                        sortLargestOnTop: {$set: true},
                        fillOpacityMin: {$set: 0.2},
                        fillOpacityMax: {$set: 0.8},
                        chromInfo: {$set: self.chromInfoCache[newGenome]},
                        binSize: {$set: 20},
                      },
                      tilesetUid: {$set: sampleToRender.uuid},
                      uid: {$set: uuid4()},
                      resolutions: {$set: [13107200, 6553600, 3276800, 1638400, 819200, 409600, 204800, 102400, 51200, 25600, 12800, 6400, 3200, 1600, 800, 400, 200]},
                    });
                    newTopIdx += 1;
                  })

                  // console.log("post-samples-to-render newTop", newTop);

                  //
                  // add chromosome track
                  //
                  newTop[newTopIdx] = update(topClone[1], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    height: {$set: newHgViewTrackChromosomeHeight},
                    tilesetUid: {$set: newChromsizesUUID},
                    uid: {$set: uuid4()},
                    options: {
                      backgroundColor: {$set: "white"},
                      showMousePosition: {$set: true},
                    }
                  });
                  newTopIdx += 1;

                  // console.log("post-chromosome-track newTop", newTop);

                  //
                  // add numSamples track
                  //
                  // let numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamplesForPmMode];
                  // // console.log(`numSamplesUUID ${numSamplesUUID}`);
                  // // console.log(`self.newHgViewParams ${JSON.stringify(self.newHgViewParams, null, 2)}`);

                  // if (self.newHgViewParams && self.newHgViewParams.itt === "ht") {
                  //   numSamplesUUID = Constants.viewerHgViewconfFixedBinNumSamplesUUIDs[newNumSamplesForPmMode];
                  //   if (!numSamplesUUID) {
                  //     numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamplesForPmMode];
                  //   }
                  // }

                  // let numSamplesTrackType = Constants.viewerHgViewconfNumSamplesTrackType;
                  // let numSamplesValueScaleMin = Constants.viewerHgViewconfNumSamplesValueScaleMin[newNumSamplesForPmMode];
                  // let numSamplesValueScaleMax = Constants.viewerHgViewconfNumSamplesValueScaleMax[newNumSamplesForPmMode];
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackNumSamplesHeightForPmMode},
                  //   type: {$set: numSamplesTrackType},
                  //   tilesetUid: {$set: numSamplesUUID},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     minHeight: {$set: 0},
                  //     backgroundColor: {$set: "rgb(242,242,242)"},
                  //     axisLabelFormatting: {$set: "normal"},
                  //     axisPositionHorizontal: {$set: "outsideRight"},
                  //     barFillColor: {$set: "black"},
                  //     pointColor: {$set: "black"},
                  //     pointSize: {$set: 4},
                  //     zeroLineVisible: {$set: true},
                  //     zeroLineColor: {$set: "black"},
                  //     zeroLineOpacity: {$set: 0.0},
                  //     valueScaling: {$set: "log"},
                  //     valueScaleMin: {$set: numSamplesValueScaleMin},
                  //     valueScaleMax: {$set: numSamplesValueScaleMax},
                  //     valueFormat: {$set: "d"},
                  //     aggregationMode: {$set: "median"},
                  //     showTooltip: {$set: true},
                  //     showMousePosition: {$set: true}
                  //   }
                  // });
                  // newTopIdx += 1;

                  // console.log("post-num-samples newTop", newTop);

                  //
                  // add BED12 Index element
                  //
                  switch (self.state.hgViewParams.itt) {
                    case Constants.applicationIttModes.bb: {
                      // bigBed
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                        // server: {$set: Constants.applicationEndpointRootURL},
                        height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                        type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                        tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                        uid: {$set: uuid4()},
                        options: {
                          minHeight: {$set: 0},
                          backgroundColor: {$set: "white"},
                          isBarPlotLike: {$set: true},
                          itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                          showMousePosition: {$set: true},
                        }
                      });
                      break;
                    }
                    case 'ht': {
                      // higlass-transcripts
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                        type: {$set: "horizontal-transcripts"},
                        tilesetUid: {$set: "D5k7ajwfT9mzwbybaSS0VA"},
                        uid: {$set: uuid4()},
                        options: {
                          showMousePosition: {$set: true},
                          blockStyle: {$set: "boxplot"},
                          showToggleTranscriptsButton: {$set: false},
                          labelFontSize: {$set: "11"},
                          labelFontWeight: {$set: "500"},
                          maxTexts: {$set: 100},
                          itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                          startCollapsed: {$set: false},
                          trackMargin: {$set: {
                            top: 10,
                            bottom: 10,
                            left: 0,
                            right: 0,
                          }},
                          transcriptHeight: {$set: 16},
                          transcriptSpacing: {$set: 5},
                          isVisible: {$set: true},
                        }
                      });
                      break;
                    }
                    default:
                      throw new URIError(`Unknown Index DHS track type (itt) mode ${self.state.hgViewParams.itt}`);
                  }
                  newTopIdx += 1;

                  // console.warn(`Adding DHS index track... ${this.state.currentViewScale} vs ${this.state.hgViewParams.dtt}`);
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackIndexDHSHeight},
                  //   type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                  //   tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     minHeight: {$set: 0},
                  //     backgroundColor: {$set: "white"},
                  //     isBarPlotLike: {$set: true},
                  //     itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                  //     showMousePosition: {$set: true},
                  //   }
                  // });
                  // newTopIdx += 1;
                  //
                  // add all-DHS Component track
                  //
                  // console.warn(`all-DHS track height ${newHgViewTrackDHSComponentsHeight}`);
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackDHSComponentsHeight},
                  //   type: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['type']},
                  //   tilesetUid: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['uuid']},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     minHeight: {$set: 0},
                  //     backgroundColor: {$set: "white"},
                  //     colorRange: {$set: Constants.dhsComponentColorsAsRgb},
                  //     colorLabels: {$set: Constants.dhsComponentLabels},
                  //     showMousePosition: {$set: true},
                  //     valueScaling: {$set: null},
                  //     valueScaleMin: {$set: 1},
                  //     valueScaleMax: {$set: 17},
                  //     valueScaleNaN: {$set: 17},
                  //     zeroValueColor: {$set: "white"},
                  //     heatmapValueScaling: {$set: "categorical"},
                  //     colorbarPosition: {$set: null}
                  //   }
                  // });
                  // newTopIdx += 1;

                  // console.log("post-dhs-component newTop", newTop);

                  //
                  // add gene annotation track
                  //
                  newTop[newTopIdx] = update(topClone[2], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    height: {$set: newHgViewTrackGeneAnnotationsHeight},
                    tilesetUid: {$set: newGenesUUID},
                    uid: {$set: uuid4()},
                    options: {
                      backgroundColor: {$set: "white"},
                      plusStrandColor: {$set: "black"},
                      minusStrandColor: {$set: "black"},
                      showMousePosition: {$set: true},
                    }
                  });

                  // console.log("post-gene-annotation newTop", newTop);

                  // replace the top view tracks with the newly build track layout
                  // console.log("updated newTop", JSON.stringify(newTop, null, 2));
                  res.data.views[0].tracks.top = newTop;
                  // confirm layout
                  // console.log("updated res.data", JSON.stringify(res.data, null, 2));

                  // get child view heights
                  const childViews = res.data.views[0].tracks.top;
                  let childViewHeightTotal = 0;
                  childViews.forEach((cv) => { childViewHeightTotal += cv.height });
                  childViewHeightTotal += 10;
                  let childViewHeightTotalPx = childViewHeightTotal + "px";
                  // console.log("childViewHeightTotalPx", childViewHeightTotalPx);
                  
                  // update Viewer state
                  // console.log("Updating hgViewconf from res.data");
                  self.setState({
                    hgViewParams: newHgViewParams,
                    hgViewHeight: childViewHeightTotalPx,
                    hgViewconf: res.data,
                    currentPositionKey: Math.random(),
                    currentPosition : {
                      chrLeft : chrLeft,
                      chrRight : chrRight,
                      startLeft : parseInt(start),
                      stopLeft : parseInt(stop),
                      startRight : parseInt(start),
                      stopRight : parseInt(stop)
                    },
                    selectedProteinCodingsRowIdx: -1,
                    selectedTranscriptionFactorsRowIdx: -1,
                    selectedRoiRowIdx: -1,
                  }, () => {
                    self.setState({
                      hgViewKey: self.state.hgViewKey + 1,
                      drawerContentKey: self.state.drawerContentKey + 1,
                    }, () => {
                      // update browser history (address bar URL)
                      // console.log("calling [updateViewerURL] from [triggerUpdate]", this.state.hgViewParams.mode);
                      self.updateViewerURL(self.state.hgViewParams.mode,
                                           self.state.currentPosition.chrLeft,
                                           self.state.currentPosition.chrRight,
                                           self.state.currentPosition.startLeft,
                                           self.state.currentPosition.stopRight,
                                           self.state.hgViewParams.numSamples);
                      // add location event handler
                      self.hgView.api.on("location", (event) => { 
                        self.updateViewerLocation(event);
                      });
                    })
                  })
                }

                if (chromInfoCacheExists) {
                  updateViewerStateForPmModeAndChromInfo(this.chromInfoCache[newGenome], this);
                }
                else {
                  let chromSizesURL = this.getChromSizesURL(newGenome);
                  ChromosomeInfo(chromSizesURL)
                    .then((chromInfo) => {
                      this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                      updateViewerStateForPmModeAndChromInfo(chromInfo, this);
                    })
                    .catch((err) => {
                      let msg = this.errorMessage(err, `Could not retrieve chromosome information`, chromSizesURL);
                      this.setState({
                        overlayMessage: msg,
                        hgViewconf: {}
                      }, () => {
                        this.fadeInOverlay();
                      });
                    });
                }
              
              })
              .catch((err) => {
                // console.log(err.response);
                let msg = this.errorMessage(err, `Could not retrieve view configuration`, newHgViewconfURL);
                this.setState({
                  overlayMessage: msg,
                  hgViewconf: {}
                }, () => {
                  this.fadeInOverlay();
                });
              });
            })
            .catch((err) => {
              throw new Error(`Could not retrieve UUIDs for all specified components - ${JSON.stringify(err)}`);
              // console.log("Could not retrieve UUIDs for all specified components");
            });
          break;
        }
        case "pd": {
          const angle = window.screen.orientation ? window.screen.orientation.angle : window.orientation;
          let orientationIsPortrait = (angle === 0) || (angle === 180);
          let windowInnerHeight = `${parseInt(window.innerHeight)}px`;
          let windowInnerWidth = `${parseInt(window.innerWidth)}px`;
          if (orientationIsPortrait) {
            if (parseInt(windowInnerWidth) > parseInt(windowInnerHeight)) {
              windowInnerHeight = `${parseInt(window.innerWidth)}px`;
              windowInnerWidth = `${parseInt(window.innerHeight)}px`;
              orientationIsPortrait = false;
            }
          }
          else {
            if (parseInt(windowInnerWidth) < parseInt(windowInnerHeight)) {
              windowInnerHeight = `${parseInt(window.innerWidth)}px`;
              windowInnerWidth = `${parseInt(window.innerHeight)}px`;
              orientationIsPortrait = true;
            }
          }
          // console.log(`orientationIsPortrait in pd ${orientationIsPortrait}`)
          if (orientationIsPortrait) {
            let samplesToRenderAsTracks = [];
            let samplePromises = [];
            const pdSample = {
              "name" : "WM20181113_signal_track_per_component.hg38.multires.mv5",
              "filename" : "WM20181113_signal_track_per_component.hg38.multires.mv5",
              "componentIndex": 1,
              "samples": 1,
              "active": true,
              "longName": "All samples",
              "uuid": "HlXADoAsQt6tTm-NkrhWgw",
            };
            samplesToRenderAsTracks.push(pdSample);
            let endpoint = Constants.applicationHiGlassServerEndpointRootURL;
            samplePromises.push(Helpers.uuidQueryPromise(pdSample["filename"], endpoint, this));
            Promise.all(samplePromises).then(() => {
                let samplesToBeRendered = samplesToRenderAsTracks.reduce((d, c) => { return parseInt(d + c.samples) }, 0);
                axios.get(newHgViewconfURL)
                  .then((res) => {
                    if (!res.data) {
                      throw String("Error: New viewconf not returned from query to " + newHgViewconfURL);
                    }
                    
                    // console.log("old res.data", res.data);
                    
                    // ensure the template is not editable
                    res.data.editable = false;
                    
                    // update hgview parameters
                    newHgViewParams.genome = newGenome;
                    newHgViewParams.mode = newMode;
                    
                    let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
                    let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
                    let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
                    let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
                    //console.log("position: ", chrLeft, chrRight, start, stop);

                    const updateViewerStateForPdModeAndChromInfo = function(chromInfo, self) {
                      if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                        chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                        chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                        start = Constants.defaultApplicationPositions[newGenome].start;
                        stop = Constants.defaultApplicationPositions[newGenome].stop;
                      }
                      if (start > chromInfo.chromLengths[chrLeft]) {
                        start = chromInfo.chromLengths[chrLeft] - 10000;
                      }
                      if (stop > chromInfo.chromLengths[chrRight]) {
                        stop = chromInfo.chromLengths[chrRight] - 1000;
                      }
                      let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
                      let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
                      // console.log("chrLeft, start, absLeft", chrLeft, start, absLeft);
                      // console.log("chrRight, stop, absRight", chrRight, stop, absRight);
                      res.data.views[0].initialXDomain = [absLeft, absRight];
                      res.data.views[0].initialYDomain = [absLeft, absRight];
                      // update track heights -- requires preknowledge of track order from template
                      let windowInnerHeight = document.documentElement.clientHeight + "px";
                      // console.log("windowInnerHeight", windowInnerHeight);
                      // console.log("newHgViewTrackNumSamplesHeight", newHgViewTrackNumSamplesHeight);
                      //let availableWindowInnerHeight = parseInt(windowInnerHeight) - newHgViewTrackChromosomeHeight - newHgViewTrackIndexAnnotationsHeight - newHgViewTrackNumSamplesHeight - newHgViewTrackGeneAnnotationsHeight - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                      // let newHgViewTrackIndexDHSHeight = (self.state.hgViewParams.itt === Constants.applicationIttModes.ht) ? Constants.defaultIndexDHSHiglassTranscriptsTrackHeight : Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight;
                      //let newHgViewTrackIndexDHSHeight = self.state.indexDHSTrackHeight;
                      //let newHgViewTrackGeneAnnotationsHeight = Constants.defaultGeneAnnotationsHiglassTranscriptsTrackHeight;
                      let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(Constants.defaultIndexDHSHiglassTranscriptsMobileTrackPortraitHeight) - parseInt(Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackPortraitHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarMobileDeviceHeight) + "px";
                      // console.log("availableWindowInnerHeight", availableWindowInnerHeight);
                      let heightPerSample = parseFloat(parseInt(availableWindowInnerHeight) / samplesToBeRendered);
                      // console.log("heightPerSample", heightPerSample);
                      
                      // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
                      let topClone = update(res.data.views[0].tracks.top, {$push: []});
                      //console.log("topClone", topClone);

                      // build a new view from pieces of this clone
                      let newTop = [];
                      let newTopIdx = 0;

                      samplesToRenderAsTracks.forEach((sampleToRender) => {
                        // console.log("sampleToRender", sampleToRender);
                        newTop[newTopIdx] = update(topClone[0], {
                          server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                          height: {$set: parseInt(sampleToRender.samples * heightPerSample)},
                          name: {$set: "16ComponentBasicMultipleBarChart"},
                          /* type: {$set: "horizontal-multivec"}, */
                          type: {$set: "basic-multiple-bar-chart"},
                          /* type: {$set: "horizontal-stacked-delta-bar"}, */
                          /* type: {$set: "horizontal-stacked-bar"}, */
                          // minHeight: {$set: 1},
                          // resolutions: {$set: Constants.componentResolutions},
                          options: {
                            minHeight: {$set: 0},
                            backgroundColor: {$set: "black"},
                            colorScale: {$set: Constants.systemColorPalettesAsHex['hg38']['16']},
                            labelPosition: {$set: "topLeft"},
                            labelTextOpacity: {$set: 0.0},
                            labelBackgroundOpacity: {$set: 0.0},
                            labelColor: {$set: "white"},
                            valueScaleMin: {$set: 0.0},
                            valueScaleMax: {$set: parseFloat(self.state.hgViewParams.gmax)},
                            name: {$set: sampleToRender.longName},
                            valueScaling: {$set: "linear"},
                            // colorRange: {$set: Constants.componentColorMapGreyscale},
                            colorRange: {$set: Constants.componentColorMapViridisV1},
                            showMousePosition: {$set: true},
                            colorbarPosition: {$set: null},
                            trackBorderWidth: {$set: 0},
                            trackBorderColor: {$set: "black"},
                            barBorder: {$set: false},
                            sortLargestOnTop: {$set: true},
                            // name: {$set: "16ComponentBasicMultipleBarChart"},
                            chromInfo: {$set: self.chromInfoCache[newGenome]},
                            binSize: {$set: 20},
                          },
                          tilesetUid: {$set: sampleToRender.uuid},
                          uid: {$set: uuid4()},
                          resolutions: {$set: [13107200, 6553600, 3276800, 1638400, 819200, 409600, 204800, 102400, 51200, 25600, 12800, 6400, 3200, 1600, 800, 400, 200]},
                        });
                        newTopIdx += 1;
                      })

                      // console.log("post-samples-to-render newTop", newTop);

                      //
                      // add chromosome track
                      //
                      newTop[newTopIdx] = update(topClone[1], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: newHgViewTrackChromosomeHeight},
                        tilesetUid: {$set: newChromsizesUUID},
                        uid: {$set: uuid4()},
                        options: {
                          backgroundColor: {$set: "white"},
                          showMousePosition: {$set: true},
                        }
                      });
                      newTopIdx += 1;

                      // console.log("post-chromosome-track newTop", newTop);

                      //
                      // add numSamples track
                      //
                      // let numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamples];
                      // console.log(`numSamplesUUID ${numSamplesUUID}`);
                      // console.log(`self.newHgViewParams ${JSON.stringify(self.newHgViewParams, null, 2)}`);

                      // if (self.newHgViewParams && self.newHgViewParams.itt === "ht") {
                      //   numSamplesUUID = Constants.viewerHgViewconfFixedBinNumSamplesUUIDs[newNumSamples];
                      //   if (!numSamplesUUID) {
                      //     numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamples];
                      //   }
                      // }

                      // let numSamplesTrackType = Constants.viewerHgViewconfNumSamplesTrackType;
                      // let numSamplesValueScaleMin = Constants.viewerHgViewconfNumSamplesValueScaleMin[newNumSamples];
                      // let numSamplesValueScaleMax = Constants.viewerHgViewconfNumSamplesValueScaleMax[newNumSamples];
                      // newTop[newTopIdx] = update(topClone[2], {
                      //   server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                      //   height: {$set: newHgViewTrackNumSamplesHeight},
                      //   type: {$set: numSamplesTrackType},
                      //   tilesetUid: {$set: numSamplesUUID},
                      //   uid: {$set: uuid4()},
                      //   options: {
                      //     minHeight: {$set: 0},
                      //     backgroundColor: {$set: "rgb(242,242,242)"},
                      //     axisLabelFormatting: {$set: "normal"},
                      //     axisPositionHorizontal: {$set: "outsideRight"},
                      //     barFillColor: {$set: "black"},
                      //     pointColor: {$set: "black"},
                      //     pointSize: {$set: 4},
                      //     zeroLineVisible: {$set: true},
                      //     zeroLineColor: {$set: "black"},
                      //     zeroLineOpacity: {$set: 0.0},
                      //     valueScaling: {$set: "log"},
                      //     valueScaleMin: {$set: numSamplesValueScaleMin},
                      //     valueScaleMax: {$set: numSamplesValueScaleMax},
                      //     valueFormat: {$set: "d"},
                      //     aggregationMode: {$set: "median"},
                      //     showTooltip: {$set: true},
                      //     showMousePosition: {$set: true}
                      //   }
                      // });
                      // newTopIdx += 1;

                      // console.log("post-num-samples newTop", newTop);

                      //
                      // add BED12 Index element
                      //
                      switch (self.state.hgViewParams.itt) {
                        case Constants.applicationIttModes.bb: {
                          // bigBed
                          newTop[newTopIdx] = update(topClone[2], {
                            server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                            // server: {$set: Constants.applicationEndpointRootURL},
                            height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                            type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                            tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                            uid: {$set: uuid4()},
                            options: {
                              minHeight: {$set: 0},
                              backgroundColor: {$set: "white"},
                              isBarPlotLike: {$set: true},
                              itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                              showMousePosition: {$set: true},
                            }
                          });
                          break;
                        }
                        case Constants.applicationIttModes.ht: {
                          // higlass-transcripts
                          newTop[newTopIdx] = update(topClone[2], {
                            server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                            height: {$set: Constants.defaultIndexDHSHiglassTranscriptsMobileTrackPortraitHeight},
                            type: {$set: "horizontal-transcripts"},
                            tilesetUid: {$set: "D5k7ajwfT9mzwbybaSS0VA"},
                            uid: {$set: uuid4()},
                            options: {
                              showMousePosition: {$set: true},
                              blockStyle: {$set: "boxplot"},
                              showToggleTranscriptsButton: {$set: false},
                              labelFontSize: {$set: "11"},
                              labelFontWeight: {$set: "500"},
                              maxTexts: {$set: 100},
                              maxRows: {$set: Constants.defaultIndexDHSHiglassTranscriptsMobileTrackPortraitMaxRows},
                              minHeight: {$set: Constants.defaultIndexDHSHiglassTranscriptsMobileTrackPortraitHeight},
                              itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                              startCollapsed: {$set: false},
                              trackMargin: {$set: {
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                              }},
                              transcriptHeight: {$set: 20},
                              transcriptSpacing: {$set: 10},
                              isVisible: {$set: true},
                            }
                          });
                          break;
                        }
                        default:
                          throw new URIError(`Unknown Index DHS track type (itt) mode ${self.state.hgViewParams.itt}`);
                      }
                      newTopIdx += 1;

                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackHeight},
                        // tilesetUid: {$set: "a8079g0hSweKXxaaFIMayA"},
                        tilesetUid: {$set: "fH_YlxYlQAmxwl_htZ4FLg"},
                        uid: {$set: uuid4()},
                        options: {
                          backgroundColor: {$set: "white"},
                          plusStrandColor: {$set: "black"},
                          minusStrandColor: {$set: "black"},
                          showMousePosition: {$set: true},
                        }
                      });
                      newTopIdx += 1;

                      // newTop[newTopIdx] = update(topClone[2], {
                      //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      //   height: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackPortraitHeight},
                      //   type: {$set: "horizontal-transcripts"},
                      //   //tilesetUid: {$set: "a8079g0hSweKXxaaFIMayA"},
                      //   tilesetUid: {$set: "KPNgc7HhS4ilhcv0Cr95vg"},
                      //   uid: {$set: uuid4()},
                      //   options: {
                      //     maxRows: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackPortraitMaxRows},
                      //     minHeight: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackPortraitHeight},
                      //     showMousePosition: {$set: true},
                      //     startCollapsed: {$set: false},
                      //     // blockStyle: {$set: "UCSC-like"},
                      //     blockStyle: {$set: "directional"},
                      //     // highlightTranscriptType: {$set: "longestIsoform"},
                      //     // highlightTranscriptTrackBackgroundColor: {$set: "#fdfdcf"},
                      //     showToggleTranscriptsButton: {$set: false},
                      //     utrColor: {$set: "#afafaf"},
                      //     plusStrandColor: {$set: "#666666"},
                      //     minusStrandColor: {$set: "#666666"},
                      //     trackMargin: {$set: {
                      //       top: 0,
                      //       bottom: 0,
                      //       left: 0,
                      //       right: 0,
                      //     }}
                      //   }
                      // });
                      // newTopIdx += 1;

                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: ""},
                        type: {$set: "empty"},
                        // height: {$set: parseInt(self.state.indexDHSTrackHeight * 0.33)},
                        height: {$set: Constants.defaultSpacerMobileTrackHeight},
                        tilesetUid: {$set: ""},
                        uid: {$set: uuid4()},
                        options: {
                          backgroundColor: {$set: "white"},
                          plusStrandColor: {$set: "black"},
                          minusStrandColor: {$set: "black"},
                          showMousePosition: {$set: true},
                        }
                      });
                      newTopIdx += 1;
                      
                      //newTopIdx += 1;

                      //console.warn(`Adding DHS index track... ${this.state.currentViewScale} vs ${this.state.hgViewParams.dtt}`);
                      // newTop[newTopIdx] = update(topClone[2], {
                      //   server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                      //   height: {$set: newHgViewTrackIndexDHSHeight},
                      //   type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                      //   tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                      //   uid: {$set: uuid4()},
                      //   options: {
                      //     minHeight: {$set: 0},
                      //     backgroundColor: {$set: "white"},
                      //     isBarPlotLike: {$set: true},
                      //     itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                      //     showMousePosition: {$set: true},
                      //   }
                      // });
                      // newTopIdx += 1;
                      //
                      // add all-DHS Component track
                      //
                      //console.warn(`all-DHS track height ${newHgViewTrackDHSComponentsHeight}`);
                      // newTop[newTopIdx] = update(topClone[2], {
                      //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      //   height: {$set: newHgViewTrackDHSComponentsHeight},
                      //   type: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['type']},
                      //   tilesetUid: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['uuid']},
                      //   uid: {$set: uuid4()},
                      //   options: {
                      //     minHeight: {$set: 0},
                      //     backgroundColor: {$set: "white"},
                      //     colorRange: {$set: Constants.dhsComponentColorsAsRgb},
                      //     colorLabels: {$set: Constants.dhsComponentLabels},
                      //     showMousePosition: {$set: true},
                      //     valueScaling: {$set: null},
                      //     valueScaleMin: {$set: 1},
                      //     valueScaleMax: {$set: 17},
                      //     valueScaleNaN: {$set: 17},
                      //     zeroValueColor: {$set: "white"},
                      //     heatmapValueScaling: {$set: "categorical"},
                      //     colorbarPosition: {$set: null}
                      //   }
                      // });
                      // newTopIdx += 1;

                      // console.log("post-dhs-component newTop", newTop);

                      //
                      // add gene annotation track
                      //
                      // newTop[newTopIdx] = update(topClone[2], {
                      //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      //   height: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackHeight},
                      //   tilesetUid: {$set: newGenesUUID},
                      //   uid: {$set: uuid4()},
                      //   options: {
                      //     backgroundColor: {$set: "white"},
                      //     plusStrandColor: {$set: "black"},
                      //     minusStrandColor: {$set: "black"},
                      //     showMousePosition: {$set: true},
                      //   }
                      // });
                      // newTop[newTopIdx] = update(topClone[2], {
                      //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      //   height: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackHeight},
                      //   type: {$set: "horizontal-transcripts"},
                      //   tilesetUid: {$set: "a8079g0hSweKXxaaFIMayA"},
                      //   uid: {$set: uuid4()},
                      //   options: {
                      //     maxRows: {$set: 1},
                      //     minHeight: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackHeight},
                      //     showMousePosition: {$set: true},
                      //     startCollapsed: {$set: true},
                      //     blockStyle: {$set: "UCSC-like"},
                      //     highlightTranscriptType: {$set: "longestIsoform"},
                      //     highlightTranscriptTrackBackgroundColor: {$set: "#fdfdcf"},
                      //     showToggleTranscriptsButton: {$set: false},
                      //     utrColor: {$set: "#afafaf"},
                      //     plusStrandColor: {$set: "#111111"},
                      //     minusStrandColor: {$set: "#111111"},
                      //   }
                      // });

                      // console.log("post-gene-annotation newTop", newTop);

                      // replace the top view tracks with the newly build track layout
                      //console.log("updated newTop", JSON.stringify(newTop, null, 2));
                      res.data.views[0].tracks.top = newTop;
                      // confirm layout
                      //console.log("updated res.data", JSON.stringify(res.data, null, 2));

                      // get child view heights
                      const childViews = res.data.views[0].tracks.top;
                      let childViewHeightTotal = 0;
                      childViews.forEach((cv) => { childViewHeightTotal += cv.height });
                      childViewHeightTotal += 10;
                      let childViewHeightTotalPx = childViewHeightTotal + "px";
                      //console.log("childViewHeightTotalPx", childViewHeightTotalPx);
                      
                      // update Viewer state
                      //console.log("Updating hgViewconf from res.data");
                      self.setState({
                        hgViewParams: newHgViewParams,
                        hgViewHeight: childViewHeightTotalPx,
                        hgViewconf: res.data,
                        currentPositionKey: Math.random(),
                        currentPosition : {
                          chrLeft : chrLeft,
                          chrRight : chrRight,
                          startLeft : parseInt(start),
                          stopLeft : parseInt(stop),
                          startRight : parseInt(start),
                          stopRight : parseInt(stop)
                        },
                        selectedProteinCodingsRowIdx: -1,
                        selectedTranscriptionFactorsRowIdx: -1,
                        selectedRoiRowIdx: -1,
                      }, () => {
                        self.setState({
                          hgViewKey: self.state.hgViewKey + 1,
                          drawerContentKey: self.state.drawerContentKey + 1,
                        }, () => {
                          // update browser history (address bar URL)
                          //console.log("calling [updateViewerURL] from [triggerUpdate]", this.state.hgViewParams.mode);
                          self.updateViewerURL(self.state.hgViewParams.mode,
                                              self.state.currentPosition.chrLeft,
                                              self.state.currentPosition.chrRight,
                                              self.state.currentPosition.startLeft,
                                              self.state.currentPosition.stopRight,
                                              self.state.hgViewParams.numSamples);
                          // add location event handler
                          self.hgView.api.on("location", (event) => { 
                            self.updateViewerLocation(event);
                          });
                          // setTimeout(() => {
                          //   const indexDHSTrackObj = self.hgView.api.getComponent().getTrackObject(
                          //     self.state.hgViewconf.views[0].uid,
                          //     self.state.hgViewconf.views[0].tracks.top[2].uid,
                          //   );
                          //   // eslint-disable-next-line no-unused-vars
                          //   indexDHSTrackObj.pubSub.subscribe("trackDimensionsModified", (msg) => { 
                          //     if ((parseInt(indexDHSTrackObj.trackHeight) !== self.state.indexDHSTrackHeight) && (parseInt(indexDHSTrackObj.trackHeight) !== 20)) {
                          //       self.updateViewerIndexDHSTrackHeight(parseInt(indexDHSTrackObj.trackHeight), indexDHSTrackObj);
                          //       // self.setState({
                          //       //   indexDHSTrackHeight: parseInt(indexDHSTrackObj.trackHeight),
                          //       // }, () => {
                          //       //   console.log(`indexDHSTrackObj trackDimensionsModified event sent ${self.state.indexDHSTrackHeight}px`);
                          //       //   self.updateViewportDimensions();
                          //       //   indexDHSTrackObj.pubSub.unsubscribe("trackDimensionsModified");
                          //       // });
                          //     }
                          //   });
                          // }, 1500);
                        })
                      })
                    }

                    if (chromInfoCacheExists) {
                      updateViewerStateForPdModeAndChromInfo(this.chromInfoCache[newGenome], this);
                    }
                    else {
                      let chromSizesURL = this.getChromSizesURL(newGenome);
                      ChromosomeInfo(chromSizesURL)
                        .then((chromInfo) => {
                          this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                          updateViewerStateForPdModeAndChromInfo(chromInfo, this);
                        })
                        .catch((err) => {
                          let msg = this.errorMessage(err, `Could not retrieve chromosome information`, chromSizesURL);
                          this.setState({
                            overlayMessage: msg,
                            hgViewconf: {}
                          }, () => {
                            this.fadeInOverlay();
                          });
                        });
                    }
                  
                  })
                  .catch((err) => {
                    //console.log(err.response);
                    let msg = this.errorMessage(err, `Could not retrieve view configuration`, newHgViewconfURL);
                    this.setState({
                      overlayMessage: msg,
                      hgViewconf: {}
                    }, () => {
                      this.fadeInOverlay();
                    });
                  });
              })
              .catch((err) => {
                throw new Error(`Could not retrieve UUIDs for all specified components - ${JSON.stringify(err)}`);
                // console.log("Could not retrieve UUIDs for all specified components");
              });
          }
          /* landscape orientation */
          else {
            // get per-component multivec item
            let samplesToRenderAsTracks = [];
            let samplePromises = [];
            let pmSample = {
              "name" : "WM20181113_signal_track_per_component.hg38.multires.mv5",
              "filename" : "WM20181113_signal_track_per_component.hg38.multires.mv5",
              "componentIndex": 1,
              "samples": 1,
              "active": true,
              "longName": "All samples",
              "uuid": "HlXADoAsQt6tTm-NkrhWgw",
            };
            samplesToRenderAsTracks.push(pmSample);
            let endpoint = Constants.applicationHiGlassServerEndpointRootURL;
            samplePromises.push(Helpers.uuidQueryPromise(pmSample["filename"], endpoint, this));
            Promise.all(samplePromises).then(() => {
                let samplesToBeRendered = samplesToRenderAsTracks.reduce((d, c) => { return parseInt(d + c.samples) }, 0);
                axios.get(newHgViewconfURL)
                .then((res) => {
                  if (!res.data) {
                    throw new Error("Error: New viewconf not returned from query to " + newHgViewconfURL);
                  }
                  
                  // console.log("old res.data", res.data);
                  
                  // ensure the template is not editable
                  res.data.editable = false;
                  
                  // update hgview parameters
                  newHgViewParams.genome = newGenome;
                  newHgViewParams.mode = newMode;
                  
                  let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
                  let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
                  let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
                  let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
                  //console.log("position: ", chrLeft, chrRight, start, stop);
  
                  function updateViewerStateForPmModeAndChromInfo(chromInfo, self) {
                    if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                      chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                      chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                      start = Constants.defaultApplicationPositions[newGenome].start;
                      stop = Constants.defaultApplicationPositions[newGenome].stop;
                    }
                    if (start > chromInfo.chromLengths[chrLeft]) {
                      start = chromInfo.chromLengths[chrLeft] - 10000;
                    }
                    if (stop > chromInfo.chromLengths[chrRight]) {
                      stop = chromInfo.chromLengths[chrRight] - 1000;
                    }
                    let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
                    let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
                    // console.log("chrLeft, start, absLeft", chrLeft, start, absLeft);
                    // console.log("chrRight, stop, absRight", chrRight, stop, absRight);
                    res.data.views[0].initialXDomain = [absLeft, absRight];
                    res.data.views[0].initialYDomain = [absLeft, absRight];
                    // update track heights -- requires preknowledge of track order from template
                    let windowInnerHeight = document.documentElement.clientHeight + "px";
                    // console.log("windowInnerHeight", windowInnerHeight);
                    // console.log("newHgViewTrackNumSamplesHeight", newHgViewTrackNumSamplesHeight);
                    //let availableWindowInnerHeight = parseInt(windowInnerHeight) - newHgViewTrackChromosomeHeight - newHgViewTrackIndexAnnotationsHeight - newHgViewTrackNumSamplesHeight - newHgViewTrackGeneAnnotationsHeight - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                    //let newHgViewTrackIndexDHSHeight = (self.state.hgViewParams.itt === Constants.applicationIttModes.ht) ? Constants.defaultIndexDHSHiglassTranscriptsTrackHeight : Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight;
                    //let newHgViewTrackIndexDHSHeight = self.state.indexDHSTrackHeight;
                    //let newHgViewTrackGeneAnnotationsHeight = Constants.defaultGeneAnnotationsHiglassTranscriptsTrackHeight;
                    let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(Constants.defaultIndexDHSHiglassTranscriptsMobileTrackLandscapeHeight) - parseInt(Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackLandscapeHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarMobileDeviceHeight) - (Constants.defaultSpacerMobileTrackHeight * 2) + "px";
                    // console.log("availableWindowInnerHeight", availableWindowInnerHeight);
                    let heightPerSample = parseFloat(parseInt(availableWindowInnerHeight) / samplesToBeRendered);
                    // console.log("heightPerSample", heightPerSample);
                    
                    // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
                    let topClone = update(res.data.views[0].tracks.top, {$push: []});
                    // console.log("topClone", topClone);
  
                    // build a new view from pieces of this clone
                    let newTop = [];
                    let newTopIdx = 0;
  
                    samplesToRenderAsTracks.forEach((sampleToRender) => {
                      // console.log("sampleToRender", sampleToRender);
                      newTop[newTopIdx] = update(topClone[0], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: parseInt(sampleToRender.samples * heightPerSample)},
                        name: {$set: "16ComponentStackedDelta"},
                        /* type: {$set: "horizontal-multivec"}, */
                        /* type: {$set: "basic-multiple-bar-chart"}, */
                        type: {$set: "horizontal-stacked-delta-bar"},
                        /* type: {$set: "horizontal-stacked-bar"}, */
                        // minHeight: {$set: 1},
                        // resolutions: {$set: Constants.componentResolutions},
                        options: {
                          minHeight: {$set: 0},
                          backgroundColor: {$set: "black"},
                          // colorRange: {$set: Constants.componentColorMapGreyscale},
                          colorRange: {$set: Constants.componentColorMapViridisV1},
                          colorScale: {$set: Constants.systemColorPalettesAsHex['hg38']['16']},
                          labelPosition: {$set: "topLeft"},
                          labelTextOpacity: {$set: 0.0},
                          labelBackgroundOpacity: {$set: 0.0},
                          labelColor: {$set: "black"},
                          valueScaling: {$set: "linear"},
                          valueScaleMin: {$set: 0.0},
                          valueScaleMax: {$set: parseFloat(self.state.hgViewParams.gmax)},
                          name: {$set: sampleToRender.longName},
                          showMousePosition: {$set: true},
                          colorbarPosition: {$set: null},
                          trackBorderWidth: {$set: 0},
                          trackBorderColor: {$set: "black"},
                          barBorder: {$set: false},
                          sortLargestOnTop: {$set: true},
                          fillOpacityMin: {$set: 0.2},
                          fillOpacityMax: {$set: 0.8},
                          chromInfo: {$set: self.chromInfoCache[newGenome]},
                          binSize: {$set: 20},
                        },
                        tilesetUid: {$set: sampleToRender.uuid},
                        uid: {$set: uuid4()},
                        resolutions: {$set: [13107200, 6553600, 3276800, 1638400, 819200, 409600, 204800, 102400, 51200, 25600, 12800, 6400, 3200, 1600, 800, 400, 200]},
                      });
                      newTopIdx += 1;
                    })
  
                    // console.log("post-samples-to-render newTop", newTop);
  
                    //
                    // add chromosome track
                    //
                    newTop[newTopIdx] = update(topClone[1], {
                      server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      height: {$set: newHgViewTrackChromosomeHeight},
                      tilesetUid: {$set: newChromsizesUUID},
                      uid: {$set: uuid4()},
                      options: {
                        backgroundColor: {$set: "white"},
                        showMousePosition: {$set: true},
                      }
                    });
                    newTopIdx += 1;
  
                    // console.log("post-chromosome-track newTop", newTop);
  
                    //
                    // add numSamples track
                    //
                    // let numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamplesForPmMode];
                    // // console.log(`numSamplesUUID ${numSamplesUUID}`);
                    // // console.log(`self.newHgViewParams ${JSON.stringify(self.newHgViewParams, null, 2)}`);
  
                    // if (self.newHgViewParams && self.newHgViewParams.itt === "ht") {
                    //   numSamplesUUID = Constants.viewerHgViewconfFixedBinNumSamplesUUIDs[newNumSamplesForPmMode];
                    //   if (!numSamplesUUID) {
                    //     numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamplesForPmMode];
                    //   }
                    // }
  
                    // let numSamplesTrackType = Constants.viewerHgViewconfNumSamplesTrackType;
                    // let numSamplesValueScaleMin = Constants.viewerHgViewconfNumSamplesValueScaleMin[newNumSamplesForPmMode];
                    // let numSamplesValueScaleMax = Constants.viewerHgViewconfNumSamplesValueScaleMax[newNumSamplesForPmMode];
                    // newTop[newTopIdx] = update(topClone[2], {
                    //   server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                    //   height: {$set: newHgViewTrackNumSamplesHeightForPmMode},
                    //   type: {$set: numSamplesTrackType},
                    //   tilesetUid: {$set: numSamplesUUID},
                    //   uid: {$set: uuid4()},
                    //   options: {
                    //     minHeight: {$set: 0},
                    //     backgroundColor: {$set: "rgb(242,242,242)"},
                    //     axisLabelFormatting: {$set: "normal"},
                    //     axisPositionHorizontal: {$set: "outsideRight"},
                    //     barFillColor: {$set: "black"},
                    //     pointColor: {$set: "black"},
                    //     pointSize: {$set: 4},
                    //     zeroLineVisible: {$set: true},
                    //     zeroLineColor: {$set: "black"},
                    //     zeroLineOpacity: {$set: 0.0},
                    //     valueScaling: {$set: "log"},
                    //     valueScaleMin: {$set: numSamplesValueScaleMin},
                    //     valueScaleMax: {$set: numSamplesValueScaleMax},
                    //     valueFormat: {$set: "d"},
                    //     aggregationMode: {$set: "median"},
                    //     showTooltip: {$set: true},
                    //     showMousePosition: {$set: true}
                    //   }
                    // });
                    // newTopIdx += 1;
  
                    // console.log("post-num-samples newTop", newTop);
  
                    //
                    // add BED12 Index element
                    //
                    switch (self.state.hgViewParams.itt) {
                      case Constants.applicationIttModes.bb: {
                        // bigBed
                        newTop[newTopIdx] = update(topClone[2], {
                          server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                          // server: {$set: Constants.applicationEndpointRootURL},
                          height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                          type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                          tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                          uid: {$set: uuid4()},
                          options: {
                            minHeight: {$set: 0},
                            backgroundColor: {$set: "white"},
                            isBarPlotLike: {$set: true},
                            itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                            showMousePosition: {$set: true},
                          }
                        });
                        break;
                      }
                      case 'ht': {
                        // higlass-transcripts
                        newTop[newTopIdx] = update(topClone[2], {
                          server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                          height: {$set: Constants.defaultIndexDHSHiglassTranscriptsMobileTrackLandscapeHeight},
                          type: {$set: "horizontal-transcripts"},
                          tilesetUid: {$set: "D5k7ajwfT9mzwbybaSS0VA"},
                          uid: {$set: uuid4()},
                          options: {
                            showMousePosition: {$set: true},
                            blockStyle: {$set: "boxplot"},
                            showToggleTranscriptsButton: {$set: false},
                            labelFontSize: {$set: "11"},
                            labelFontWeight: {$set: "500"},
                            maxTexts: {$set: 100},
                            maxRows: {$set: Constants.defaultIndexDHSHiglassTranscriptsMobileTrackLandscapeMaxRows},
                            minHeight: {$set: Constants.defaultIndexDHSHiglassTranscriptsMobileTrackLandscapeHeight},
                            itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                            startCollapsed: {$set: false},
                            trackMargin: {$set: {
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                            }},
                            transcriptHeight: {$set: 20},
                            transcriptSpacing: {$set: 5},
                            isVisible: {$set: true},
                          }
                        });
                        break;
                      }
                      default:
                        throw new URIError(`Unknown Index DHS track type (itt) mode ${self.state.hgViewParams.itt}`);
                    }
                    newTopIdx += 1;

                    newTop[newTopIdx] = update(topClone[2], {
                      server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      height: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackHeight},
                      // tilesetUid: {$set: "a8079g0hSweKXxaaFIMayA"},
                      tilesetUid: {$set: "fH_YlxYlQAmxwl_htZ4FLg"},
                      uid: {$set: uuid4()},
                      options: {
                        backgroundColor: {$set: "white"},
                        plusStrandColor: {$set: "black"},
                        minusStrandColor: {$set: "black"},
                        showMousePosition: {$set: true},
                      }
                    });
                    // newTopIdx += 1;

                    // newTop[newTopIdx] = update(topClone[2], {
                    //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    //   height: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackLandscapeHeight},
                    //   type: {$set: "horizontal-transcripts"},
                    //   // tilesetUid: {$set: "a8079g0hSweKXxaaFIMayA"},
                    //   // tilesetUid: {$set: "TI0NNFLlQJuYpBlKob2MbQ"},
                    //   tilesetUid: {$set: "KPNgc7HhS4ilhcv0Cr95vg"},
                    //   uid: {$set: uuid4()},
                    //   options: {
                    //     maxRows: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackLandscapeMaxRows},
                    //     minHeight: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsMobileTrackLandscapeHeight},
                    //     showMousePosition: {$set: true},
                    //     startCollapsed: {$set: false},
                    //     // blockStyle: {$set: "UCSC-like"},
                    //     blockStyle: {$set: "directional"},
                    //     // highlightTranscriptType: {$set: "longestIsoform"},
                    //     // highlightTranscriptTrackBackgroundColor: {$set: "#fdfdcf"},
                    //     // showHighlightedTranscriptsOnly: {$set: true},
                    //     showToggleTranscriptsButton: {$set: false},
                    //     utrColor: {$set: "#afafaf"},
                    //     plusStrandColor: {$set: "#666666"},
                    //     minusStrandColor: {$set: "#666666"},
                    //     trackMargin: {$set: {
                    //       top: 0,
                    //       bottom: 0,
                    //       left: 0,
                    //       right: 0,
                    //     }}
                    //   }
                    // });
                    newTopIdx += 1;

                    newTop[newTopIdx] = update(topClone[2], {
                      server: {$set: ""},
                      type: {$set: "empty"},
                      height: {$set: Constants.defaultSpacerMobileTrackHeight},
                      tilesetUid: {$set: ""},
                      uid: {$set: uuid4()},
                      options: {
                        backgroundColor: {$set: "white"},
                        plusStrandColor: {$set: "black"},
                        minusStrandColor: {$set: "black"},
                        showMousePosition: {$set: true},
                      }
                    });
                    newTopIdx += 1;
  
                    // console.warn(`Adding DHS index track... ${this.state.currentViewScale} vs ${this.state.hgViewParams.dtt}`);
                    // newTop[newTopIdx] = update(topClone[2], {
                    //   server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                    //   height: {$set: newHgViewTrackIndexDHSHeight},
                    //   type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                    //   tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                    //   uid: {$set: uuid4()},
                    //   options: {
                    //     minHeight: {$set: 0},
                    //     backgroundColor: {$set: "white"},
                    //     isBarPlotLike: {$set: true},
                    //     itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                    //     showMousePosition: {$set: true},
                    //   }
                    // });
                    // newTopIdx += 1;
                    //
                    // add all-DHS Component track
                    //
                    // console.warn(`all-DHS track height ${newHgViewTrackDHSComponentsHeight}`);
                    // newTop[newTopIdx] = update(topClone[2], {
                    //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    //   height: {$set: newHgViewTrackDHSComponentsHeight},
                    //   type: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['type']},
                    //   tilesetUid: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['uuid']},
                    //   uid: {$set: uuid4()},
                    //   options: {
                    //     minHeight: {$set: 0},
                    //     backgroundColor: {$set: "white"},
                    //     colorRange: {$set: Constants.dhsComponentColorsAsRgb},
                    //     colorLabels: {$set: Constants.dhsComponentLabels},
                    //     showMousePosition: {$set: true},
                    //     valueScaling: {$set: null},
                    //     valueScaleMin: {$set: 1},
                    //     valueScaleMax: {$set: 17},
                    //     valueScaleNaN: {$set: 17},
                    //     zeroValueColor: {$set: "white"},
                    //     heatmapValueScaling: {$set: "categorical"},
                    //     colorbarPosition: {$set: null}
                    //   }
                    // });
                    // newTopIdx += 1;
  
                    // console.log("post-dhs-component newTop", newTop);
  
                    //
                    // add gene annotation track
                    //
                    // newTop[newTopIdx] = update(topClone[2], {
                    //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    //   height: {$set: newHgViewTrackGeneAnnotationsHeight},
                    //   tilesetUid: {$set: newGenesUUID},
                    //   uid: {$set: uuid4()},
                    //   options: {
                    //     backgroundColor: {$set: "white"},
                    //     plusStrandColor: {$set: "black"},
                    //     minusStrandColor: {$set: "black"},
                    //     showMousePosition: {$set: true},
                    //   }
                    // });
                    // newTop[newTopIdx] = update(topClone[2], {
                    //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    //   height: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsTrackHeight},
                    //   type: {$set: "horizontal-transcripts"},
                    //   tilesetUid: {$set: "a8079g0hSweKXxaaFIMayA"},
                    //   uid: {$set: uuid4()},
                    //   options: {
                    //     showMousePosition: {$set: true},
                    //     startCollapsed: {$set: true},
                    //     blockStyle: {$set: "UCSC-like"},
                    //     highlightTranscriptType: {$set: "longestIsoform"},
                    //     highlightTranscriptTrackBackgroundColor: {$set: "#fdfdcf"},
                    //     showToggleTranscriptsButton: {$set: true},
                    //     utrColor: {$set: "#afafaf"},
                    //     plusStrandColor: {$set: "#111111"},
                    //     minusStrandColor: {$set: "#111111"},
                    //   }
                    // });
  
                    // console.log("post-gene-annotation newTop", newTop);
  
                    // replace the top view tracks with the newly build track layout
                    // console.log("updated newTop", JSON.stringify(newTop, null, 2));
                    res.data.views[0].tracks.top = newTop;
                    // confirm layout
                    // console.log("updated res.data", JSON.stringify(res.data, null, 2));
  
                    // get child view heights
                    const childViews = res.data.views[0].tracks.top;
                    let childViewHeightTotal = 0;
                    childViews.forEach((cv) => { childViewHeightTotal += cv.height });
                    childViewHeightTotal += 10;
                    let childViewHeightTotalPx = childViewHeightTotal + "px";
                    // console.log("childViewHeightTotalPx", childViewHeightTotalPx);
                    
                    // update Viewer state
                    // console.log("Updating hgViewconf from res.data");
                    self.setState({
                      hgViewParams: newHgViewParams,
                      hgViewHeight: childViewHeightTotalPx,
                      hgViewconf: res.data,
                      currentPositionKey: Math.random(),
                      currentPosition : {
                        chrLeft : chrLeft,
                        chrRight : chrRight,
                        startLeft : parseInt(start),
                        stopLeft : parseInt(stop),
                        startRight : parseInt(start),
                        stopRight : parseInt(stop)
                      },
                      selectedProteinCodingsRowIdx: -1,
                      selectedTranscriptionFactorsRowIdx: -1,
                      selectedRoiRowIdx: -1,
                    }, () => {
                      self.setState({
                        hgViewKey: self.state.hgViewKey + 1,
                        drawerContentKey: self.state.drawerContentKey + 1,
                      }, () => {
                        // update browser history (address bar URL)
                        // console.log("calling [updateViewerURL] from [triggerUpdate]", this.state.hgViewParams.mode);
                        self.updateViewerURL(self.state.hgViewParams.mode,
                                             self.state.currentPosition.chrLeft,
                                             self.state.currentPosition.chrRight,
                                             self.state.currentPosition.startLeft,
                                             self.state.currentPosition.stopRight,
                                             self.state.hgViewParams.numSamples);
                        // add location event handler
                        self.hgView.api.on("location", (event) => { 
                          self.updateViewerLocation(event);
                        });
                        // setTimeout(() => {
                        //   const indexDHSTrackObj = self.hgView.api.getComponent().getTrackObject(
                        //     self.state.hgViewconf.views[0].uid,
                        //     self.state.hgViewconf.views[0].tracks.top[2].uid,
                        //   );
                        //   // eslint-disable-next-line no-unused-vars
                        //   indexDHSTrackObj.pubSub.subscribe("trackDimensionsModified", (msg) => { 
                        //     if ((parseInt(indexDHSTrackObj.trackHeight) !== self.state.indexDHSTrackHeight) && (parseInt(indexDHSTrackObj.trackHeight) !== 20)) {
                        //       self.updateViewerIndexDHSTrackHeight(parseInt(indexDHSTrackObj.trackHeight), indexDHSTrackObj);
                        //       // self.setState({
                        //       //   indexDHSTrackHeight: parseInt(indexDHSTrackObj.trackHeight),
                        //       // }, () => {
                        //       //   console.log(`indexDHSTrackObj trackDimensionsModified event sent ${self.state.indexDHSTrackHeight}px`);
                        //       //   self.updateViewportDimensions();
                        //       //   indexDHSTrackObj.pubSub.unsubscribe("trackDimensionsModified");
                        //       // });
                        //     }
                        //   });
                        // }, 1500);
                      })
                    })
                  }
  
                  if (chromInfoCacheExists) {
                    updateViewerStateForPmModeAndChromInfo(this.chromInfoCache[newGenome], this);
                  }
                  else {
                    let chromSizesURL = this.getChromSizesURL(newGenome);
                    ChromosomeInfo(chromSizesURL)
                      .then((chromInfo) => {
                        this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                        updateViewerStateForPmModeAndChromInfo(chromInfo, this);
                      })
                      .catch((err) => {
                        let msg = this.errorMessage(err, `Could not retrieve chromosome information`, chromSizesURL);
                        this.setState({
                          overlayMessage: msg,
                          hgViewconf: {}
                        }, () => {
                          this.fadeInOverlay();
                        });
                      });
                  }
                
                })
                .catch((err) => {
                  // console.log(err.response);
                  let msg = this.errorMessage(err, `Could not retrieve view configuration`, newHgViewconfURL);
                  this.setState({
                    overlayMessage: msg,
                    hgViewconf: {}
                  }, () => {
                    this.fadeInOverlay();
                  });
                });
              })
              .catch((err) => {
                throw new Error(`Could not retrieve UUIDs for all specified components - ${JSON.stringify(err)}`);
                // console.log("Could not retrieve UUIDs for all specified components");
              });
            break;
          }
          break;
        }
        default: {
          throw new Error(`Unknown mode type specified [${newMode}]`);
        }
      }
    }
  }
  
  trackLabels = () => {
    let viewconf = this.state.hgViewconf;
    if (!viewconf || !viewconf.views) return;
    let genome = this.state.hgViewParams.genome;
    let annotationText = Constants.annotations[genome];
    let mode = this.state.hgViewParams.mode;
    const childViews = viewconf.views[0].tracks.top;
    let childViewHeights = [];
    childViews.forEach((cv, i) => { childViewHeights[i] = cv.height; });
    const add = (a, b) => a + b;
    let results = [];
    switch (mode) {
      case "ac":
      case "pc": {
        // portrait mode
        if (this.state.orientationIsPortrait) {
          let cumulativeHeight = 0;
          let componentIndexOffset = 0;
          // skip chromosome track
          cumulativeHeight += childViewHeights[componentIndexOffset];
          componentIndexOffset++;
          // all tracks
          results.push(<div key="ac-label-components" className="epilogos-viewer-container-track-label-small" style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarMobileDeviceHeight + cumulativeHeight + 5)+'px',right:'8px',textAlign:"right"}}>DNase I normalized density<br/><span style={{fontSize:"0.8em",marginTop:"0px",paddingTop:"0px"}}>All 733 biosamples</span></div>);
          cumulativeHeight += childViewHeights[componentIndexOffset];
          componentIndexOffset++;
          // index dhs
          results.push(<div key="ac-label-index" className="epilogos-viewer-container-track-label-small" style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarMobileDeviceHeight + cumulativeHeight + 35)+'px',right:'8px'}}>Index DHS</div>);
          cumulativeHeight += childViewHeights[componentIndexOffset];
          componentIndexOffset++;
          // gene annotations
          results.push(<div key="ac-label-annotation" className="epilogos-viewer-container-track-label-small" style={{top:parseInt(childViewHeights.reduce(add) - 30)+'px',right:'8px'}}>{annotationText}</div>);
        }
        // landscape mode
        else {
          let cumulativeHeight = 0;
          let componentIndexOffset = 0;
          // skip chromosome track
          cumulativeHeight += childViewHeights[componentIndexOffset];
          componentIndexOffset++;
          // all tracks
          results.push(<div key="ac-label-components" className="epilogos-viewer-container-track-label-small" style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarMobileDeviceHeight + cumulativeHeight + 5)+'px',right:'8px',textAlign:"right"}}>DNase I normalized density<br/><span style={{fontSize:"0.8em",marginTop:"0px",paddingTop:"0px"}}>All 733 biosamples</span></div>);
          cumulativeHeight += childViewHeights[componentIndexOffset];
          componentIndexOffset++;
          // index dhs
          results.push(<div key="ac-label-index" className="epilogos-viewer-container-track-label-small" style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarMobileDeviceHeight + cumulativeHeight + 35)+'px',right:'8px'}}>Index DHS</div>);
          cumulativeHeight += childViewHeights[componentIndexOffset];
          componentIndexOffset++;
          // gene annotations
          results.push(<div key="ac-label-annotation" className="epilogos-viewer-container-track-label-small" style={{top:parseInt(childViewHeights.reduce(add) - 30)+'px',right:'8px'}}>{annotationText}</div>);
        }
        break;
      }    
      case "pw": {
        // portait mode
        if (this.state.orientationIsPortrait) {
          let cumulativeHeight = 0;
          let pwVerticalOffset = 6;
          let componentIndexOffset = 0;
          Object.keys(Constants.components).forEach((c, ci) => {
            if (Constants.components[c].active) {
              results.push(<div key={`pw-label-samples-${ci}`} className="epilogos-viewer-container-track-label epilogos-viewer-container-track-label-xs epilogos-viewer-container-track-label-xs-inverse" style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarMobileDeviceHeight + cumulativeHeight + pwVerticalOffset)+'px',right:'8px'}}>{Constants.components[c].longName}</div>);
              cumulativeHeight += childViewHeights[ci];
              componentIndexOffset++;
            }
          });
          cumulativeHeight += childViewHeights[componentIndexOffset];
          componentIndexOffset++;
          results.push(<div key="ac-label-index" className="epilogos-viewer-container-track-label-small" style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarMobileDeviceHeight + cumulativeHeight + 35)+'px',right:'8px'}}>Index DHS</div>);
          cumulativeHeight += childViewHeights[componentIndexOffset];
          componentIndexOffset++;
          results.push(<div key="ac-label-annotation" className="epilogos-viewer-container-track-label-small" style={{top:parseInt(childViewHeights.reduce(add) - 23)+'px',right:'8px'}}>{annotationText}</div>);
        }
        // landscape mode
        else {
          let cumulativeHeight = 0;
          let componentIndexOffset = 0;
          cumulativeHeight += childViewHeights[componentIndexOffset];
          componentIndexOffset++;
          results.push(<div key="ac-label-components" className="epilogos-viewer-container-track-label-small" style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarMobileDeviceHeight + cumulativeHeight + 5)+'px',right:'8px'}}>16 Components</div>);
          cumulativeHeight += childViewHeights[componentIndexOffset];
          componentIndexOffset++;
          results.push(<div key="ac-label-index" className="epilogos-viewer-container-track-label-small" style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarMobileDeviceHeight + cumulativeHeight + 35)+'px',right:'8px'}}>Index DHS</div>);
          cumulativeHeight += childViewHeights[componentIndexOffset];
          componentIndexOffset++;
          results.push(<div key="ac-label-annotation" className="epilogos-viewer-container-track-label-small" style={{top:parseInt(childViewHeights.reduce(add) - 23)+'px',right:'8px'}}>{annotationText}</div>);
        }
        break;
      }
      case "co":
      case "pm":
      case "pd": {
        break;
      }
      default: {
        throw new Error("Unknown mode type specified");
      }
    }
    return results;
  }
  
  render() {    
    return (
      <div 
        id="epilogos-viewer-container-parent" 
        ref={(component) => this.epilogosViewerContainerParent = component}
        style={{fontSize:"0.8rem"}}>
      
        <div ref={(component) => this.epilogosViewerTrackLabelParent = component} id="epilogos-viewer-container-track-label-parent" className="epilogos-viewer-container-track-label-parent">
          {this.trackLabels()}
        </div>
        
        <div id="epilogos-viewer-drawer-parent">
          <Drawer 
            disableOverlayClick={true}
            noOverlay={this.state.hideDrawerOverlay}
            className="epilogos-viewer-drawer epilogos-viewer-drawer-mobiledevice" 
            width={this.state.drawerWidth}
            pageWrapId={"epilogos-viewer-container"} 
            outerContainerId={"epilogos-viewer-container-parent"}
            isOpen={this.state.drawerIsOpen}
            onStateChange={(state) => this.handleDrawerStateChange(state)}>
            <div>
              <DrawerContent 
                key={this.state.drawerContentKey}
                activeTab={this.state.drawerActiveTabOnOpen}
                type={this.state.drawerSelection} 
                title={this.state.drawerTitle}
                viewParams={this.state.hgViewParams}
                currentCoordIdx={0}
                drawerWidth={this.state.drawerWidth}
                drawerHeight={this.state.drawerHeight}
                orientationIsPortrait={this.state.orientationIsPortrait}
                changeViewParams={this.changeViewParams}
                updateActiveTab={this.updateActiveTab}
                proteinCodingsEnabled={this.state.proteinCodingsEnabled}
                proteinCodingsTableData={this.state.proteinCodingsTableData}
                proteinCodingsSystems={this.state.proteinCodingsSystems}
                selectedProteinCodingsRowIdx={this.state.selectedProteinCodingsRowIdx}
                onProteinCodingsColumnSort={this.updateSortOrderOfProteinCodingsTableDataIndices}
                transcriptionFactorsEnabled={this.state.transcriptionFactorsEnabled}
                transcriptionFactorsTableData={this.state.transcriptionFactorsTableData}
                transcriptionFactorsSystems={this.state.transcriptionFactorsSystems}
                selectedTranscriptionFactorsRowIdx={this.state.selectedTranscriptionFactorsRowIdx}
                onTranscriptionFactorsColumnSort={this.updateSortOrderOfTranscriptionFactorsTableDataIndices}
                roiEnabled={this.state.roiEnabled}
                roiTableData={this.state.roiTableData}
                roiTableDataLongestNameLength={this.state.roiTableDataLongestNameLength}
                roiTableDataLongestAllowedNameLength={this.state.roiTableDataLongestAllowedNameLength}
                roiMaxColumns={this.state.roiMaxColumns}
                selectedRoiRowIdx={this.state.selectedRoiRowIdx}
                onRoiColumnSort={this.updateSortOrderOfRoiTableDataIndices}
                jumpToRegion={this.jumpToRegion}
                />
            </div>
          </Drawer>
        </div>
      
        <div ref={(ref) => this.indexViewer = ref} id="epilogos-viewer-container" className="epilogos-viewer-container">

          <Navbar id="epilogos-viewer-container-navbar" color="#000000" expand="md" className="navbar-top navbar-top-custom justify-content-start" style={{"zIndex":10001, backgroundColor:"#000000", cursor:"pointer", maxHeight:"38px", paddingLeft:"10px", paddingRight:"10px", paddingTop:"5px", paddingBottom:"6px"}}>
          
            <NavItem>
              <div title={(this.state.drawerIsOpen)?"Close drawer":"Settings and exemplar regions"} id="epilogos-viewer-hamburger-button" ref={(component) => this.epilogosViewerHamburgerButtonParent = component} className="epilogos-viewer-hamburger-button epilogos-viewer-hamburger-button-mobiledevice" style={{paddingRight:"8px", lineHeight:"20px"}}>
                <div className="hamburger-button" ref={(component) => this.epilogosViewerHamburgerButton = component} onClick={() => this.toggleDrawer("settings")} style={{position:"relative", top:"-1px"}}>
                  {!this.state.drawerIsOpen ? <FaBars size="1.2em" /> : <FaTimes size="1.2em" />}
                </div>
              </div>
            </NavItem>
            
            <NavbarBrand className="brand-container navbar-brand-custom navbar-brand-mobile" style={{maxHeight:"24px"}} id="epilogos-viewer-brand" > 
              <div className="brand" title={"Return to portal"}>
                <div className="brand-content brand-content-viewer-mobiledevice">
                  <div className="brand-content-header-viewer brand-content-text-viewer brand-content-text-viewer-mobiledevice" 
                    onClick={this.onClick} 
                    data-id={ Helpers.stripQueryStringAndHashFromPath(document.location.href) } 
                    data-target="_self"
                    ref={(component) => this.epilogosViewerBrandText = component} >
                    index{'\u00A0'}
                  </div>
                </div>
              </div>
            </NavbarBrand>
            
            <NavItem id="epilogos-viewer-search-input-parent" className="epilogos-viewer-search-input-parent" style={(this.state.autocompleteIsVisible)?{display:"block"}:{display:"none"}}>
              <Autocomplete
                ref={(component) => this.epilogosAutocomplete = component}
                className={"epilogos-viewer-search-input epilogos-viewer-search-input-viewermobile"}
                placeholder={Constants.defaultSingleGroupSearchInputPlaceholder}
                annotationScheme={Constants.annotationScheme}
                annotationHost={Constants.annotationHost}
                annotationPort={Constants.annotationPort}
                annotationAssemblyRaw={this.state.hgViewParams.genome}
                annotationAssembly={this.state.hgViewParams.genome}
                mapIndexDHSScheme={Constants.mapIndexDHSScheme}
                mapIndexDHSHost={Constants.mapIndexDHSHost}
                mapIndexDHSPort={Constants.mapIndexDHSPort}
                mapIndexDHSSetName={Constants.mapIndexDHSSetName}
                onChangeLocation={this.onChangeSearchInputLocation}
                onChangeInput={this.onChangeSearchInput}
                onFocus={this.onFocusSearchInput}
                title={"Search for a gene of interest or jump to a genomic interval"}
                suggestionsClassName={"suggestions viewer-suggestions"}
                maxSuggestionHeight={this.state.maxAutocompleteSuggestionHeight}
                isMobile={true}
              />
            </NavItem>
            
            <Nav className="ml-auto" navbar>
              <div className="navigation-summary-mobiledevice" ref={(component) => this.epilogosViewerNavbarRighthalf = component} id="navbar-righthalf" key={this.state.currentPositionKey} style={this.state.currentPosition ? {} : { display: 'none' }}>
                <div className="navigation-summary-position-mobiledevice">{ Helpers.positionSummaryElement(false, this.state.orientationIsPortrait, this) }</div> 
              </div>
            </Nav>
            
          </Navbar>
          
          <div className="higlass-content" style={{"height": this.state.hgViewHeight, "paddingTop": `${this.state.orientationIsPortrait?"0px":"5px"}`}}>
            <HiGlassComponent
              key={this.state.hgViewKey}
              ref={(component) => this.hgView = component}
              options={{ 
                bounded: true,
                pixelPreciseMarginPadding: false,
                containerPaddingX: 0,
                containerPaddingY: 0,
                viewMarginTop: 0,
                viewMarginBottom: 0,
                viewMarginLeft: 0,
                viewMarginRight: 0,
                viewPaddingTop: 0,
                viewPaddingBottom: 0,
                viewPaddingLeft: 0,
                viewPaddingRight: 0 
              }}
              viewConfig={this.state.hgViewconf}
              />
          </div>
          
        </div>

      </div>
    )
  }
}

export default ViewerMobile;
  