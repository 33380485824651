import React, { Component } from "react";

import PropTypes from 'prop-types';

import {
  Navbar,
  NavbarBrand,
  NavItem,
  Nav,
  Collapse,
  Button,
  Table,
  Badge,
} from "reactstrap";

import axios from "axios";

// higlass
// cf. https://www.npmjs.com/package/higlass
import "higlass/dist/hglib.css";
import { 
  HiGlassComponent, 
  ChromosomeInfo 
} from "higlass"; 

// higlass-multivec
// cf. https://www.npmjs.com/package/higlass-multivec
// import "higlass-multivec/dist/higlass-multivec.min.js";
import "higlass-multivec/dist/higlass-multivec.js";

// higlass-pileup
// ref. https://github.com/higlass/higlass-pileup
import "higlass-pileup/dist/higlass-pileup.js";

// higlass-transcripts
// cf. https://github.com/higlass/higlass-transcripts
// import "higlass-transcripts/dist/higlass-transcripts.js";

// Target content
import QueryTargetViewer from "./QueryTargetViewer"

// Application autocomplete
import Autocomplete from "./Autocomplete/Autocomplete";

// Region indicator content
import RegionMidpointIndicator from "./RegionMidpointIndicator";
import RegionIntervalIndicator from "./RegionIntervalIndicator";

// Drawer content
import DrawerContent from "./DrawerContent";

// GenomeSelectButton
import GenomeSelectButton from "./GenomeSelectButton";

// Recommender
import RecommenderSearchButton from "./RecommenderSearchButton";
import { RecommenderV3SearchButtonDefaultLabel, RecommenderSearchButtonInProgressLabel } from "./RecommenderSearchButton";
import { RecommenderSearchLinkDefaultLabel, RecommenderSearchLinkInProgressLabel } from "./RecommenderSearchLink";
import { RecommenderExpandLinkDefaultLabel } from "./RecommenderExpandLink";

// SimSearch
import SimsearchPill from "./SimsearchPill";

// Application constants
import * as Constants from "../Constants.js";
import * as Helpers from "../Helpers.js";

// Drawer
import { slide as Drawer } from 'react-burger-menu';
import { FaBars, FaTimes, FaArrowAltCircleDown, FaClipboard, FaToggleOn } from 'react-icons/fa';

// HTML5 file saver
// cf. https://github.com/eligrey/FileSaver.js/
import saveAs from 'file-saver';

// Copy data to clipboard
import { CopyToClipboard } from 'react-copy-to-clipboard';

// React-toggle (toggle switch)
import 'react-toggle/style.css';
import Toggle from 'react-toggle';

// Validate strings (URLs etc.)
import validator from 'validator';

// Deep clone an object 
import update from 'immutability-helper';

// Generate UUIDs
export const uuid4 = require("uuid4");

// Query JSON objects (to build dropdowns and other inputs)
// cf. https://www.npmjs.com/package/jsonpath-lite
export const jp = require("jsonpath");

// Compare JSON objects for equality
export const equal = require("deep-equal");

class Viewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: props.height, 
      width: props.width,
      contactEmail: "info@altius.org",
      twitterHref: "https://twitter.com/AltiusInst",
      linkedInHref: "https://www.linkedin.com/company/altius-institute-for-biomedical-sciences",
      altiusHref: "https://www.altius.org",
      higlassHref: "http://higlass.io",
      hgViewKey: 0,
      hgViewLoopEnabled: true,
      hgViewHeight: Constants.viewerHgViewParameters.hgViewTrackEpilogosHeight + Constants.viewerHgViewParameters.hgViewTrackChromatinMarksHeight + Constants.viewerHgViewParameters.hgViewTrackChromosomeHeight + Constants.viewerHgViewParameters.hgViewTrackGeneAnnotationsHeight + Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight,
      epilogosContentHeight: 0,
      mainHgViewconf: {},
      hgViewParams: Constants.viewerHgViewParameters,
      hgViewRefreshTimerActive: true,
      hgViewClickPageX: Constants.defaultHgViewClickPageX,
      hgViewClickTimePrevious: Constants.defaultHgViewClickTimePrevious,
      hgViewClickTimeCurrent: Constants.defaultHgViewClickTimeCurrent,
      hgViewClickInstance: 0,
      currentPosition: { chrLeft: "", chrRight: "", startLeft: 0, stopLeft: 0, startRight: 0, stopRight: 0 },
      currentPositionKey: Math.random(),
      drawerWidth: Constants.defaultMinimumDrawerWidth,
      searchInputValue: "",
      drawerIsOpen: false,
      drawerSelection: null,
      drawerTitle: "Title",
      drawerHeight: 0,
      drawerContentKey: 0,
      activeTab: null,
      drawerActiveTabOnOpen: Constants.defaultDrawerTabOnOpen,
      hideDrawerOverlay: true,
      showDataNotice: true,
      showUpdateNotice: false,
      tempHgViewParams: {...Constants.viewerHgViewParameters},
      genomeSelectIsEnabled: false,
      genomeSelectIsVisible: true,
      genomeSelectIsActive: true,
      downloadButtonBoundingRect: {top:0, right:0, left:0, bottom: 0, height: 0, width: 0},
      downloadPopupBoundingRect: {top:0, right:0, left:0, bottom: 0, height: 0, width: 0},
      downloadIsVisible: false,
      downloadWidgetIsVisible: true,
      tabixDataDownloadCommandVisible: false,
      tabixDataDownloadCommand: "tabix https://meuleman-higlass-us-west-2.altius.org/tabix/index/all.gz chr1:1000001-1000600",
      tabixDataDownloadCommandCopied: false,
      trackLabelsVisible: true,
      selectedNonRoiRowIdxOnLoad: -1,
      proteinCodingsEnabled: false,
      proteinCodingsJumpActive: false,
      proteinCodingsRegions: [],
      proteinCodingsTableData: [],
      proteinCodingsTableDataCopy: [],
      proteinCodingsTableDataIdxBySort: [],
      proteinCodingsSystems: [],
      selectedProteinCodingsRowIdx: -1,
      selectedProteinCodingsChrLeft: "",
      selectedProteinCodingsChrRight: "",
      selectedProteinCodingsStart: -1,
      selectedProteinCodingsStop: -1,
      selectedProteinCodingsBeingUpdated: false,
      transcriptionFactorsEnabled: false,
      transcriptionFactorsJumpActive: false,
      transcriptionFactorsRegions: [],
      transcriptionFactorsTableData: [],
      transcriptionFactorsTableDataCopy: [],
      transcriptionFactorsTableDataIdxBySort: [],
      transcriptionFactorsSystems: [],
      selectedTranscriptionFactorsRowIdx: -1,
      selectedTranscriptionFactorsChrLeft: "",
      selectedTranscriptionFactorsChrRight: "",
      selectedTranscriptionFactorsStart: -1,
      selectedTranscriptionFactorsStop: -1,
      selectedTranscriptionFactorsBeingUpdated: false,
      overlayVisible: false,
      showOverlayNotice: true,
      overlayMessage: "Placeholder",
      isMobile: false,
      isPortrait: false,
      //verticalDropLabel: "chrN:X-Y",
      roiEnabled: false,
      roiJumpActive: false,
      roiRegions: [],
      roiTableData: [],
      roiTableDataCopy: [],
      roiTableDataIdxBySort: [],
      roiTableDataLongestNameLength: Constants.defaultRoiTableDataLongestNameLength,
      roiTableDataLongestAllowedNameLength: Constants.defaultRoiTableDataLongestAllowedNameLength,
      roiEncodedSet: "",
      roiRawSet: "",
      roiEncodedURL: "",
      roiRawURL: "",
      roiMode: Constants.defaultApplicationRoiMode,
      roiPaddingFractional: Constants.defaultApplicationRoiPaddingFraction,
      roiPaddingAbsolute: Constants.defaultApplicationRoiPaddingAbsolute,
      roiMaxColumns: 0,
      selectedRoiRowIdxOnLoad: Constants.defaultApplicationSrrIdx,
      selectedRoiRowIdx: Constants.defaultApplicationSrrIdx,
      selectedRoiChrLeft: "",
      selectedRoiChrRight: "",
      selectedRoiStart: -1,
      selectedRoiStop: -1,
      selectedRoiBeingUpdated: false,
      searchInputLocationBeingChanged: false,
      positionSummaryElementKey: -1,
      currentViewScale: -1,
      previousViewScale: -1,
      currentViewScaleAsString: "",
      chromsAreIdentical: false,
      regionIndicatorData: {},
      regionIndicatorOuterWidth: 0,
      indexDHSTrackHeight: Constants.defaultIndexDHSHiglassTranscriptsTrackHeight,
      geneAnnotationTrackHeight: Constants.defaultGeneAnnotationsHiglassTranscriptsTrackHeight,
      searchInputText: null,
      scaleSummaryKey: -1,
      recommenderVersion: "",
      recommenderV3SearchIsVisible: false,
      recommenderV3SearchIsEnabled: false,
      recommenderV3SearchInProgress: false,
      recommenderV3SearchButtonLabel: RecommenderV3SearchButtonDefaultLabel,
      recommenderV3SearchLinkLabel: RecommenderSearchLinkDefaultLabel,
      recommenderV3ExpandIsEnabled: false,
      recommenderV3ExpandLinkLabel: RecommenderExpandLinkDefaultLabel,
      recommenderV3CanAnimate: true,
      recommenderV3AnimationHasFinished: true,
      protectElementSelection: false,
      transcriptsTrackHeight: 0,
      parameterSummaryKey: 0,
      queryTargetHgViewKey: 0,
      queryTargetQueryRegionLabel: '',
      queryTargetQueryRegion: {},
      queryTargetTargetRegionLabel: '',
      queryTargetTargetRegion: {},
      queryTargetLockFlag: Constants.defaultQueryTargetLockFlag,
      queryHgViewKey: 0,
      queryHgViewHeight: 0,
      queryHgViewconf: {},
      mainRegionIndicatorData: {},
      mainRegionIndicatorOuterWidth: 0,
      mainRegionIndicatorContentTopOffset: 8,
      queryRegionIndicatorData: {},
      queryRegionIndicatorOuterWidth: 0,
      queryRegionIndicatorContentTopOffset: 8,

      simSearchTabTitle: Constants.drawerTitleByType.simsearch,
      simSearchEnabled: false,
      simSearchJumpActive: false,
      simSearchRegions: [], 
      simSearchTableData: [],
      simSearchTableDataCopy: [],
      simSearchTableDataIdxBySort: [],
      simSearchTableDataLongestNameLength: Constants.defaultSimSearchTableDataLongestNameLength,
      simSearchTableDataLongestAllowedNameLength: Constants.defaultSimSearchTableDataLongestAllowedNameLength,
      simSearchEncodedURL: "",
      simSearchRawURL: null,
      simSearchMode: Constants.defaultApplicationSimSearchMode,
      simSearchPaddingFractional: Constants.defaultApplicationSimSearchPaddingFraction,
      simSearchPaddingAbsolute: Constants.defaultApplicationSimSearchPaddingAbsolute,
      simSearchMaxColumns: 0,
      selectedSimSearchRowIdx: Constants.defaultApplicationSsrIdx,
      selectedSimSearchRowIdxOnLoad: Constants.defaultApplicationSsrIdx,
      simSearchTableIsVisible: false,
      selectedSimSearchRegionChrLeft: "",
      selectedSimSearchRegionChrRight: "",
      selectedSimSearchRegionStart: -1,
      selectedSimSearchRegionStop: -1,
      selectedSimSearchRegionBeingUpdated: false,
      simSearchTableIsVisible: false,
      simSearchTableKey: 0,
      simSearchIndicatorIsVisible: false,
      simSearchIndicatorLeftPx: -1,
      simSearchIndicatorRightPx: -1,
      simSearchIndicatorRegion: [],
      simSearchQueryInProgress: false,
      simSearchQueryCount: -1,
      simSearchQueryCountIsVisible: false,
      simSearchQueryCountIsEnabled: false,

      showingClipboardCopiedAlert: false,
      mousePosition: {x:-1000, y:-1000},
    };

    this.updateViewerHistory = this.debounce((viewerUrlStr) => {
      // console.log(`updateViewerHistory - ${viewerUrlStr}`);
      const previousUrlStr = window.history.state;
      // if (!previousUrlStr) return;
      const previousUrlQuery = previousUrlStr && Helpers.getJsonFromSpecifiedUrl(previousUrlStr);
      const currentUrlQuery = Helpers.getJsonFromSpecifiedUrl(viewerUrlStr);

      // if (!previousUrlQuery.hasOwnProperty('gatt')) previousUrlQuery.gatt = Constants.defaultApplicationGattCategory;
      // if (!currentUrlQuery.hasOwnProperty('gatt')) currentUrlQuery.gatt = Constants.defaultApplicationGattCategory;

      //
      // allow up to ten bases of slippage
      //
      const baseSlippage = 10;
      const previousCurrentDiffWithinBounds = (previousUrlQuery) ? 
        (previousUrlQuery.mode === this.state.hgViewParams.mode) && 
        // (previousUrlQuery.genome === this.state.hgViewParams.genome) && 
        // (previousUrlQuery.model === this.state.hgViewParams.model) && 
        // (previousUrlQuery.complexity === this.state.hgViewParams.complexity) && 
        // (previousUrlQuery.group === this.state.hgViewParams.group) && 
        // (previousUrlQuery.sampleSet === this.state.hgViewParams.sampleSet) && 
        (previousUrlQuery.chrLeft === this.state.currentPosition.chrLeft) && 
        (previousUrlQuery.chrRight === this.state.currentPosition.chrRight) && 
        (Math.abs(this.state.currentPosition.startLeft - previousUrlQuery.start) < baseSlippage) && (Math.abs(this.state.currentPosition.stopRight - previousUrlQuery.stop) < baseSlippage) 
        : 
        false;

      // console.log(`previousCurrentDiffWithinBounds ${previousCurrentDiffWithinBounds}`);

      const exemplarRowSelectionChanged = (currentUrlQuery.serIdx && (currentUrlQuery.serIdx !== Constants.defaultApplicationSerIdx)) || (previousUrlQuery && previousUrlQuery.serIdx && (previousUrlQuery.serIdx !== currentUrlQuery.serIdx) && (currentUrlQuery.serIdx !== Constants.defaultApplicationSerIdx));

      const roiRowSelectionChanged = ((previousUrlQuery) && (!previousUrlQuery.srrIdx) && ((currentUrlQuery.srrIdx) && (currentUrlQuery.srrIdx !== Constants.defaultApplicationSrrIdx))) || ((previousUrlQuery) && (previousUrlQuery.srrIdx) && (currentUrlQuery.srrIdx) && (previousUrlQuery.srrIdx !== currentUrlQuery.srrIdx) && (currentUrlQuery.srrIdx !== Constants.defaultApplicationSrrIdx)); 

      const roiRowSelectionNotDefault = (this.state.selectedRoiRowIdx !== Constants.defaultApplicationSrrIdx);

      const simSearchRowSelectionChanged = (currentUrlQuery.ssrIdx && (currentUrlQuery.ssrIdx !== Constants.defaultApplicationSsrIdx)) || (previousUrlQuery && previousUrlQuery.ssrIdx && (previousUrlQuery.ssrIdx !== currentUrlQuery.ssrIdx) && (currentUrlQuery.ssrIdx !== Constants.defaultApplicationSsrIdx));

      const gattSelectionChanged = (previousUrlQuery && (previousUrlQuery.gatt !== currentUrlQuery.gatt));

      if (!previousUrlStr || !previousCurrentDiffWithinBounds || roiRowSelectionChanged || exemplarRowSelectionChanged || simSearchRowSelectionChanged || gattSelectionChanged || roiRowSelectionNotDefault) {
        let stub = "";
        if (this.state.searchInputText) {
          stub = `- ${this.state.searchInputText}`;
        }
        else if ((this.state.currentPosition.chrLeft === this.state.currentPosition.chrRight) && (this.state.currentPosition.startLeft !== this.state.currentPosition.stopRight)) {
          stub = `- ${this.state.currentPosition.chrLeft}:${this.state.currentPosition.startLeft}-${this.state.currentPosition.stopRight}`;
        }
        else if (this.state.currentPosition.chrLeft !== this.state.currentPosition.chrRight) {
          stub = `- ${this.state.currentPosition.chrLeft}:${this.state.currentPosition.startLeft}-${this.state.currentPosition.chrRight}:${this.state.currentPosition.stopRight}`;
        }
        const historyTitle = `Altius Index ${stub}`;
        // some versions of window.history.pushState do not provide title update functionality
        window.history.pushState(viewerUrlStr, historyTitle, viewerUrlStr);
        document.title = historyTitle;
      }
      this.updateScale(true, "constructor");
    }, Constants.defaultViewerHistoryChangeEventDebounceTimeout);
    
    this.hgView = React.createRef();
    this.queryTargetHgView = React.createRef();

    this.epilogosViewerContainerParent = React.createRef();
    this.epilogosViewerContainerOverlay = React.createRef();
    this.epilogosViewerContainerErrorOverlay = React.createRef();
    this.epilogosViewerContainerErrorOverlayNotice = React.createRef();
    this.epilogosViewerHamburgerButton = React.createRef();
    this.epilogosViewerUpdateNotice = React.createRef();
    this.epilogosViewerDataNotice = React.createRef();
    this.epilogosViewerParameterSummary = React.createRef();
    this.epilogosViewerNavbarRighthalf = React.createRef();
    this.viewerUpdateNoticeUpdateButton = React.createRef();
    this.autocompleteInputRef = React.createRef();
    this.epilogosViewerTrackLabelParent = React.createRef();
    this.genomeSelectButtonRef = React.createRef();
    //
    this.epilogosViewerContainerVerticalDrop = React.createRef();
    //this.epilogosViewerContainerVerticalDropLabel = React.createRef();
    this.epilogosViewerContainerVerticalDropRegionMidpointIndicator = React.createRef();
    this.epilogosViewerContainerVerticalDropTop = React.createRef();
    this.epilogosViewerContainerVerticalDropBottom = React.createRef();
    //
    this.epilogosViewerContainerIntervalDrop = React.createRef();
    //this.epilogosViewerContainerIntervalDropLabel = React.createRef();
    this.epilogosViewerContainerIntervalDropRegionIntervalIndicator = React.createRef();
    this.epilogosViewerContainerIntervalDropLeftTop = React.createRef();
    this.epilogosViewerContainerIntervalDropRightTop = React.createRef();
    this.epilogosViewerContainerIntervalDropLeftBottom = React.createRef();
    this.epilogosViewerContainerIntervalDropRightBottom = React.createRef();

    // recommender
    this.epilogosViewerRecommenderV3Button = React.createRef();
    this.indexDHSViewerSuggestionPill = React.createRef();

    // cache of ChromosomeInfo response
    this.chromInfoCache = {};
    
    // timeout for location change
    this.viewerLocationChangeEventTimer = null;
    this.viewerZoomPastExtentTimer = null;
    this.viewerHistoryChangeEventTimer = null;
    
    // get current URL attributes (protocol, port, etc.)
    this.currentURL = document.createElement('a');
    this.currentURL.setAttribute('href', window.location.href);
    
    // is this site production or development?
    let sitePort = parseInt(this.currentURL.port);
    if (isNaN(sitePort)) sitePort = 443;
    this.isProductionSite = ((sitePort === "") || (sitePort === 443)); // || (sitePort !== 3000 && sitePort !== 3001));
    this.isProductionProxySite = (sitePort === Constants.applicationProductionProxyPort); // || (sitePort !== 3000 && sitePort !== 3001));
    // console.log("this.isProductionSite", this.isProductionSite);
    // console.log("this.isProductionProxySite", this.isProductionProxySite);
    
    const queryObj = Helpers.getJsonFromUrl();
    //console.warn(`${JSON.stringify(queryObj, null, 2)}`);
    let newTempHgViewParams = {...this.state.tempHgViewParams};
    newTempHgViewParams.genome = queryObj.genome || Constants.defaultApplicationGenome;
    newTempHgViewParams.model = queryObj.model || Constants.defaultApplicationModel;
    newTempHgViewParams.complexity = queryObj.complexity || Constants.defaultApplicationComplexity;
    newTempHgViewParams.group = queryObj.group || Constants.defaultApplicationGroup;
    newTempHgViewParams.chrLeft = queryObj.chrLeft || Constants.defaultApplicationChr;
    newTempHgViewParams.chrRight = queryObj.chrRight || Constants.defaultApplicationChr;
    newTempHgViewParams.start = parseInt(queryObj.start || Constants.defaultApplicationStart);
    newTempHgViewParams.stop = parseInt(queryObj.stop || Constants.defaultApplicationStop);
    newTempHgViewParams.mode = queryObj.mode || Constants.defaultApplicationMode;
    newTempHgViewParams.mode = (newTempHgViewParams.mode == "qt") ? Constants.defaultApplicationMode : newTempHgViewParams.mode;
    // newTempHgViewParams.numSamples = queryObj.numSamples || Constants.defaultApplicationNumSamples;
    newTempHgViewParams.dtt = parseInt(queryObj.dtt || Constants.defaultApplicationDttThreshold);
    newTempHgViewParams.dtc = queryObj.dtc || Constants.defaultApplicationDtcCategory;
    newTempHgViewParams.itt = queryObj.itt || Constants.defaultApplicationIttCategory;
    newTempHgViewParams.gatt = queryObj.gatt || Constants.defaultApplicationGattCategory;
    newTempHgViewParams.gac = queryObj.gac || Constants.defaultApplicationGacCategory;
    newTempHgViewParams.gmax = queryObj.gmax || Constants.defaultApplicationSignalGlobalMax;
    this.state.selectedExemplarRowIdx = queryObj.serIdx || Constants.defaultApplicationSerIdx;
    this.state.selectedRoiRowIdx = parseInt(queryObj.srrIdx) || Constants.defaultApplicationSrrIdx;
    this.state.selectedRoiRowIdxOnLoad = parseInt(queryObj.srrIdx) || Constants.defaultApplicationSrrIdx;
    if (!this.state.selectedRoiRowIdx || !this.state.selectedRoiRowIdxOnLoad) {
      this.state.selectedRoiRowIdx = Constants.defaultApplicationSrrIdx;
      this.state.selectedRoiRowIdxOnLoad = Constants.defaultApplicationSrrIdx;
    }
    this.state.roiMode = queryObj.roiMode || Constants.defaultApplicationRoiMode;
    this.state.roiPaddingFractional = queryObj.roiPaddingFractional || Constants.defaultApplicationRoiPaddingFraction;
    this.state.roiPaddingAbsolute = queryObj.roiPaddingAbsolute || Constants.defaultApplicationRoiPaddingAbsolute;

    this.state.selectedSimSearchRowIdx = parseInt(queryObj.ssrIdx || Constants.defaultApplicationSsrIdx);
    this.state.selectedSimSearchRowIdxOnLoad = parseInt(queryObj.ssrIdx || Constants.defaultApplicationSsrIdx);

    this.state.drawerActiveTabOnOpen = queryObj.activeTab || Constants.defaultDrawerTabOnOpen;
    if (newTempHgViewParams.start === newTempHgViewParams.stop) {
      //console.log("Coordinates are identical!")
      newTempHgViewParams.chrLeft = Constants.defaultApplicationPositions[newTempHgViewParams.genome].chr;
      newTempHgViewParams.chrRight = Constants.defaultApplicationPositions[newTempHgViewParams.genome].chr;
      newTempHgViewParams.start = Constants.defaultApplicationPositions[newTempHgViewParams.genome].start;
      newTempHgViewParams.stop = Constants.defaultApplicationPositions[newTempHgViewParams.genome].stop;
      this.state.currentPosition = {
        chrLeft : newTempHgViewParams.chrLeft,
        chrRight : newTempHgViewParams.chrRight,
        startLeft : newTempHgViewParams.start,
        startRight : newTempHgViewParams.start,
        stopLeft : newTempHgViewParams.stop,
        stopRight : newTempHgViewParams.stop
      };
      console.log("calling [updateViewerURL] from [constructor]");
      this.updateViewerURL(newTempHgViewParams.mode,
                           this.state.currentPosition.chrLeft,
                           this.state.currentPosition.chrRight,
                           this.state.currentPosition.startLeft,
                           this.state.currentPosition.stopRight,
                           true,
                           "constructor");
      // this.updateScale();
    }
    
    function updateWithRoisInMemory(self) {
      //console.log("ROI table data updated!");
      const queryObj = Helpers.getJsonFromUrl();
      //console.log("queryObj", JSON.stringify(queryObj, null, 2));
      const firstROI = self.state.roiTableData[0];
      //console.log("firstROI", JSON.stringify(firstROI, null, 2));
      const intervalPaddingFraction = (queryObj.roiPaddingFractional) ? parseFloat(queryObj.roiPaddingFractional) : Constants.defaultApplicationRoiPaddingFraction;
      //console.log("intervalPaddingFraction", intervalPaddingAbsolute);
      const intervalPaddingAbsolute = (queryObj.roiSet) ? Constants.defaultApplicationRoiSetPaddingAbsolute : ((queryObj.roiPaddingAbsolute) ? parseInt(queryObj.roiPaddingAbsolute) : Constants.defaultApplicationRoiPaddingAbsolute);
      //console.log("intervalPaddingAbsolute", intervalPaddingAbsolute);
      if (queryObj.roiSet && !queryObj.roiPaddingAbsolute) {
        self.state.roiPaddingAbsolute = Constants.defaultApplicationRoiSetPaddingAbsolute;
      }
      const rowIndex = (self.state.selectedRoiRowIdxOnLoad !== Constants.defaultApplicationSrrIdx) ? self.state.selectedRoiRowIdxOnLoad : 1;
      const roiStart = parseInt(firstROI.chromStart);
      const roiStop = parseInt(firstROI.chromEnd);
      let roiPadding = (queryObj.roiPaddingFractional) ? parseInt(intervalPaddingFraction * (roiStop - roiStart)) : intervalPaddingAbsolute;
      //console.log("roiPadding", roiPadding);
      newTempHgViewParams.chrLeft = firstROI.chrom;
      newTempHgViewParams.chrRight = firstROI.chrom;
      newTempHgViewParams.start = roiStart - roiPadding;
      newTempHgViewParams.stop = roiStop + roiPadding;
      let scale = Helpers.calculateScale(newTempHgViewParams.chrLeft, newTempHgViewParams.chrRight, newTempHgViewParams.start, newTempHgViewParams.stop, self, false);
      self.state.previousViewScale = scale.diff;
      self.state.currentViewScale = scale.diff;
      self.state.currentViewScaleAsString = scale.scaleAsStr;
      self.state.chromsAreIdentical = scale.chromsAreIdentical;
      self.state.hgViewParams = newTempHgViewParams;
      self.state.tempHgViewParams = newTempHgViewParams;
      self.state.currentPosition.chrLeft = firstROI.chrom;
      self.state.currentPosition.chrRight = firstROI.chrom;
      self.state.currentPosition.startLeft = roiStart - roiPadding;
      self.state.currentPosition.stopLeft = roiStop + roiPadding;
      self.state.currentPosition.startRight = roiStart - roiPadding;
      self.state.currentPosition.stopRight = roiStop + roiPadding;
      self.triggerUpdate("update");
      setTimeout(() => {
        self.setState({
          drawerIsOpen: true,
        }, () => {
          setTimeout(() => {
            self.jumpToRegion(firstROI.position, Constants.applicationRegionTypes.roi, rowIndex, firstROI.strand);
          }, 0);
        });
      }, 2000); 
    }

    function updateWithSimSearchRegionsInMemory(self) {
      const firstSimSearchRegion = self.state.simSearchTableData[0];

      // console.log(`firstSimSearchRegion ${JSON.stringify(firstSimSearchRegion)}`);
      
      let newCurrentPosition = self.state.currentPosition;
      newCurrentPosition.chrLeft = self.state.queryRegionIndicatorData.chromosome;
      newCurrentPosition.chrRight = self.state.queryRegionIndicatorData.chromosome;
      newCurrentPosition.startLeft = self.state.queryRegionIndicatorData.start;
      newCurrentPosition.stopLeft = self.state.queryRegionIndicatorData.stop;
      newCurrentPosition.startRight = self.state.queryRegionIndicatorData.start;
      newCurrentPosition.stopRight = self.state.queryRegionIndicatorData.stop;
      
      newTempHgViewParams.mode = "qt";
      newTempHgViewParams.chrLeft = self.state.queryRegionIndicatorData.chromosome;
      newTempHgViewParams.chrRight = self.state.queryRegionIndicatorData.chromosome;
      newTempHgViewParams.start = self.state.queryRegionIndicatorData.start;
      newTempHgViewParams.stop = self.state.queryRegionIndicatorData.stop;
      const queryTargetQueryRegionLabel = self.state.queryRegionIndicatorData.regionLabel;
      const queryTargetQueryRegion = {
        'left' : {
          'chr' : self.state.queryRegionIndicatorData.chromosome,
          'start' : self.state.queryRegionIndicatorData.start,
          'stop' : self.state.queryRegionIndicatorData.stop,
        },
        'right' : {
          'chr' : self.state.queryRegionIndicatorData.chromosome,
          'start' : self.state.queryRegionIndicatorData.start,
          'stop' : self.state.queryRegionIndicatorData.stop,
        },
      };
      const queryTargetTargetRegionLabel = firstSimSearchRegion.position;
      const queryTargetTargetRegion = {
        'left' : {
          'chr' : firstSimSearchRegion.chrom,
          'start' : firstSimSearchRegion.chromStart,
          'stop' : firstSimSearchRegion.chromEnd,
        },
        'right' : {
          'chr' : firstSimSearchRegion.chrom,
          'start' : firstSimSearchRegion.chromStart,
          'stop' : firstSimSearchRegion.chromEnd,
        },
      };
      
      self.state.hgViewParams = newTempHgViewParams;
      self.state.tempHgViewParams = newTempHgViewParams;
      self.state.currentPosition = newCurrentPosition;
      self.state.queryTargetQueryRegionLabel = queryTargetQueryRegionLabel;
      self.state.queryTargetQueryRegion = queryTargetQueryRegion;
      self.state.queryTargetTargetRegionLabel = queryTargetTargetRegionLabel;
      self.state.queryTargetTargetRegion = queryTargetTargetRegion;
      self.state.recommenderV3SearchIsVisible = self.recommenderV3SearchCanBeVisible();
      self.state.recommenderV3SearchIsEnabled = self.recommenderV3SearchCanBeEnabled();
      self.state.recommenderV3ExpandIsEnabled = self.recommenderV3ExpandCanBeEnabled();
      self.state.exemplarsEnabled = false;
      self.state.roiEnabled = false;
      self.state.simSearchEnabled = false;
      self.state.genomeSelectIsActive = false;
      self.state.autocompleteInputDisabled = true;
      self.state.drawerIsEnabled = false;
      
      self.state.recommenderV3SearchInProgress = false;
      self.state.recommenderV3SearchButtonLabel = RecommenderV3SearchButtonDefaultLabel;
      self.state.recommenderV3SearchLinkLabel = RecommenderSearchLinkDefaultLabel;
      self.state.recommenderV3ExpandLinkLabel = RecommenderExpandLinkDefaultLabel;

      self.triggerUpdate("update", "constructor");
      const keepSuggestionInterval = true;
      self.updateViewerURL(
        self.state.hgViewParams.mode,
        self.state.hgViewParams.genome,
        self.state.hgViewParams.model,
        self.state.hgViewParams.complexity,
        self.state.hgViewParams.group,
        self.state.hgViewParams.sampleSet,
        self.state.queryRegionIndicatorData.chromosome,
        self.state.queryRegionIndicatorData.chromosome,
        self.state.queryRegionIndicatorData.start,
        self.state.queryRegionIndicatorData.stop,
        keepSuggestionInterval,
        "constructor",
      );

      // if (self.state.selectedSimSearchRowIdxOnLoad !== Constants.defaultApplicationSsrIdx) {
      //   self.queryTargetHgView.jumpToTargetRegionByIdx(self.state.selectedSimSearchRowIdxOnLoad);
      // }
    }
    
    function updateWithDefaults(self) {
      // console.log(`${JSON.stringify(newTempHgViewParams)}`);
      const scale = Helpers.calculateScale(newTempHgViewParams.chrLeft, newTempHgViewParams.chrRight, newTempHgViewParams.start, newTempHgViewParams.stop, self);
      self.state.previousViewScale = scale.diff;
      self.state.currentViewScale = scale.diff;
      self.state.currentViewScaleAsString = scale.scaleAsStr;
      self.state.chromsAreIdentical = scale.chromsAreIdentical;
      self.state.hgViewParams = newTempHgViewParams;
      self.state.tempHgViewParams = newTempHgViewParams;
      self.state.recommenderV3SearchIsVisible = self.recommenderV3SearchCanBeVisible();
      self.state.recommenderV3SearchIsEnabled = self.recommenderV3SearchCanBeEnabled();
      self.state.recommenderV3ExpandIsEnabled = self.recommenderV3ExpandCanBeEnabled();
      self.triggerUpdate("update");
      if (self.state.activeTab !== null) {
        setTimeout(() => {
          self.setState({
            drawerIsOpen: true,
            drawerActiveTabOnOpen: self.state.activeTab,
            drawerContentKey: self.state.drawerContentKey + 1,
          }, () => {
            const nonROIRowIndex = self.state.selectedNonRoiRowIdxOnLoad;
            const nonROIRegion = (self.state.activeTab === "proteinCodings") ? self.state.proteinCodingsTableData[(nonROIRowIndex - 1)] : self.state.transcriptionFactorsTableData[(nonROIRowIndex - 1)];
            const nonROIRegionType = (self.state.activeTab === "proteinCodings") ? Constants.applicationRegionTypes.proteinCodings : Constants.applicationRegionTypes.transcriptionFactors;
            setTimeout(() => {
              // console.log(`${nonROIRegion.position}, ${nonROIRegionType}, ${nonROIRowIndex}, ${nonROIRegion.strand}`);
              self.jumpToRegion(nonROIRegion.position, nonROIRegionType, nonROIRowIndex, nonROIRegion.strand);
            }, 0);
          });
        }, 2000);
      }
    }
    
    setTimeout(() => { 
      this.updateTranscriptionFactors(newTempHgViewParams.genome);
      this.updateProteinCodings(newTempHgViewParams.genome);
    }, 0);  
    
    //
    // If the roiSet parameter is defined, we check if there is a string defining intervals
    // for the url-decoded key.
    //
    if (queryObj.roiSet) {
      this.state.roiEncodedSet = queryObj.roiSet;
      this.state.roiRawSet = decodeURIComponent(this.state.roiEncodedSet);
      if (this.state.roiRawSet in Constants.roiSets) {
        // console.log("queryObj.roiSet (decoded)", this.state.roiRawSet);
        this.roiRegionsUpdate(Constants.roiSets[this.state.roiRawSet], updateWithRoisInMemory, this);
      }
      else {
        //console.log("queryObj.roiSet (decoded) not in Constants.roiSets", this.state.roiRawSet);
        updateWithDefaults(this);
      }
    }
    //
    // If the roiURL parameter is defined, we fire a callback once the URL is loaded 
    // to get the first row, to set position before the HiGlass container is rendered
    //
    else if (queryObj.roiURL) {
      setTimeout(() => {
        // console.log("queryObj.roiURL", queryObj.roiURL);
        this.updateRois(queryObj.roiURL, updateWithRoisInMemory);
      }, 0);
    }
    //
    // handle recommender (QueryTarget/SimSearch) mode
    //
    else if (newTempHgViewParams.mode === "qt") {
      //
      // TODO: 
      // We need to set up the hgViewconf skeleton here before we can instantiate a qt-view mode.

      const queryChr = newTempHgViewParams.chrLeft;
      const queryStart = newTempHgViewParams.start;
      const queryEnd = newTempHgViewParams.stop;
      const queryScale = Helpers.calculateScale(queryChr, queryChr, queryStart, queryEnd, this, false);
      const queryWindowSize = parseInt(parseInt(queryScale.diff) / 1000); // kb

      this.state.simSearchQueryCountIsVisible = false;
      this.state.simSearchQueryCountIsEnabled = false;

      // console.log(`queryScale ${JSON.stringify(queryScale)}`);
      // console.log(`queryWindowSize ${JSON.stringify(queryWindowSize)}`);

      const handleSimSearchQueryForChromInfoFn = function handleSimSearchQueryForChromInfo(chromInfo, self) {

        const newViewconfUUID = Constants.viewerHgViewconfTemplates[newTempHgViewParams.mode];
        const newHgViewconfURL = Helpers.hgViewconfDownloadURL(
          self.state.hgViewParams.hgViewconfEndpointURL,
          newViewconfUUID, 
          self);

        // console.log(`newHgViewconfURL ${newHgViewconfURL}`);

        axios.get(newHgViewconfURL)
          .then((res) => {
            if (!res.data) {
              throw String("Error: New viewconf not returned from query to " + newHgViewconfURL);
            }
            // //
            // // update track features
            // //
            // self.state.mainHgViewconf = res.data;
            // const newEpilogosTrackFilename = Helpers.epilogosTrackFilenameForSingleSampleSet(
            //   newTempHgViewParams.sampleSet, 
            //   newTempHgViewParams.genome, 
            //   newTempHgViewParams.model, 
            //   newTempHgViewParams.group, 
            //   newTempHgViewParams.complexity
            // );
            // const newEpilogosTrackUUIDQueryPromise = Helpers.uuidQueryPromise(newEpilogosTrackFilename, this);
            // newEpilogosTrackUUIDQueryPromise.then((res) => {
            //   self.state.mainHgViewconf.views[0].uid = uuid4();
            //   self.state.mainHgViewconf.views[0].tracks.top[0].tilesetUid = res;
            //   const newColormap = Constants.viewerHgViewconfColormapsPatchedForDuplicates[newTempHgViewParams.genome][newTempHgViewParams.model];
            //   self.state.mainHgViewconf.views[0].tracks.top[0].options.colorScale = newColormap;
            //   const newChromsizesUUID = Constants.viewerHgViewconfGenomeAnnotationUUIDs[newTempHgViewParams.genome]['chromsizes'];
            //   self.state.mainHgViewconf.views[0].tracks.top[2].tilesetUid = newChromsizesUUID;
            //   const newGenesUUID = Constants.viewerHgViewconfGenomeAnnotationUUIDs[newTempHgViewParams.genome]['genes'];
            //   self.state.mainHgViewconf.views[0].tracks.top[3].tilesetUid = newGenesUUID;

            //   // view parameters should be updated
            //   self.state.tempHgViewParams = newTempHgViewParams;
            //   const newSimSearchQuery = Helpers.recommenderV3QueryPromise(queryChr, queryStart, queryEnd, queryWindowSize, self);
            //   newSimSearchQuery.then((res) => {
            //     if (!res.query) {
            //       console.log(`res ${JSON.stringify(res)}`);
            //     }
            //     const queryRegionIndicatorData = {
            //       chromosome: res.query.chromosome,
            //       start: res.query.start,
            //       stop: res.query.end,
            //       midpoint: res.query.midpoint,
            //       sizeKey: res.query.sizeKey,
            //       regionLabel: `${res.query.chromosome}:${res.query.start}-${res.query.end}`,
            //       hitCount: res.query.hitCount,
            //       hitDistance: res.query.hitDistance,
            //       hitFirstInterval: res.query.hitFirstInterval,
            //       hitStartDiff: res.query.hitStartDiff,
            //       hitEndDiff: res.query.hitEndDiff,
            //     };
                
            //     // console.log(`queryRegionIndicatorData ${JSON.stringify(queryRegionIndicatorData)}`);
            //     // console.log(`res.hits[0] ${JSON.stringify(res.hits[0])}`);
      
            //     // console.log(`mainHgViewconf before qt ${JSON.stringify(self.state.mainHgViewconf)}`);

            //     self.state.queryTargetModeWillRequireFullExpand = true;
            //     self.state.queryRegionIndicatorData = queryRegionIndicatorData;
            //     // self.roiRegionsUpdate(res.hits[0], updateWithSimSearchRoisInMemory, self);
            //     self.simSearchRegionsUpdate(res.hits[0], updateWithSimSearchRegionsInMemory, self);
            //   })
            //   // eslint-disable-next-line no-unused-vars
            //   .catch((err) => {
            //     console.error(`Could not retrieve view configuration`);
            //   });
            // });
          })

        // eslint-disable-next-line no-unused-vars
        .catch((err) => {})
      }

      const genome = this.state.hgViewParams.genome;
      const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, genome);
      if (chromInfoCacheExists) {
        handleSimSearchQueryForChromInfoFn(this.chromInfoCache[genome], this);
      }
      else {
        let chromSizesURL = this.getChromSizesURL(genome);
        ChromosomeInfo(chromSizesURL)
          .then((chromInfo) => {
            this.chromInfoCache[genome] = Object.assign({}, chromInfo);
            handleSimSearchQueryForChromInfoFn(chromInfo, this);
          })
          .catch((err) => {
            throw new Error(`Error - [handleZoomPastExtent] could not retrieve chromosome information - ${JSON.stringify(err)}`);
          });
      }

      

      // console.log(`Setting up QueryTargetViewer state here`);
      // this.state.selectedExemplarRowIdxOnLoad = Constants.defaultApplicationSerIdx;
      // this.state.selectedExemplarRowIdx = Constants.defaultApplicationSerIdx;
      // this.state.selectedNonRoiRowIdxOnLoad = Constants.defaultApplicationSerIdx;
      // this.state.selectedRoiRowIdx = Constants.defaultApplicationSrrIdx;
      // this.state.selectedRoiRowIdxOnLoad = Constants.defaultApplicationSrrIdx;
      // this.state.roiMode = Constants.defaultApplicationRoiMode;
      // this.state.drawerActiveTabOnOpen = Constants.defaultDrawerTabOnOpen;
      // this.state.recommenderVersion = "v3";
      // this.state.recommenderV3SearchInProgress = true;
      // this.state.recommenderV3SearchButtonLabel = RecommenderSearchButtonInProgressLabel;
      // this.state.recommenderV3SearchLinkLabel = RecommenderSearchLinkInProgressLabel;
      // this.state.recommenderV3ExpandIsEnabled = false;
      // this.state.recommenderV3ExpandLinkLabel = RecommenderExpandLinkDefaultLabel;
      // const genome = newTempHgViewParams.genome;
      // const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, genome);
      // if (chromInfoCacheExists) {
      //   updateRecommendationsForChromInfo(this.chromInfoCache[genome], this);
      // }
      // else {
      //   let chromSizesURL = this.getChromSizesURL(genome);
      //   ChromosomeInfo(chromSizesURL)
      //     .then((chromInfo) => {
      //       // console.log(`[constructor] chromInfo > ${JSON.stringify(chromInfo)}`);
      //       this.chromInfoCache[genome] = Object.assign({}, chromInfo);
      //       updateRecommendationsForChromInfo(chromInfo, this);
      //     })
      //     .catch((err) => {
      //       throw new Error(`Error - [constructor] could not retrieve chromosome information for qt mode - genome ${genome} - chromSizesURL ${chromSizesURL} - ${JSON.stringify(err)}`);
      //       // console.log("Error - [recommenderV3SearchOnClick] could not retrieve chromosome information - ", err);
      //     });
      // }
    }
    else {
      if ((typeof queryObj.spcIdx !== "undefined") && (queryObj.spcIdx !== Constants.defaultApplicationSerIdx)) {
        this.state.selectedNonRoiRowIdxOnLoad = parseInt(queryObj.spcIdx);
        this.state.activeTab = "proteinCodings";
      }
      else if ((typeof queryObj.stfIdx !== "undefined") && (queryObj.stfIdx !== Constants.defaultApplicationSerIdx)) {
        this.state.selectedNonRoiRowIdxOnLoad = parseInt(queryObj.stfIdx);
        this.state.activeTab = "transcriptionFactors";
      }
      updateWithDefaults(this);
    }
  }
  
  componentDidMount() {
    document.body.style.overflow = "hidden";
    setTimeout(() => { 
      this.updateViewportDimensions();
      this.addCanvasWebGLContextLossEventListener();
      //this.simulateWebGLContextLoss();
    }, 2000);
    window.addEventListener("resize", this.updateViewportDimensions);
    document.addEventListener("keydown", this.handleKeyDown);
    window.addEventListener("popstate", (e) => this.handlePopState(e));
  }

  canvasWebGLContextLossEventHandler = () => {
    // window.location.reload();
    // if (this.hgView) {
    //   const newHgViewKey = this.state.hgViewKey + 1;
    //   const newHgViewconf = this.state.mainHgViewconf;
    //   this.setState({
    //     hgViewKey: newHgViewKey,
    //     mainHgViewconf: newHgViewconf,
    //   }, () => {
    //     console.error("WebGL context was lost; refreshed HiGlass canvas");
    //   });
    // }
  }

  addCanvasWebGLContextLossEventListener = () => {
    const canvases = document.getElementsByTagName("canvas");
    if (canvases.length === 1) {
      const canvas = canvases[0];
      canvas.addEventListener('webglcontextlost', this.canvasWebGLContextLossEventHandler());
    }
  }

  removeCanvasWebGLContextLossEventListener = () => {
    const canvases = document.getElementsByTagName("canvas");
    if (canvases.length === 1) {
      const canvas = canvases[0];
      canvas.removeEventListener('webglcontextlost', this.canvasWebGLContextLossEventHandler());
    }
  }

  simulateWebGLContextLoss = () => {
    // 
    // simulate loss of WebGL context, for the purposes
    // of improving user experience when the browser is 
    // overwhelmed
    //
    const canvases = document.getElementsByTagName("canvas");
    if (canvases.length === 1) {
      setTimeout(() => {
        const canvas = canvases[0];
        const webgl2Context = canvas.getContext("webgl2", {});
        if (webgl2Context) {
          // console.log(`losing webgl2 context...`);
          webgl2Context.getExtension('WEBGL_lose_context').loseContext();
        }
        else {
          const webglContext = canvas.getContext("webgl", {});
          if (webglContext) {
            // console.log(`losing webgl context...`);
            webglContext.getExtension('WEBGL_lose_context').loseContext();
          }
        }
      }, 5000);
    }
  }
  
  componentDidUpdate(prevProps, prevState) {
    Object.entries(this.props).forEach(([key, val]) =>
      prevProps[key] !== val // && console.log(`Prop '${key}' changed`)
    );
    if (this.state) {
      Object.entries(this.state).forEach(([key, val]) =>
        prevState[key] !== val // && console.log(`State '${key}' changed`)
      );
    }
  }
  
  componentWillUnmount() {
    document.body.style.overflow = null;
    window.removeEventListener("resize", this.updateViewportDimensions);
    document.removeEventListener("keydown", this.handleKeyDown);
    window.removeEventListener("popstate", null);
    this.removeCanvasWebGLContextLossEventListener();
  }

  debounce = (callback, wait, immediate = false) => {
    let timeout = null;
    return function() {
      const callNow = immediate && !timeout;
      const next = () => callback.apply(this, arguments);
      clearTimeout(timeout)
      timeout = setTimeout(next, wait);
      if (callNow) {
        next();
      }
    }
  }
  
  shouldComponentUpdate(nextProps, nextState) {
    var deepDiffMapper = function () {
      return {
        VALUE_CREATED: 'created',
        VALUE_UPDATED: 'updated',
        VALUE_DELETED: 'deleted',
        VALUE_UNCHANGED: 'unchanged',
        map: function(obj1, obj2) {
          if (this.isFunction(obj1) || this.isFunction(obj2)) {
            throw new Error('Invalid argument. Function given, object expected.');
          }
          if (this.isValue(obj1) || this.isValue(obj2)) {
            return {
              type: this.compareValues(obj1, obj2),
              data: obj1 === undefined ? obj2 : obj1
            };
          }
    
          var diff = {};
          for (let key in obj1) {
            if (this.isFunction(obj1[key])) {
              continue;
            }    
            var value2 = undefined;
            if (obj2[key] !== undefined) {
              value2 = obj2[key];
            }
            diff[key] = this.map(obj1[key], value2);
          }
          for (let key in obj2) {
            if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
              continue;
            }
            diff[key] = this.map(undefined, obj2[key]);
          }
          return diff;
        },
        compareValues: function (value1, value2) {
          if (value1 === value2) {
            return this.VALUE_UNCHANGED;
          }
          if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
            return this.VALUE_UNCHANGED;
          }
          if (value1 === undefined) {
            return this.VALUE_CREATED;
          }
          if (value2 === undefined) {
            return this.VALUE_DELETED;
          }
          return this.VALUE_UPDATED;
        },
        isFunction: function (x) {
          return Object.prototype.toString.call(x) === '[object Function]';
        },
        isArray: function (x) {
          return Object.prototype.toString.call(x) === '[object Array]';
        },
        isDate: function (x) {
          return Object.prototype.toString.call(x) === '[object Date]';
        },
        isObject: function (x) {
          return Object.prototype.toString.call(x) === '[object Object]';
        },
        isValue: function (x) {
          return !this.isObject(x) && !this.isArray(x);
        }
      }
    }();
    var result = deepDiffMapper.map(nextState, this.state);
    
    if (
      (result.searchInputValue.type === "updated")
    ) {
      return false;
    }
    
    let nothingChanged = true;
    Object.keys(result).forEach((k) => {
      if (result[k].type && result[k].type !== "unchanged") {
        //console.log(k, result[k].type, result[k].data);
        nothingChanged = false;
      }
    });
    
    return (!nothingChanged);
  }
  
  onClickSettingsButton = (event) => {
    // console.log("onClickSettingsButton", event.target.value);
    let newViewParams = {...this.state.hgViewParams};
    newViewParams[event.target.name] = event.target.value;
    if (event.target.name === "mode") {
      // get toggle value from event.target.checked
      event.target.value = (!event.target.checked) ? "ac" : "pd";
      newViewParams.mode = event.target.value;
    }
    let newViewParamsAreEqual = this.compareViewParams(newViewParams, this.props.hgViewParams);
    if (!newViewParamsAreEqual) { 
      //console.log("new", newViewParams); 
      this.setState({
        hgViewParams: newViewParams,
        tempHgViewParams: newViewParams,
      }, () => {
        if ((this.state.selectedProteinCodingsRowIdx !== Constants.defaultApplicationSerIdx) || (this.state.selectedTranscriptionFactorsRowIdx !== Constants.defaultApplicationSerIdx) || (this.state.selectedRoiRowIdx !== Constants.defaultApplicationSerIdx)) {
          this.setState({
            selectedProteinCodingsBeingUpdated: true,
            selectedTranscriptionFactorsBeingUpdated: true,
            selectedRoiBeingUpdated: true,
          }, () => {
            this.triggerUpdate("update");
            setTimeout(() => {
              this.setState({
                selectedProteinCodingsBeingUpdated: false,
                selectedTranscriptionFactorsBeingUpdated: false,
                selectedRoiBeingUpdated: false,
              });
            }, 1000);
          });
        }
        else {
          this.triggerUpdate("update");
        }
        // this.triggerUpdate("update");
        // this.closeDrawer(() => {
        //   this.triggerUpdate("update");
        // });
      });
    }
  }
  
  onClickModeToggle = (newMode) => {
    // console.log("onClickModeToggle", newMode);
    // this.closeDrawer(() => {
    //   let newViewParams = {...this.state.hgViewParams};
    //   newViewParams.mode = newMode;
    //   //console.log("newViewParams.mode", newViewParams.mode, "this.state.hgViewParams.mode", this.state.hgViewParams.mode);
    //   if (newViewParams.mode !== this.state.hgViewParams.mode) {
    //     this.setState({
    //       hgViewParams: newViewParams,
    //       tempHgViewParams: newViewParams,
    //     }, () => {
    //       this.triggerUpdate("update");
    //     });
    //   }
    // });
    let newViewParams = {...this.state.hgViewParams};
    newViewParams.mode = newMode;
    // console.log("newViewParams.mode", newViewParams.mode, "this.state.hgViewParams.mode", this.state.hgViewParams.mode);
    if (newViewParams.mode !== this.state.hgViewParams.mode) {
      this.setState({
        hgViewParams: newViewParams,
        tempHgViewParams: newViewParams,
      }, () => {
        if ((this.state.selectedProteinCodingsRowIdx !== Constants.defaultApplicationSerIdx) || (this.state.selectedTranscriptionFactorsRowIdx !== Constants.defaultApplicationSerIdx) || (this.state.selectedRoiRowIdx !== Constants.defaultApplicationSerIdx)) {
          this.setState({
            selectedProteinCodingsBeingUpdated: true,
            selectedTranscriptionFactorsBeingUpdated: true,
            selectedRoiBeingUpdated: true,
          }, () => {
            this.triggerUpdate("update");
            setTimeout(() => {
              this.setState({
                selectedProteinCodingsBeingUpdated: false,
                selectedTranscriptionFactorsBeingUpdated: false,
                selectedRoiBeingUpdated: false,
              });
            }, 1000);
          });
        }
        else {
          this.triggerUpdate("update");
        }
      });
    }
  }

  simSearchRegionsUpdate = (data, cb, self) => {
    // console.log("[simSearchRegionsUpdate] regions", JSON.stringify(data));

    const [simSearchTableRows, simSearchTableRowsCopy, simSearchTableRowsIdxBySort, dataRegions, newSimSearchMaxColumns, newSimSearchTableDataLongestNameLength] = this.roiProcessData(data);

    //
    // update state
    //
    // console.log("[simSearchRegionsUpdate] simSearchTableRows", simSearchTableRows);
    if (self) {
      self.state.simSearchTabTitle = Constants.drawerTitleByType.simsearch;
      self.state.simSearchEnabled = true;
      self.state.simSearchRegions = dataRegions;
      self.state.simSearchTableData = simSearchTableRows;
      self.state.simSearchTableDataCopy = simSearchTableRowsCopy;
      self.state.simSearchTableDataIdxBySort = simSearchTableRowsIdxBySort;
      self.state.simSearchMaxColumns = newSimSearchMaxColumns;
      self.state.simSearchTableDataLongestNameLength = newSimSearchTableDataLongestNameLength;
      const queryObj = Helpers.getJsonFromUrl();
      const activeTab = (queryObj.activeTab) ? queryObj.activeTab : Constants.drawerTitleByType.simsearch;
      const rowIndex = (self.state.selectedSimSearchRowIdxOnLoad !== Constants.defaultApplicationSsrIdx) ? self.state.selectedSimSearchRowIdxOnLoad : 1;
      // const strand = firstRoi.strand;
      self.state.drawerActiveTabOnOpen = activeTab;
      self.state.selectedSimSearchRowIdxOnLoad = rowIndex;
      // console.log(`C > A > B | ${this.state.selectedRoiRowIdx} because of onload ${self.state.selectedSimSearchRowIdxOnLoad}`);
      if (cb) {
        cb(self);
      }
      // setTimeout(() => {
      //   if (cb) {
      //     cb(self);
      //   }
      // }, 1000);
    }
    else {
      this.setState({
        simSearchTabTitle: Constants.drawerTitleByType.simsearch,
        simSearchEnabled: true,
        simSearchRegions: dataRegions,
        simSearchTableData: simSearchTableRows,
        simSearchTableDataCopy: simSearchTableRowsCopy,
        simSearchTableDataIdxBySort: simSearchTableRowsIdxBySort,
        simSearchMaxColumns: newSimSearchMaxColumns,
        simSearchTableDataLongestNameLength: newSimSearchTableDataLongestNameLength,
      }, () => {
        //
        // let the callback know that ROI data is available
        //
        if (cb) {
          cb(this);
        }
        const queryObj = Helpers.getJsonFromUrl();
        // console.log("[roiRegionsUpdate] queryObj", JSON.stringify(queryObj));
        // const activeTab = queryObj.activeTab || Constants.drawerTypeByName.roi;
        setTimeout(() => {
          const firstSimSearchRegion = simSearchTableRows[(this.state.selectedSimSearchRowIdxOnLoad !== Constants.defaultApplicationSsrIdx) ? this.state.selectedSimSearchRowIdxOnLoad - 1 : 0];
          try {
            const region = firstSimSearchRegion.position;
            const regionType = Constants.applicationRegionTypes.simsearch;
            const rowIndex = (this.state.selectedSimSearchRowIdxOnLoad !== Constants.defaultApplicationSsrIdx) ? this.state.selectedSimSearchRowIdxOnLoad : 1;
            // console.log("[simSearchRegionsUpdate] rowIndex", rowIndex);
            const strand = firstSimSearchRegion.strand;
            this.setState({
              // drawerIsOpen: true,
              // drawerActiveTabOnOpen: activeTab,
              // drawerContentKey: this.state.drawerContentKey + 1,
              selectedSimSearchRowIdx: rowIndex
            }, () => {
              // if (this.state.roiTableIsVisible) {
              if (true) {
                // console.log("[roiRegionsUpdate] state", JSON.stringify(this.state));
                setTimeout(() => {
                  this.jumpToRegion(region, regionType, rowIndex, strand, true);
                }, 500);
                this.updateViewportDimensions();
              }
            });
          }
          catch (err) {
            if (err instanceof TypeError) {
              throw new Error(`[simSearchRegionsUpdate] Error - ROI parsing error ${JSON.stringify(simSearchTableRows)}`);
            }
          }
        }, 2500);
      });
    }
  } 
  
  compareViewParams = (a, b) => {
    return equal(a, b);
  }
  
  handlePopState = () => {
    //console.log("handlePopState", location);
    const queryObj = Helpers.getJsonFromUrl();
    let newTempHgViewParams = {...this.state.tempHgViewParams};
    newTempHgViewParams.genome = queryObj.genome || Constants.defaultApplicationGenome;
    newTempHgViewParams.model = queryObj.model || Constants.defaultApplicationModel;
    newTempHgViewParams.complexity = queryObj.complexity || Constants.defaultApplicationComplexity;
    newTempHgViewParams.group = queryObj.group || Constants.defaultApplicationGroup;
    newTempHgViewParams.chrLeft = queryObj.chrLeft || Constants.defaultApplicationChr;
    newTempHgViewParams.chrRight = queryObj.chrRight || Constants.defaultApplicationChr;
    newTempHgViewParams.start = parseInt(queryObj.start || Constants.defaultApplicationStart);
    newTempHgViewParams.stop = parseInt(queryObj.stop || Constants.defaultApplicationStop);
    newTempHgViewParams.mode = queryObj.mode || Constants.defaultApplicationMode;
    newTempHgViewParams.roiMode = queryObj.roiMode || Constants.defaultApplicationRoiMode;
    this.setState({
      tempHgViewParams: newTempHgViewParams
    }, () => { 
      setTimeout(() => {
        this.closeDrawer();
        this.viewerHistoryChangeEventTimer = {};
        this.triggerUpdate("update"); 
      }, 0);
      setTimeout(() => {
        this.viewerHistoryChangeEventTimer = null;
      }, 1000);
    });
  }
  
  handleKeyDown = (event) => {
    const ESCAPE_KEY = 27;
    const RETURN_KEY = 13;
    const LEFT_ARROW_KEY = 37;
    const UP_ARROW_KEY = 38;
    const RIGHT_ARROW_KEY = 39;
    const DOWN_ARROW_KEY = 40;
    const FORWARD_SLASH_KEY = 191;
    // console.log(`event.keyCode ${event.keyCode} | this.state.drawerIsOpen ${this.state.drawerIsOpen} | this.state.selectedRoiBeingUpdated ${this.state.selectedRoiBeingUpdated}`);
    switch (event.keyCode) {
      case ESCAPE_KEY: 
        if (this.state.drawerIsOpen) {
          this.triggerUpdate("cancel");
        }
        else if (this.state.autocompleteInputEntered) {
          this.autocompleteInputRef.clearUserInput();
        }
        if (this.state.tabixDataDownloadCommandVisible) {
          this.fadeOutContainerOverlay(() => { this.setState({ tabixDataDownloadCommandVisible: false }); });
        }
        break;
      case RETURN_KEY:
        if (this.state.drawerIsOpen) {
          this.triggerUpdate("update");
        }
        break;
      case LEFT_ARROW_KEY:
      case UP_ARROW_KEY:
        if (this.state.hgViewParams.mode !== 'qt') {
          if (this.state.drawerIsOpen) {
            if (!this.state.selectedProteinCodingsBeingUpdated) { this.updatedProteinCodingsRowIdxFromCurrentIdx("previous"); }
            if (!this.state.selectedTranscriptionFactorsBeingUpdated) { this.updatedTranscriptionFactorsRowIdxFromCurrentIdx("previous"); }
            if (!this.state.selectedRoiBeingUpdated) { this.updatedRoiRowIdxFromCurrentIdx("previous"); }
          }
        }
        else {
          event.preventDefault();
          this.queryTargetHgView.updateCurrentRecommendationIdx("previous");
        }
        break;
      case RIGHT_ARROW_KEY:
      case DOWN_ARROW_KEY:
        if (this.state.hgViewParams.mode !== 'qt') { 
          if (this.state.drawerIsOpen) {
            if (!this.state.selectedProteinCodingsBeingUpdated) { this.updatedProteinCodingsRowIdxFromCurrentIdx("next"); }
            if (!this.state.selectedTranscriptionFactorsBeingUpdated) { this.updatedTranscriptionFactorsRowIdxFromCurrentIdx("next"); }
            if (!this.state.selectedRoiBeingUpdated) { this.updatedRoiRowIdxFromCurrentIdx("next"); }
          }
        }
        else {
          event.preventDefault();
          this.queryTargetHgView.updateCurrentRecommendationIdx("next");
        }
        break;
      case FORWARD_SLASH_KEY: {
        // console.log(`FORWARD_SLASH_KEY`);
        // console.log(`this.state.drawerIsOpen ${this.state.drawerIsOpen}`);
        if (this.state.drawerIsOpen) {
          this.closeDrawer();
        }
        // https://stackoverflow.com/questions/49328382/browser-detection-in-reactjs
        // Firefox has built-in search, which we don't want to trigger
        const isFirefox = typeof InstallTrigger !== 'undefined';
        if (!isFirefox) {
          this.autocompleteInputRef.applyFocus();
        }
        break;
      }
      default: 
        break;
    }
  }

  updateSimSearchRowIdxFromCurrentIdx = (direction, overrideNewRowIdx) => {
    if (!this.state.simSearchTableIsVisible) return;
    console.log("[updateSimSearchRowIdxFromCurrentIdx]");
    console.log("[updateSimSearchRowIdxFromCurrentIdx] direction", direction);
    console.log("[updateSimSearchRowIdxFromCurrentIdx] this.state.selectedSimSearchRowIdx", this.state.selectedSimSearchRowIdx);
    // console.log("[updateSimSearchRowIdxFromCurrentIdx] this.state.simSearchTableDataIdxBySort", JSON.stringify(this.state.simSearchTableDataIdxBySort, null, 2));
    let currentIdx = this.state.selectedSimSearchRowIdx;
    if (((currentIdx < 1) || (!this.state.simSearchTableData) || (this.state.simSearchTableData.length === 0)) && (direction !== "skip")) return;
    let indexOfCurrentIdx = parseInt(this.state.simSearchTableDataIdxBySort.indexOf(currentIdx));
    let newRowIdx = currentIdx;
    let minIdx = Math.min(...this.state.simSearchTableDataIdxBySort) - 1;
    let maxIdx = Math.max(...this.state.simSearchTableDataIdxBySort) - 1;
    // console.log("[updateSimSearchRowIdxFromCurrentIdx] maxIdx", maxIdx);
    switch (direction) {
      case "previous":
        if (indexOfCurrentIdx > minIdx) {
          let previousValue = this.state.simSearchTableDataIdxBySort[indexOfCurrentIdx - 1];
          let indexOfPreviousValue = this.state.simSearchTableDataIdxBySort.indexOf(previousValue);
          newRowIdx = parseInt(this.state.simSearchTableDataIdxBySort[indexOfPreviousValue]);
        }
        break;
      case "next":
        if (indexOfCurrentIdx < maxIdx) {
          let nextValue = this.state.simSearchTableDataIdxBySort[indexOfCurrentIdx + 1];
          let indexOfNextValue = this.state.simSearchTableDataIdxBySort.indexOf(nextValue);
          newRowIdx = parseInt(this.state.simSearchTableDataIdxBySort[indexOfNextValue]);
        }
        break;
      case "skip":
        newRowIdx = overrideNewRowIdx;
        break;
      default:
        throw new Error('[updateSimSearchRowIdxFromCurrentIdx] Unknown direction for simSearch row index update', direction);
    }
    // console.log(`[updateSimSearchRowIdxFromCurrentIdx] indexOfCurrentIdx ${indexOfCurrentIdx} newRowIdx ${newRowIdx}`);
    let newSimSearchObj = this.state.simSearchTableData.filter((e) => e.idx === newRowIdx);
    console.log(`newSimSearchObj ${JSON.stringify(newSimSearchObj)}`);
    let newSimSearchPosition = newSimSearchObj[0].position;
    const pos = Helpers.getRangeFromString(newSimSearchPosition, false, true, this.state.hgViewParams.genome);
    // console.log(`pos ${pos} | newSimSearchPosition ${JSON.stringify(newSimSearchPosition)}`);
    const chromosome = pos[0];
    const start = parseInt(pos[1]);
    const stop = parseInt(pos[2]);

    // console.log(`newSimSearchPosition ${JSON.stringify(newSimSearchPosition)}`);

    const simSearchEl = document.getElementById(`simSearch_idx_${newRowIdx}`);
    if (simSearchEl) simSearchEl.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    
    /* { this.setState({
      selectedSimSearchBeingUpdated: true
    }, () => {
      this.jumpToRegion(newSimSearchPosition, Constants.applicationRegionTypes.simsearch, newRowIdx);
    }); } */
    
    // update selection
    this.setState({
      selectedSimSearchRowIdx: parseInt(newRowIdx),
      selectedSimSearchRegionBeingUpdated: true,
      selectedSimSearchRegionChrLeft: chromosome,
      selectedSimSearchRegionChrRight: chromosome,
      selectedSimSearchRegionStart: start,
      selectedSimSearchRegionStop: stop,
    }, () => {
      // debounce the true key event action
      clearTimeout(this.viewerKeyEventChangeEventTimer);
      this.viewerKeyEventChangeEventTimer = setTimeout(() => {
        this.hgViewUpdateSimSearchPosition(
          this.state.hgViewParams, 
          parseInt(newRowIdx)
        );
        // if (this.state.roiTableData && this.state.roiTableData[this.state.selectedRoiRowIdx - 1]) {
        //   // this.jumpToRegion(
        //   //   this.state.roiTableData[this.state.selectedRoiRowIdx - 1].position,
        //   //   Constants.applicationRegionTypes.roi, 
        //   //   this.state.selectedRoiRowIdx);
        // }
        // this.setState({
        //   selectedRoiBeingUpdated: false
        // });
      }, Constants.defaultViewerKeyEventChangeEventDebounceTimeout);
    });
  }

  updateViewerURLForQueryTargetMode = (mode, chrLeft, chrRight, start, stop, cf) => {
    console.log(`updateViewerURLForQueryTargetMode <- ${cf} | ${mode} ${chrLeft} ${chrRight} ${start} ${stop}`);
    if (!mode) {
      mode = this.state.hgViewParams.mode;
    }
    this.setState({
      currentPositionKey: Math.random(),
      currentPosition : {
        chrLeft : chrLeft,
        chrRight : chrRight,
        startLeft : start,
        stopLeft : stop,
        startRight : start,
        stopRight : stop
      },
    }, () => {
      console.log("calling [updateViewerURL] from [updateViewerURLForQueryTargetMode]");
      this.updateViewerURL(mode, 
        chrLeft, 
        chrRight, 
        start, 
        stop,
        true,
        "updateViewerURLForQueryTargetMode");
    })
  }

  updateViewerAutocompleteState = (enabledFlag) => {
    this.setState({
      autocompleteInputDisabled: !enabledFlag,
    });
  }

  updateViewerHamburgerMenuState = (enabledFlag) => {
    this.setState({
      drawerIsEnabled: enabledFlag,
    });
  }

  updateViewerDownloadState = (enabledFlag) => {
    this.setState({
      downloadIsEnabled: enabledFlag,
    });
  }

  updateViewerOverlay = (msg) => {
    this.setState({
      overlayMessage: msg
    }, () => {
      this.fadeInOverlay(() => {
        this.setState({
          selectedExemplarRowIdx: Constants.defaultApplicationSerIdx,
          recommenderV3SearchInProgress: false,
          recommenderV3SearchButtonLabel: RecommenderV3SearchButtonDefaultLabel,
          recommenderV3SearchLinkLabel: RecommenderSearchLinkDefaultLabel,
          recommenderV3ExpandIsEnabled: false,
          recommenderV3ExpandLinkLabel: RecommenderExpandLinkDefaultLabel,
        })
      });
    })
  }
  
  updateViewerState = (keyword, value, cb) => {
    this.setState({
      [keyword] : value
    }, () => {
      if (cb) {
        cb();
      }
    })
  }

  updateRegionsForQueryTargetView = (data, cb) => {
    const [roiTableRows, roiTableRowsCopy, roiTableRowsIdxBySort, dataRegions, newRoiMaxColumns, newRoiTableDataLongestNameLength] = this.roiProcessData(data);
    this.setState({
      roiRegions: dataRegions,
      roiTableData: roiTableRows,
      roiTableDataCopy: roiTableRowsCopy,
      roiTableDataIdxBySort: roiTableRowsIdxBySort,
      roiMaxColumns: newRoiMaxColumns,
      roiTableDataLongestNameLength: newRoiTableDataLongestNameLength,
    }, () => {
      if (cb) {
        cb();
      }
    });
  }

  expandViewerToRegion = (region) => {
    console.log(`Viewer | expandViewerToRegion ${JSON.stringify(region)} | ${JSON.stringify(this.state.mainHgViewconf.views[0].uid)}`);
    // return;
    // {"left":{"chr":"chr1","start":82288000,"stop":82313000},"right":{"chr":"chr1","start":82288000,"stop":82313000}}
    const newHgViewParams = {...this.state.hgViewParams};
    newHgViewParams.mode = "pd";
    this.setState({
      recommenderV3SearchIsVisible: true,
      recommenderV3SearchIsEnabled: true,
      genomeSelectIsActive: true,
      hgViewParams: newHgViewParams,
      tempHgViewParams: newHgViewParams,
      exemplarsEnabled: true,
      roiEnabled: false,
      autocompleteInputDisabled: false,
      drawerSelection: Constants.defaultDrawerType,
      drawerActiveTabOnOpen: Constants.defaultDrawerTabOnOpen,
      currentPosition: {
        chrLeft: region.left.chr,
        chrRight: region.right.chr,
        startLeft: region.left.start,
        stopLeft: region.left.stop,
        startRight: region.right.start,
        stopRight: region.right.stop
      },
    }, () => {
      const range = [
        region.left.chr,
        region.left.start,
        region.right.stop,
      ];
      //
      // if the viewer was opened directly to the recommender view, it is likely that the
      // main view container was not initialized, so we redirect via URL and allow the app 
      // to be reconstructed that way
      //
      // if (!this.state.mainHgViewconf.hasOwnProperty('views')) {
      if (!Object.prototype.hasOwnProperty.call(this.state.mainHgViewconf, 'views')) {
        console.log(`missing main view container`);
        const mode = this.state.hgViewParams.mode;
        const chrLeft = this.state.currentPosition.chrLeft;
        const chrRight = this.state.currentPosition.chrRight;
        const start = this.state.currentPosition.startLeft;
        const stop = this.state.currentPosition.stopRight;
        const viewerUrl = this.updateViewerURL(mode, chrLeft, chrRight, start, stop);
        const viewerUrlTarget = "_self";
        window.open(viewerUrl, viewerUrlTarget);
        // setTimeout(() => {
        //   this.openViewerAtChrRange(range, false);
        // }, 500); 
      }
      //
      // otherwise, we can safely manipulate the main view container
      //
      else {
        setTimeout(() => {
          this.openViewerAtChrRange(range, false, this.state.hgViewParams);
          this.hgView.api.on("location", (event) => { 
            this.updateViewerLocation(event);
          });
        }, 100);
      }
    });
  }

  updatedRoiRowIdxFromCurrentIdx = (direction) => {
    // console.log("updatedRoiRowIdxFromCurrentIdx");
    // console.log("direction", direction);
    // console.log("this.state.selectedRoiRowIdx", this.state.selectedRoiRowIdx);
    // console.log("this.state.roiTableDataIdxBySort", this.state.roiTableDataIdxBySort);
    let currentIdx = this.state.selectedRoiRowIdx;
    if (currentIdx < 1) return;
    let indexOfCurrentIdx = parseInt(this.state.roiTableDataIdxBySort.indexOf(currentIdx));
    let newRowIdx = currentIdx;
    let minIdx = Math.min(...this.state.roiTableDataIdxBySort) - 1;
    let maxIdx = Math.max(...this.state.roiTableDataIdxBySort) - 1;
    //console.log("maxIdx", maxIdx);
    switch (direction) {
      case "previous":
        if (indexOfCurrentIdx > minIdx) {
          newRowIdx = parseInt(this.state.roiTableDataIdxBySort[indexOfCurrentIdx - 1]);
        }
        break;
      case "next":
        if (indexOfCurrentIdx < maxIdx) {
          newRowIdx = parseInt(this.state.roiTableDataIdxBySort[indexOfCurrentIdx + 1]);
        }
        break;
      default:
        throw new Error('Unknown direction for ROI row index update', direction);
    }
    //console.log("newRowIdx", newRowIdx);
    let newRoiObj = this.state.roiTableData.filter((e) => e.idx === newRowIdx);
    let newRoi = newRoiObj[0].position;
    this.setState({
      selectedRoiBeingUpdated: true
    }, () => {
      this.jumpToRegion(newRoi, Constants.applicationRegionTypes.roi, newRowIdx);
    })
  }
  
  updatedProteinCodingsRowIdxFromCurrentIdx = (direction) => {
    //console.log("updatedProteinCodingsRowIdxFromCurrentIdx");
    //console.log("direction", direction);
    //console.log("this.state.selectedProteinCodingsRowIdx", this.state.selectedProteinCodingsRowIdx);
    //console.log("this.state.proteinCodingsTableDataIdxBySort", this.state.proteinCodingsTableDataIdxBySort);
    let currentIdx = this.state.selectedProteinCodingsRowIdx;
    if (currentIdx < 1) return;
    let indexOfCurrentIdx = parseInt(this.state.proteinCodingsTableDataIdxBySort.indexOf(currentIdx));
    let newRowIdx = currentIdx;
    //console.log("this.state.transcriptionFactorsTableDataIdxBySort", this.state.transcriptionFactorsTableDataIdxBySort);
    let minIdx = Math.min(...this.state.proteinCodingsTableDataIdxBySort) - 1;
    let maxIdx = Math.max(...this.state.proteinCodingsTableDataIdxBySort) - 1;
    //console.log(direction, currentIdx, indexOfCurrentIdx, newRowIdx, minIdx, maxIdx);
    switch (direction) {
      case "previous":
        if (indexOfCurrentIdx > minIdx) {
          newRowIdx = parseInt(this.state.proteinCodingsTableDataIdxBySort[indexOfCurrentIdx - 1]);
        }
        break;
      case "next":
        if (indexOfCurrentIdx < maxIdx) {
          newRowIdx = parseInt(this.state.proteinCodingsTableDataIdxBySort[indexOfCurrentIdx + 1]);
        }
        break;
      default:
        throw new Error('Unknown direction for protein-coding genes row index update', direction);
    }
    let newProteinCodingsObj = this.state.proteinCodingsTableData.filter((e) => e.idx === newRowIdx);
    let newProteinCoding = newProteinCodingsObj[0].position;
    this.setState({
      selectedProteinCodingsBeingUpdated: true
    }, () => {
      this.jumpToRegion(newProteinCoding, Constants.applicationRegionTypes.proteinCodings, newRowIdx);
    })
  }
  
  updatedTranscriptionFactorsRowIdxFromCurrentIdx = (direction) => {
    //console.log("updatedTranscriptionFactorsRowIdxFromCurrentIdx");
    //console.log("direction", direction);
    //console.log("this.state.selectedTranscriptionFactorsRowIdx", this.state.selectedTranscriptionFactorsRowIdx);
    //console.log("this.state.transcriptionFactorsTableDataIdxBySort", this.state.transcriptionFactorsTableDataIdxBySort);
    let currentIdx = this.state.selectedTranscriptionFactorsRowIdx;
    if (currentIdx < 1) return;
    let indexOfCurrentIdx = parseInt(this.state.transcriptionFactorsTableDataIdxBySort.indexOf(currentIdx));
    let newRowIdx = currentIdx;
    //console.log("this.state.transcriptionFactorsTableDataIdxBySort", this.state.transcriptionFactorsTableDataIdxBySort);
    let minIdx = Math.min(...this.state.transcriptionFactorsTableDataIdxBySort) - 1;
    let maxIdx = Math.max(...this.state.transcriptionFactorsTableDataIdxBySort) - 1;
    //console.log(direction, currentIdx, indexOfCurrentIdx, newRowIdx, minIdx, maxIdx);
    switch (direction) {
      case "previous":
        if (indexOfCurrentIdx > minIdx) {
          newRowIdx = parseInt(this.state.transcriptionFactorsTableDataIdxBySort[indexOfCurrentIdx - 1]);
        }
        break;
      case "next":
        if (indexOfCurrentIdx < maxIdx) {
          newRowIdx = parseInt(this.state.transcriptionFactorsTableDataIdxBySort[indexOfCurrentIdx + 1]);
        }
        break;
      default:
        throw new Error('Unknown direction for transcription factors row index update', direction);
    }
    let newTranscriptionFactorsObj = this.state.transcriptionFactorsTableData.filter((e) => e.idx === newRowIdx);
    let newTranscriptionFactor = newTranscriptionFactorsObj[0].position;
    this.setState({
      selectedTranscriptionFactorsBeingUpdated: true
    }, () => {
      this.jumpToRegion(newTranscriptionFactor, Constants.applicationRegionTypes.transcriptionFactors, newRowIdx);
    })
  }
  
  getChromSizesURL = (genome) => {
    // let chromSizesURL = this.state.hgViewParams.hgGenomeURLs[genome];
    // if (this.state.hgViewParams.itt === "ht") {
    //   chromSizesURL = this.state.hgViewParams.hgFixedBinGenomeURLs[genome];
    // }
    let chromSizesURL = this.state.hgViewParams.hgFixedBinGenomeURLs[genome];
    // console.log(`chromSizesURL ${chromSizesURL}`);
    if (this.isProductionSite) {
      chromSizesURL = chromSizesURL.replace(Constants.applicationDevelopmentPort, Constants.applicationProductionPort);
    }
    else if (this.isProductionProxySite) {
      chromSizesURL = chromSizesURL.replace(Constants.applicationDevelopmentPort, Constants.applicationProductionProxyPort);
      chromSizesURL = chromSizesURL.replace(/^https/, "http");
    }
    else {
      let port = parseInt(this.currentURL.port);
      if (isNaN(port)) { port = Constants.applicationProductionPort; }
      chromSizesURL = chromSizesURL.replace(":" + Constants.applicationDevelopmentPort, `:${port}`);
    }
    return chromSizesURL;
  }
  
  handleZoomPastExtent = () => {
    // console.log("this.handleZoomPastExtent()");
    if (this.state.searchInputLocationBeingChanged) return;
    
    if (!this.viewerZoomPastExtentTimer) {
      clearTimeout(this.viewerZoomPastExtentTimer);
      this.viewerZoomPastExtentTimer = setTimeout(() => {
        let genome = this.state.hgViewParams.genome;
        let boundsLeft = 20;
        let boundsRight = Constants.assemblyBounds[genome].chrY.ub - boundsLeft;

        const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, genome);

        function handleZoomPastExtentForChromInfo(chromInfo, self) {
          setTimeout(() => {
            self.hgView.zoomTo(
              self.state.mainHgViewconf.views[0].uid,
              chromInfo.chrToAbs(["chr1", boundsLeft]),
              chromInfo.chrToAbs(["chrY", boundsRight]),
              chromInfo.chrToAbs(["chr1", boundsLeft]),
              chromInfo.chrToAbs(["chrY", boundsRight]),
              100
            );
          }, 0);
          setTimeout(() => { 
            self.viewerZoomPastExtentTimer = null; 
            // console.log("this.viewerZoomPastExtentTimer *unset*"); 
            console.log("calling [updateViewerURL] from [handleZoomPastExtent]");
            self.updateViewerURL(self.state.tempHgViewParams.mode,
                                 "chr1",
                                 "chrY",
                                 boundsLeft,
                                 boundsRight,
                                 true,
                                 "handleZoomPastExtent");
            setTimeout(() => {
              self.updateScale(true, "handleZoomPastExtent");
            }, 2500);
          }, 2000);
        }

        if (chromInfoCacheExists) {
          // console.log(`this.state ${JSON.stringify(this.state)}`);
          handleZoomPastExtentForChromInfo(this.chromInfoCache[genome], this);
        }
        else {
          let chromSizesURL = this.getChromSizesURL(genome);
          ChromosomeInfo(chromSizesURL)
            .then((chromInfo) => {
              this.chromInfoCache[genome] = Object.assign({}, chromInfo);
              handleZoomPastExtentForChromInfo(chromInfo, this);
            })
            .catch((err) => {
              throw new Error(`Error - [handleZoomPastExtent] could not retrieve chromosome information - ${JSON.stringify(err)}`);
            });
        }

      }, 2000);
    }
  }
  
  updateViewerLocation = (event) => {
    if (!this.state.selectedProteinCodingsBeingUpdated && !this.state.selectedTranscriptionFactorsBeingUpdated && !this.state.selectedRoiBeingUpdated) {
      if (this.epilogosViewerContainerVerticalDrop && this.epilogosViewerContainerVerticalDrop.style) this.fadeOutVerticalDrop();
      if (this.epilogosViewerContainerIntervalDrop && this.epilogosViewerContainerIntervalDrop.style) this.fadeOutIntervalDrop();
    }
    this.updateViewerURLWithLocation(event);
    // if (!this.viewerLocationChangeEventTimer) {
    //   clearTimeout(this.viewerLocationChangeEventTimer);
    //   this.viewerLocationChangeEventTimer = setTimeout(() => {
    //     setTimeout(() => { 
    //       this.updateViewerURLWithLocation(event);
    //       // this.viewerLocationChangeEventTimer = null;
    //     }, 0);
    //     // this.updateScale();
    //     // console.log("[updateViewerLocation] this.viewerLocationChangeEventTimer set");
    //   }, 750);
    // }
  }

  updateViewerURLAtCurrentState = (cb) => {
    // console.log(`updateViewerURLAtCurrentState | selectedRoiRowIdx ${this.state.selectedRoiRowIdx}`);
    this.updateViewerURL(this.state.hgViewParams.mode,
                         this.state.currentPosition.chrLeft,
                         this.state.currentPosition.chrRight,
                         this.state.currentPosition.startLeft,
                         this.state.currentPosition.stopRight,
                         true,
                         "updateViewerURLAtCurrentState",);
    if (cb) cb();
  }
  
  updateViewerURL = (mode, chrLeft, chrRight, start, stop, keepSuggestionInterval, cf) => {
    // console.log(`updateViewerURL <- ${cf} | ${mode}, ${chrLeft}, ${chrRight}, ${start}, ${stop} | spcIdx ${this.state.selectedProteinCodingsRowIdx} | stfIdx ${this.state.selectedTranscriptionFactorsRowIdx} | srrIdx ${this.state.srrIdx}`);

    let viewerUrl = Helpers.stripQueryStringAndHashFromPath(document.location.href) + "?application=viewer";
    viewerUrl += "&mode=" + mode;
    viewerUrl += "&chrLeft=" + chrLeft;
    viewerUrl += "&chrRight=" + chrRight;
    viewerUrl += "&start=" + parseInt(start);
    viewerUrl += "&stop=" + parseInt(stop);
    
    if (parseInt(this.state.selectedProteinCodingsRowIdx) > 0) {
      viewerUrl += "&spcIdx=" + parseInt(this.state.selectedProteinCodingsRowIdx);
    }
    if (parseInt(this.state.selectedTranscriptionFactorsRowIdx) > 0) {
      viewerUrl += "&stfIdx=" + parseInt(this.state.selectedTranscriptionFactorsRowIdx);
    }
    if (this.state.roiEncodedURL.length > 0) {
      viewerUrl += `&roiURL=${this.state.roiEncodedURL}`;
    }
    if (this.state.roiEncodedSet.length > 0) {
      viewerUrl += `&roiSet=${this.state.roiEncodedSet}`;
    }
    if (parseInt(this.state.selectedRoiRowIdx) > 0) {
      viewerUrl += "&srrIdx=" + parseInt(this.state.selectedRoiRowIdx);
    }

    if (parseInt(this.state.selectedSimSearchRowIdx) > 0) {
      viewerUrl += "&ssrIdx=" + parseInt(this.state.selectedSimSearchRowIdx);
    }

    if (this.state.roiMode && (this.state.roiMode.length > 0) && (this.state.roiMode !== Constants.defaultApplicationRoiMode)) {
      viewerUrl += `&roiMode=${this.state.roiMode}`;
    }
    if (this.state.roiPaddingAbsolute && (parseInt(this.state.roiPaddingAbsolute) > 0) && (parseInt(this.state.roiPaddingAbsolute) !== Constants.defaultApplicationRoiPaddingAbsolute)) {
      viewerUrl += `&roiPaddingAbsolute=${this.state.roiPaddingAbsolute}`;
    }
    if (this.state.roiPaddingFractional && ((parseFloat(this.state.roiPaddingFractional) > 0) && (parseFloat(this.state.roiPaddingFractional) < 1)) && (parseFloat(this.state.roiPaddingFractional) !== Constants.defaultApplicationRoiPaddingFraction)) {
      viewerUrl += `&roiPaddingFractional=${this.state.roiPaddingFractional}`;
    }
    if (!this.isProductionSite) {
      if (parseInt(this.state.hgViewParams.dtt) >= 0) {
        viewerUrl += "&dtt=" + parseInt(this.state.hgViewParams.dtt);
      }
      if (this.state.hgViewParams.dtc.length > 0) {
        viewerUrl += `&dtc=${this.state.hgViewParams.dtc}`;
      }
      else {
        viewerUrl += `&dtc=${Constants.defaultApplicationDtcCategory}`;
      }
    }
    if (this.state.hgViewParams.itt && (this.state.hgViewParams.itt.length > 0) && (this.state.hgViewParams.itt !== Constants.defaultApplicationIttCategory)) {
      viewerUrl += `&itt=${this.state.hgViewParams.itt}`;
    }
    if (this.state.hgViewParams.gatt && (this.state.hgViewParams.gatt.length > 0)) {
      viewerUrl += `&gatt=${this.state.hgViewParams.gatt}`;
    }
    if (this.state.hgViewParams.gac && (this.state.hgViewParams.gac.length > 0)) {
      viewerUrl += `&gac=${this.state.hgViewParams.gac}`;
    }
    if (this.state.hgViewParams.gmax && (this.state.hgViewParams.gmax > 0.0) && (this.state.hgViewParams.gmax !== Constants.defaultApplicationSignalGlobalMax)) {
      viewerUrl += `&gmax=${this.state.hgViewParams.gmax}`;
    }
    // console.log(`viewerUrl: ${viewerUrl}`);
    // this.updateViewerHistory(viewerUrl);
    // this.updateScale();
    const newCurrentPosition = {
      chrLeft: chrLeft,
      chrRight: chrRight,
      startLeft: parseInt(start),
      stopLeft: parseInt(stop),
      startRight: parseInt(start),
      stopRight: parseInt(stop),
    };
    // console.log(`${JSON.stringify(newCurrentPosition)}`);
    this.setState({
      currentPosition: newCurrentPosition
    }, () => {
      setTimeout(() => { 
        if (typeof keepSuggestionInterval === "undefined") keepSuggestionInterval = true;
      this.updateScale(keepSuggestionInterval, "updateViewerURL");
      }, 500);
      this.updateViewerHistory(viewerUrl);
    });

    return viewerUrl;
  }
  
  updateViewerURLWithLocation = (event) => {
    const genome = this.state.hgViewParams.genome;
    const oldMode = this.state.hgViewParams.mode;
    const newMode = this.state.tempHgViewParams.mode;
    const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, genome);

    function updateViewerURLWithLocationForChromInfo(chromInfo, self) {
      //console.log(`self.state ${JSON.stringify(self.state)}`);
      let chrStartPos = chromInfo.absToChr(event.xDomain[0]);
      let chrStopPos = chromInfo.absToChr(event.xDomain[1]);
      let chrLeft = chrStartPos[0];
      let start = chrStartPos[1];
      let chrRight = chrStopPos[0];
      let stop = chrStopPos[1];
      let selectedProteinCodingsRowIdx = self.state.selectedProteinCodingsRowIdx;
      let selectedTranscriptionFactorsRowIdx = self.state.selectedTranscriptionFactorsRowIdx;
      let selectedRoiRowIdx = self.state.selectedRoiRowIdx;
      // console.log(`old mode ${oldMode} | new mode ${newMode}`)
      if ((chrLeft !== self.state.selectedProteinCodingsChrLeft) || (chrRight !== self.state.selectedProteinCodingsChrRight) || (start !== self.state.selectedProteinCodingsStart) || (stop !== self.state.selectedProteinCodingsStop) || (chrLeft !== self.state.selectedTranscriptionFactorsChrLeft) || (chrRight !== self.state.selectedTranscriptionFactorsChrRight) || (start !== self.state.selectedTranscriptionFactorsStart) || (stop !== self.state.selectedTranscriptionFactorsStop) || (chrLeft !== self.state.selectedRoiChrLeft) || (chrRight !== self.state.selectedRoiChrRight) || (start !== self.state.selectedRoiStart) || (stop !== self.state.selectedRoiStop)) {
        const queryObj = Helpers.getJsonFromUrl();
        if (!self.state.selectedProteinCodingsBeingUpdated && !self.state.selectedTranscriptionFactorsBeingUpdated && !queryObj.roiURL && !queryObj.roiSet) {
          // console.log("resetting selected row idx for protein coding or TF category", self.state.selectedProteinCodingsBeingUpdated, self.state.selectedTranscriptionFactorsBeingUpdated);
          selectedProteinCodingsRowIdx = Constants.defaultApplicationSerIdx;
          selectedTranscriptionFactorsRowIdx = Constants.defaultApplicationSerIdx;
          // console.log("calling fadeOutVerticalDrop from updateViewerURL because this.state.selectedExemplarBeingUpdated is false");
          // self.fadeOutVerticalDrop();
          self.triggerUpdate();
        }
        if (!self.state.selectedRoiBeingUpdated && (queryObj.roiURL || queryObj.roiSet)) {
          //console.log("resetting selected row idx for ROI category");
          selectedRoiRowIdx = Constants.defaultApplicationSrrIdx;
          //console.log("calling fadeOutVerticalDrop and fadeOutIntervalDrop from updateViewerURL because this.state.selectedRoiBeingUpdated is false");
          // self.fadeOutVerticalDrop();
          // self.fadeOutIntervalDrop();
        }
      }
      self.setState({
        //positionSummaryElementKey: Math.random(),
        currentPositionKey: Math.random(),
        currentPosition : {
          chrLeft : chrLeft,
          chrRight : chrRight,
          startLeft : start,
          stopLeft : stop,
          startRight : start,
          stopRight : stop
        },
        selectedProteinCodingsRowIdx: selectedProteinCodingsRowIdx,
        selectedTranscriptionFactorsRowIdx: selectedTranscriptionFactorsRowIdx,
        selectedRoiRowIdx: selectedRoiRowIdx,
      }, () => {
        // self.updateScale();
        // console.log(`calling [updateViewerURL] from [updateViewerURLWithLocation] | ${JSON.stringify(self.state.currentPosition)}`);
        self.setState({
          positionSummaryElementKey: Math.random(),
          scaleSummaryKey: Math.random(),
        }, () => {
          self.updateViewerURL(self.state.hgViewParams.mode,
                               self.state.currentPosition.chrLeft,
                               self.state.currentPosition.chrRight,
                               self.state.currentPosition.startLeft,
                               self.state.currentPosition.stopRight,
                               true,
                               "updateViewerURLWithLocation",);
          // setTimeout(()=>{
          //   self.updateScale();
          // }, 500);
          self.updateScale(true, "updateViewerURLWithLocation");
        })

        let boundsLeft = 20;
        let boundsRight = Constants.assemblyBounds[self.state.hgViewParams.genome].chrY.ub - boundsLeft;
        if (((chrLeft === "chr1") && (start < boundsLeft)) && ((chrRight === "chrY") && (stop > boundsRight))) {
          // console.log("handleZoomPastExtent() called");
          self.handleZoomPastExtent();
        } 
        // console.log("updateViewerURLWithLocation() finished");
      });
    }

    if (chromInfoCacheExists) {
      updateViewerURLWithLocationForChromInfo(this.chromInfoCache[genome], this);
    }
    else {
      let chromSizesURL = this.getChromSizesURL(genome);
      ChromosomeInfo(chromSizesURL)
        .then((chromInfo) => {
          this.chromInfoCache[genome] = Object.assign({}, chromInfo);
          updateViewerURLWithLocationForChromInfo(chromInfo, this);
        })
        .catch((err) => {
          throw new Error(`Error - [updateViewerURLWithLocation] could not retrieve chromosome information - ${JSON.stringify(err)}`);
        });
    }
  }
  
  log10 = (val) => {
    return Math.log(val) / Math.LN10;
  }
  
  updateScale = (keepSuggestionInterval, cf) => {
    // console.log(`[updateScale] <- ${cf} | ${keepSuggestionInterval} | ${this.state.selectedSuggestionRowIdxOnLoad} | ${JSON.stringify(this.state.currentPosition)}`);
    const scale = Helpers.calculateScale(
      this.state.currentPosition.chrLeft, 
      this.state.currentPosition.chrRight, 
      this.state.currentPosition.startLeft, 
      this.state.currentPosition.stopLeft, 
      this,
      true,
    );
    this.setState({
      chromsAreIdentical: scale.chromsAreIdentical,
      currentViewScaleAsString: scale.scaleAsStr,
      previousViewScale: this.state.currentViewScale,
      currentViewScale: scale.diff,
    }, () => {
      this.setState({
        recommenderV3SearchIsEnabled: this.recommenderV3SearchCanBeEnabled(),
      }, () => {
        this.simSearchQuery(this.state.currentPosition.chrLeft, this.state.currentPosition.startLeft, this.state.currentPosition.stopLeft);
      });
      // console.log(`updateScale | ${JSON.stringify(this.state.currentPosition)} ${scale.scaleAsStr}`);
      if (!this.isProductionSite) {
        if ( ((this.state.previousViewScale < this.state.hgViewParams.dtt) && (this.state.currentViewScale >= this.state.hgViewParams.dtt)) || ((this.state.previousViewScale >= this.state.hgViewParams.dtt) && (this.state.currentViewScale < this.state.hgViewParams.dtt)) ) {
          // console.warn(`triggering update...`);
          setTimeout(() => {
            this.updateViewportDimensions();
          }, 0);              
        }
      }
    });    
  }
  
  updateHgViewWithPosition = () => {
    //console.log("updateHgViewWithPosition()");
    let obj = Helpers.getJsonFromUrl();
    const chr = obj.chr || Constants.defaultApplicationChr;
    const txStart = obj.start || Constants.defaultApplicationStart;
    const txEnd = obj.stop || Constants.defaultApplicationStop;
    this.hgViewUpdatePosition(this.state.hgViewParams.build, chr, txStart, txEnd, chr, txStart, txEnd);
    setTimeout(() => { this.updateViewportDimensions(); }, 500);
  }
  
  updateViewportDimensions = () => {
    // console.log("updateViewportDimensions()");
/*
    let windowInnerHeight = window.innerHeight + "px";
    let windowInnerWidth = window.innerWidth + "px";
*/
    let windowInnerHeight = document.documentElement.clientHeight + "px";
    let windowInnerWidth = document.documentElement.clientWidth + "px";
    
    let isMobile = false;
    if ((parseInt(windowInnerHeight) < parseInt(Constants.mobileThresholds.maxHeight)) || (parseInt(windowInnerWidth) < parseInt(Constants.mobileThresholds.maxWidth))) {
      isMobile = true;
    }
    //console.log("isMobile?", isMobile);
    let isPortrait = (parseInt(windowInnerHeight) > parseInt(windowInnerWidth));
    //console.log("isPortrait?", isPortrait);
    
    //console.log("isMobile", isMobile);
    //console.log("isPortrait", isPortrait);
    
    if (!isMobile) {
      this.fadeInParameterSummary();
    }
    
    let epilogosViewerHeaderNavbarHeight = parseInt(document.getElementById("epilogos-viewer-container-navbar").innerHeight) + "px";
    let epilogosViewerDrawerHeight = parseInt(parseInt(windowInnerHeight) - parseInt("55px") - 70) + "px";
    let navbarRighthalfDiv = document.getElementsByClassName("navbar-righthalf")[0];
    let navbarRighthalfDivStyle = navbarRighthalfDiv.currentStyle || window.getComputedStyle(navbarRighthalfDiv);
    let navbarRighthalfDivWidth = parseInt(navbarRighthalfDiv.clientWidth);
    let navbarRighthalfDivMarginLeft = parseInt(navbarRighthalfDivStyle.marginLeft);
    let epilogosViewerHeaderNavbarRighthalfWidth = parseInt(navbarRighthalfDivWidth + navbarRighthalfDivMarginLeft + 15) + "px";
    let epilogosViewerHeaderNavbarLefthalfWidth = Constants.defaultMinimumDrawerWidth;
    
    if (document.getElementById("navigation-summary-parameters")) {
      epilogosViewerHeaderNavbarLefthalfWidth = parseInt(parseInt(windowInnerWidth) - parseInt(epilogosViewerHeaderNavbarRighthalfWidth) - parseInt(document.getElementById("navigation-summary-parameters").offsetWidth) - parseInt(document.getElementById("navigation-mode-toggle").offsetWidth)) + "px";
    }
    else {
      epilogosViewerHeaderNavbarLefthalfWidth = parseInt(parseInt(windowInnerWidth) - parseInt(epilogosViewerHeaderNavbarRighthalfWidth) - parseInt(document.getElementById("navigation-mode-toggle").offsetWidth)) + "px";
    }
    
    if (isMobile && isPortrait) {
      epilogosViewerHeaderNavbarLefthalfWidth = parseInt(windowInnerWidth) - 20 - parseInt(document.getElementById("navigation-mode-toggle").offsetWidth) + "px";
    }
    else if (isMobile && (isPortrait === false)) {
      epilogosViewerHeaderNavbarLefthalfWidth = parseInt(parseInt(windowInnerWidth)/2) - 20 - parseInt(document.getElementById("navigation-mode-toggle").offsetWidth) + "px";
    }
    
    let epilogosContentHeight = 0;
    
    // customize track heights -- requires preknowledge of track order, which will differ between viewer and portal
    if (!this.state.mainHgViewconf.views) return;
    let deepCopyHgViewconf = JSON.parse(JSON.stringify(this.state.mainHgViewconf));
    if (!deepCopyHgViewconf.views) return;

    let itt = this.state.hgViewParams.itt;
    let gatt = this.state.hgViewParams.gatt;
    let mode = this.state.hgViewParams.mode;
    
    let newHgViewTrackChromosomeHeight = (isMobile && (isPortrait === false)) ? 0 : parseInt(this.state.hgViewParams.hgViewTrackChromosomeHeight);
    let newHgViewTrackGeneAnnotationsHeight = parseInt(this.state.hgViewParams.hgViewTrackGeneAnnotationsHeight);
    let newHgViewTrackIndexAnnotationsHeight = (this.state.isMobile && (this.state.isPortrait === false)) 
        ? 
          0 
        : 
          ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite))
          ?
            Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight
          :
            Constants.viewerHgViewParameters.hgViewTrackDHSComponentsHeight;
    // let newHgViewTrackNumSamplesHeight = ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? Constants.viewerHgViewParameters.hgViewTrackSamplesWithDHSHeight : parseFloat(0.001);
    let newHgViewTrackNumSamplesHeight = Constants.viewerHgViewParameters.hgViewTrackSamplesWithDHSHeight;
    // let newHgViewTrackIndexDHSHeight = ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight : 0.001;
    let newHgViewTrackIndexDHSHeight = Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight;
    let newHgViewTrackDHSComponentsHeight = ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? 0.001 : Constants.viewerHgViewParameters.hgViewTrackDHSComponentsHeight;

    if (mode === "pc") {
      let pcSamplesToBeRendered = Constants.allComponent.all.samples;
      let pcAvailableWindowInnerHeight = parseInt(windowInnerHeight) - newHgViewTrackChromosomeHeight - newHgViewTrackIndexAnnotationsHeight - newHgViewTrackNumSamplesHeight - newHgViewTrackGeneAnnotationsHeight - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
      let pcHeightPerSample = parseFloat(parseInt(pcAvailableWindowInnerHeight) / pcSamplesToBeRendered);
      let pcComponentsToBeRendered = Object.keys(Constants.components).length - 1;
      let pcHeightPerTrack = pcSamplesToBeRendered / pcComponentsToBeRendered * pcHeightPerSample;
      Object.keys(Constants.components).forEach((c, ci) => {
        if (ci >= 15) return;
        deepCopyHgViewconf.views[0].tracks.top[ci].height = pcHeightPerTrack;
        epilogosContentHeight += pcHeightPerTrack;
      });
    }

    else if (mode === "ac") {
      if (itt === "ht") {
        newHgViewTrackIndexDHSHeight = parseInt(this.state.indexDHSTrackHeight);
        deepCopyHgViewconf.views[0].tracks.top[2].height = newHgViewTrackIndexDHSHeight;
      }
      if (gatt === "ht") {
        newHgViewTrackGeneAnnotationsHeight = parseInt(this.state.geneAnnotationTrackHeight);
        deepCopyHgViewconf.views[0].tracks.top[3].height = newHgViewTrackGeneAnnotationsHeight;
      }
      let acSamplesToBeRendered = Constants.allComponent.all.samples;
      let acAvailableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
      let acHeightPerSample = parseFloat(parseInt(acAvailableWindowInnerHeight) / acSamplesToBeRendered);
      //
      deepCopyHgViewconf.views[0].tracks.top[0].height = parseInt(acSamplesToBeRendered * acHeightPerSample);
      epilogosContentHeight += parseInt(acSamplesToBeRendered * acHeightPerSample);
      //
      let acComponentsToBeRendered = 1; // gel + chromosome tracks
      if (isNaN(deepCopyHgViewconf.views[0].tracks.top[0].height)) return;
    }
    
    else if (mode === "pw") {
      let pwSamplesToBeRendered = Constants.allComponent.all.samples;
      newHgViewTrackNumSamplesHeight = ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? newHgViewTrackNumSamplesHeight : 0;
      let pwAvailableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackNumSamplesHeight) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackDHSComponentsHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
      let pwHeightPerSample = parseFloat(parseInt(pwAvailableWindowInnerHeight) / pwSamplesToBeRendered);
      let pwComponentsToBeRendered = Object.keys(Constants.components).length - 1;
      let pwHeightPerTrack = pwSamplesToBeRendered / pwComponentsToBeRendered * pwHeightPerSample;
      Object.keys(Constants.components).forEach((c, ci) => {
        if (ci >= 15) return;
        deepCopyHgViewconf.views[0].tracks.top[ci].height = pwHeightPerTrack;
        epilogosContentHeight += pwHeightPerTrack;
      });
      //
      // update remaining track heights and properties
      //
      // numSamples
      //console.warn(`updateViewportDimensions (${this.state.currentViewScale}) > numSamples > ${(pwComponentsToBeRendered + 1)} -> ${newHgViewTrackNumSamplesHeight}`)
      deepCopyHgViewconf.views[0].tracks.top[pwComponentsToBeRendered + 1].height = ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? Constants.viewerHgViewParameters.hgViewTrackSamplesWithDHSHeight : 0.001;
      deepCopyHgViewconf.views[0].tracks.top[pwComponentsToBeRendered + 1].options.axisPositionHorizontal = ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? Constants.viewerHgViewParameters.hgViewTrackSamplesWithDHSAxisPositionHorizontal : null;
      // BED12
      //console.warn(`updateViewportDimensions (${this.state.currentViewScale}) > BED12 > ${(pwComponentsToBeRendered + 2)} -> ${newHgViewTrackIndexDHSHeight}`)
      deepCopyHgViewconf.views[0].tracks.top[pwComponentsToBeRendered + 2].height = ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight : 0.001;
      deepCopyHgViewconf.views[0].tracks.top[pwComponentsToBeRendered + 2].options.isVisible = ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? true : false;
      // all-DHS
      //console.warn(`updateViewportDimensions (${this.state.currentViewScale}) > all-DHS > ${(pwComponentsToBeRendered + 3)} -> ${newHgViewTrackDHSComponentsHeight}`)
      deepCopyHgViewconf.views[0].tracks.top[pwComponentsToBeRendered + 3].height = ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? 0.001 : Constants.viewerHgViewParameters.hgViewTrackDHSComponentsHeight;
    }
    
    else if (mode === "co") {
      //let coNewHgViewTrackDHSComponentsHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - 2*parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) - Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight - 20;
      let coNewHgViewTrackDHSComponentsHeight = parseInt(windowInnerHeight) - 2*parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) - Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight - 20;
      //console.log("coNewHgViewTrackDHSComponentsHeight", coNewHgViewTrackDHSComponentsHeight);
      //deepCopyHgViewconf.views[0].tracks.top[1].height = coNewHgViewTrackDHSComponentsHeight;
      deepCopyHgViewconf.views[0].tracks.top[0].height = coNewHgViewTrackDHSComponentsHeight / 2;
      deepCopyHgViewconf.views[0].tracks.top[2].height = coNewHgViewTrackDHSComponentsHeight / 2;
      epilogosContentHeight += coNewHgViewTrackDHSComponentsHeight;
    }

    else if (mode === "cf") {
      //let coNewHgViewTrackDHSComponentsHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - 2*parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) - Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight - 20;
      //let cfNewHgViewTrackDHSComponentsHeight = parseInt(windowInnerHeight) - 2*parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) - Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight - 20;

      let cfNewHgViewTrackDHSComponentsHeight = parseInt(windowInnerHeight) - 2*parseInt(newHgViewTrackChromosomeHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) - Constants.viewerHgViewParameters.hgViewTrackIndexDHSHiglassTranscriptsHeight - Constants.viewerHgViewParameters.hgViewTrackGeneAnnotationsHiglassTranscriptsHeight - 20;

      //console.log("coNewHgViewTrackDHSComponentsHeight", coNewHgViewTrackDHSComponentsHeight);
      //deepCopyHgViewconf.views[0].tracks.top[1].height = coNewHgViewTrackDHSComponentsHeight;
      deepCopyHgViewconf.views[0].tracks.top[0].height = cfNewHgViewTrackDHSComponentsHeight / 2;
      deepCopyHgViewconf.views[0].tracks.top[2].height = cfNewHgViewTrackDHSComponentsHeight / 2;
      epilogosContentHeight += cfNewHgViewTrackDHSComponentsHeight;
    }

    else if (mode === "pm") {
      if (itt === "ht") {
        newHgViewTrackIndexDHSHeight = this.state.indexDHSTrackHeight;
      }
      let pmAvailableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackNumSamplesHeight) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackDHSComponentsHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
      deepCopyHgViewconf.views[0].tracks.top[0].height = parseInt(pmAvailableWindowInnerHeight);
      // deepCopyHgViewconf.views[0].tracks.top[3].height = parseInt(this.state.indexDHSTrackHeight);
      if (itt === "ht") {
        deepCopyHgViewconf.views[0].tracks.top[3].height = parseInt(this.state.indexDHSTrackHeight);
      }
      deepCopyHgViewconf.views[0].tracks.top[5].height = parseInt(this.state.geneAnnotationTrackHeight);
    }

    else if (mode === "pd") {
      if (itt === "ht") {
        newHgViewTrackIndexDHSHeight = parseInt(this.state.indexDHSTrackHeight);
        deepCopyHgViewconf.views[0].tracks.top[2].height = newHgViewTrackIndexDHSHeight;
      }
      if (gatt === "ht") {
        newHgViewTrackGeneAnnotationsHeight = parseInt(this.state.geneAnnotationTrackHeight);
        deepCopyHgViewconf.views[0].tracks.top[3].height = newHgViewTrackGeneAnnotationsHeight;
      }
      let pdAvailableWindowInnerHeight = parseInt(windowInnerHeight) 
        - parseInt(newHgViewTrackChromosomeHeight) 
        - parseInt(newHgViewTrackIndexDHSHeight) 
        - parseInt(newHgViewTrackGeneAnnotationsHeight) 
        - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight)
        - parseInt(Constants.applicationViewerHgViewPaddingTop) 
        - parseInt(Constants.applicationViewerHgViewPaddingBottom) + "px";

      deepCopyHgViewconf.views[0].tracks.top[0].height = parseInt(pdAvailableWindowInnerHeight);
      //console.log(`${deepCopyHgViewconf.views[0].tracks.top[0].height} | ${deepCopyHgViewconf.views[0].tracks.top[1].height} | ${deepCopyHgViewconf.views[0].tracks.top[2].height} | ${deepCopyHgViewconf.views[0].tracks.top[3].height} | ${pdAvailableWindowInnerHeight}`);
      if (isNaN(deepCopyHgViewconf.views[0].tracks.top[0].height)) return;
    }
    
    else if (mode === "pds") {
      if (itt === "ht") {
        newHgViewTrackIndexDHSHeight = parseInt(this.state.indexDHSTrackHeight);
        deepCopyHgViewconf.views[0].tracks.top[2].height = newHgViewTrackIndexDHSHeight;
      }
      if (gatt === "ht") {
        newHgViewTrackGeneAnnotationsHeight = parseInt(this.state.geneAnnotationTrackHeight);
        deepCopyHgViewconf.views[0].tracks.top[3].height = newHgViewTrackGeneAnnotationsHeight;
      }
      let pdAvailableWindowInnerHeight = parseInt(windowInnerHeight) 
        - parseInt(newHgViewTrackChromosomeHeight) 
        - parseInt(newHgViewTrackIndexDHSHeight) 
        - parseInt(newHgViewTrackGeneAnnotationsHeight) 
        - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight)
        - parseInt(Constants.applicationViewerHgViewPaddingTop) 
        - parseInt(Constants.applicationViewerHgViewPaddingBottom) + "px";

      deepCopyHgViewconf.views[0].tracks.top[0].height = parseInt(pdAvailableWindowInnerHeight);
      //console.log(`${deepCopyHgViewconf.views[0].tracks.top[0].height} | ${deepCopyHgViewconf.views[0].tracks.top[1].height} | ${deepCopyHgViewconf.views[0].tracks.top[2].height} | ${deepCopyHgViewconf.views[0].tracks.top[3].height} | ${pdAvailableWindowInnerHeight}`);
      if (isNaN(deepCopyHgViewconf.views[0].tracks.top[0].height)) return;
    }

    // get child view heights
    const childViews = deepCopyHgViewconf.views[0].tracks.top;
    let childViewHeightTotal = 0;
    childViews.forEach((cv) => { childViewHeightTotal += cv.height });
    childViewHeightTotal += 10;
    let childViewHeightTotalPx = childViewHeightTotal + "px";
    
    // if epilogosViewerHeaderNavbarLefthalfWidth is smaller than needed, adjust to minimum
    epilogosViewerHeaderNavbarLefthalfWidth = (epilogosViewerHeaderNavbarLefthalfWidth < Constants.defaultMinimumDrawerWidth) ? Constants.defaultMinimumDrawerWidth : epilogosViewerHeaderNavbarLefthalfWidth;
    
    // if ROI table width is wider, use it, instead
    let roiTableWidth = 0;
    let isDrawerWidthWider = false;
    if (document.getElementById("drawer-content-roi-table")) {
      roiTableWidth = parseInt(document.getElementById("drawer-content-roi-table").offsetWidth);
      // console.log("roiTableWidth", roiTableWidth);
      if (roiTableWidth > parseInt(epilogosViewerHeaderNavbarLefthalfWidth)) {
        epilogosViewerHeaderNavbarLefthalfWidth = ((roiTableWidth + 50) > Constants.defaultMaximumDrawerWidth ? Constants.defaultMaximumDrawerWidth : (roiTableWidth + 50)) + "px";
      }
      if (parseInt(epilogosViewerHeaderNavbarLefthalfWidth) > Constants.defaultMaximumDrawerWidth) {
        epilogosViewerHeaderNavbarLefthalfWidth = `${Constants.defaultMaximumDrawerWidth}px`;
      }
    }
    
    // console.log("epilogosViewerHeaderNavbarLefthalfWidth", epilogosViewerHeaderNavbarLefthalfWidth);
    
    // update state
    this.setState({
      height: windowInnerHeight,
      width: windowInnerWidth,
      hgViewHeight: childViewHeightTotalPx,
      mainHgViewconf: deepCopyHgViewconf,
      epilogosContentHeight: epilogosContentHeight,
      drawerWidth: epilogosViewerHeaderNavbarLefthalfWidth,
      drawerHeight: epilogosViewerDrawerHeight,
      drawerContentKey: this.state.drawerContentKey + (isDrawerWidthWider ? 1 : 0),
      downloadIsVisible: false,
      isMobile: isMobile,
      isPortrait: isPortrait
    }, () => { 
      //console.log("W x H", this.state.width, this.state.height);
      //console.log("mainHgViewconf", JSON.stringify(this.state.mainHgViewconf, null, 2));
      //console.log("Drawer height", this.state.drawerHeight);
      //console.log("navbarLefthalfWidth (drawer width)", this.state.navbarLefthalfWidth);
      this.setState({
        currentPositionKey: this.state.currentPositionKey + 1,
      });
    });
  }
  
  hgViewUpdatePosition = (genome, chrLeft, startLeft, stopLeft, chrRight, startRight, stopRight) => {
    // console.log("[hgViewUpdatePosition]", genome, chrLeft, startLeft, stopLeft, chrRight, startRight, stopRight);
    startLeft = parseInt(startLeft);
    stopLeft = parseInt(stopLeft);
    startRight = parseInt(startRight);
    stopRight = parseInt(stopRight);
    if ((typeof startLeft === "undefined") || (typeof stopLeft === "undefined") || (typeof startRight === "undefined") || (typeof stopRight === "undefined")) {
      return;
    }

    const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, genome);
    //let chromInfoCacheExists = this.chromInfoCache.hasOwnProperty(genome);

    function updateViewerStateForChromInfo(chromInfo, self) {
      if (self.state.hgViewParams.paddingMidpoint === 0) {
        // console.log(`self.state.mainHgViewconf ${JSON.stringify(self.state.mainHgViewconf)}`);
        self.hgView.zoomTo(
          self.state.mainHgViewconf.views[0].uid,
          chromInfo.chrToAbs([chrLeft, startLeft]),
          chromInfo.chrToAbs([chrLeft, stopLeft]),
          chromInfo.chrToAbs([chrRight, startRight]),
          chromInfo.chrToAbs([chrRight, stopRight]),
          self.state.hgViewParams.hgViewAnimationTime
        );
      }
      else {
        let midpointLeft = parseInt(startLeft) + parseInt((parseInt(stopLeft) - parseInt(startLeft))/2);
        let midpointRight = parseInt(startRight) + parseInt((parseInt(stopRight) - parseInt(startRight))/2);
        
        // adjust position
        startLeft = parseInt(midpointLeft - self.state.hgViewParams.paddingMidpoint);
        stopLeft = parseInt(midpointLeft + self.state.hgViewParams.paddingMidpoint);
        startRight = parseInt(midpointRight - self.state.hgViewParams.paddingMidpoint);
        stopRight = parseInt(midpointRight + self.state.hgViewParams.paddingMidpoint);
        
        self.hgView.zoomTo(
          self.state.mainHgViewconf.views[0].uid,
          chromInfo.chrToAbs([chrLeft, startLeft]),
          chromInfo.chrToAbs([chrLeft, stopLeft]),
          chromInfo.chrToAbs([chrRight, startRight]),
          chromInfo.chrToAbs([chrRight, stopRight]),
          self.state.hgViewParams.hgViewAnimationTime
        );
      }
      setTimeout(() => {
        self.setState({
          positionSummaryElementKey: Math.random(),
          scaleSummaryKey: Math.random(),
          currentPositionKey: Math.random(),
          currentPosition : {
            chrLeft : chrLeft,
            chrRight : chrRight,
            startLeft : startLeft,
            stopLeft : stopLeft,
            startRight : startRight,
            stopRight : stopRight
          }
        }, () => {
          // console.log("calling [updateViewerURL] from [hgViewUpdatePosition]", this.state.hgViewParams.mode, this.state.currentPosition.chrLeft, this.state.currentPosition.chrRight, this.state.currentPosition.startLeft, this.state.currentPosition.stopRight, this.state.hgViewParams.numSamples);
          self.updateViewerURL(self.state.hgViewParams.mode,
                               self.state.currentPosition.chrLeft,
                               self.state.currentPosition.chrRight,
                               self.state.currentPosition.startLeft,
                               self.state.currentPosition.stopRight,
                               true,
                               "hgViewUpdatePosition");
        })
      }, Constants.defaultHgViewRegionPositionRefreshTimer);
    }

    if (chromInfoCacheExists) {
      updateViewerStateForChromInfo(this.chromInfoCache[genome], this);
    }
    else {
      let chromSizesURL = this.getChromSizesURL(genome);
      ChromosomeInfo(chromSizesURL)
        .then((chromInfo) => {
          this.chromInfoCache[genome] = Object.assign({}, chromInfo);
          updateViewerStateForChromInfo(chromInfo, this);
        })
        .catch((err) => {
          console.log(`chromSizesURL ${chromSizesURL}`);
          throw new Error(`Error - [updateViewerURLWithLocation] failed to fetch chromosome information - ${JSON.stringify(err)}`);
        });
    }
  }

  updateViewerURLForCurrentState = (cb, keepSuggestionInterval) => {
    this.updateViewerURL(this.state.hgViewParams.mode,
                         this.state.currentPosition.chrLeft,
                         this.state.currentPosition.chrRight,
                         this.state.currentPosition.startLeft,
                         this.state.currentPosition.stopRight,
                         keepSuggestionInterval,
                         "updateViewerURLForCurrentState",
                        );
    if (cb) cb();
  }
  
  updateSimSearchHitSelection = (newIdx) => {
    console.log(`updateSimSearchHitSelection | ${newIdx}`);
    this.setState({
      selectedSimSearchRowIdx: newIdx
    }, () => {
      console.log(`selectedSimSearchRowIdx | ${this.state.selectedSimSearchRowIdx}`);
      this.updateViewerURLForCurrentState(null, true);
    });
  }

  hgViewUpdateSimSearchPosition = (params, rowIndex) => {
    
    console.log(`[hgViewUpdateSimSearchPosition]`);

    const viewRef = this.mainHgView;
    const viewconfRef = this.state.mainHgViewconf;
    const animationTime = params.hgViewAnimationTime;

    const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, params.genome);

    function hgViewUpdateSimSearchPositionForChromInfo(chromInfo, self) {
      const region = `${self.state.selectedSimSearchRegionChrLeft}:${self.state.selectedSimSearchRegionStart}-${self.state.selectedSimSearchRegionStop}`;
      console.log(`hgViewUpdateSimSearchPositionForChromInfo ${JSON.stringify(region)}`);
      // const regionState = self.state.suggestionTableData[(rowIndex - 1)].state.numerical;
      // const regionStateLabel = Constants.stateColorPalettes[self.state.hgViewParams.genome][self.state.hgViewParams.model][regionState][0];
      // const regionStateColor = Constants.stateColorPalettes[self.state.hgViewParams.genome][self.state.hgViewParams.model][regionState][1];
      const unpaddedChromosome = self.state.selectedSimSearchRegionChrLeft;
      const unpaddedStart = self.state.selectedSimSearchRegionStart;
      const unpaddedStop = self.state.selectedSimSearchRegionStop;
      const unpaddedBasesDiff = parseFloat(unpaddedStop - unpaddedStart);

      const unpaddedUpstreamPadding = parseInt(unpaddedBasesDiff / 2); // Constants.defaultHgViewRegionUpstreamPadding;
      const unpaddedDownstreamPadding = parseInt(unpaddedBasesDiff / 2); // Constants.defaultHgViewRegionDownstreamPadding;

      const windowInnerWidth = parseFloat(document.documentElement.clientWidth);
      
      const drawerCoverage = parseFloat(320 / windowInnerWidth); 
      const basesCoveredBySimSearchTable = parseInt((drawerCoverage * (unpaddedUpstreamPadding + unpaddedDownstreamPadding + unpaddedBasesDiff)) / (1 - drawerCoverage)); 

      const upstreamPadding = unpaddedUpstreamPadding + basesCoveredBySimSearchTable;
      const downstreamPadding = unpaddedDownstreamPadding;
      const upstreamPaddingObj = { 
        "roi" : (self.state.mainRegionIndicatorData && self.state.mainRegionIndicatorData.upstreamPadding && self.state.mainRegionIndicatorData.upstreamPadding.roi) 
          ? self.state.mainRegionIndicatorData.upstreamPadding.roi 
          : Constants.defaultApplicationRoiSetPaddingAbsolute, 
        "exemplars" : upstreamPadding,
        "simsearch" : upstreamPadding,
      };
      const downstreamPaddingObj = { 
        "roi" : (self.state.mainRegionIndicatorData && self.state.mainRegionIndicatorData.downstreamPadding && self.state.mainRegionIndicatorData.downstreamPadding.roi) 
          ? self.state.mainRegionIndicatorData.downstreamPadding.roi 
          : Constants.defaultApplicationRoiSetPaddingAbsolute, 
        "exemplars" : downstreamPadding,
        "simsearch" : downstreamPadding,
      };

      const paddedStart = self.state.selectedSimSearchRegionStart - upstreamPadding;
      const paddedStop = self.state.selectedSimSearchRegionStop + downstreamPadding;
      const regionIndicatorData = {
        chromosome: unpaddedChromosome,
        start: unpaddedStart,
        stop: unpaddedStop,
        midpoint: parseInt(unpaddedStart + ((unpaddedStop - unpaddedStart) / 2)),
        upstreamPadding: upstreamPaddingObj,
        downstreamPadding: downstreamPaddingObj,
        regionLabel: region,
        // regionState: { 
        //   numerical: regionState, 
        //   label: regionStateLabel, 
        //   color: regionStateColor 
        // },
        msg: null
      };
      const newCurrentPositionKey = (self.state.simSearchTableIsVisible) ? self.state.currentPositionKey + 1 : self.state.currentPositionKey;
      const newCurrentPosition = (self.state.simSearchTableIsVisible) ? {
        chrLeft : unpaddedChromosome,
        chrRight : unpaddedChromosome,
        startLeft : paddedStart,
        stopLeft : paddedStop,
        startRight : paddedStart,
        stopRight : paddedStop
      } : self.state.currentPosition;
      // console.log(`regionIndicatorData ${JSON.stringify(regionIndicatorData)}`);
      self.setState({
        mainRegionIndicatorData: regionIndicatorData,
        currentPositionKey: newCurrentPositionKey,
        currentPosition : newCurrentPosition,
        selectedSimSearchRegionBeingUpdated: false,
      }, () => {
        // console.log(`zoomTo: ${chromInfo.chrToAbs([unpaddedChromosome, paddedStart])} | ${chromInfo.chrToAbs([unpaddedChromosome, paddedStop])} | ${chromInfo.chrToAbs([unpaddedChromosome, paddedStart])} | ${chromInfo.chrToAbs([unpaddedChromosome, paddedStop])}`);
        if (self.state.simSearchTableIsVisible) {
          viewRef.zoomTo(
            viewconfRef.views[0].uid,
            chromInfo.chrToAbs([unpaddedChromosome, paddedStart]),
            chromInfo.chrToAbs([unpaddedChromosome, paddedStop]),
            chromInfo.chrToAbs([unpaddedChromosome, paddedStart]),
            chromInfo.chrToAbs([unpaddedChromosome, paddedStop]),
            animationTime
          );
        }
        // self.fadeOutIntervalDrop();
        // self.fadeInIntervalDrop(unpaddedChromosome, unpaddedChromosome, unpaddedStart, unpaddedStop, paddedStart, paddedStop);
        self.updateScale(true, "hgViewUpdateSimSearchPosition");
        self.updateViewerURLForCurrentState(null, true);
        self.fadeInSimSearchIntervalDrop(unpaddedChromosome, unpaddedChromosome, unpaddedStart, unpaddedStop, paddedStart, paddedStop);
      });
    }

    if (chromInfoCacheExists) {
      hgViewUpdateSimSearchPositionForChromInfo(this.chromInfoCache[params.genome], this);
    }
    else {
      const chromSizesURL = this.getChromSizesURL(params.genome);
      ChromosomeInfo(chromSizesURL)
        .then((chromInfo) => {
          this.chromInfoCache[params.genome] = Object.assign({}, chromInfo);
          hgViewUpdateSimSearchPositionForChromInfo(chromInfo, this);
        })
        .catch((err) => {
          throw new Error(`Warning - [hgViewUpdateSimSearchPosition] could not retrieve chromosome information - ${JSON.stringify(err)}`);
        });
    }
  }
  
  onClick = (event) => { 
    if (event.currentTarget.dataset.id) {
      event.preventDefault();
      let target = event.currentTarget.dataset.target || "_blank";
      window.open(event.currentTarget.dataset.id, target);
    }
  }
  
  handleDrawerStateChange = (state) => {     
    if (state.isOpen) {
      //let windowInnerHeight = window.innerHeight + "px";
      let windowInnerHeight = document.documentElement.clientHeight + "px";
      let epilogosViewerHeaderNavbarHeight = parseInt(document.getElementById("epilogos-viewer-container-navbar").clientHeight) + "px";
      let epilogosViewerDrawerHeight = parseInt(parseInt(windowInnerHeight) - parseInt("55px") - 70) + "px";
      this.setState({
        drawerSelection: Constants.defaultDrawerType,
        drawerHeight: epilogosViewerDrawerHeight
      }, () => {
        this.setState({ 
          drawerIsOpen: state.isOpen
        });
      })
    }
    else {
      this.setState({ 
        drawerIsOpen: state.isOpen
      });
    }
  }
  
  closeDrawer = (cb) => { 
    if (!this.state.drawerIsOpen) return;
    this.setState({ 
      drawerIsOpen: false 
    }, () => {
      if (cb) cb();
      this.autocompleteInputRef.applyFocus();
    }); 
  }
  
  toggleDrawer = (name) => {
    //let windowInnerWidth = window.innerWidth + "px";
    let windowInnerWidth = document.documentElement.clientWidth + "px";
    let navbarRighthalfDiv = document.getElementsByClassName("navbar-righthalf")[0];
    let navbarRighthalfDivStyle = navbarRighthalfDiv.currentStyle || window.getComputedStyle(navbarRighthalfDiv);
    let navbarRighthalfDivWidth = parseInt(navbarRighthalfDiv.clientWidth);
    let navbarRighthalfDivMarginLeft = parseInt(navbarRighthalfDivStyle.marginLeft);
    let epilogosViewerHeaderNavbarRighthalfWidth = parseInt(navbarRighthalfDivWidth + navbarRighthalfDivMarginLeft + 15) + "px";
    let epilogosViewerHeaderNavbarLefthalfWidth = 0;
    if (document.getElementById("navigation-summary-parameters")) {
      epilogosViewerHeaderNavbarLefthalfWidth = parseInt(parseInt(windowInnerWidth) - parseInt(epilogosViewerHeaderNavbarRighthalfWidth) - parseInt(document.getElementById("navigation-summary-parameters").offsetWidth) - parseInt(document.getElementById("navigation-mode-toggle").offsetWidth)) + "px";  
    }
    else {
      epilogosViewerHeaderNavbarLefthalfWidth = parseInt(parseInt(windowInnerWidth) - parseInt(epilogosViewerHeaderNavbarRighthalfWidth) - parseInt(document.getElementById("navigation-mode-toggle").offsetWidth)) + "px";  
    }
    
    if (this.state.isMobile && this.state.isPortrait) {
      epilogosViewerHeaderNavbarLefthalfWidth = parseInt(windowInnerWidth) - 20 - parseInt(document.getElementById("navigation-mode-toggle").offsetWidth) + "px";
    }
    else if (this.state.isMobile && !this.state.isPortrait) {
      epilogosViewerHeaderNavbarLefthalfWidth = parseInt(parseInt(windowInnerWidth)/2) - 20 - parseInt(document.getElementById("navigation-mode-toggle").offsetWidth) + "px";
    }
    
    // if ROI table width is wider, use it
    let roiTableWidth = 0;
    let isRoiTableWidthWidened = false;
    if (document.getElementById("drawer-content-roi-table")) {
      roiTableWidth = parseInt(document.getElementById("drawer-content-roi-table").offsetWidth);
      //console.log("roiTableWidth", roiTableWidth);
      if (roiTableWidth > parseInt(epilogosViewerHeaderNavbarLefthalfWidth)) {
        epilogosViewerHeaderNavbarLefthalfWidth = roiTableWidth + "px";
        isRoiTableWidthWidened = true;
      }
    }
    
    const drawerType = (name) ? Constants.drawerTypeByName[name] : Constants.defaultDrawerType;
    this.setState({
      drawerSelection: drawerType,
      drawerWidth: parseInt(epilogosViewerHeaderNavbarLefthalfWidth) + (isRoiTableWidthWidened ? 45 : 0) 
    }, () => {
      //let selection = name;
      //let title = (selection) ? Constants.drawerTitleByType[selection] : "";
      //console.log("toggleDrawer() - title", title);
      this.handleDrawerStateChange({
        isOpen:!this.state.drawerIsOpen
      });
    });
  }

  // eslint-disable-next-line no-unused-vars
  onClickGenomeSelect = (evt) => {
    if (this.state.downloadIsVisible) { this.setDownloadInvisible(); }
    this.closeDrawer();
    const newGenomeSelectIsEnabled = !this.state.genomeSelectIsEnabled;
    this.setGenomeSelect(newGenomeSelectIsEnabled);
  }

  disableGenomeSelect = () => { 
    this.setGenomeSelect(false);
  };
  
  setGenomeSelect = (v) => {
    if (this.genomeSelectButtonRef) this.setState({ genomeSelectIsEnabled: v });
  }

  onSwitchGenomeSelect = (newGenome) => {
    // if (Object.keys(Constants.genomes).includes(newGenome)) {
    //   const newHgViewParams = Helpers.adjustHgViewParamsForNewGenome(this.state.hgViewParams, newGenome);
    //   this.setState({
    //     drawerContentKey: this.state.drawerContentKey + 1,
    //     tempHgViewParams: newHgViewParams,
    //     genomeSelectIsEnabled: false,
    //   }, () => {
    //     this.triggerUpdate("update");
    //   });
    // }
  }

  // eslint-disable-next-line no-unused-vars
  onMouseoverGenomeSelect = (evt) => {
    if (this.state.downloadIsVisible) { this.setDownloadInvisible(); }
  }

  setDownloadInvisible = () => {
    this.setState({
      downloadIsVisible: !this.state.downloadIsVisible
    });
  }
  
  onChangeSearchInput = (value) => {
    // console.log("onChangeSearchInput", value);
    this.setState({
      searchInputValue: value
    });
  }
  
  onChangeSearchInputLocation = (location, applyPadding, userInput) => {
    // console.log("onChangeSearchInputLocation", location);
    let range = Helpers.getRangeFromString(location, applyPadding, false, this.state.hgViewParams.genome);
    // console.log("range", range);
    if (range) {
      this.setState({
        searchInputLocationBeingChanged: true
      }, () => {
        this.fadeOutVerticalDrop();
        this.fadeOutIntervalDrop();
        const applyPadding = false;
        this.openViewerAtChrRange(range, applyPadding);
        setTimeout(() => {
          this.setState({
            searchInputText: userInput,
            searchInputLocationBeingChanged: false
          }, () => {
            setTimeout(() => {
              this.setState({
                searchInputText: null,
              });
            }, 1000);
          });
        }, 1000);
      })
    }
  }
  
  onFocusSearchInput = (cb) => {
    // console.log(`onFocusSearchInput`);
    if (this.state.drawerIsOpen) {
      this.closeDrawer(() => {
        if (cb) cb();
        // setTimeout(() => {
        //   document.getElementById("autocomplete-input").focus();
        // }, 100);
      });
    }
  }
  
  jumpToRegion = (region, regionType, rowIndex, strand) => {
    // console.log(`jumpToRegion(${region}, ${regionType}, ${rowIndex}, ${strand})`);
    const pos = Helpers.getRangeFromString(region, false, ((regionType === Constants.applicationRegionTypes.roi) ? false : true), this.state.hgViewParams.genome);
    // console.log(`pos ${pos}`);
    let regionLabel = null;
    let exemplarTableData = null;
    if (regionType === Constants.applicationRegionTypes.proteinCodings) {
      exemplarTableData = this.state.proteinCodingsTableData;
    }
    else if (regionType === Constants.applicationRegionTypes.transcriptionFactors) {
      exemplarTableData = this.state.transcriptionFactorsTableData;
    }
    // console.log(`exemplarTableData ${JSON.stringify(exemplarTableData[0])}`);
    let regionSystem = (regionType === Constants.applicationRegionTypes.roi) ? null : exemplarTableData[(rowIndex - 1)].system;
    let regionSystemLabel = (regionType === Constants.applicationRegionTypes.roi) ? null : Constants.components[regionSystem].longName;
    let regionSystemColor = (regionType === Constants.applicationRegionTypes.roi) ? null : Constants.components[regionSystem].color;
    let regionIndicatorData = {
      chromosome: pos[0],
      start: parseInt(pos[1]),
      stop: parseInt(pos[2]),
      midpoint: parseInt(parseInt(pos[1]) + ((parseInt(pos[2]) - parseInt(pos[1])) / 2)),
      regionLabel: region,
      regionSystem: { 
        system: regionSystem, 
        label: regionSystemLabel, 
        color: regionSystemColor 
      },
      msg: null
    };
    switch (regionType) {
      case Constants.applicationRegionTypes.roi: {
        this.setState({
          selectedRoiRowIdx: rowIndex
        }, () => {
          this.updateViewerURLAtCurrentState();
        });
        switch (this.state.roiMode) {
          case Constants.applicationRoiModes.default: {
            regionLabel = `${String.fromCharCode(8676)} ${region} ${String.fromCharCode(8677)}`;
            break;
          }
          case Constants.applicationRoiModes.midpoint: {
            const start = parseInt(pos[1]);
            const stop = parseInt(pos[2]);
            const midpoint = parseInt(start + ((stop - start) / 2));
            const midpointLabel = `${pos[0]}:${midpoint}-${(midpoint + 1)}`;
            regionLabel = midpointLabel;
            regionIndicatorData.regionLabel = regionLabel;
            break;
          }
          default:
            throw new Error('Unknown ROI mode', this.state.roiMode);  
            //break;
        }
        break;
      }
      case Constants.applicationRegionTypes.proteinCodings:
      case Constants.applicationRegionTypes.transcriptionFactors: {
        regionLabel = region;
        break;
      }
      default:
        throw new Error('Unknown application region type', regionType);
        //break;
    }

    // this.setState({
    //   //verticalDropLabel: regionLabel,
    //   regionIndicatorData: regionIndicatorData,
    // });
    // if ((this.epilogosViewerContainerVerticalDrop.style) && (this.epilogosViewerContainerVerticalDrop.style.opacity !== 0)) { /* console.log("calling fadeOutVerticalDrop from jumpToRegion because it is visible"); */ this.fadeOutVerticalDrop() }
    // if ((this.epilogosViewerContainerIntervalDrop.style) && (this.epilogosViewerContainerIntervalDrop.style.opacity !== 0)) { /* console.log("calling fadeOutIntervalDrop from jumpToRegion because it is visible"); */ this.fadeOutIntervalDrop() }
    // this.openViewerAtChrPosition(pos,
    //                              Constants.defaultHgViewRegionUpstreamPadding,
    //                              Constants.defaultHgViewRegionDownstreamPadding,
    //                              regionType,
    //                              rowIndex,
    //                              strand);

    if ((this.epilogosViewerContainerVerticalDrop.style) && (this.epilogosViewerContainerVerticalDrop.style.opacity !== 0)) { /* console.log("calling fadeOutVerticalDrop from jumpToRegion because it is visible"); */ this.fadeOutVerticalDrop() }
    if ((this.epilogosViewerContainerIntervalDrop.style) && (this.epilogosViewerContainerIntervalDrop.style.opacity !== 0)) { /* console.log("calling fadeOutIntervalDrop from jumpToRegion because it is visible"); */ this.fadeOutIntervalDrop() }
    this.setState({
      regionIndicatorData: regionIndicatorData,
      searchInputText: null,
    }, () => {
      this.openViewerAtChrPosition(pos,
        Constants.defaultHgViewRegionUpstreamPadding,
        Constants.defaultHgViewRegionDownstreamPadding,
        regionType,
        rowIndex,
        strand);
    });
  }
  
  updateSortOrderOfRoiTableDataIndices = (field, order) => {
    //console.log("updateSortOrderOfRoiTableDataIndices", field, order);
    //console.log("(before) this.state.roiTableDataIdxBySort", this.state.roiTableDataIdxBySort);
    let resortData = Array.from(this.state.roiTableDataCopy);
    switch(field) {
      case 'idx':
        //console.log("resorting data table field [" + field + "] in order [" + order + "]");
        if (order === "asc") {
          resortData.sort((a, b) => (a.idx > b.idx) ? 1 : -1);
        }
        else if (order === "desc") {
          resortData.sort((a, b) => (b.idx > a.idx) ? 1 : -1);
        }
        break;
      case 'element':
        //console.log("resorting data table field [" + field + "] in order [" + order + "]");
        if (order === "asc") {
          resortData.sort((a, b) => b.element.paddedPosition.localeCompare(a.element.paddedPosition));
        }
        else if (order === "desc") {
          resortData.sort((a, b) => a.element.paddedPosition.localeCompare(b.element.paddedPosition));
        }
        break;
      case 'name':
        if (order === "asc") {
          resortData.sort((a, b) => a.name.localeCompare(b.name));
        }
        else if (order === "desc") {
          resortData.sort((a, b) => b.name.localeCompare(a.name));
        }
        break;
      case 'score':
        if (order === "asc") {
          resortData.sort((a, b) => (parseFloat(a.score) > parseFloat(b.score)) ? 1 : -1);
        }
        else if (order === "desc") {
          resortData.sort((a, b) => (parseFloat(b.score) > parseFloat(a.score)) ? 1 : -1);
        }
        break;
      case 'strand':
        if (order === "asc") {
          resortData.sort((a, b) => b.strand.localeCompare(a.strand));
        }
        else if (order === "desc") {
          resortData.sort((a, b) => a.strand.localeCompare(b.strand));
        }
        break;
      default:
        throw new Error('Unknown data table field', field);
    }
    let resortedIndices = resortData.map((e) => parseInt(e.idx));
    this.setState({
      roiTableDataIdxBySort: resortedIndices
    }, () => {
      // console.log("(after) this.state.roiTableDataIdxBySort", this.state.roiTableDataIdxBySort);
      // obtain ROI table row element from sort order, not from raw element id
      const roiTdIdxNeeded = this.state.roiTableDataIdxBySort.indexOf(this.state.selectedRoiRowIdx);
      const roiTableEl = document.getElementById('drawer-content-roi-table');
      const roiTbodyEl = roiTableEl.getElementsByTagName('tbody')[0];
      const roiTrEls = roiTbodyEl.getElementsByTagName('tr');
      const roiTrEl = roiTrEls[roiTdIdxNeeded];
      const roiTdEls = roiTrEl.cells;
      roiTdEls[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    })
  }
  
  updateSortOrderOfProteinCodingsTableDataIndices = (field, order) => {
    //console.log("updateSortOrderOfProteinCodingsTableDataIndices", field, order);
    //console.log("(before) this.state.proteinCodingsTableDataIdxBySort", this.state.proteinCodingsTableDataIdxBySort);
    let resortData = Array.from(this.state.proteinCodingsTableDataCopy);
    switch(field) {
      case 'idx':
        //console.log("resorting data table field [" + field + "] in order [" + order + "]");
        if (order === "asc") {
          resortData.sort((a, b) => (a.idx > b.idx) ? 1 : -1);
        }
        else if (order === "desc") {
          resortData.sort((a, b) => (b.idx > a.idx) ? 1 : -1);
        }
        break;
      case 'system':
        //console.log("resorting data table field [" + field + "] in order [" + order + "]");
        if (order === "asc") {
          resortData.sort((a, b) => b.system.localeCompare(a.system));
        }
        else if (order === "desc") {
          resortData.sort((a, b) => a.system.localeCompare(b.system));
        }
        break;
      case 'gene':
        //console.log("resorting data table field [" + field + "] in order [" + order + "]");
        if (order === "asc") {
          resortData.sort((a, b) => b.gene.localeCompare(a.gene));
        }
        else if (order === "desc") {
          resortData.sort((a, b) => a.gene.localeCompare(b.gene));
        }
        break;
      default:
        throw new Error('Unknown data table field', field);
    }
    let resortedIndices = resortData.map((e) => parseInt(e.idx));
    this.setState({
      proteinCodingsTableDataIdxBySort: resortedIndices
    }, () => {
      //console.log("(after) this.state.transcriptionFactorsTableDataIdxBySort", this.state.transcriptionFactorsTableDataIdxBySort);
    })
  }
  
  updateSortOrderOfTranscriptionFactorsTableDataIndices = (field, order) => {
    //console.log("updateSortOrderOfTranscriptionFactorsTableDataIndices", field, order);
    //console.log("(before) this.state.transcriptionFactorsTableDataIdxBySort", this.state.transcriptionFactorsTableDataIdxBySort);
    let resortData = Array.from(this.state.transcriptionFactorsTableDataCopy);
    switch(field) {
      case 'idx':
        //console.log("resorting data table field [" + field + "] in order [" + order + "]");
        if (order === "asc") {
          resortData.sort((a, b) => (a.idx > b.idx) ? 1 : -1);
        }
        else if (order === "desc") {
          resortData.sort((a, b) => (b.idx > a.idx) ? 1 : -1);
        }
        break;
      case 'system':
        //console.log("resorting data table field [" + field + "] in order [" + order + "]");
        if (order === "asc") {
          resortData.sort((a, b) => b.system.localeCompare(a.system));
        }
        else if (order === "desc") {
          resortData.sort((a, b) => a.system.localeCompare(b.system));
        }
        break;
      case 'gene':
        //console.log("resorting data table field [" + field + "] in order [" + order + "]");
        if (order === "asc") {
          resortData.sort((a, b) => b.gene.localeCompare(a.gene));
        }
        else if (order === "desc") {
          resortData.sort((a, b) => a.gene.localeCompare(b.gene));
        }
        break;
      default:
        throw new Error('Unknown data table field', field);
    }
    let resortedIndices = resortData.map((e) => parseInt(e.idx));
    this.setState({
      transcriptionFactorsTableDataIdxBySort: resortedIndices
    }, () => {
      //console.log("(after) this.state.transcriptionFactorsTableDataIdxBySort", this.state.transcriptionFactorsTableDataIdxBySort);
    })
  }
  
  changeViewParams = (isDirty, tempHgViewParams) => {
    //let hideOverlay = !isDirty; /* false = overlay; true = hide overlay */
    //console.log("tempHgViewParams", tempHgViewParams);
    this.setState({
      tempHgViewParams: {...tempHgViewParams}
    }, () => {
      if (isDirty) {
        this.triggerUpdate("update");
      }
    });
  }
  
  updateActiveTab = (newTab) => {
    this.setState({
      drawerActiveTabOnOpen: newTab
    });
  }
  
  viewerDataNotice = () => {
    let result = [];
    let header = <div key="viewer-data-notice-parameter-header" className="viewer-data-notice-parameter-header">data export</div>;
    result.push(header);
    let body = <div key="viewer-data-notice-parameter-body" className="viewer-data-notice-parameter-body">
      <div key="viewer-data-notice-parameter-body-paragraph-1" className="viewer-data-notice-parameter-body-paragraph">The <em>tabix</em> utility quickly retrieves elements from indexed datasets overlapping regions of interest. This utility may be used to query Index DHS data for your coordinates of interest:</div>
      <div key="viewer-data-notice-parameter-body-tabix-url" className="viewer-data-notice-parameter-body-tabix-url">
        <div key="viewer-data-notice-parameter-body-tabix-label" className="viewer-data-notice-parameter-body-tabix-url-label">
          $ {this.state.tabixDataDownloadCommand}
        </div>
        <div key="viewer-data-notice-parameter-body-tabix-url-clipboard" className="viewer-data-notice-parameter-body-tabix-url-clipboard">
          <CopyToClipboard text={this.state.tabixDataDownloadCommand} onCopy={(e) => { this.onClickDownloadDataCommand(e) }}>
            <Button className="box-button" title="Copy to clipboard"><FaClipboard /></Button>
          </CopyToClipboard>
        </div>
        
      </div>
      <div key="viewer-data-notice-parameter-body-paragraph-2" className="viewer-data-notice-parameter-body-paragraph viewer-data-notice-parameter-body-paragraph-warning">{(this.state.tabixDataDownloadCommandCopied) ? "Tabix command copied to clipboard!" : ""}</div>
      <div key="viewer-data-notice-parameter-body-paragraph-3" className="viewer-data-notice-parameter-body-paragraph viewer-data-notice-parameter-body-paragraph-note" style={{maxHeight:"100px", overflowY:"scroll"}}>
        <div style={{paddingBottom:"10px", fontWeight:"bolder"}}>Output format:</div>
        <Table size="sm">
          <thead>
            <tr>
              <th>Column</th>
              <th>Field</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td className="viewer-data-notice-parameter-body-table-row">Seqname</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td className="viewer-data-notice-parameter-body-table-row">Start</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td className="viewer-data-notice-parameter-body-table-row">End</td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td className="viewer-data-notice-parameter-body-table-row">Identifier</td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td className="viewer-data-notice-parameter-body-table-row">Mean signal</td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td className="viewer-data-notice-parameter-body-table-row">Summit</td>
            </tr>
            <tr>
              <th scope="row">7</th>
              <td className="viewer-data-notice-parameter-body-table-row">Component</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div key="viewer-data-notice-parameter-body-paragraph-4" className="viewer-data-notice-parameter-body-paragraph viewer-data-notice-parameter-body-paragraph-note">Note: The <em>tabix</em> application can be compiled from source available via <a href="https://github.com/samtools/htslib" target="_blank" rel="noopener noreferrer">GitHub</a> or installed via package managers like <em>apt-get</em> (Ubuntu), <em>yum</em> (CentOS/RHL), <a href="https://bioconda.github.io/" target="_blank" rel="noopener noreferrer">Bioconda</a> or <a href="https://brew.sh/" target="_blank" rel="noopener noreferrer">Homebrew</a> (OS X).</div>
      <div key="viewer-data-notice-parameter-body-paragraph-5" className="viewer-data-notice-parameter-body-paragraph viewer-data-notice-parameter-body-paragraph-controls">
        <Button title={"Close data export window"} size="sm" onClick={() => { this.fadeOutContainerOverlay(() => { this.setState({ tabixDataDownloadCommandVisible: false }); }); }}>Dismiss</Button>{"\u00a0\u00a0"}<CopyToClipboard text={this.state.tabixDataDownloadCommand} onCopy={(e) => { this.onClickDownloadDataCommand(e) }}><Button title={"Copy tabix command and close data export window"} size="sm" className="btn-epilogos" onClick={() => { this.fadeOutContainerOverlay(() => { this.setState({ tabixDataDownloadCommandVisible: false }); }); }}>Copy</Button></CopyToClipboard>
      </div>
    </div>;
    result.push(body);
    return result;
  }
  
  viewerUpdateNotice = () => {
    let result = [];
    let genome = this.state.tempHgViewParams.genome;
    let genomeText = Constants.genomes[genome];
    let group = this.state.tempHgViewParams.group;
    let groupText = Constants.groupsByGenome[genome][group].text;
    let model = this.state.tempHgViewParams.model;
    let modelText = Constants.models[model];
    let complexity = this.state.tempHgViewParams.complexity;
    let complexityText = Constants.complexities[complexity];
    result.push(<h6 key="viewer-update-notice-parameter-header" className="drawer-settings-parameter-header">Apply new viewer parameters</h6>);
    result.push(<div key="viewer-update-notice-parameter-body" className="drawer-settings-parameter-body"><span key="viewer-update-notice-parameter-body-genome" className="drawer-settings-parameter-item">{genomeText}</span> | <span key="viewer-update-notice-parameter-body-group" className="drawer-settings-parameter-item">{groupText}</span> | <span key="viewer-update-notice-parameter-body-model" className="drawer-settings-parameter-item">{modelText}</span> | <span key="viewer-update-notice-parameter-body-complexity" className="drawer-settings-parameter-item" dangerouslySetInnerHTML={{ __html: complexityText }} /></div>);
    result.push(<div key="viewer-update-notice-button-group" style={{display:'block'}}><Button key="viewer-update-notice-cancel-button" color="secondary" size="sm" ref={(component) => this.viewerUpdateNoticeCancelButton = component} onClick={() => this.triggerUpdate("cancel")}>Revert</Button> <Button key="viewer-update-notice-update-button" color="primary" size="sm" ref={(component) => this.viewerUpdateNoticeUpdateButton = component} onClick={() => this.triggerUpdate("update")}>Update</Button></div>)
    return <div className="drawer-settings-section-body-content">{result}</div>;
  }
  
  errorMessage = (err, errorMsg, errorURL) => {
    return <div className="viewer-overlay-notice"><div className="viewer-overlay-notice-header">{(err.response && err.response.status) || "500"} Error</div><div className="viewer-overlay-notice-body"><div>{errorMsg}</div><div>{(err.response && err.response.statusText)}: {errorURL}</div><div className="viewer-overlay-notice-body-controls"><Button title={"Dismiss"} color="primary" size="sm" onClick={() => { this.fadeOutOverlay() }}>Dismiss</Button></div></div></div>;
  }
  
  triggerUpdate = (updateMode) => {
    if (updateMode === "cancel") {
      this.closeDrawer();
      this.setState({
        showUpdateNotice: false,
        hideDrawerOverlay: true,
        drawerIsOpen: true,
        tempHgViewParams: {...this.state.hgViewParams},
        drawerContentKey: this.state.drawerContentKey + 1
      });
    }
    else if (updateMode === "update") {
      
      // console.log("triggering update...");
      
      //
      // get parameters from tempHgViewParams
      //
      let newGenome = this.state.tempHgViewParams.genome;
      let newMode = this.state.tempHgViewParams.mode;
      let newNumSamples = this.state.tempHgViewParams.numSamples;
      
      const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, newGenome);
      //let chromInfoCacheExists = this.chromInfoCache.hasOwnProperty(newGenome);

      const queryObj = Helpers.getJsonFromUrl();

      // console.log(`newMode ${newMode}`);

      //console.log("updating TF exemplars...");      
/*
      setTimeout(() => { 
        this.updateTranscriptionFactors(newGenome);
        this.updateProteinCodings(newGenome);
      }, 0);  
*/

      //
      // clear out indicator markers
      //
      if ((this.state.selectedProteinCodingsRowIdx === Constants.defaultApplicationSerIdx) && (this.state.selectedTranscriptionFactorsRowIdx === Constants.defaultApplicationSerIdx) && (this.state.selectedRoiRowIdx === Constants.defaultApplicationSerIdx)) {
        if (this.epilogosViewerContainerVerticalDrop && this.epilogosViewerContainerVerticalDrop.style) this.fadeOutVerticalDrop();
        if (this.epilogosViewerContainerIntervalDrop && this.epilogosViewerContainerIntervalDrop.style) this.fadeOutIntervalDrop();
      }
      
/*
      if (queryObj.roiURL) {
        setTimeout(() => {
          //console.log("queryObj.roiURL", queryObj.roiURL);
          this.updateRois(queryObj.roiURL);
        }, 0);
      }
*/
      
      //
      // return a Promise to request a UUID from a filename pattern
      //
      const uuidQueryPromise = function(fn, endpoint, self) {
        // console.log(`uuidQueryPromise: fn ${fn}`);
        let hgUUIDQueryURL = `${endpoint}/tilesets?ac=${fn}`;
        // console.log(`hgUUIDQueryURL: ${hgUUIDQueryURL}`);
        return axios.get(hgUUIDQueryURL).then((res) => {
          if (res.data && res.data.results && res.data.results[0]) {
            return {'fn': fn, 'uuid': res.data.results[0].uuid};
          }
          else {
            let err = {};
            err.response = {};
            err.response.status = "404";
            err.response.statusText = "No tileset data found for specified UUID";
            //throw {response:{status:"404", statusText:"No tileset data found for specified UUID"}};
            throw err;
          }
        })
        .catch((err) => {
          //console.log(JSON.stringify(err));
          let msg = self.errorMessage(err, `Could not retrieve UUID for track query (${fn})`, hgUUIDQueryURL);
          self.setState({
            overlayMessage: msg,
            mainHgViewconf: {}
          }, () => {
            self.fadeInOverlay();
          });
        });
      }
      
      //
      // build new viewconf
      //
   
      // 
      // we start with a template that is specific to the 'mode'
      //   
      let newViewconfUUID = Constants.viewerHgViewconfTemplates[newMode];
      //
      // try to fix bad URL parameters
      //
      //if (newGroup.includes("_vs_")) { newMode = "paired"; newViewconfUUID = Constants.viewerHgViewconfTemplates.paired; }
      // 
      // we also need the UUID of the chromsizes and gene annotations track, which is 'genome'-specific
      //
      let newChromsizesUUID = Constants.viewerHgViewconfGenomeAnnotationUUIDs[newGenome]['chromsizes'];
      let newGenesUUID = Constants.viewerHgViewconfGenomeAnnotationUUIDs[newGenome]['genes'];
      let newCTCFBindingSitesUUID = Constants.viewerHgViewconfGenomeAnnotationUUIDs[newGenome]['CTCF_binding_sites'];

      if ((this.state.hgViewParams.itt === "ht") || (this.state.hgViewParams.mode === "pm") || (this.state.hgViewParams.mode === "pd") || (this.state.hgViewParams.mode === "pds") || (this.state.hgViewParams.mode === "ac")) {
        newChromsizesUUID = Constants.viewerHgViewconfGenomeAnnotationUUIDs[newGenome]['chromsizes_fixed_bin'];
        newCTCFBindingSitesUUID = Constants.viewerHgViewconfGenomeAnnotationUUIDs[newGenome]['CTCF_binding_sites_fixed_bin'];
        newGenesUUID = Constants.viewerHgViewconfGenomeAnnotationUUIDs[newGenome]['genes_fixed_bin'];
      }

      //
      // we also need the colormap, which is 'genome' and 'model' specific
      //
      //let newColormap = Constants.viewerHgViewconfColormaps[newGenome][newModel];
      
      let newHgViewconfURL = Helpers.hgViewconfDownloadURL(this.state.hgViewParams.hgViewconfEndpointURL, newViewconfUUID, this);
      // console.log("newHgViewconfURL", newHgViewconfURL);
      
      //
      // mobile and scale adjustments
      //
      let newHgViewParams = {...this.state.hgViewParams};
      let newHgViewTrackChromosomeHeight = (this.state.isMobile && (this.state.isPortrait === false)) ? 0 : parseInt(newHgViewParams.hgViewTrackChromosomeHeight);
      let newHgViewTrackGeneAnnotationsHeight = (this.state.isMobile && (this.state.isPortrait === false)) ? 0 : parseInt(newHgViewParams.hgViewTrackGeneAnnotationsHeight);
      let newHgViewTrackIndexAnnotationsHeight = (this.state.isMobile && (this.state.isPortrait === false)) 
        ? 
          0.001
        : 
          ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite))
          ?
            Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight
          :
            Constants.viewerHgViewParameters.hgViewTrackDHSComponentsHeight;
      //let newHgViewTrackNumSamplesHeight = (this.state.isMobile && (this.state.isPortrait === false)) ? 0 : parseInt(newHgViewParams.hgViewTrackGeneAnnotationsHeight / 3.0);
      
      let newHgViewTrackNumSamplesHeight = (this.state.isMobile && (this.state.isPortrait === false)) 
        ? 
          0.001
        : 
          ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite))
          ?
            Constants.viewerHgViewParameters.hgViewTrackSamplesWithDHSHeight
          :
            0.001;
      
      // let newHgViewTrackIndexDHSHeight = ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight : 0.001;

      let newHgViewTrackIndexDHSHeight = Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight;

      let newHgViewTrackDHSComponentsHeight = ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? 0.001 : Constants.viewerHgViewParameters.hgViewTrackDHSComponentsHeight;
      
      //console.log("this.state.isMobile", this.state.isMobile);
      //console.log("this.state.isPortrait", this.state.isPortrait);
      //console.log("newHgViewTrackChromosomeHeight", newHgViewTrackChromosomeHeight);
      //console.log("newHgViewTrackGeneAnnotationsHeight", newHgViewTrackGeneAnnotationsHeight);
      
      
      //
      // all-component mode
      //
      if (newMode === "ac") {
        // get all-component item
        let samplesToRenderAsTracks = [];
        let samplePromises = [];
        Object.keys(Constants.allComponent).forEach((d) => {
          if (Constants.allComponent[d].active) {
            let o = Constants.allComponent[d];
            o['name'] = d;
            o['filename'] = `${d}.multires.mv5`
            samplesToRenderAsTracks.push(o);
            let endpoint = Constants.applicationHiGlassServerEndpointRootURL;
            samplePromises.push(uuidQueryPromise(o['filename'], endpoint, this));
          }
        });
      
        //console.log("samplesToRenderAsTracks", samplesToRenderAsTracks);
        Promise.all(samplePromises).then((v) => {
            let uuid = {};
            v.forEach((d) => {
              uuid[d.fn] = d.uuid;
            });
            samplesToRenderAsTracks.forEach((d) => {
              d['uuid'] = uuid[d['filename']];
            });
            console.log(`samplesToRenderAsTracks ${JSON.stringify(samplesToRenderAsTracks)}`);
            let samplesToBeRendered = samplesToRenderAsTracks.reduce((d, c) => { return parseInt(d + c.samples) }, 0);
            console.log("samplesToBeRendered", samplesToBeRendered);
            //console.log("newHgViewconfURL", newHgViewconfURL);
            axios.get(newHgViewconfURL)
              .then((res) => {
                if (!res.data) {
                  throw String("Error: New viewconf not returned from query to " + newHgViewconfURL);
                }
                
                //console.log("old res.data", res.data);
                
                // ensure the template is not editable
                res.data.editable = false;
                
                // update hgview parameters
                newHgViewParams.genome = newGenome;
                newHgViewParams.mode = newMode;
                
                let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
                let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
                let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
                let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
                //console.log("position: ", chrLeft, chrRight, start, stop);

                function updateViewerStateForAcModeAndChromInfo(chromInfo, self) {
                  //console.log(`updateViewerStateForAcModeAndChromInfo`);
                  if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                    chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                    chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                    start = Constants.defaultApplicationPositions[newGenome].start;
                    stop = Constants.defaultApplicationPositions[newGenome].stop;
                  }
                  if (start > chromInfo.chromLengths[chrLeft]) {
                    start = chromInfo.chromLengths[chrLeft] - 10000;
                  }
                  if (stop > chromInfo.chromLengths[chrRight]) {
                    stop = chromInfo.chromLengths[chrRight] - 1000;
                  }
                  let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
                  let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
                  //console.log(chrLeft, start, absLeft);
                  //console.log(chrRight, stop, absRight);
                  res.data.views[0].initialXDomain = [absLeft, absRight];
                  res.data.views[0].initialYDomain = [absLeft, absRight];
                  // update track heights -- requires preknowledge of track order from template
                  let windowInnerHeight = document.documentElement.clientHeight + "px";
                  //console.log("windowInnerHeight", windowInnerHeight);
                  //console.log("newHgViewTrackNumSamplesHeight", newHgViewTrackNumSamplesHeight);
                  //let availableWindowInnerHeight = parseInt(windowInnerHeight) - newHgViewTrackChromosomeHeight - newHgViewTrackIndexAnnotationsHeight - newHgViewTrackNumSamplesHeight - newHgViewTrackGeneAnnotationsHeight - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                  // let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackNumSamplesHeight) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackDHSComponentsHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                  // let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackDHSComponentsHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                  
                  let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";

                  //console.log("availableWindowInnerHeight", availableWindowInnerHeight);
                  let heightPerSample = parseFloat(parseInt(availableWindowInnerHeight) / samplesToBeRendered);
                  //console.log("heightPerSample", heightPerSample);
                  // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
                  let topClone = update(res.data.views[0].tracks.top, {$push: []});
                  //console.log("topClone", topClone);
                  // build a new view from pieces of this clone
                  let newTop = [];
                  let newTopIdx = 0;
                  samplesToRenderAsTracks.forEach((sampleToRender) => {
                    //console.log("sampleToRender", sampleToRender);
                    newTop[newTopIdx] = update(topClone[0], {
                      server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      height: {$set: parseInt(sampleToRender.samples * heightPerSample)},
                      name: {$set: sampleToRender.longName},
                      type: {$set: "horizontal-multivec"},
                      minHeight: {$set: 1},
                      resolutions: {$set: Constants.componentResolutions},
                      options: {
                        labelPosition: {$set: "topLeft"},
                        labelTextOpacity: {$set: 0.0},
                        labelBackgroundOpacity: {$set: 0.0},
                        labelColor: {$set: "white"},
                        valueScaleMin: {$set: 0.0},
                        valueScaleMax: {$set: (self.state.hgViewParams.gmax > 0) ? self.state.hgViewParams.gmax : 2.0},
                        name: {$set: sampleToRender.longName},
                        valueScaling: {$set: "linear"},
                        // colorRange: {$set: Constants.componentColorMapGreyscale},
                        colorRange: {$set: Constants.componentColorMapViridisV1},
                        showMousePosition: {$set: true},
                        is2d: {$set: true},
                        colorbarPosition: {$set: null},
                        heatmapType: {$set: "genericIndexDHS"},
                        heatmapComponents: {$set: Constants.components},
                        chromInfo: {$set: self.chromInfoCache[newGenome]},
                        binSize: {$set: 20},
                      },
                      tilesetUid: {$set: sampleToRender.uuid},
                      uid: {$set: uuid4()},
                    });
                    newTopIdx += 1;
                  });
                  //console.log(`added samplesToRenderAsTracks`);
                  //
                  // add chromosome track
                  //
                  newTop[newTopIdx] = update(topClone[1], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    height: {$set: newHgViewTrackChromosomeHeight + 10},
                    tilesetUid: {$set: newChromsizesUUID},
                    uid: {$set: uuid4()},
                    options: {
                      backgroundColor: {$set: "white"},
                      showMousePosition: {$set: true},
                      is2d: {$set: false},
                    }
                  });
                  newTopIdx += 1;
                  // console.log(`added chromosome track`);
                  //
                  // add numSamples track
                  //
                  // let numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamples];
                  // console.log(`numSamplesUUID ${numSamplesUUID}`);
                  
                  // console.log(`self.state.hgViewParams.itt ${self.state.hgViewParams.itt}`);
                  // if (self.state.hgViewParams.itt === Constants.applicationIttModes.ht) {
                  //   numSamplesUUID = Constants.viewerHgViewconfFixedBinNumSamplesUUIDs[newNumSamples];
                  // }

                  // let numSamplesTrackType = Constants.viewerHgViewconfNumSamplesTrackType;
                  // let numSamplesValueScaleMin = Constants.viewerHgViewconfNumSamplesValueScaleMin[newNumSamples];
                  // let numSamplesValueScaleMax = Constants.viewerHgViewconfNumSamplesValueScaleMax[newNumSamples];
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackNumSamplesHeight},
                  //   type: {$set: numSamplesTrackType},
                  //   tilesetUid: {$set: numSamplesUUID},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     minHeight: {$set: 0},
                  //     backgroundColor: {$set: "rgb(242,242,242)"},
                  //     axisLabelFormatting: {$set: "normal"},
                  //     axisPositionHorizontal: {$set: "outsideRight"},
                  //     barFillColor: {$set: "black"},
                  //     pointColor: {$set: "black"},
                  //     pointSize: {$set: 4},
                  //     zeroLineVisible: {$set: true},
                  //     zeroLineColor: {$set: "black"},
                  //     zeroLineOpacity: {$set: 0.0},
                  //     valueScaling: {$set: "log"},
                  //     valueScaleMin: {$set: numSamplesValueScaleMin},
                  //     valueScaleMax: {$set: numSamplesValueScaleMax},
                  //     valueFormat: {$set: "d"},
                  //     aggregationMode: {$set: "median"},
                  //     showTooltip: {$set: true},
                  //     showMousePosition: {$set: true}
                  //   }
                  // });
                  // newTopIdx += 1;
                  // console.log(`added num samples track`);
                  //
                  // add BED12 Index element
                  //
                  switch (self.state.hgViewParams.itt) {
                    case Constants.applicationIttModes.bb:
                      // bigBed
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                        height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                        type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                        tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                        uid: {$set: uuid4()},
                        options: {
                          minHeight: {$set: 0},
                          backgroundColor: {$set: "white"},
                          isBarPlotLike: {$set: true},
                          itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                          showMousePosition: {$set: true},
                          is2d: {$set: false},
                        }
                      });
                      break;
                    case 'ht':
                      // higlass-transcripts
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                        type: {$set: "horizontal-transcripts"},
                        tilesetUid: {$set: "D5k7ajwfT9mzwbybaSS0VA"},
                        uid: {$set: uuid4()},
                        options: {
                          showMousePosition: {$set: true},
                          blockStyle: {$set: "boxplot"},
                          showToggleTranscriptsButton: {$set: false},
                          labelFontSize: {$set: "11"},
                          labelFontWeight: {$set: "500"},
                          maxTexts: {$set: 100},
                          itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                          startCollapsed: {$set: false},
                          trackMargin: {$set: {
                            top: 10,
                            bottom: 10,
                            left: 0,
                            right: 0,
                          }},
                          transcriptHeight: {$set: 16},
                          transcriptSpacing: {$set: 5},
                          isVisible: {$set: true},
                          is2d: {$set: false},
                        }
                      });
                      break;
                    case Constants.applicationIttModes.hp: {
                      // higlass-pileup
                      newTop[newTopIdx] = update(topClone[2], {
                        name: {$set: "masterlist_DHSs_733samples_WM20180608_all_mean_signal_colorsMax.bed.unc.b12.lf.AR20210927.withBiosampleCounts"},
                        type: {$set: "pileup"},
                        height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                        options: {
                          backgroundColor: {$set: "#ffffff"},
                          axisPositionHorizontal: {$set: "right"},
                          axisLabelFormatting: {$set: "normal"},
                          outlineReadOnHover: {$set: "no"},
                          showCoverage: {$set: false},
                          colorScale: {$set: [
                            // A T G C N Other
                            "#2c7bb6",
                            "#92c5de",
                            "#ffffbf",
                            "#fdae61",
                            "#808080",
                            "#DCDCDC"
                          ]},
                          indexDHS: {$set: {
                            group: "MasterlistDHS733Sample",
                            set: "",
                            backgroundColor: "#ffffff",
                            biosampleCount: 733,
                            itemRGBMap: {
                              "255,229,0": "Placental / trophoblast",
                              "254,129,2": "Lymphoid",
                              "255,0,0": "Myeloid / erythroid",
                              "7,175,0": "Cardiac",
                              "76,125,20": "Musculoskeletal",
                              "65,70,19": "Vascular / endothelial",
                              "5,193,217": "Primitive / embryonic",
                              "4,103,253": "Neural",
                              "0,149,136": "Digestive",
                              "187,45,212": "Stromal A",
                              "122,0,255": "Stromal B",
                              "74,104,118": "Renal / cancer",
                              "8,36,91": "Cancer / epithelial",
                              "185,70,29": "Pulmonary development",
                              "105,33,8": "Organ development / renal",
                              "195,195,195": "Tissue invariant"
                            },
                          }},
                          showLoadingText: {$set: false},
                          showMousePosition: {$set: true},
                        },
                        uid: {$set: "FylkvVBTSumoJ959H-5A-3"},
                        data: {$set: {
                          type: "bam",
                          bamUrl: "https://areynolds-us-west-2.s3.us-west-2.amazonaws.com/masterlist_DHSs_733samples_WM20180608_all_mean_signal_colorsMax.bed.unc.b12.lf.AR20210927.withBiosampleCounts.bam",
                          baiUrl: "https://areynolds-us-west-2.s3.us-west-2.amazonaws.com/masterlist_DHSs_733samples_WM20180608_all_mean_signal_colorsMax.bed.unc.b12.lf.AR20210927.withBiosampleCounts.bam.bai",
                          chromSizesUrl: "https://areynolds-us-west-2.s3.amazonaws.com/hg38.meuleman.fixedBin.chrom.sizes",
                          options: {
                            maxTileWidth: 1000000,
                          },
                        }}
                      });
                      break;
                    }
                    default:
                      throw new URIError(`Unknown Index DHS track type (itt) mode ${self.state.hgViewParams.itt}`);
                  }
                  newTopIdx += 1;
                  //console.log(`added BED12 track`);
                  //console.warn(`Adding DHS index track... ${this.state.currentViewScale} vs ${this.state.hgViewParams.dtt}`);
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackIndexDHSHeight},
                  //   type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                  //   tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     minHeight: {$set: 0},
                  //     backgroundColor: {$set: "white"},
                  //     isBarPlotLike: {$set: true},
                  //     itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                  //     showMousePosition: {$set: true},
                  //   }
                  // });
                  // newTopIdx += 1;
                  //
                  // add all-DHS Component track
                  //
                  //console.warn(`all-DHS track height ${newHgViewTrackDHSComponentsHeight}`);
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackDHSComponentsHeight},
                  //   type: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['type']},
                  //   tilesetUid: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['uuid']},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     minHeight: {$set: 0},
                  //     backgroundColor: {$set: "white"},
                  //     colorRange: {$set: Constants.dhsComponentColorsAsRgb},
                  //     colorLabels: {$set: Constants.dhsComponentLabels},
                  //     showMousePosition: {$set: true},
                  //     valueScaling: {$set: null},
                  //     valueScaleMin: {$set: 1},
                  //     valueScaleMax: {$set: 17},
                  //     valueScaleNaN: {$set: 17},
                  //     zeroValueColor: {$set: "white"},
                  //     heatmapValueScaling: {$set: "categorical"},
                  //     colorbarPosition: {$set: null}
                  //   }
                  // });
                  // newTopIdx += 1;            
                  //console.log(`added DHS track`);

                  //
                  // add gene annotation track
                  //
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackGeneAnnotationsHeight},
                  //   tilesetUid: {$set: newGenesUUID},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     backgroundColor: {$set: "white"},
                  //     plusStrandColor: {$set: "black"},
                  //     minusStrandColor: {$set: "black"},
                  //     showMousePosition: {$set: true},
                  //   }
                  // });
                  switch (self.state.hgViewParams.gatt) {
                    case Constants.applicationGattModes.cv: {
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: newHgViewTrackGeneAnnotationsHeight},
                        tilesetUid: {$set: newGenesUUID},
                        // tilesetUid: {$set: "UQPDVgG1TQi7Pw0P88RV-w"},
                        uid: {$set: uuid4()},
                        options: {
                          backgroundColor: {$set: "white"},
                          plusStrandColor: {$set: "black"},
                          minusStrandColor: {$set: "black"},
                          showMousePosition: {$set: true},
                          is2d: {$set: false},
                        }
                      });
                      break;
                    }
                    case Constants.applicationGattModes.ht: {
                      const blockCalculateTranscriptCountsEnabled = Constants.applicationGacModes[self.state.hgViewParams.gac];
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: self.state.geneAnnotationTrackHeight},
                        type: {$set: "horizontal-transcripts"},
                        tilesetUid: {$set: Constants.viewerHgViewconfGenomeAnnotationUUIDs[self.state.hgViewParams.genome]["transcripts"]},
                        uid: {$set: uuid4()},
                        options: {
                          maxRows: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsDesktopTrackMaxRows},
                          maxTexts: {$set: 50},
                          labelFontSize: {$set: 10},
                          labelFontWeight: {$set: 500},
                          transcriptHeight: {$set: 14},
                          transcriptSpacing: {$set: 4},
                          // minHeight: {$set: self.state.geneAnnotationTrackHeight},
                          showMousePosition: {$set: true},
                          startCollapsed: {$set: false},
                          // blockStyle: {$set: "UCSC-like"},
                          blockStyle: {$set: "directional"},
                          // highlightTranscriptType: {$set: "longestIsoform"},
                          // highlightTranscriptTrackBackgroundColor: {$set: "#fdcfcf"},
                          // showToggleTranscriptsButton: {$set: true},
                          showToggleTranscriptsButton: {$set: false},
                          utrColor: {$set: "grey"},
                          plusStrandColor: {$set: "#111111"},
                          minusStrandColor: {$set: "#111111"},
                          blockCalculateTranscriptCounts: {$set: blockCalculateTranscriptCountsEnabled},
                          trackMargin: {$set: {top:10, bottom:10, left:0, right:0}},
                          is2d: {$set: false},
                        }
                      });
                      break;
                    }
                    default:
                      throw new URIError(`Unknown gene annotation track type (gatt) mode ${self.state.hgViewParams.gatt}`);
                  }


                  //console.log(`added gene annotation track`);
                  // replace the top view tracks with the newly build track layout
                  //console.log("updated newTop", JSON.stringify(newTop, null, 2));
                  res.data.views[0].tracks.top = newTop;
                  // confirm layout
                  // console.log("updated res.data", JSON.stringify(res.data, null, 2));
                  // get child view heights
                  const childViews = res.data.views[0].tracks.top;
                  let childViewHeightTotal = 0;
                  childViews.forEach((cv) => { childViewHeightTotal += cv.height });
                  childViewHeightTotal += 10;
                  let childViewHeightTotalPx = childViewHeightTotal + "px";
                  //console.log("childViewHeightTotalPx", childViewHeightTotalPx);
                  
                  // update Viewer state
                  //console.log("Updating mainHgViewconf from res.data");
                  self.setState({
                    hgViewParams: newHgViewParams,
                    hgViewHeight: childViewHeightTotalPx,
                    mainHgViewconf: res.data,
                    currentPositionKey: Math.random(),
                    currentPosition : {
                      chrLeft : chrLeft,
                      chrRight : chrRight,
                      startLeft : parseInt(start),
                      stopLeft : parseInt(stop),
                      startRight : parseInt(start),
                      stopRight : parseInt(stop)
                    },
                    // selectedProteinCodingsRowIdx: -1,
                    // selectedTranscriptionFactorsRowIdx: -1,
                    // selectedRoiRowIdx: -1,
                  }, () => {
                    self.setState({
                      hgViewKey: self.state.hgViewKey + 1,
                      drawerContentKey: self.state.drawerContentKey + 1,
                    }, () => {
                      // update browser history (address bar URL)
                      console.log("calling [updateViewerURL] from [triggerUpdate]", self.state.hgViewParams.mode);
                      self.updateViewerURL(self.state.hgViewParams.mode,
                                           self.state.currentPosition.chrLeft,
                                           self.state.currentPosition.chrRight,
                                           self.state.currentPosition.startLeft,
                                           self.state.currentPosition.stopRight,
                                           true,
                                           "update (ac)");
                      // add location event handler
                      self.hgView.api.on("location", (event) => { 
                        self.updateViewerLocation(event);
                      });

                      setTimeout(() => {
                        // const indexDHSTrackObj = self.hgView.api.getComponent().getTrackObject(
                        //   self.state.mainHgViewconf.views[0].uid,
                        //   self.state.mainHgViewconf.views[0].tracks.top[3].uid,
                        // );
                        const geneAnnotationTrackObj = self.hgView.api.getComponent().getTrackObject(
                          self.state.mainHgViewconf.views[0].uid,
                          self.state.mainHgViewconf.views[0].tracks.top[3].uid,
                        );
                        // eslint-disable-next-line no-unused-vars
                        // indexDHSTrackObj.pubSub.subscribe("trackDimensionsModified", (msg) => { 
                        //   const newIndexDHSTrackObjTrackHeight = parseInt(indexDHSTrackObj.trackHeight);
                        //   if (newIndexDHSTrackObjTrackHeight !== self.state.indexDHSTrackHeight && newIndexDHSTrackObjTrackHeight !== 0) {
                        //     self.setState({
                        //       indexDHSTrackHeight: parseInt(indexDHSTrackObj.trackHeight),
                        //     }, () => {
                        //       // console.log(`indexDHSTrackObj trackDimensionsModified event sent ${self.state.indexDHSTrackHeight}px`);
                        //       self.updateViewportDimensions();
                        //       indexDHSTrackObj.pubSub.unsubscribe("trackDimensionsModified");
                        //     });
                        //   }
                        // });
                        geneAnnotationTrackObj.pubSub.subscribe("trackDimensionsModified", (msg) => { 
                          const newGeneAnnotationTrackObjTrackHeight = parseInt(geneAnnotationTrackObj.trackHeight);
                          if (newGeneAnnotationTrackObjTrackHeight !== self.state.geneAnnotationTrackHeight && newGeneAnnotationTrackObjTrackHeight !== 0) {
                            self.setState({
                              geneAnnotationTrackHeight: parseInt(geneAnnotationTrackObj.trackHeight) + 10,
                            }, () => {
                              // console.log(`geneAnnotationTrackObj trackDimensionsModified event sent ${self.state.geneAnnotationTrackHeight}px`);
                              self.updateViewportDimensions();
                              geneAnnotationTrackObj.pubSub.unsubscribe("trackDimensionsModified");
                            });
                          }
                        });
                      }, 500);
                    })
                  })
                }

                // console.log(`chromInfoCacheExists ${chromInfoCacheExists}`);
                if (chromInfoCacheExists) {
                  updateViewerStateForAcModeAndChromInfo(this.chromInfoCache[newGenome], this);
                }
                else {
                  let chromSizesURL = this.getChromSizesURL(newGenome);
                  ChromosomeInfo(chromSizesURL)
                    .then((chromInfo) => {
                      this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                      updateViewerStateForAcModeAndChromInfo(chromInfo, this);
                    })
                    .catch((err) => {
                      let msg = this.errorMessage(err, `Could not retrieve chromosome information`, chromSizesURL);
                      this.setState({
                        overlayMessage: msg,
                        mainHgViewconf: {}
                      }, () => {
                        this.fadeInOverlay();
                      });
                    });
                }
              
              })
              .catch((err) => {
                // console.log(err.response);
                let msg = this.errorMessage(err, `[ac] Could not retrieve view configuration`, newHgViewconfURL);
                this.setState({
                  overlayMessage: msg,
                  mainHgViewconf: {}
                }, () => {
                  this.fadeInOverlay();
                });
              });
          })
          .catch((err) => {
            throw new Error(`Could not retrieve UUIDs for all specified components - ${JSON.stringify(err)}`);
            //console.log("Could not retrieve UUIDs for all specified components");
          });
      }
      
      //
      // per-component mode
      //  
      else if (newMode === "pc") {
        // get all active per-component items
        let samplesToRenderAsTracks = [];
        let samplePromises = [];
        Object.keys(Constants.components).forEach((d, di) => {
          if (di >= 15) return;
          if (Constants.components[d].active) {
            let o = Constants.components[d];
            o['name'] = d;
            o['filename_prefix'] = o['filenameKey'];
            o['filename'] = `${o['filename_prefix']}.multires.mv5`
            samplesToRenderAsTracks.push(o);
            let endpoint = Constants.applicationHiGlassServerEndpointRootURL;
            samplePromises.push(uuidQueryPromise(o['filename'], endpoint, this));
          }
        });
        //console.log("samplesToRenderAsTracks", samplesToRenderAsTracks);
        Promise.all(samplePromises).then((v) => {
          let uuid = {};
          v.forEach((d) => {
            uuid[d.fn] = d.uuid;
          });
          samplesToRenderAsTracks.forEach((d) => {
            d['uuid'] = uuid[d['filename']];
          });
          // console.log(samplesToRenderAsTracks);
          let samplesToBeRendered = samplesToRenderAsTracks.reduce((d, c) => { return parseInt(d + c.samples) }, 0);
          // console.log("samplesToBeRendered", samplesToBeRendered);
          // console.log("newHgViewconfURL", newHgViewconfURL);
          axios.get(newHgViewconfURL)
            .then((res) => {
              if (!res.data) {
                throw new Error(`Error: New viewconf not returned from query to ${JSON.stringify(newHgViewconfURL)}`);
              }
              
              // console.log("old res.data", res.data);
              
              // ensure the template is not editable
              res.data.editable = false;
              
              // update hgview parameters
              newHgViewParams.genome = newGenome;
              newHgViewParams.mode = newMode;
              
              let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
              let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
              let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
              let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
              // console.log("position: ", chrLeft, chrRight, start, stop);

              function updateViewerStateForPcModeAndChromInfo(chromInfo, self) {
                if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                  chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                  chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                  start = Constants.defaultApplicationPositions[newGenome].start;
                  stop = Constants.defaultApplicationPositions[newGenome].stop;
                }
                if (start > chromInfo.chromLengths[chrLeft]) {
                  start = chromInfo.chromLengths[chrLeft] - 10000;
                }
                if (stop > chromInfo.chromLengths[chrRight]) {
                  stop = chromInfo.chromLengths[chrRight] - 1000;
                }
                let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
                let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
                //console.log(chrLeft, start, absLeft);
                //console.log(chrRight, stop, absRight);
                res.data.views[0].initialXDomain = [absLeft, absRight];
                res.data.views[0].initialYDomain = [absLeft, absRight];
                // update track heights -- requires preknowledge of track order from template
                let windowInnerHeight = document.documentElement.clientHeight + "px";
                //console.log("windowInnerHeight", windowInnerHeight);
                let availableWindowInnerHeight = parseInt(windowInnerHeight) - newHgViewTrackChromosomeHeight - newHgViewTrackIndexAnnotationsHeight - newHgViewTrackNumSamplesHeight - newHgViewTrackGeneAnnotationsHeight - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                //console.log("availableWindowInnerHeight", availableWindowInnerHeight);
                let heightPerSample = parseFloat(parseInt(availableWindowInnerHeight) / samplesToBeRendered);
                //console.log("heightPerSample", heightPerSample);
                // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
                let topClone = update(res.data.views[0].tracks.top, {$push: []});
                //console.log("topClone", topClone);
                // build a new view from pieces of this clone
                let newTop = [];
                let newTopIdx = 0;
                samplesToRenderAsTracks.forEach((sampleToRender) => {
                  //console.log("sampleToRender", sampleToRender);
                  newTop[newTopIdx] = update(topClone[0], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    height: {$set: parseInt(sampleToRender.samples * heightPerSample)},
                    name: {$set: sampleToRender.longName},
                    type: {$set: "horizontal-multivec"},
                    minHeight: {$set: 1},
                    resolutions: {$set: Constants.componentResolutions},
                    options: {
                      labelPosition: {$set: "topLeft"},
                      labelTextOpacity: {$set: 0.0},
                      labelBackgroundOpacity: {$set: 0.0},
                      labelColor: {$set: "white"},
                      valueScaleMin: {$set: 0.0},
                      valueScaleMax: {$set: (self.state.hgViewParams.gmax > 0) ? self.state.hgViewParams.gmax : 1.0},
                      name: {$set: sampleToRender.longName},
                      valueScaling: {$set: "linear"},
                      // colorRange: {$set: Constants.componentColorMapGreyscale},
                      colorRange: {$set: Constants.componentColorMapViridisV1},
                      showMousePosition: {$set: true},
                    },
                    tilesetUid: {$set: sampleToRender.uuid},
                    uid: {$set: uuid4()},
                  });
                  newTopIdx += 1;
                })
                // add chromosome track
                newTop[newTopIdx] = update(topClone[1], {
                  server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  height: {$set: newHgViewTrackChromosomeHeight + 10},
                  tilesetUid: {$set: newChromsizesUUID},
                  uid: {$set: uuid4()},
                  options: {
                    backgroundColor: {$set: "white"},
                    showMousePosition: {$set: true},
                  }
                });
                newTopIdx += 1;
                // add numSamples track
                let numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamples];
                // if (self.newHgViewParams && self.newHgViewParams.itt && self.newHgViewParams.itt === "ht") {
                if (newHgViewParams && newHgViewParams.itt && newHgViewParams.itt === "ht") {
                  numSamplesUUID = Constants.viewerHgViewconfFixedBinNumSamplesUUIDs[newNumSamples];
                  if (!numSamplesUUID) {
                    numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamples];
                  }
                }
                let numSamplesTrackType = Constants.viewerHgViewconfNumSamplesTrackType;
                let numSamplesValueScaleMin = Constants.viewerHgViewconfNumSamplesValueScaleMin[newNumSamples];
                let numSamplesValueScaleMax = Constants.viewerHgViewconfNumSamplesValueScaleMax[newNumSamples];
                newTop[newTopIdx] = update(topClone[2], {
                  server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                  height: {$set: newHgViewTrackNumSamplesHeight},
                  type: {$set: numSamplesTrackType},
                  tilesetUid: {$set: numSamplesUUID},
                  uid: {$set: uuid4()},
                  options: {
                    backgroundColor: {$set: "rgb(242,242,242)"},
                    axisLabelFormatting: {$set: "normal"},
                    barFillColor: {$set: "black"},
                    pointColor: {$set: "black"},
                    pointSize: {$set: 4},
                    zeroLineVisible: {$set: true},
                    zeroLineColor: {$set: "black"},
                    zeroLineOpacity: {$set: 1.0},
                    valueScaling: {$set: "log"},
                    valueScaleMin: {$set: numSamplesValueScaleMin},
                    valueScaleMax: {$set: numSamplesValueScaleMax},
                    aggregationMode: {$set: "median"},
                    showTooltip: {$set: true},
                    showMousePosition: {$set: true}
                  }
                });
                newTopIdx += 1;
                // add BED12 Index element track (copy of gene annotation track)
                switch (self.state.hgViewParams.itt) {
                  case Constants.applicationIttModes.bb: {
                    // bigBed
                    newTop[newTopIdx] = update(topClone[2], {
                      server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                      height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                      type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                      tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                      uid: {$set: uuid4()},
                      options: {
                        minHeight: {$set: 0},
                        backgroundColor: {$set: "white"},
                        isBarPlotLike: {$set: true},
                        itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                        showMousePosition: {$set: true},
                      }
                    });
                    break;
                  }
                  case 'ht': {
                    // higlass-transcripts
                    newTop[newTopIdx] = update(topClone[2], {
                      server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                      type: {$set: "horizontal-transcripts"},
                      tilesetUid: {$set: "D5k7ajwfT9mzwbybaSS0VA"},
                      uid: {$set: uuid4()},
                      options: {
                        showMousePosition: {$set: true},
                        blockStyle: {$set: "boxplot"},
                        showToggleTranscriptsButton: {$set: false},
                        labelFontSize: {$set: "11"},
                        labelFontWeight: {$set: "500"},
                        maxTexts: {$set: 100},
                        itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                        startCollapsed: {$set: false},
                        trackMargin: {$set: {
                          top: 10,
                          bottom: 10,
                          left: 0,
                          right: 0,
                        }},
                        transcriptHeight: {$set: 16},
                        transcriptSpacing: {$set: 5},
                        isVisible: {$set: false},
                      }
                    });
                    break;
                  }
                  default:
                    throw new URIError(`Unknown Index DHS track type (itt) mode ${self.state.hgViewParams.itt}`);
                }
                newTopIdx += 1;
                // let testIndexElementsUUID = "AkkhKTLxQyeu2-Toxio4lQ";
                // let testIndexElementsType = "horizontal-gene-bed12-annotations";
                // newTop[newTopIdx] = update(topClone[2], {
                //   server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                //   height: {$set: newHgViewTrackIndexAnnotationsHeight},
                //   type: {$set: testIndexElementsType},
                //   tilesetUid: {$set: testIndexElementsUUID},
                //   uid: {$set: uuid4()},
                //   options: {
                //     backgroundColor: {$set: "white"},
                //     isBarPlotLike: {$set: true},
                //     itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                //     showMousePosition: {$set: true},
                //   }
                // });
                // newTopIdx += 1;
                // add gene annotation track
                newTop[newTopIdx] = update(topClone[2], {
                  server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  height: {$set: newHgViewTrackGeneAnnotationsHeight},
                  tilesetUid: {$set: newGenesUUID},
                  uid: {$set: uuid4()},
                  options: {
                    backgroundColor: {$set: "white"},
                    plusStrandColor: {$set: "black"},
                    minusStrandColor: {$set: "black"},
                    showMousePosition: {$set: true},
                  }
                });
                // replace the top view tracks with the newly build track layout
                // console.log("updated newTop", JSON.stringify(newTop, null, 2));
                res.data.views[0].tracks.top = newTop;
                // confirm layout
                // console.log("updated res.data", JSON.stringify(res.data, null, 2));
                // get child view heights
                const childViews = res.data.views[0].tracks.top;
                let childViewHeightTotal = 0;
                childViews.forEach((cv) => { childViewHeightTotal += cv.height });
                childViewHeightTotal += 10;
                let childViewHeightTotalPx = childViewHeightTotal + "px";
                //console.log("childViewHeightTotalPx", childViewHeightTotalPx);
                
                // update Viewer state
                // console.log("Updating mainHgViewconf from new res.data");
                self.setState({
                  hgViewParams: newHgViewParams,
                  hgViewHeight: childViewHeightTotalPx,
                  mainHgViewconf: res.data,
                  currentPositionKey: Math.random(),
                  currentPosition : {
                    chrLeft : chrLeft,
                    chrRight : chrRight,
                    startLeft : parseInt(start),
                    stopLeft : parseInt(stop),
                    startRight : parseInt(start),
                    stopRight : parseInt(stop)
                  },
                  selectedProteinCodingsRowIdx: -1,
                  selectedTranscriptionFactorsRowIdx: -1,
                  selectedRoiRowIdx: -1,
                }, () => {
                  self.setState({
                    hgViewKey: self.state.hgViewKey + 1,
                    drawerContentKey: self.state.drawerContentKey + 1,
                    positionSummaryElementKey: self.state.positionSummaryElementKey + 1,
                    scaleSummaryKey: Math.random(),
                  }, () => {
                    // update browser history (address bar URL)
                    console.log("calling [updateViewerURL] from [triggerUpdate]", self.state.hgViewParams.mode);
                    self.updateViewerURL(self.state.hgViewParams.mode,
                                         self.state.currentPosition.chrLeft,
                                         self.state.currentPosition.chrRight,
                                         self.state.currentPosition.startLeft,
                                         self.state.currentPosition.stopRight,
                                         true,
                                         "triggerUpdate (pc)",);
                    // add location event handler
                    self.hgView.api.on("location", (event) => { 
                      self.updateViewerLocation(event);
                    });
                  })
                })
              }

              if (chromInfoCacheExists) {
                updateViewerStateForPcModeAndChromInfo(this.chromInfoCache[newGenome], this);
              }
              else {
                let chromSizesURL = this.getChromSizesURL(newGenome);
                ChromosomeInfo(chromSizesURL)
                  .then((chromInfo) => {
                    this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                    updateViewerStateForPcModeAndChromInfo(chromInfo, this);
                  })
                  .catch((err) => {
                    // console.log(`${JSON.stringify(this.chromInfoCache[newGenome])}`);
                    // console.log(`${JSON.stringify(err)}`);
                    let msg = this.errorMessage(err, `Viewer > updateViewerStateForPcModeAndChromInfo > Could not retrieve chromosome information`, chromSizesURL);
                    this.setState({
                      overlayMessage: msg,
                      mainHgViewconf: {}
                    }, () => {
                      this.fadeInOverlay();
                    });
                  });
              }
            
            })
            .catch((err) => {
              //console.log(err.response);
              let msg = this.errorMessage(err, `Viewer > updateViewerStateForPcModeAndChromInfo > Could not retrieve view configuration`, newHgViewconfURL);
              this.setState({
                overlayMessage: msg,
                mainHgViewconf: {}
              }, () => {
                this.fadeInOverlay();
              });
            });
        })
        .catch((err) => {
          throw new Error(`Viewer > updateViewerStateForPcModeAndChromInfo > Could not retrieve UUIDs for all specified components - ${JSON.stringify(err)}`);
          //console.log("Viewer > updateViewerStateForPcModeAndChromInfo > Could not retrieve UUIDs for all specified components");
        });
      }
      
      //
      // per-component (bigWig) mode
      //  
      else if (newMode === "pw") {
        // get all active per-component items
        let samplesToRenderAsTracks = [];
        let samplePromises = [];
        Object.keys(Constants.components).forEach((d, di) => {
          if (di >= 15) return; // we do not care about the tissue-invariant track
          if (Constants.components[d].active) {
            let o = Constants.components[d];
            let i = o.componentIndex;
            o['name'] = d;
            o['filename'] = `WM20181113_signal_track_per_component.comp${i}.bw`
            samplesToRenderAsTracks.push(o);
            let endpoint = Constants.applicationHiGlassDevServerEndpointRootURL;
            samplePromises.push(uuidQueryPromise(o['filename'], endpoint, this));
          }
        });
        //console.log("samplesToRenderAsTracks", samplesToRenderAsTracks);
        Promise.all(samplePromises).then((v) => {
            let uuid = {};
            v.forEach((d) => {
              uuid[d.fn] = d.uuid;
            });
            samplesToRenderAsTracks.forEach((d) => {
              d['uuid'] = uuid[d['filename']];
            });
            //console.log(samplesToRenderAsTracks);
            let samplesToBeRendered = samplesToRenderAsTracks.reduce((d, c) => { return parseInt(d + c.samples) }, 0);
            let componentsToBeRendered = samplePromises.length;
            //console.log("samplesToBeRendered", samplesToBeRendered);
            //console.log("componentsToBeRendered", componentsToBeRendered);
            //console.log("newHgViewconfURL", newHgViewconfURL);
            axios.get(newHgViewconfURL)
              .then((res) => {
                if (!res.data) {
                  throw String("Error: New viewconf not returned from query to " + newHgViewconfURL);
                }
                
                //console.log("old res.data", res.data);
                
                // ensure the template is not editable
                res.data.editable = false;
                
                // update hgview parameters
                newHgViewParams.genome = newGenome;
                newHgViewParams.mode = newMode;
                
                let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
                let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
                let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
                let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
                //console.log("position: ", chrLeft, chrRight, start, stop);
                
                function updateViewerStateForPwModeAndChromInfo(chromInfo, self) {
                  if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                    chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                    chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                    start = Constants.defaultApplicationPositions[newGenome].start;
                    stop = Constants.defaultApplicationPositions[newGenome].stop;
                  }
                  if (start > chromInfo.chromLengths[chrLeft]) {
                    start = chromInfo.chromLengths[chrLeft] - 10000;
                  }
                  if (stop > chromInfo.chromLengths[chrRight]) {
                    stop = chromInfo.chromLengths[chrRight] - 1000;
                  }
                  let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
                  let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
                  //console.log(chrLeft, start, absLeft);
                  //console.log(chrRight, stop, absRight);
                  res.data.views[0].initialXDomain = [absLeft, absRight];
                  res.data.views[0].initialYDomain = [absLeft, absRight];
                  // update track heights -- requires preknowledge of track order from template
                  let windowInnerHeight = document.documentElement.clientHeight + "px";
                  //console.log("windowInnerHeight", windowInnerHeight);
                  //newHgViewTrackNumSamplesHeight = (this.state.currentViewScale <= this.state.hgViewParams.dtt) ? newHgViewTrackNumSamplesHeight : 0;
                  let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackNumSamplesHeight) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackDHSComponentsHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                  //console.log("availableWindowInnerHeight", availableWindowInnerHeight);
                  let heightPerSample = parseFloat(parseInt(availableWindowInnerHeight) / samplesToBeRendered);
                  //console.log("heightPerSample", heightPerSample);
                  let heightPerTrack = samplesToBeRendered / componentsToBeRendered * heightPerSample;
                  //console.log("heightPerTrack", heightPerTrack);
                  // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
                  let topClone = update(res.data.views[0].tracks.top, {$push: []});
                  //console.log("topClone", topClone);
                  // build a new view from pieces of this clone
                  let newTop = [];
                  let newTopIdx = 0;
                  samplesToRenderAsTracks.forEach((sampleToRender) => {
                    //console.log("sampleToRender", sampleToRender);
                    newTop[newTopIdx] = update(topClone[0], {
                      server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                      height: {$set: heightPerTrack},
                      minHeight: {$set: 1},
                      name: {$set: sampleToRender.longName},
                      type: {$set: "horizontal-bar"},
                      resolutions: {$set: Constants.componentResolutions},
                      options: {
                        backgroundColor: {$set: "black"},
                        labelPosition: {$set: "topLeft"},
                        labelTextOpacity: {$set: 0.0},
                        labelBackgroundColor: {$set: "black"},
                        labelBackgroundOpacity: {$set: 0.0},
                        labelColor: {$set: "white"},
                        name: {$set: sampleToRender.longName},
                        valueScaling: {$set: "linear"},
                        //lineStrokeColor: {$set: sampleToRender.color},
                        barFillColor: {$set: sampleToRender.color},
                        showTooltip: {$set: true},
                        showMousePosition: {$set: true},
                      },
                      tilesetUid: {$set: sampleToRender.uuid},
                      uid: {$set: uuid4()},
                    });
                    newTopIdx += 1;
                  })
                  //
                  // add chromosome track
                  //
                  newTop[newTopIdx] = update(topClone[1], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    height: {$set: newHgViewTrackChromosomeHeight + 10},
                    tilesetUid: {$set: newChromsizesUUID},
                    uid: {$set: uuid4()},
                    options: {
                      backgroundColor: {$set: "white"},
                      showMousePosition: {$set: true},
                    }
                  });
                  newTopIdx += 1;
                  //
                  // add numSamples track
                  //
                  let numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamples];
                  let numSamplesServerEndpointRootURL = Constants.applicationHiGlassDevServerEndpointRootURL;
                  if (newHgViewParams.itt === "ht") {
                    numSamplesUUID = Constants.viewerHgViewconfFixedBinNumSamplesUUIDs[newNumSamples];
                    numSamplesServerEndpointRootURL = Constants.applicationHiGlassServerEndpointRootURL;
                    if (!numSamplesUUID) {
                      numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamples];
                      numSamplesServerEndpointRootURL = Constants.applicationHiGlassDevServerEndpointRootURL;
                    }
                  }

                  let numSamplesTrackType = Constants.viewerHgViewconfNumSamplesTrackType;
                  let numSamplesValueScaleMin = Constants.viewerHgViewconfNumSamplesValueScaleMin[newNumSamples];
                  let numSamplesValueScaleMax = Constants.viewerHgViewconfNumSamplesValueScaleMax[newNumSamples];
                  newTop[newTopIdx] = update(topClone[2], {
                    server: {$set: numSamplesServerEndpointRootURL},
                    height: {$set: newHgViewTrackNumSamplesHeight},
                    type: {$set: numSamplesTrackType},
                    tilesetUid: {$set: numSamplesUUID},
                    uid: {$set: uuid4()},
                    options: {
                      minHeight: {$set: 0},
                      backgroundColor: {$set: "rgb(242,242,242)"},
                      axisLabelFormatting: {$set: "normal"},
                      axisPositionHorizontal: {$set: "outsideRight"},
                      barFillColor: {$set: "black"},
                      pointColor: {$set: "black"},
                      pointSize: {$set: 4},
                      zeroLineVisible: {$set: true},
                      zeroLineColor: {$set: "black"},
                      zeroLineOpacity: {$set: 0.0},
                      valueScaling: {$set: "log"},
                      valueScaleMin: {$set: numSamplesValueScaleMin},
                      valueScaleMax: {$set: numSamplesValueScaleMax},
                      valueFormat: {$set: "d"},
                      aggregationMode: {$set: "median"},
                      showTooltip: {$set: true},
                      showMousePosition: {$set: true}
                    }
                  });
                  newTopIdx += 1;
                  //
                  // add Index DHS track
                  //
                  switch (self.state.hgViewParams.itt) {
                    case Constants.applicationIttModes.bb:
                      // bigBed
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                        height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                        type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                        tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                        uid: {$set: uuid4()},
                        options: {
                          minHeight: {$set: 0},
                          backgroundColor: {$set: "white"},
                          isBarPlotLike: {$set: true},
                          itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                          showMousePosition: {$set: true},
                        }
                      });
                      break;
                    case 'ht':
                      // higlass-transcripts
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                        type: {$set: "horizontal-transcripts"},
                        tilesetUid: {$set: "D5k7ajwfT9mzwbybaSS0VA"},
                        uid: {$set: uuid4()},
                        options: {
                          showMousePosition: {$set: true},
                          blockStyle: {$set: "boxplot"},
                          showToggleTranscriptsButton: {$set: false},
                          labelFontSize: {$set: "11"},
                          labelFontWeight: {$set: "500"},
                          maxTexts: {$set: 100},
                          itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                          startCollapsed: {$set: false},
                          trackMargin: {$set: {
                            top: 10,
                            bottom: 10,
                            left: 0,
                            right: 0,
                          }},
                          transcriptHeight: {$set: 16},
                          transcriptSpacing: {$set: 5},
                          isVisible: {$set: true},
                        }
                      });
                      break;
                    default:
                      throw new URIError(`Unknown Index DHS track type (itt) mode ${self.state.hgViewParams.itt}`);
                  }
                  newTopIdx += 1;
                  //console.warn(`Adding DHS index track... ${this.state.currentViewScale} vs ${this.state.hgViewParams.dtt}`);
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackIndexDHSHeight},
                  //   type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                  //   tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     minHeight: {$set: 0},
                  //     backgroundColor: {$set: "white"},
                  //     isBarPlotLike: {$set: true},
                  //     itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                  //     showMousePosition: {$set: true},
                  //   }
                  // });
                  // newTopIdx += 1;
                  //
                  // add all-DHS Component track
                  //
                  //console.warn(`all-DHS track height ${newHgViewTrackDHSComponentsHeight}`);
                  newTop[newTopIdx] = update(topClone[2], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    height: {$set: newHgViewTrackDHSComponentsHeight},
                    type: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['type']},
                    tilesetUid: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['uuid']},
                    uid: {$set: uuid4()},
                    options: {
                      minHeight: {$set: 0},
                      backgroundColor: {$set: "white"},
                      colorRange: {$set: Constants.dhsComponentColorsAsRgb},
                      colorLabels: {$set: Constants.dhsComponentLabels},
                      showMousePosition: {$set: true},
                      valueScaling: {$set: null},
                      valueScaleMin: {$set: 1},
                      valueScaleMax: {$set: 17},
                      valueScaleNaN: {$set: 17},
                      zeroValueColor: {$set: "white"},
                      heatmapValueScaling: {$set: "categorical"},
                      colorbarPosition: {$set: null}
                    }
                  });
                  newTopIdx += 1;
                  //
                  // add gene annotation track
                  //
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackGeneAnnotationsHeight},
                  //   tilesetUid: {$set: newGenesUUID},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     backgroundColor: {$set: "white"},
                  //     plusStrandColor: {$set: "black"},
                  //     minusStrandColor: {$set: "black"},
                  //     showMousePosition: {$set: true},
                  //   }
                  // });
                  switch (self.state.hgViewParams.itt) {
                    case Constants.applicationIttModes.bb:
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: newHgViewTrackGeneAnnotationsHeight},
                        tilesetUid: {$set: newGenesUUID},
                        uid: {$set: uuid4()},
                        options: {
                          backgroundColor: {$set: "white"},
                          plusStrandColor: {$set: "black"},
                          minusStrandColor: {$set: "black"},
                          showMousePosition: {$set: true},
                        }
                      });
                      break;
                    case 'ht':
                      // higlass-transcripts
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: newHgViewTrackGeneAnnotationsHeight},
                        tilesetUid: {$set: newGenesUUID},
                        uid: {$set: uuid4()},
                        options: {
                          backgroundColor: {$set: "white"},
                          plusStrandColor: {$set: "black"},
                          minusStrandColor: {$set: "black"},
                          showMousePosition: {$set: true},
                        }
                      });
                      break;
                    default:
                      break;
                  }
                  // replace the top view tracks with the newly build track layout
                  //console.log("updated newTop", JSON.stringify(newTop, null, 2));
                  res.data.views[0].tracks.top = newTop;
                  // confirm layout
                  //console.log("updated res.data", JSON.stringify(res.data, null, 2));
                  // lock vertical scale of per-component signals
                  if (Constants.lockPwModeVerticalScale) {
                    // locksByViewUid
                    const viewUid = res.data.views[0].uid;
                    const newLockUid = uuid4();
                    let newLocksByViewUid = {};
                    Object.keys(Constants.components).forEach((c, ci) => {
                      if (ci >= 15) return;
                      let newLockViewKey = `${viewUid}.${res.data.views[0].tracks.top[ci].uid}`;
                      newLocksByViewUid[newLockViewKey] = newLockUid;
                    });
                    res.data.valueScaleLocks.locksByViewUid = newLocksByViewUid;
                    // locksDict
                    let newLocksDict = {};
                    //newLocksDict.ignoreOffScreenValues = true;
                    newLocksDict[newLockUid] = {};
                    newLocksDict[newLockUid].uid = newLockUid;
                    newLocksDict[newLockUid].ignoreOffScreenValues = true;
                    Object.keys(Constants.components).forEach((c, ci) => {
                      if (ci >= 15) return;
                      let newLockDictKey = `${viewUid}.${res.data.views[0].tracks.top[ci].uid}`;
                      newLocksDict[newLockUid][newLockDictKey] = {};
                      newLocksDict[newLockUid][newLockDictKey].view = viewUid;
                      newLocksDict[newLockUid][newLockDictKey].track = res.data.views[0].tracks.top[ci].uid;
                      //newLocksDict[newLockUid][newLockDictKey].ignoreOffScreenValues = true;
                    });
                    res.data.valueScaleLocks.locksDict = newLocksDict;
                  }
                  // get child view heights
                  const childViews = res.data.views[0].tracks.top;
                  let childViewHeightTotal = 0;
                  childViews.forEach((cv) => { childViewHeightTotal += cv.height });
                  childViewHeightTotal += 10;
                  let childViewHeightTotalPx = childViewHeightTotal + "px";
                  //console.log("childViewHeightTotalPx", childViewHeightTotalPx);
                  
                  // update Viewer state
                  //console.log("Updating mainHgViewconf from new res.data", JSON.stringify(res.data));
                  self.setState({
                    hgViewParams: newHgViewParams,
                    hgViewHeight: childViewHeightTotalPx,
                    mainHgViewconf: res.data,
                    currentPositionKey: Math.random(),
                    currentPosition : {
                      chrLeft : chrLeft,
                      chrRight : chrRight,
                      startLeft : parseInt(start),
                      stopLeft : parseInt(stop),
                      startRight : parseInt(start),
                      stopRight : parseInt(stop)
                    },
                    selectedProteinCodingsRowIdx: -1,
                    selectedTranscriptionFactorsRowIdx: -1,
                    selectedRoiRowIdx: -1,
                  }, () => {
                    self.setState({
                      hgViewKey: self.state.hgViewKey + 1,
                      //drawerContentKey: self.state.drawerContentKey + 1,
                      positionSummaryElementKey: self.state.positionSummaryElementKey + 1,
                      scaleSummaryKey: Math.random(),
                    }, () => {
                      // update browser history (address bar URL)
                      console.log("calling [updateViewerURL] from [triggerUpdate]", self.state.hgViewParams.mode);
                      self.updateViewerURL(self.state.hgViewParams.mode,
                                           self.state.currentPosition.chrLeft,
                                           self.state.currentPosition.chrRight,
                                           self.state.currentPosition.startLeft,
                                           self.state.currentPosition.stopRight,
                                           true,
                                           "triggerUpdate (pw)",);
                      // add location event handler
                      self.hgView.api.on("location", (event) => { 
                        self.updateViewerLocation(event);
                      });
                    })
                  })
                }
                
                if (chromInfoCacheExists) {
                  updateViewerStateForPwModeAndChromInfo(this.chromInfoCache[newGenome], this);
                }
                else {
                  let chromSizesURL = this.getChromSizesURL(newGenome);
                  //console.log(`Viewer > updateViewerStateForPwModeAndChromInfo > chromSizesURL ${chromSizesURL}`);
                  ChromosomeInfo(chromSizesURL)
                    .then((chromInfo) => {
                      this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                      updateViewerStateForPwModeAndChromInfo(chromInfo, this);
                    })
                    .catch((err) => {
                      let msg = this.errorMessage(err, `Viewer > updateViewerStateForPwModeAndChromInfo > Could not retrieve chromosome information`, chromSizesURL);
                      this.setState({
                        overlayMessage: msg,
                        mainHgViewconf: {}
                      }, () => {
                        this.fadeInOverlay();
                      });
                    });
                }
             
              })
              .catch((err) => {
                //console.log(err.response);
                let msg = this.errorMessage(err, `Viewer > updateViewerStateForPwModeAndChromInfo > Could not retrieve view configuration`, newHgViewconfURL);
                this.setState({
                  overlayMessage: msg,
                  mainHgViewconf: {}
                }, () => {
                  this.fadeInOverlay();
                });
              });
          })
          .catch((err) => {
            throw new Error(`Viewer > updateViewerStateForPwModeAndChromInfo > Could not retrieve UUIDs for all specified components - ${JSON.stringify(err)}`);
            //console.log("Viewer > updateViewerStateForPwModeAndChromInfo > Could not retrieve UUIDs for all specified components");
          });
      }
      
      //
      // DHS Component track-only mode
      //  
      else if (newMode === "co") {
        // console.log("newHgViewconfURL", newHgViewconfURL);
        axios.get(newHgViewconfURL)
          .then((res) => {
            if (!res.data) {
              throw String("Error: New viewconf not returned from query to " + newHgViewconfURL);
            }
            
            //console.log("old res.data", res.data);
            
            // ensure the template is not editable
            res.data.editable = false;
            
            // update hgview parameters
            newHgViewParams.genome = newGenome;
            newHgViewParams.mode = newMode;
            
            let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
            let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
            let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
            let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
            //console.log("position: ", chrLeft, chrRight, start, stop);
            
            let valueScaleMax = 17;
            let valueScaleNaN = valueScaleMax;
            let colorRange = Constants.dhsComponentColorsAsRgb;
            let colorLabels = Constants.dhsComponentLabels;
            switch (this.state.hgViewParams.dtc) {
              case "av5":
                colorRange = Constants.dhsComponentTwoLevelColorsAsRgb;
                colorLabels = Constants.dhsComponentTwoLevelLabels;
                valueScaleMax = 33;
                valueScaleNaN = 33;
                break;
              default:
                break;
            }

            function updateViewerStateForCoModeAndChromInfo(chromInfo, self) {
              if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                start = Constants.defaultApplicationPositions[newGenome].start;
                stop = Constants.defaultApplicationPositions[newGenome].stop;
              }
              if (start > chromInfo.chromLengths[chrLeft]) {
                start = chromInfo.chromLengths[chrLeft] - 10000;
              }
              if (stop > chromInfo.chromLengths[chrRight]) {
                stop = chromInfo.chromLengths[chrRight] - 1000;
              }
              let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
              let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
              //console.log(chrLeft, start, absLeft);
              //console.log(chrRight, stop, absRight);
              res.data.views[0].initialXDomain = [absLeft, absRight];
              res.data.views[0].initialYDomain = [absLeft, absRight];
              // update track heights -- requires preknowledge of track order from template
              // let windowInnerHeight = document.documentElement.clientHeight + "px";
              //console.log("windowInnerHeight", windowInnerHeight);
              //newHgViewTrackNumSamplesHeight = (this.state.currentViewScale <= this.state.hgViewParams.dtt) ? newHgViewTrackNumSamplesHeight : 0;
              // let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackDHSComponentsHeight) - 2*parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) - Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight + "px";
              //console.log("availableWindowInnerHeight", availableWindowInnerHeight);
              //console.log("heightPerTrack", heightPerTrack);
              // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
              let topClone = update(res.data.views[0].tracks.top, {$push: []});
              //console.log("topClone", topClone);
              // build a new view from pieces of this clone
              let newTop = [];
              let newTopIdx = 0;
              //
              // add chromosome track
              //
              // newTop[newTopIdx] = update(topClone[1], {
              //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
              //   height: {$set: newHgViewTrackChromosomeHeight + 10},
              //   tilesetUid: {$set: newChromsizesUUID},
              //   uid: {$set: uuid4()},
              //   options: {
              //     backgroundColor: {$set: "black"},
              //     showMousePosition: {$set: true},
              //   }
              // });
              // newTopIdx += 1;
              //
              // add all-DHS Component track
              //
              // console.warn(`all-DHS track height ${newHgViewTrackDHSComponentsHeight}`);
              newTop[newTopIdx] = update(topClone[2], {
                name: {$set: "DHS Components"},
                server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                height: {$set: newHgViewTrackDHSComponentsHeight},
                type: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['type']},
                tilesetUid: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['uuid']},
                uid: {$set: uuid4()},
                options: {
                  minHeight: {$set: 0},
                  backgroundColor: {$set: "white"},
                  colorRange: {$set: colorRange},
                  colorLabels: {$set: colorLabels},
                  showMousePosition: {$set: true},
                  valueScaling: {$set: null},
                  valueScaleMin: {$set: 1},
                  valueScaleMax: {$set: valueScaleMax},
                  valueScaleNaN: {$set: valueScaleNaN},
                  zeroValueColor: {$set: "white"},
                  heatmapValueScaling: {$set: "categorical"},
                  colorbarPosition: {$set: null}
                }
              });
              newTopIdx += 1;
              //
              // add 833-sample, 18-state Boix epilogos track
              //
              // newTop[newTopIdx] = update(topClone[0], {
              //   name: {$set: "833sample18StateBoixEtAl"},
              //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
              //   height: {$set: newHgViewTrackDHSComponentsHeight - 10},
              //   type: {$set: "horizontal-stacked-bar"},
              //   tilesetUid: {$set: "NE0f95VjTguQodAljWYmVA"},
              //   uid: {$set: uuid4()},
              //   resolutions: {$set: [13107200, 6553600, 3276800, 1638400, 819200, 409600, 204800, 102400, 51200, 25600, 12800, 6400, 3200, 1600, 800, 400, 200]},
              //   options: {
              //     minHeight: {$set: 0},
              //     backgroundColor: {$set: "black"},
              //     colorScale: {$set: Constants.viewerHgViewconfColormapsPatchedForDuplicates['hg38']['18']},
              //     showMousePosition: {$set: true},
              //     colorbarPosition: {$set: null},
              //     labelPosition: {$set: "topLeft"},
              //     labelColor: {$set: "white"},
              //     labelTextOpacity: {$set: 0},
              //     labelBackgroundOpacity: {$set: 0},
              //     valueScaling: {$set: "exponential"},
              //     trackBorderWidth: {$set: 0},
              //     trackBorderColor: {$set: "black"},
              //     barBorder: {$set: false},
              //     sortLargestOnTop: {$set: true},
              //     name: {$set: "833sample18StateBoixEtAl"},
              //   }
              // });
              // newTopIdx += 1;
              //
              // add chromosome track
              //
              newTop[newTopIdx] = update(topClone[1], {
                server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                height: {$set: newHgViewTrackChromosomeHeight + 10},
                tilesetUid: {$set: newChromsizesUUID},
                uid: {$set: uuid4()},
                options: {
                  backgroundColor: {$set: "white"},
                  showMousePosition: {$set: true},
                }
              });
              newTopIdx += 1;
              //
              // add epilogos-like track (cUvLDpuuSgOsnLWLTmS1dg)
              //
              newTop[newTopIdx] = update(topClone[0], {
                name: {$set: "WM20201125_multiDHS_domains.hg38.16.S1"},
                server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                height: {$set: newHgViewTrackDHSComponentsHeight - 10},
                type: {$set: "horizontal-stacked-bar"},
                tilesetUid: {$set: "du2v51QYR7auH-kmvuwCsQ"}, //"cUvLDpuuSgOsnLWLTmS1dg"},
                uid: {$set: uuid4()},
                resolutions: {$set: [13107200, 6553600, 3276800, 1638400, 819200, 409600, 204800, 102400, 51200, 25600, 12800, 6400, 3200, 1600, 800, 400, 200]},
                options: {
                  minHeight: {$set: 0},
                  colorScale: {$set: Constants.dhsComponentColorsAsHex},
                  showMousePosition: {$set: true},
                  colorbarPosition: {$set: null},
                  labelPosition: {$set: "topLeft"},
                  labelColor: {$set: "white"},
                  labelTextOpacity: {$set: 0},
                  labelBackgroundOpacity: {$set: 0},
                  valueScaling: {$set: "exponential"},
                  trackBorderWidth: {$set: 0},
                  trackBorderColor: {$set: "black"},
                  backgroundColor: {$set: "white"},
                  barBorder: {$set: false},
                  sortLargestOnTop: {$set: true},
                  name: {$set: "WM20201125_multiDHS_domains.hg38.16.S1"},
                }
              });
              newTopIdx += 1;
              //
              // add CTCF binding site track
              //
              newTop[newTopIdx] = update(topClone[2], {
                name: {$set: "CTCF binding sites"},
                server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                height: {$set: newHgViewTrackGeneAnnotationsHeight},
                tilesetUid: {$set: newCTCFBindingSitesUUID},
                uid: {$set: uuid4()},
                options: {
                  backgroundColor: {$set: "white"},
                  plusStrandColor: {$set: "blue"},
                  minusStrandColor: {$set: "orange"},
                  showMousePosition: {$set: true},
                }
              });
              newTopIdx += 1;
              //
              // add Index DHSs
              //
              switch (self.state.hgViewParams.itt) {
                case Constants.applicationIttModes.bb:
                  // bigBed
                  newTop[newTopIdx] = update(topClone[2], {
                    server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                    height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                    type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                    tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                    uid: {$set: uuid4()},
                    options: {
                      minHeight: {$set: 0},
                      backgroundColor: {$set: "white"},
                      isBarPlotLike: {$set: true},
                      itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                      showMousePosition: {$set: true},
                    }
                  });
                  break;
                case 'ht':
                  // higlass-transcripts
                  newTop[newTopIdx] = update(topClone[2], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                    type: {$set: "horizontal-transcripts"},
                    tilesetUid: {$set: "D5k7ajwfT9mzwbybaSS0VA"},
                    uid: {$set: uuid4()},
                    options: {
                      showMousePosition: {$set: true},
                      blockStyle: {$set: "boxplot"},
                      showToggleTranscriptsButton: {$set: false},
                      labelFontSize: {$set: "11"},
                      labelFontWeight: {$set: "500"},
                      maxTexts: {$set: 100},
                      itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                      startCollapsed: {$set: false},
                      trackMargin: {$set: {
                        top: 10,
                        bottom: 10,
                        left: 0,
                        right: 0,
                      }},
                      transcriptHeight: {$set: 16},
                      transcriptSpacing: {$set: 5},
                    }
                  });
                  break;
                default:
                  throw new URIError(`Unknown Index DHS track type (itt) mode ${self.state.hgViewParams.itt}`);
              }
              newTopIdx += 1;
              //
              // add gene annotation track
              //
              newTop[newTopIdx] = update(topClone[2], {
                name: {$set: "Gene annotations"},
                server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                height: {$set: newHgViewTrackGeneAnnotationsHeight},
                tilesetUid: {$set: newGenesUUID},
                uid: {$set: uuid4()},
                //type: {$set: "horizontal-1d-tiles"},
                options: {
                  backgroundColor: {$set: "white"},
                  plusStrandColor: {$set: "black"},
                  minusStrandColor: {$set: "black"},
                  showMousePosition: {$set: true},
                }
              });
              newTopIdx += 1;
              //
              // add chromosome track
              //
              // newTop[newTopIdx] = update(topClone[1], {
              //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
              //   height: {$set: newHgViewTrackChromosomeHeight + 10},
              //   tilesetUid: {$set: newChromsizesUUID},
              //   uid: {$set: uuid4()},
              //   options: {
              //     backgroundColor: {$set: "white"},
              //     showMousePosition: {$set: true},
              //   }
              // });
              // newTopIdx += 1;
              // replace the top view tracks with the newly build track layout
              //console.log("updated newTop", JSON.stringify(newTop, null, 2));
              res.data.views[0].tracks.top = newTop;
              // confirm layout
              // console.log("updated res.data", JSON.stringify(res.data, null, 2));

              // get child view heights
              const childViews = res.data.views[0].tracks.top;
              let childViewHeightTotal = 0;
              childViews.forEach((cv) => { childViewHeightTotal += cv.height });
              childViewHeightTotal += 10;
              let childViewHeightTotalPx = childViewHeightTotal + "px";
              // console.log("childViewHeightTotalPx", childViewHeightTotalPx);
              
              // update Viewer state
              // console.log("Updating mainHgViewconf from new res.data");
              self.setState({
                hgViewParams: newHgViewParams,
                hgViewHeight: childViewHeightTotalPx,
                mainHgViewconf: res.data,
                currentPositionKey: Math.random(),
                currentPosition : {
                  chrLeft : chrLeft,
                  chrRight : chrRight,
                  startLeft : parseInt(start),
                  stopLeft : parseInt(stop),
                  startRight : parseInt(start),
                  stopRight : parseInt(stop)
                },
                selectedProteinCodingsRowIdx: -1,
                selectedTranscriptionFactorsRowIdx: -1,
                selectedRoiRowIdx: -1,
              }, () => {
                self.setState({
                  hgViewKey: self.state.hgViewKey + 1,
                  // drawerContentKey: this.state.drawerContentKey + 1,
                  positionSummaryElementKey: self.state.positionSummaryElementKey + 1,
                  scaleSummaryKey: Math.random(),
                }, () => {
                  // update browser history (address bar URL)
                  console.log("calling [updateViewerURL] from [triggerUpdate]", self.state.hgViewParams.mode);
                  self.updateViewerURL(self.state.hgViewParams.mode,
                                       self.state.currentPosition.chrLeft,
                                       self.state.currentPosition.chrRight,
                                       self.state.currentPosition.startLeft,
                                       self.state.currentPosition.stopRight,
                                       true,
                                       "triggerUpdate (co)",);
                  // add location event handler
                  self.hgView.api.on("location", (event) => { 
                    self.updateViewerLocation(event);
                  });
                })
              })
            }

            if (chromInfoCacheExists) {
              updateViewerStateForCoModeAndChromInfo(this.chromInfoCache[newGenome], this);
            }
            else {
              let chromSizesURL = this.getChromSizesURL(newGenome);
              ChromosomeInfo(chromSizesURL)
                .then((chromInfo) => {
                  this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                  updateViewerStateForCoModeAndChromInfo(chromInfo, this);
                })
                .catch((err) => {
                  let msg = this.errorMessage(err, `Could not retrieve chromosome information`, chromSizesURL);
                  this.setState({
                    overlayMessage: msg,
                    mainHgViewconf: {}
                  }, () => {
                    this.fadeInOverlay();
                  });
                });
            }

          })
          .catch((err) => {
            // console.log(err.response);
            let msg = this.errorMessage(err, `[co] Could not retrieve view configuration`, newHgViewconfURL);
            this.setState({
              overlayMessage: msg,
              mainHgViewconf: {}
            }, () => {
              this.fadeInOverlay();
            });
          });
      }

      //
      // DHS Component track-only mode (200nt fixed bins)
      //  
      else if (newMode === "cf") {
        console.log("newHgViewconfURL", newHgViewconfURL);
        axios.get(newHgViewconfURL)
          .then((res) => {
            if (!res.data) {
              throw new Error("Error: New viewconf not returned from query to " + newHgViewconfURL);
            }
            
            // console.log("old res.data", res.data);
            
            // ensure the template is not editable
            res.data.editable = false;
            
            // update hgview parameters
            newHgViewParams.genome = newGenome;
            newHgViewParams.mode = newMode;
            
            let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
            let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
            let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
            let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
            // console.log("position: ", chrLeft, chrRight, start, stop);
            
            // let valueScaleMax = 17;
            // let valueScaleNaN = valueScaleMax;
            // let colorRange = Constants.dhsComponentColorsAsRgb;
            // let colorLabels = Constants.dhsComponentLabels;
            switch (this.state.hgViewParams.dtc) {
              case "av5": {
                // colorRange = Constants.dhsComponentTwoLevelColorsAsRgb;
                // colorLabels = Constants.dhsComponentTwoLevelLabels;
                // valueScaleMax = 33;
                // valueScaleNaN = 33;
                break;
              }
              default:
                break;
            }

            function updateViewerStateForCfModeAndChromInfo(chromInfo, self) {
              if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                start = Constants.defaultApplicationPositions[newGenome].start;
                stop = Constants.defaultApplicationPositions[newGenome].stop;
              }
              if (start > chromInfo.chromLengths[chrLeft]) {
                start = chromInfo.chromLengths[chrLeft] - 10000;
              }
              if (stop > chromInfo.chromLengths[chrRight]) {
                stop = chromInfo.chromLengths[chrRight] - 1000;
              }
              let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
              let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
              //console.log(chrLeft, start, absLeft);
              //console.log(chrRight, stop, absRight);
              res.data.views[0].initialXDomain = [absLeft, absRight];
              res.data.views[0].initialYDomain = [absLeft, absRight];
              // update track heights -- requires preknowledge of track order from template
              // let windowInnerHeight = document.documentElement.clientHeight + "px";
              //console.log("windowInnerHeight", windowInnerHeight);
              //newHgViewTrackNumSamplesHeight = (this.state.currentViewScale <= this.state.hgViewParams.dtt) ? newHgViewTrackNumSamplesHeight : 0;
              // let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackDHSComponentsHeight) - 2*parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) - Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight + "px";
              //console.log("availableWindowInnerHeight", availableWindowInnerHeight);
              //console.log("heightPerTrack", heightPerTrack);
              // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
              let topClone = update(res.data.views[0].tracks.top, {$push: []});
              //console.log("topClone", topClone);
              // build a new view from pieces of this clone
              let newTop = [];
              let newTopIdx = 0;
              //
              // add chromosome track
              //
              // newTop[newTopIdx] = update(topClone[1], {
              //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
              //   height: {$set: newHgViewTrackChromosomeHeight + 10},
              //   tilesetUid: {$set: newChromsizesUUID},
              //   uid: {$set: uuid4()},
              //   options: {
              //     backgroundColor: {$set: "black"},
              //     showMousePosition: {$set: true},
              //   }
              // });
              // newTopIdx += 1;
              //
              // add all-DHS Component track
              //
              // console.warn(`all-DHS track height ${newHgViewTrackDHSComponentsHeight}`);
              // newTop[newTopIdx] = update(topClone[2], {
              //   name: {$set: "DHS Components"},
              //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
              //   height: {$set: newHgViewTrackDHSComponentsHeight},
              //   type: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['type']},
              //   tilesetUid: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['uuid']},
              //   uid: {$set: uuid4()},
              //   options: {
              //     minHeight: {$set: 0},
              //     backgroundColor: {$set: "white"},
              //     colorRange: {$set: colorRange},
              //     colorLabels: {$set: colorLabels},
              //     showMousePosition: {$set: true},
              //     valueScaling: {$set: null},
              //     valueScaleMin: {$set: 1},
              //     valueScaleMax: {$set: valueScaleMax},
              //     valueScaleNaN: {$set: valueScaleNaN},
              //     zeroValueColor: {$set: "white"},
              //     heatmapValueScaling: {$set: "categorical"},
              //     colorbarPosition: {$set: null}
              //   }
              // });
              // newTopIdx += 1;
              
              
              {
                //
                // add epilogos-like track 
                // (clpKnUPPQwWayiPM6tSIeg, 200bp bin-fixed)
                //
                newTop[newTopIdx] = update(topClone[0], {
                  name: {$set: "WM20201125_multiDHS_domains.hg38.16.S1.fixedBin"},
                  server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  height: {$set: newHgViewTrackDHSComponentsHeight - 10},
                  type: {$set: "horizontal-stacked-bar"},
                  tilesetUid: {$set: "clpKnUPPQwWayiPM6tSIeg"}, // "du2v51QYR7auH-kmvuwCsQ"}, //"cUvLDpuuSgOsnLWLTmS1dg"},
                  uid: {$set: uuid4()},
                  resolutions: {$set: [13107200, 6553600, 3276800, 1638400, 819200, 409600, 204800, 102400, 51200, 25600, 12800, 6400, 3200, 1600, 800, 400, 200]},
                  options: {
                    minHeight: {$set: 0},
                    backgroundColor: {$set: "black"},
                    colorScale: {$set: Constants.dhsComponentColorsAsHex},
                    showMousePosition: {$set: true},
                    colorbarPosition: {$set: null},
                    labelPosition: {$set: "topLeft"},
                    labelColor: {$set: "white"},
                    labelTextOpacity: {$set: 0},
                    labelBackgroundOpacity: {$set: 0},
                    valueScaling: {$set: "exponential"},
                    trackBorderWidth: {$set: 0},
                    trackBorderColor: {$set: "black"},
                    barBorder: {$set: false},
                    sortLargestOnTop: {$set: true},
                    name: {$set: "WM20201125_multiDHS_domains.hg38.16.S1.fixedBin"},
                  }
                });
                newTopIdx += 1;
              }

              {
                //
                // add chromosome track
                //
                newTop[newTopIdx] = update(topClone[1], {
                  server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  height: {$set: newHgViewTrackChromosomeHeight + 10},
                  tilesetUid: {$set: newChromsizesUUID},
                  uid: {$set: uuid4()},
                  options: {
                    backgroundColor: {$set: "white"},
                    showMousePosition: {$set: true},
                  }
                });
                newTopIdx += 1;
              }
              
              {
                //
                // add 833-sample, 18-state Boix epilogos track
                //
                newTop[newTopIdx] = update(topClone[0], {
                  name: {$set: "833sample18StateBoixEtAl"},
                  server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  height: {$set: newHgViewTrackDHSComponentsHeight - 10},
                  type: {$set: "horizontal-stacked-bar"},
                  tilesetUid: {$set: "CXUbWkQ8QOaec_ip0CSiHw"},
                  uid: {$set: uuid4()},
                  resolutions: {$set: [13107200, 6553600, 3276800, 1638400, 819200, 409600, 204800, 102400, 51200, 25600, 12800, 6400, 3200, 1600, 800, 400, 200]},
                  options: {
                    minHeight: {$set: 0},
                    backgroundColor: {$set: "black"},
                    colorScale: {$set: Constants.viewerHgViewconfColormapsPatchedForDuplicates['hg38']['18']},
                    showMousePosition: {$set: true},
                    colorbarPosition: {$set: null},
                    labelPosition: {$set: "topLeft"},
                    labelColor: {$set: "white"},
                    labelTextOpacity: {$set: 0},
                    labelBackgroundOpacity: {$set: 0},
                    valueScaling: {$set: "exponential"},
                    trackBorderWidth: {$set: 0},
                    trackBorderColor: {$set: "black"},
                    barBorder: {$set: false},
                    sortLargestOnTop: {$set: true},
                    name: {$set: "833sample18StateBoixEtAl"},
                  }
                });
                newTopIdx += 1;
              }

              // {
              //   //
              //   // add CTCF binding site track
              //   //
              //   newTop[newTopIdx] = update(topClone[2], {
              //     name: {$set: "CTCF binding sites"},
              //     server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
              //     height: {$set: newHgViewTrackGeneAnnotationsHeight},
              //     tilesetUid: {$set: newCTCFBindingSitesUUID},
              //     uid: {$set: uuid4()},
              //     options: {
              //       backgroundColor: {$set: "white"},
              //       plusStrandColor: {$set: "blue"},
              //       minusStrandColor: {$set: "orange"},
              //       showMousePosition: {$set: true},
              //     }
              //   });
              //   newTopIdx += 1;
              // }

              {
                //
                // add chromosome track
                //
                newTop[newTopIdx] = update(topClone[1], {
                  server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  height: {$set: newHgViewTrackChromosomeHeight + 10},
                  tilesetUid: {$set: newChromsizesUUID},
                  uid: {$set: uuid4()},
                  options: {
                    backgroundColor: {$set: "white"},
                    showMousePosition: {$set: true},
                  }
                });
                newTopIdx += 1;
              }

              {
                //
                // add Index DHSs
                //
                switch (self.state.hgViewParams.itt) {
                  case Constants.applicationIttModes.bb:
                    // bigBed
                    newTop[newTopIdx] = update(topClone[2], {
                      server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                      height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                      type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                      tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                      uid: {$set: uuid4()},
                      options: {
                        minHeight: {$set: 0},
                        backgroundColor: {$set: "white"},
                        isBarPlotLike: {$set: true},
                        itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                        showMousePosition: {$set: true},
                      }
                    });
                    break;
                  case 'ht':
                    // higlass-transcripts
                    newTop[newTopIdx] = update(topClone[2], {
                      server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHiglassTranscriptsHeight},
                      type: {$set: "horizontal-transcripts"},
                      tilesetUid: {$set: "D5k7ajwfT9mzwbybaSS0VA"},
                      uid: {$set: uuid4()},
                      options: {
                        minHeight: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHiglassTranscriptsHeight + 20},
                        showMousePosition: {$set: true},
                        blockStyle: {$set: "boxplot"},
                        showToggleTranscriptsButton: {$set: false},
                        labelFontSize: {$set: "11"},
                        labelFontWeight: {$set: "500"},
                        maxTexts: {$set: 100},
                        itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                        startCollapsed: {$set: false},
                        trackMargin: {$set: {
                          top: 10,
                          bottom: 10,
                          left: 0,
                          right: 0,
                        }},
                        transcriptHeight: {$set: 16},
                        transcriptSpacing: {$set: 5},
                        allowResizeParentDiv: {$set: false},
                      }
                    });
                    break;
                  default:
                    throw new URIError(`Unknown Index DHS track type (itt) mode ${self.state.hgViewParams.itt}`);
                }
                newTopIdx += 1;
              }
              {
                //
                // add gene annotation track
                //
                switch (self.state.hgViewParams.itt) {
                  case Constants.applicationIttModes.bb:
                    newTop[newTopIdx] = update(topClone[2], {
                      server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      height: {$set: newHgViewTrackGeneAnnotationsHeight},
                      tilesetUid: {$set: newGenesUUID},
                      uid: {$set: uuid4()},
                      options: {
                        backgroundColor: {$set: "white"},
                        plusStrandColor: {$set: "black"},
                        minusStrandColor: {$set: "black"},
                        showMousePosition: {$set: true},
                      }
                    });
                    break;
                  case 'ht':
                    // higlass-transcripts
                    newTop[newTopIdx] = update(topClone[2], {
                      server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      height: {$set: Constants.viewerHgViewParameters.hgViewTrackGeneAnnotationsHiglassTranscriptsHeight},
                      type: {$set: "horizontal-transcripts"},
                      tilesetUid: {$set: newGenesUUID},
                      uid: {$set: uuid4()},
                      options: {
                        minHeight: {$set: Constants.viewerHgViewParameters.hgViewTrackGeneAnnotationsHiglassTranscriptsHeight + 20},
                        backgroundColor: {$set: "white"},
                        plusStrandColor: {$set: "grey"},
                        minusStrandColor: {$set: "grey"},
                        utrColor: {$set: "grey"},
                        blockStyle: {$set: "UCSC-like"},
                        showToggleTranscriptsButton: {$set: false},
                        startCollapsed: {$set: false},
                        labelFontSize: {$set: "11"},
                        labelFontWeight: {$set: "500"},
                        maxTexts: {$set: 100},
                        trackMargin: {$set: {
                          top: 10,
                          bottom: 10,
                          left: 0,
                          right: 0,
                        }},
                        transcriptHeight: {$set: 16},
                        transcriptSpacing: {$set: 5},
                        isVisible: {$set: true},
                        showMousePosition: {$set: true},
                        highlightTranscriptType: {$set: "longestIsoform"},
                      }
                    });
                    break;
                  default:
                    break;
                }
                newTopIdx += 1;
              }
              
              // newTop[newTopIdx] = update(topClone[2], {
              //   name: {$set: "Gene annotations"},
              //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
              //   height: {$set: newHgViewTrackGeneAnnotationsHeight},
              //   tilesetUid: {$set: newGenesUUID},
              //   uid: {$set: uuid4()},
              //   //type: {$set: "horizontal-1d-tiles"},
              //   options: {
              //     backgroundColor: {$set: "white"},
              //     plusStrandColor: {$set: "black"},
              //     minusStrandColor: {$set: "black"},
              //     showMousePosition: {$set: true},
              //   }
              // });
              // newTopIdx += 1;
              //
              // add chromosome track
              //
              // newTop[newTopIdx] = update(topClone[1], {
              //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
              //   height: {$set: newHgViewTrackChromosomeHeight + 10},
              //   tilesetUid: {$set: newChromsizesUUID},
              //   uid: {$set: uuid4()},
              //   options: {
              //     backgroundColor: {$set: "white"},
              //     showMousePosition: {$set: true},
              //   }
              // });
              // newTopIdx += 1;
              // replace the top view tracks with the newly build track layout
              //console.log("updated newTop", JSON.stringify(newTop, null, 2));
              res.data.views[0].tracks.top = newTop;
              // confirm layout
              //console.log("updated res.data", JSON.stringify(res.data, null, 2));

              // get child view heights
              const childViews = res.data.views[0].tracks.top;
              let childViewHeightTotal = 0;
              childViews.forEach((cv) => { childViewHeightTotal += cv.height });
              childViewHeightTotal += 10;
              let childViewHeightTotalPx = childViewHeightTotal + "px";
              //console.log("childViewHeightTotalPx", childViewHeightTotalPx);
              
              // update Viewer state
              //console.log("Updating mainHgViewconf from new res.data");
              self.setState({
                hgViewParams: newHgViewParams,
                hgViewHeight: childViewHeightTotalPx,
                mainHgViewconf: res.data,
                currentPositionKey: Math.random(),
                currentPosition : {
                  chrLeft : chrLeft,
                  chrRight : chrRight,
                  startLeft : parseInt(start),
                  stopLeft : parseInt(stop),
                  startRight : parseInt(start),
                  stopRight : parseInt(stop)
                },
                selectedProteinCodingsRowIdx: -1,
                selectedTranscriptionFactorsRowIdx: -1,
                selectedRoiRowIdx: -1,
              }, () => {
                self.setState({
                  hgViewKey: self.state.hgViewKey + 1,
                  //drawerContentKey: this.state.drawerContentKey + 1,
                  positionSummaryElementKey: self.state.positionSummaryElementKey + 1,
                  scaleSummaryKey: Math.random(),
                }, () => {
                  // update browser history (address bar URL)
                  console.log("calling [updateViewerURL] from [triggerUpdate]", self.state.hgViewParams.mode);
                  self.updateViewerURL(self.state.hgViewParams.mode,
                                       self.state.currentPosition.chrLeft,
                                       self.state.currentPosition.chrRight,
                                       self.state.currentPosition.startLeft,
                                       self.state.currentPosition.stopRight,
                                       true,
                                       "triggerUpdate (cf)",);
                  // add location event handler
                  self.hgView.api.on("location", (event) => { 
                    self.updateViewerLocation(event);
                  });
                })
              })
            }

            if (chromInfoCacheExists) {
              updateViewerStateForCfModeAndChromInfo(this.chromInfoCache[newGenome], this);
            }
            else {
              let chromSizesURL = this.getChromSizesURL(newGenome);
              ChromosomeInfo(chromSizesURL)
                .then((chromInfo) => {
                  this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                  updateViewerStateForCfModeAndChromInfo(chromInfo, this);
                })
                .catch((err) => {
                  let msg = this.errorMessage(err, `Could not retrieve chromosome information`, chromSizesURL);
                  this.setState({
                    overlayMessage: msg,
                    mainHgViewconf: {}
                  }, () => {
                    this.fadeInOverlay();
                  });
                });
            }

          })
          .catch((err) => {
            //console.log(err.response);
            let msg = this.errorMessage(err, `[cf] Could not retrieve view configuration`, newHgViewconfURL);
            this.setState({
              overlayMessage: msg,
              mainHgViewconf: {}
            }, () => {
              this.fadeInOverlay();
            });
          });
      }

      //
      // per-component (multivec) mode
      //
      else if (newMode === "pm") {
        // get per-component multivec item
        let samplesToRenderAsTracks = [];
        let samplePromises = [];

        // Object.keys(Constants.allComponent).forEach((d) => {
        //   if (Constants.allComponent[d].active) {
        //     let o = Constants.allComponent[d];
        //     o['name'] = d;
        //     o['filename'] = `${d}.multires.mv5`
        //     samplesToRenderAsTracks.push(o);
        //     let endpoint = Constants.applicationHiGlassServerEndpointRootURL;
        //     samplePromises.push(uuidQueryPromise(o['filename'], endpoint, this));
        //   }
        // });

        let pmSample = {
          "name" : "WM20181113_signal_track_per_component.hg38.multires.mv5",
          "filename" : "WM20181113_signal_track_per_component.hg38.multires.mv5",
          "componentIndex": 1,
          "samples": 1,
          "active": true,
          "longName": "All samples",
          "uuid": "HlXADoAsQt6tTm-NkrhWgw",
        };
        samplesToRenderAsTracks.push(pmSample);
        let endpoint = Constants.applicationHiGlassServerEndpointRootURL;
        samplePromises.push(uuidQueryPromise(pmSample["filename"], endpoint, this));
      
        //console.log("samplesToRenderAsTracks", samplesToRenderAsTracks);
        Promise.all(samplePromises).then(() => {
            // let uuid = {};
            // v.forEach((d) => {
            //   uuid[d.fn] = d.uuid;
            // });
            // samplesToRenderAsTracks.forEach((d) => {
            //   d['uuid'] = uuid[d['filename']];
            // });
            // console.log(samplesToRenderAsTracks);
            let samplesToBeRendered = samplesToRenderAsTracks.reduce((d, c) => { return parseInt(d + c.samples) }, 0);
            // console.log("samplesToBeRendered", samplesToBeRendered);
            // console.log("newHgViewconfURL", newHgViewconfURL);
            axios.get(newHgViewconfURL)
              .then((res) => {
                if (!res.data) {
                  throw new Error("Error: New viewconf not returned from query to " + newHgViewconfURL);
                }
                
                // console.log("old res.data", res.data);
                
                // ensure the template is not editable
                res.data.editable = false;
                
                // update hgview parameters
                newHgViewParams.genome = newGenome;
                newHgViewParams.mode = newMode;
                
                let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
                let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
                let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
                let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
                //console.log("position: ", chrLeft, chrRight, start, stop);

                function updateViewerStateForPmModeAndChromInfo(chromInfo, self) {
                  if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                    chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                    chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                    start = Constants.defaultApplicationPositions[newGenome].start;
                    stop = Constants.defaultApplicationPositions[newGenome].stop;
                  }
                  if (start > chromInfo.chromLengths[chrLeft]) {
                    start = chromInfo.chromLengths[chrLeft] - 10000;
                  }
                  if (stop > chromInfo.chromLengths[chrRight]) {
                    stop = chromInfo.chromLengths[chrRight] - 1000;
                  }
                  let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
                  let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
                  // console.log("chrLeft, start, absLeft", chrLeft, start, absLeft);
                  // console.log("chrRight, stop, absRight", chrRight, stop, absRight);
                  res.data.views[0].initialXDomain = [absLeft, absRight];
                  res.data.views[0].initialYDomain = [absLeft, absRight];
                  // update track heights -- requires preknowledge of track order from template
                  let windowInnerHeight = document.documentElement.clientHeight + "px";
                  // console.log("windowInnerHeight", windowInnerHeight);
                  // console.log("newHgViewTrackNumSamplesHeight", newHgViewTrackNumSamplesHeight);
                  //let availableWindowInnerHeight = parseInt(windowInnerHeight) - newHgViewTrackChromosomeHeight - newHgViewTrackIndexAnnotationsHeight - newHgViewTrackNumSamplesHeight - newHgViewTrackGeneAnnotationsHeight - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                  let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackNumSamplesHeight) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackDHSComponentsHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                  // console.log("availableWindowInnerHeight", availableWindowInnerHeight);
                  let heightPerSample = parseFloat(parseInt(availableWindowInnerHeight) / samplesToBeRendered);
                  // console.log("heightPerSample", heightPerSample);
                  
                  // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
                  let topClone = update(res.data.views[0].tracks.top, {$push: []});
                  // console.log("topClone", topClone);

                  // build a new view from pieces of this clone
                  let newTop = [];
                  let newTopIdx = 0;

                  samplesToRenderAsTracks.forEach((sampleToRender) => {
                    // console.log("sampleToRender", sampleToRender);
                    newTop[newTopIdx] = update(topClone[0], {
                      server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      height: {$set: parseInt(sampleToRender.samples * heightPerSample)},
                      name: {$set: "16ComponentStackedDelta"},
                      /* type: {$set: "horizontal-multivec"}, */
                      /* type: {$set: "basic-multiple-bar-chart"}, */
                      type: {$set: "horizontal-stacked-delta-bar"},
                      /* type: {$set: "horizontal-stacked-bar"}, */
                      // minHeight: {$set: 1},
                      // resolutions: {$set: Constants.componentResolutions},
                      options: {
                        minHeight: {$set: 0},
                        backgroundColor: {$set: "black"},
                        // colorRange: {$set: Constants.componentColorMapGreyscale},
                        colorRange: {$set: Constants.componentColorMapViridisV1},
                        colorScale: {$set: Constants.systemColorPalettesAsHex['hg38']['16']},
                        labelPosition: {$set: "topLeft"},
                        labelTextOpacity: {$set: 0.0},
                        labelBackgroundOpacity: {$set: 0.0},
                        labelColor: {$set: "black"},
                        valueScaling: {$set: "linear"},
                        valueScaleMin: {$set: 0.0},
                        valueScaleMax: {$set: 4.0},
                        name: {$set: sampleToRender.longName},
                        showMousePosition: {$set: true},
                        colorbarPosition: {$set: null},
                        trackBorderWidth: {$set: 0},
                        trackBorderColor: {$set: "black"},
                        barBorder: {$set: false},
                        sortLargestOnTop: {$set: true},
                        fillOpacityMin: {$set: 0.2},
                        fillOpacityMax: {$set: 0.8},
                      },
                      tilesetUid: {$set: sampleToRender.uuid},
                      uid: {$set: uuid4()},
                      resolutions: {$set: [13107200, 6553600, 3276800, 1638400, 819200, 409600, 204800, 102400, 51200, 25600, 12800, 6400, 3200, 1600, 800, 400, 200]},
                    });
                    newTopIdx += 1;
                  })

                  // console.log("post-samples-to-render newTop", newTop);

                  //
                  // add chromosome track
                  //
                  newTop[newTopIdx] = update(topClone[1], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    height: {$set: newHgViewTrackChromosomeHeight + 10},
                    tilesetUid: {$set: newChromsizesUUID},
                    uid: {$set: uuid4()},
                    options: {
                      backgroundColor: {$set: "white"},
                      showMousePosition: {$set: true},
                    }
                  });
                  newTopIdx += 1;

                  // console.log("post-chromosome-track newTop", newTop);

                  //
                  // add numSamples track
                  //
                  let numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamples];
                  // console.log(`numSamplesUUID ${numSamplesUUID}`);
                  // console.log(`self.newHgViewParams ${JSON.stringify(self.newHgViewParams, null, 2)}`);

                  if (self.newHgViewParams && self.newHgViewParams.itt === "ht") {
                    numSamplesUUID = Constants.viewerHgViewconfFixedBinNumSamplesUUIDs[newNumSamples];
                    if (!numSamplesUUID) {
                      numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamples];
                    }
                  }

                  let numSamplesTrackType = Constants.viewerHgViewconfNumSamplesTrackType;
                  let numSamplesValueScaleMin = Constants.viewerHgViewconfNumSamplesValueScaleMin[newNumSamples];
                  let numSamplesValueScaleMax = Constants.viewerHgViewconfNumSamplesValueScaleMax[newNumSamples];
                  newTop[newTopIdx] = update(topClone[2], {
                    server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                    height: {$set: newHgViewTrackNumSamplesHeight},
                    type: {$set: numSamplesTrackType},
                    tilesetUid: {$set: numSamplesUUID},
                    uid: {$set: uuid4()},
                    options: {
                      minHeight: {$set: 0},
                      backgroundColor: {$set: "rgb(242,242,242)"},
                      axisLabelFormatting: {$set: "normal"},
                      axisPositionHorizontal: {$set: "outsideRight"},
                      barFillColor: {$set: "black"},
                      pointColor: {$set: "black"},
                      pointSize: {$set: 4},
                      zeroLineVisible: {$set: true},
                      zeroLineColor: {$set: "black"},
                      zeroLineOpacity: {$set: 0.0},
                      valueScaling: {$set: "log"},
                      valueScaleMin: {$set: numSamplesValueScaleMin},
                      valueScaleMax: {$set: numSamplesValueScaleMax},
                      valueFormat: {$set: "d"},
                      aggregationMode: {$set: "median"},
                      showTooltip: {$set: true},
                      showMousePosition: {$set: true}
                    }
                  });
                  newTopIdx += 1;

                  // console.log("post-num-samples newTop", newTop);

                  //
                  // add BED12 Index element
                  //
                  switch (self.state.hgViewParams.itt) {
                    case Constants.applicationIttModes.bb: {
                      // bigBed
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                        height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                        type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                        tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                        uid: {$set: uuid4()},
                        options: {
                          minHeight: {$set: 0},
                          backgroundColor: {$set: "white"},
                          isBarPlotLike: {$set: true},
                          itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                          showMousePosition: {$set: true},
                        }
                      });
                      break;
                    }
                    case 'ht': {
                      // higlass-transcripts
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                        type: {$set: "horizontal-transcripts"},
                        tilesetUid: {$set: "D5k7ajwfT9mzwbybaSS0VA"},
                        uid: {$set: uuid4()},
                        options: {
                          showMousePosition: {$set: true},
                          blockStyle: {$set: "boxplot"},
                          showToggleTranscriptsButton: {$set: false},
                          labelFontSize: {$set: "11"},
                          labelFontWeight: {$set: "500"},
                          maxTexts: {$set: 100},
                          itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                          startCollapsed: {$set: false},
                          trackMargin: {$set: {
                            top: 10,
                            bottom: 10,
                            left: 0,
                            right: 0,
                          }},
                          transcriptHeight: {$set: 16},
                          transcriptSpacing: {$set: 5},
                          isVisible: {$set: true},
                        }
                      });
                      break;
                    }
                    default:
                      throw new URIError(`Unknown Index DHS track type (itt) mode ${self.state.hgViewParams.itt}`);
                  }
                  newTopIdx += 1;
                  // console.warn(`Adding DHS index track... ${this.state.currentViewScale} vs ${this.state.hgViewParams.dtt}`);
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackIndexDHSHeight},
                  //   type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                  //   tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     minHeight: {$set: 0},
                  //     backgroundColor: {$set: "white"},
                  //     isBarPlotLike: {$set: true},
                  //     itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                  //     showMousePosition: {$set: true},
                  //   }
                  // });
                  // newTopIdx += 1;
                  //
                  // add all-DHS Component track
                  //
                  // console.warn(`all-DHS track height ${newHgViewTrackDHSComponentsHeight}`);
                  newTop[newTopIdx] = update(topClone[2], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    height: {$set: newHgViewTrackDHSComponentsHeight},
                    type: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['type']},
                    tilesetUid: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['uuid']},
                    uid: {$set: uuid4()},
                    options: {
                      minHeight: {$set: 0},
                      backgroundColor: {$set: "white"},
                      colorRange: {$set: Constants.dhsComponentColorsAsRgb},
                      colorLabels: {$set: Constants.dhsComponentLabels},
                      showMousePosition: {$set: true},
                      valueScaling: {$set: null},
                      valueScaleMin: {$set: 1},
                      valueScaleMax: {$set: 17},
                      valueScaleNaN: {$set: 17},
                      zeroValueColor: {$set: "white"},
                      heatmapValueScaling: {$set: "categorical"},
                      colorbarPosition: {$set: null}
                    }
                  });
                  newTopIdx += 1;

                  // console.log("post-dhs-component newTop", newTop);

                  //
                  // add gene annotation track
                  //
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackGeneAnnotationsHeight},
                  //   tilesetUid: {$set: newGenesUUID},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     backgroundColor: {$set: "white"},
                  //     plusStrandColor: {$set: "black"},
                  //     minusStrandColor: {$set: "black"},
                  //     showMousePosition: {$set: true},
                  //   }
                  // });
                  newTop[newTopIdx] = update(topClone[2], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    height: {$set: newHgViewTrackGeneAnnotationsHeight * 2},
                    type: {$set: "horizontal-transcripts"},
                    //tilesetUid: {$set: "a8079g0hSweKXxaaFIMayA"},
                    tilesetUid: {$set: "KPNgc7HhS4ilhcv0Cr95vg"},
                    uid: {$set: uuid4()},
                    options: {
                      maxRows: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsDesktopTrackMaxRows},
                      minHeight: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsDesktopTrackMinHeight},
                      showMousePosition: {$set: true},
                      startCollapsed: {$set: false},
                      // blockStyle: {$set: "UCSC-like"},
                      blockStyle: {$set: "directional"},
                      // highlightTranscriptType: {$set: "longestIsoform"},
                      // highlightTranscriptTrackBackgroundColor: {$set: "#fdfdcf"},
                      // showToggleTranscriptsButton: {$set: true},
                      showToggleTranscriptsButton: {$set: false},
                      utrColor: {$set: "grey"},
                      plusStrandColor: {$set: "#111111"},
                      minusStrandColor: {$set: "#111111"},
                    }
                  });

                  // console.log("post-gene-annotation newTop", newTop);

                  // replace the top view tracks with the newly build track layout
                  // console.log("updated newTop", JSON.stringify(newTop, null, 2));
                  res.data.views[0].tracks.top = newTop;
                  // confirm layout
                  // console.log("updated res.data", JSON.stringify(res.data, null, 2));

                  // get child view heights
                  const childViews = res.data.views[0].tracks.top;
                  let childViewHeightTotal = 0;
                  childViews.forEach((cv) => { childViewHeightTotal += cv.height });
                  childViewHeightTotal += 10;
                  let childViewHeightTotalPx = childViewHeightTotal + "px";
                  // console.log("childViewHeightTotalPx", childViewHeightTotalPx);
                  
                  // update Viewer state
                  // console.log("Updating mainHgViewconf from res.data");
                  self.setState({
                    hgViewParams: newHgViewParams,
                    hgViewHeight: childViewHeightTotalPx,
                    mainHgViewconf: res.data,
                    currentPositionKey: Math.random(),
                    currentPosition : {
                      chrLeft : chrLeft,
                      chrRight : chrRight,
                      startLeft : parseInt(start),
                      stopLeft : parseInt(stop),
                      startRight : parseInt(start),
                      stopRight : parseInt(stop)
                    },
                    selectedProteinCodingsRowIdx: -1,
                    selectedTranscriptionFactorsRowIdx: -1,
                    selectedRoiRowIdx: -1,
                  }, () => {
                    self.setState({
                      hgViewKey: self.state.hgViewKey + 1,
                      drawerContentKey: self.state.drawerContentKey + 1,
                    }, () => {
                      // update browser history (address bar URL)
                      console.log("calling [updateViewerURL] from [triggerUpdate]", self.state.hgViewParams.mode);
                      self.updateViewerURL(self.state.hgViewParams.mode,
                                           self.state.currentPosition.chrLeft,
                                           self.state.currentPosition.chrRight,
                                           self.state.currentPosition.startLeft,
                                           self.state.currentPosition.stopRight,
                                           true,
                                           "triggerUpdate (pm)",);
                      // add location event handler
                      self.hgView.api.on("location", (event) => { 
                        self.updateViewerLocation(event);
                      });

                      setTimeout(() => {
                        const indexDHSTrackObj = self.hgView.api.getComponent().getTrackObject(
                          self.state.mainHgViewconf.views[0].uid,
                          self.state.mainHgViewconf.views[0].tracks.top[3].uid,
                        );
                        const geneAnnotationTrackObj = self.hgView.api.getComponent().getTrackObject(
                          self.state.mainHgViewconf.views[0].uid,
                          self.state.mainHgViewconf.views[0].tracks.top[5].uid,
                        );
                        // eslint-disable-next-line no-unused-vars
                        indexDHSTrackObj.pubSub.subscribe("trackDimensionsModified", (msg) => { 
                          if (parseInt(indexDHSTrackObj.trackHeight) !== self.state.indexDHSTrackHeight) {
                            self.setState({
                              indexDHSTrackHeight: parseInt(indexDHSTrackObj.trackHeight),
                            }, () => {
                              //console.log(`indexDHSTrackObj trackDimensionsModified event sent ${self.state.indexDHSTrackHeight}px`);
                              self.updateViewportDimensions();
                              indexDHSTrackObj.pubSub.unsubscribe("trackDimensionsModified");
                            });
                          }
                        });
                        // eslint-disable-next-line no-unused-vars
                        geneAnnotationTrackObj.pubSub.subscribe("trackDimensionsModified", (msg) => { 
                          if (parseInt(geneAnnotationTrackObj.trackHeight) !== self.state.geneAnnotationTrackHeight) {
                            self.setState({
                              geneAnnotationTrackHeight: parseInt(geneAnnotationTrackObj.trackHeight),
                            }, () => {
                              //console.log(`geneAnnotationTrackObj trackDimensionsModified event sent ${self.state.geneAnnotationTrackHeight}px`);
                              self.updateViewportDimensions();
                              geneAnnotationTrackObj.pubSub.unsubscribe("trackDimensionsModified");
                            });
                          }
                        });
                      }, 500);
                    })
                  })
                }

                if (chromInfoCacheExists) {
                  updateViewerStateForPmModeAndChromInfo(this.chromInfoCache[newGenome], this);
                }
                else {
                  let chromSizesURL = this.getChromSizesURL(newGenome);
                  ChromosomeInfo(chromSizesURL)
                    .then((chromInfo) => {
                      this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                      updateViewerStateForPmModeAndChromInfo(chromInfo, this);
                    })
                    .catch((err) => {
                      let msg = this.errorMessage(err, `Could not retrieve chromosome information`, chromSizesURL);
                      this.setState({
                        overlayMessage: msg,
                        mainHgViewconf: {}
                      }, () => {
                        this.fadeInOverlay();
                      });
                    });
                }
              
              })
              .catch((err) => {
                // console.log(err.response);
                let msg = this.errorMessage(err, `[pm] Could not retrieve view configuration`, newHgViewconfURL);
                this.setState({
                  overlayMessage: msg,
                  mainHgViewconf: {}
                }, () => {
                  this.fadeInOverlay();
                });
              });
          })
          .catch((err) => {
            throw new Error(`Could not retrieve UUIDs for all specified components - ${JSON.stringify(err)}`);
            // console.log("Could not retrieve UUIDs for all specified components");
          });
      }

      //
      // per-component (multivec) mode, destacked bar style
      //
      else if (newMode === "pd") {
        // get per-component multivec item
        let samplesToRenderAsTracks = [];
        let samplePromises = [];

        // Object.keys(Constants.allComponent).forEach((d) => {
        //   if (Constants.allComponent[d].active) {
        //     let o = Constants.allComponent[d];
        //     o['name'] = d;
        //     o['filename'] = `${d}.multires.mv5`
        //     samplesToRenderAsTracks.push(o);
        //     let endpoint = Constants.applicationHiGlassServerEndpointRootURL;
        //     samplePromises.push(uuidQueryPromise(o['filename'], endpoint, this));
        //   }
        // });

        const pdSample = {
          "name" : "WM20181113_signal_track_per_component.hg38.multires.mv5",
          "filename" : "WM20181113_signal_track_per_component.hg38.multires.mv5",
          "componentIndex": 1,
          "samples": 1,
          "active": true,
          "longName": "All samples",
          "uuid": "HlXADoAsQt6tTm-NkrhWgw",
        };
        samplesToRenderAsTracks.push(pdSample);
        let endpoint = Constants.applicationHiGlassServerEndpointRootURL;
        samplePromises.push(uuidQueryPromise(pdSample["filename"], endpoint, this));
      
        //console.log("samplesToRenderAsTracks", samplesToRenderAsTracks);
        Promise.all(samplePromises).then(() => {
            // let uuid = {};
            // v.forEach((d) => {
            //   uuid[d.fn] = d.uuid;
            // });
            // samplesToRenderAsTracks.forEach((d) => {
            //   d['uuid'] = uuid[d['filename']];
            // });
            // console.log(samplesToRenderAsTracks);
            let samplesToBeRendered = samplesToRenderAsTracks.reduce((d, c) => { return parseInt(d + c.samples) }, 0);
            // console.log("samplesToBeRendered", samplesToBeRendered);
            // console.log("newHgViewconfURL", newHgViewconfURL);
            axios.get(newHgViewconfURL)
              .then((res) => {
                if (!res.data) {
                  throw String("Error: New viewconf not returned from query to " + newHgViewconfURL);
                }
                
                // console.log("old res.data", res.data);
                
                // ensure the template is not editable
                res.data.editable = false;
                
                // update hgview parameters
                newHgViewParams.genome = newGenome;
                newHgViewParams.mode = newMode;
                
                let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
                let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
                let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
                let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
                //console.log("position: ", chrLeft, chrRight, start, stop);

                const updateViewerStateForPdModeAndChromInfo = function(chromInfo, self) {
                  if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                    chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                    chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                    start = Constants.defaultApplicationPositions[newGenome].start;
                    stop = Constants.defaultApplicationPositions[newGenome].stop;
                  }
                  if (start > chromInfo.chromLengths[chrLeft]) {
                    start = chromInfo.chromLengths[chrLeft] - 10000;
                  }
                  if (stop > chromInfo.chromLengths[chrRight]) {
                    stop = chromInfo.chromLengths[chrRight] - 1000;
                  }
                  let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
                  let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
                  // console.log("chrLeft, start, absLeft", chrLeft, start, absLeft);
                  // console.log("chrRight, stop, absRight", chrRight, stop, absRight);
                  res.data.views[0].initialXDomain = [absLeft, absRight];
                  res.data.views[0].initialYDomain = [absLeft, absRight];
                  // update track heights -- requires preknowledge of track order from template
                  let windowInnerHeight = document.documentElement.clientHeight + "px";
                  // console.log("windowInnerHeight", windowInnerHeight);
                  // console.log("newHgViewTrackNumSamplesHeight", newHgViewTrackNumSamplesHeight);
                  //let availableWindowInnerHeight = parseInt(windowInnerHeight) - newHgViewTrackChromosomeHeight - newHgViewTrackIndexAnnotationsHeight - newHgViewTrackNumSamplesHeight - newHgViewTrackGeneAnnotationsHeight - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                  // let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackNumSamplesHeight) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackDHSComponentsHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                  // let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackNumSamplesHeight) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";

                  let availableWindowInnerHeight = parseInt(windowInnerHeight) 
                    - parseInt(newHgViewTrackChromosomeHeight) 
                    - parseInt(newHgViewTrackIndexDHSHeight) 
                    - parseInt(newHgViewTrackGeneAnnotationsHeight) 
                    - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) 
                    - parseInt(Constants.applicationViewerHgViewPaddingTop) 
                    - parseInt(Constants.applicationViewerHgViewPaddingBottom) + "px";
                  
                  // console.log(`----`);
                  // console.log(`availableWindowInnerHeight ${availableWindowInnerHeight}`);
                  // console.log(`newHgViewTrackChromosomeHeight ${newHgViewTrackChromosomeHeight}`);
                  // console.log(`newHgViewTrackIndexDHSHeight ${newHgViewTrackIndexDHSHeight}`);
                  // console.log(`newHgViewTrackGeneAnnotationsHeight ${newHgViewTrackGeneAnnotationsHeight}`);
                  // console.log(`----`);

                  let heightPerSample = parseFloat(parseInt(availableWindowInnerHeight) / samplesToBeRendered);
                  // console.log("heightPerSample", heightPerSample);
                  
                  // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
                  let topClone = update(res.data.views[0].tracks.top, {$push: []});
                  //console.log("topClone", topClone);

                  // build a new view from pieces of this clone
                  let newTop = [];
                  let newTopIdx = 0;

                  // if (this.state.hgViewParams.gmax && (this.state.hgViewParams.gmax !== Constants.defaultApplicationSignalGlobalMax)) {

                  samplesToRenderAsTracks.forEach((sampleToRender) => {
                    // console.log("sampleToRender", sampleToRender);
                    newTop[newTopIdx] = update(topClone[0], {
                      server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      height: {$set: parseInt(sampleToRender.samples * heightPerSample)},
                      name: {$set: "16ComponentBasicMultipleBarChart"},
                      /* type: {$set: "horizontal-multivec"}, */
                      type: {$set: "basic-multiple-bar-chart"},
                      /* type: {$set: "horizontal-stacked-delta-bar"}, */
                      /* type: {$set: "horizontal-stacked-bar"}, */
                      // minHeight: {$set: 1},
                      // resolutions: {$set: Constants.componentResolutions},
                      options: {
                        minHeight: {$set: 0},
                        backgroundColor: {$set: "black"},
                        colorScale: {$set: Constants.systemColorPalettesAsHex['hg38']['16']},
                        labelPosition: {$set: "topLeft"},
                        labelTextOpacity: {$set: 0.0},
                        labelBackgroundOpacity: {$set: 0.0},
                        labelColor: {$set: "white"},
                        valueScaleMin: {$set: 0.0},
                        valueScaleMax: {$set: parseFloat(self.state.hgViewParams.gmax)},
                        name: {$set: sampleToRender.longName},
                        valueScaling: {$set: "linear"},
                        // colorRange: {$set: Constants.componentColorMapGreyscale},
                        colorRange: {$set: Constants.componentColorMapViridisV1},
                        showMousePosition: {$set: true},
                        colorbarPosition: {$set: null},
                        trackBorderWidth: {$set: 0},
                        trackBorderColor: {$set: "black"},
                        barBorder: {$set: false},
                        sortLargestOnTop: {$set: true},
                        // name: {$set: "16ComponentBasicMultipleBarChart"},
                        chromInfo: {$set: self.chromInfoCache[newGenome]},
                        binSize: {$set: 20},
                      },
                      tilesetUid: {$set: sampleToRender.uuid},
                      uid: {$set: uuid4()},
                      resolutions: {$set: [13107200, 6553600, 3276800, 1638400, 819200, 409600, 204800, 102400, 51200, 25600, 12800, 6400, 3200, 1600, 800, 400, 200]},
                    });
                    newTopIdx += 1;
                  })

                  // console.log("post-samples-to-render newTop", newTop);

                  //
                  // add chromosome track
                  //
                  newTop[newTopIdx] = update(topClone[1], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    height: {$set: newHgViewTrackChromosomeHeight + 10},
                    tilesetUid: {$set: newChromsizesUUID},
                    uid: {$set: uuid4()},
                    options: {
                      backgroundColor: {$set: "white"},
                      showMousePosition: {$set: true},
                    }
                  });
                  newTopIdx += 1;

                  // console.log("> post-chromosome-track newTop", newTop);

                  //
                  // add numSamples track
                  //
                  // let numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamples];
                  // let numSamplesServerEndpointRootURL = Constants.applicationHiGlassDevServerEndpointRootURL;
                  // console.log(`numSamplesUUID ${numSamplesUUID}`);
                  // console.log(`self.newHgViewParams ${JSON.stringify(self.newHgViewParams, null, 2)}`);

                  // if (self.state.hgViewParams.itt === Constants.applicationIttModes.ht) {
                    // numSamplesUUID = Constants.viewerHgViewconfFixedBinNumSamplesUUIDs[newNumSamples];
                    // numSamplesServerEndpointRootURL = Constants.applicationHiGlassServerEndpointRootURL;
                  // }

                  // let numSamplesTrackType = Constants.viewerHgViewconfNumSamplesTrackType;
                  // let numSamplesValueScaleMin = Constants.viewerHgViewconfNumSamplesValueScaleMin[newNumSamples];
                  // let numSamplesValueScaleMax = Constants.viewerHgViewconfNumSamplesValueScaleMax[newNumSamples];
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: numSamplesServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackNumSamplesHeight},
                  //   type: {$set: numSamplesTrackType},
                  //   tilesetUid: {$set: numSamplesUUID},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     minHeight: {$set: 0},
                  //     backgroundColor: {$set: "rgb(242,242,242)"},
                  //     axisLabelFormatting: {$set: "normal"},
                  //     axisPositionHorizontal: {$set: "outsideRight"},
                  //     barFillColor: {$set: "black"},
                  //     pointColor: {$set: "black"},
                  //     pointSize: {$set: 4},
                  //     zeroLineVisible: {$set: true},
                  //     zeroLineColor: {$set: "black"},
                  //     zeroLineOpacity: {$set: 0.0},
                  //     valueScaling: {$set: "log"},
                  //     valueScaleMin: {$set: numSamplesValueScaleMin},
                  //     valueScaleMax: {$set: numSamplesValueScaleMax},
                  //     valueFormat: {$set: "d"},
                  //     aggregationMode: {$set: "median"},
                  //     showTooltip: {$set: true},
                  //     showMousePosition: {$set: true}
                  //   }
                  // });
                  // newTopIdx += 1;

                  // console.log("post-num-samples newTop", newTop);

                  //
                  // add BED12 Index element
                  //
                  switch (self.state.hgViewParams.itt) {
                    case Constants.applicationIttModes.bb: {
                      // bigBed
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                        height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                        type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                        tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                        uid: {$set: uuid4()},
                        options: {
                          minHeight: {$set: 0},
                          backgroundColor: {$set: "white"},
                          isBarPlotLike: {$set: true},
                          itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                          showMousePosition: {$set: true},
                        }
                      });
                      break;
                    }
                    case Constants.applicationIttModes.ht: {
                      // higlass-transcripts
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                        type: {$set: "horizontal-transcripts"},
                        tilesetUid: {$set: "D5k7ajwfT9mzwbybaSS0VA"},
                        uid: {$set: uuid4()},
                        options: {
                          showMousePosition: {$set: true},
                          blockStyle: {$set: "boxplot"},
                          showToggleTranscriptsButton: {$set: false},
                          labelFontSize: {$set: "11"},
                          labelFontWeight: {$set: "500"},
                          maxTexts: {$set: 100},
                          itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                          startCollapsed: {$set: false},
                          trackMargin: {$set: {
                            top: 10,
                            bottom: 10,
                            left: 0,
                            right: 0,
                          }},
                          transcriptHeight: {$set: 16},
                          transcriptSpacing: {$set: 5},
                          isVisible: {$set: true},
                        }
                      });
                      break;
                    }
                    case Constants.applicationIttModes.hp: {
                      // higlass-pileup
                      newTop[newTopIdx] = update(topClone[2], {
                        name: {$set: "masterlist_DHSs_733samples_WM20180608_all_mean_signal_colorsMax.bed.unc.b12.lf.AR20210927.withBiosampleCounts"},
                        type: {$set: "pileup"},
                        height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                        options: {
                          axisPositionHorizontal: {$set: "right"},
                          axisLabelFormatting: {$set: "normal"},
                          outlineReadOnHover: {$set: "no"},
                          showCoverage: {$set: false},
                          colorScale: {$set: [
                            // A T G C N Other
                            "#2c7bb6",
                            "#92c5de",
                            "#ffffbf",
                            "#fdae61",
                            "#808080",
                            "#DCDCDC"
                          ]},
                          indexDHS: {$set: {
                            group: "MasterlistDHS733Sample",
                            set: "",
                            backgroundColor: "#ffffff",
                            biosampleCount: 733,
                            itemRGBMap: {
                              "255,229,0": "Placental / trophoblast",
                              "254,129,2": "Lymphoid",
                              "255,0,0": "Myeloid / erythroid",
                              "7,175,0": "Cardiac",
                              "76,125,20": "Musculoskeletal",
                              "65,70,19": "Vascular / endothelial",
                              "5,193,217": "Primitive / embryonic",
                              "4,103,253": "Neural",
                              "0,149,136": "Digestive",
                              "187,45,212": "Stromal A",
                              "122,0,255": "Stromal B",
                              "74,104,118": "Renal / cancer",
                              "8,36,91": "Cancer / epithelial",
                              "185,70,29": "Pulmonary development",
                              "105,33,8": "Organ development / renal",
                              "195,195,195": "Tissue invariant"
                            },
                          }},
                          showLoadingText: {$set: false},
                          showMousePosition: {$set: true},
                        },
                        uid: {$set: "FylkvVBTSumoJ959H-5A-3"},
                        data: {$set: {
                          type: "bam",
                          bamUrl: "https://d1y3bo4esmnv83.cloudfront.net/masterlist_DHSs_733samples_WM20180608_all_mean_signal_colorsMax.bed.unc.b12.lf.AR20210927.withBiosampleCounts.bam",
                          baiUrl: "https://d1y3bo4esmnv83.cloudfront.net/masterlist_DHSs_733samples_WM20180608_all_mean_signal_colorsMax.bed.unc.b12.lf.AR20210927.withBiosampleCounts.bam.bai",
                          chromSizesUrl: "https://d1y3bo4esmnv83.cloudfront.net/hg38.meuleman.fixedBin.chrom.sizes",
                          options: {
                            maxTileWidth: 10000000,
                          },
                        }}
                      });
                      break;
                    }
                    default:
                      throw new URIError(`Unknown Index DHS track type (itt) mode ${self.state.hgViewParams.itt}`);
                  }
                  newTopIdx += 1;

                  // console.warn(`Adding DHS index track... ${this.state.currentViewScale} vs ${this.state.hgViewParams.dtt}`);
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackIndexDHSHeight},
                  //   type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                  //   tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     minHeight: {$set: 0},
                  //     backgroundColor: {$set: "white"},
                  //     isBarPlotLike: {$set: true},
                  //     itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                  //     showMousePosition: {$set: true},
                  //   }
                  // });
                  // newTopIdx += 1;
                  //
                  // add all-DHS Component track
                  //
                  //console.warn(`all-DHS track height ${newHgViewTrackDHSComponentsHeight}`);
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackDHSComponentsHeight},
                  //   type: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['type']},
                  //   tilesetUid: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['uuid']},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     minHeight: {$set: 0},
                  //     backgroundColor: {$set: "white"},
                  //     colorRange: {$set: Constants.dhsComponentColorsAsRgb},
                  //     colorLabels: {$set: Constants.dhsComponentLabels},
                  //     showMousePosition: {$set: true},
                  //     valueScaling: {$set: null},
                  //     valueScaleMin: {$set: 1},
                  //     valueScaleMax: {$set: 17},
                  //     valueScaleNaN: {$set: 17},
                  //     zeroValueColor: {$set: "white"},
                  //     heatmapValueScaling: {$set: "categorical"},
                  //     colorbarPosition: {$set: null}
                  //   }
                  // });
                  // newTopIdx += 1;

                  // console.log("post-dhs-component newTop", newTop);

                  //
                  // add gene annotation track
                  //
                  // console.log(`self.state.hgViewParams.gatt ${self.state.hgViewParams.gatt}`);
                  switch (self.state.hgViewParams.gatt) {
                    case Constants.applicationGattModes.cv: {
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: newHgViewTrackGeneAnnotationsHeight},
                        tilesetUid: {$set: newGenesUUID},
                        // tilesetUid: {$set: "UQPDVgG1TQi7Pw0P88RV-w"},
                        uid: {$set: uuid4()},
                        options: {
                          backgroundColor: {$set: "white"},
                          plusStrandColor: {$set: "black"},
                          minusStrandColor: {$set: "black"},
                          showMousePosition: {$set: true},
                        }
                      });
                      break;
                    }
                    case Constants.applicationGattModes.ht: {
                      const blockCalculateTranscriptCountsEnabled = Constants.applicationGacModes[self.state.hgViewParams.gac];
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: self.state.geneAnnotationTrackHeight},
                        type: {$set: "horizontal-transcripts"},
                        tilesetUid: {$set: Constants.viewerHgViewconfGenomeAnnotationUUIDs[self.state.hgViewParams.genome]["transcripts"]},
                        uid: {$set: uuid4()},
                        options: {
                          maxRows: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsDesktopTrackMaxRows},
                          maxTexts: {$set: 50},
                          labelFontSize: {$set: 10},
                          labelFontWeight: {$set: 500},
                          transcriptHeight: {$set: 14},
                          transcriptSpacing: {$set: 4},
                          // minHeight: {$set: self.state.geneAnnotationTrackHeight},
                          showMousePosition: {$set: true},
                          startCollapsed: {$set: false},
                          // blockStyle: {$set: "UCSC-like"},
                          blockStyle: {$set: "directional"},
                          // highlightTranscriptType: {$set: "longestIsoform"},
                          // highlightTranscriptTrackBackgroundColor: {$set: "#fdcfcf"},
                          // showToggleTranscriptsButton: {$set: true},
                          showToggleTranscriptsButton: {$set: false},
                          utrColor: {$set: "grey"},
                          plusStrandColor: {$set: "#111111"},
                          minusStrandColor: {$set: "#111111"},
                          blockCalculateTranscriptCounts: {$set: blockCalculateTranscriptCountsEnabled},
                          trackMargin: {$set: {top:10, bottom:10, left:0, right:0}},
                        }
                      });
                      break;
                    }
                    default:
                      throw new URIError(`Unknown gene annotation track type (gatt) mode ${self.state.hgViewParams.gatt}`);
                  }
                  newTopIdx += 1;

                  //
                  // 20px spacer
                  //
                  newTop[newTopIdx] = update(topClone[1], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    tilesetUid: {$set: ''},
                    type: {$set: 'empty'},
                    height: {$set: Constants.applicationViewerHgViewPaddingBottom},
                    uid: {$set: uuid4()},
                    options: {
                      backgroundColor: {$set: "white"},
                      showMousePosition: {$set: true},
                    }
                  });
                  newTopIdx += 1;

                  // console.log("post-gene-annotation newTop", newTop);

                  // replace the top view tracks with the newly build track layout
                  //console.log("updated newTop", JSON.stringify(newTop, null, 2));
                  res.data.views[0].tracks.top = newTop;
                  // confirm layout
                  //console.log("updated res.data", JSON.stringify(res.data, null, 2));

                  // get child view heights
                  const childViews = res.data.views[0].tracks.top;
                  let childViewHeightTotal = 0;
                  childViews.forEach((cv) => { childViewHeightTotal += cv.height });
                  childViewHeightTotal += 10;
                  let childViewHeightTotalPx = childViewHeightTotal + "px";
                  //console.log("childViewHeightTotalPx", childViewHeightTotalPx);
                  
                  // update Viewer state
                  //console.log("Updating mainHgViewconf from res.data");
                  self.setState({
                    hgViewParams: newHgViewParams,
                    hgViewHeight: childViewHeightTotalPx,
                    mainHgViewconf: res.data,
                    currentPositionKey: Math.random(),
                    currentPosition : {
                      chrLeft : chrLeft,
                      chrRight : chrRight,
                      startLeft : parseInt(start),
                      stopLeft : parseInt(stop),
                      startRight : parseInt(start),
                      stopRight : parseInt(stop)
                    },
                    // selectedProteinCodingsRowIdx: -1,
                    // selectedTranscriptionFactorsRowIdx: -1,
                    // selectedRoiRowIdx: -1,
                  }, () => {
                    self.setState({
                      hgViewKey: self.state.hgViewKey + 1,
                      drawerContentKey: self.state.drawerContentKey + 1,
                    }, () => {
                      // update browser history (address bar URL)
                      console.log("calling [updateViewerURL] from [triggerUpdate]", self.state.hgViewParams.mode);
                      self.updateViewerURL(self.state.hgViewParams.mode,
                                           self.state.currentPosition.chrLeft,
                                           self.state.currentPosition.chrRight,
                                           self.state.currentPosition.startLeft,
                                           self.state.currentPosition.stopRight,
                                           true,
                                           "triggerUpdate (pd)");
                      // add location event handler
                      self.hgView.api.on("location", (event) => { 
                        self.updateViewerLocation(event);
                        // setTimeout(() => { self.updateScale(); }, 1500);
                      });
                      
                      setTimeout(() => {
                        // const indexDHSTrackObj = self.hgView.api.getComponent().getTrackObject(
                        //   self.state.mainHgViewconf.views[0].uid,
                        //   self.state.mainHgViewconf.views[0].tracks.top[3].uid,
                        // );
                        const geneAnnotationTrackObj = self.hgView.api.getComponent().getTrackObject(
                          self.state.mainHgViewconf.views[0].uid,
                          self.state.mainHgViewconf.views[0].tracks.top[3].uid,
                        );
                        // eslint-disable-next-line no-unused-vars
                        // indexDHSTrackObj.pubSub.subscribe("trackDimensionsModified", (msg) => { 
                        //   const newIndexDHSTrackObjTrackHeight = parseInt(indexDHSTrackObj.trackHeight);
                        //   if (newIndexDHSTrackObjTrackHeight !== self.state.indexDHSTrackHeight && newIndexDHSTrackObjTrackHeight !== 0) {
                        //     self.setState({
                        //       indexDHSTrackHeight: parseInt(indexDHSTrackObj.trackHeight),
                        //     }, () => {
                        //       // console.log(`indexDHSTrackObj trackDimensionsModified event sent ${self.state.indexDHSTrackHeight}px`);
                        //       self.updateViewportDimensions();
                        //       indexDHSTrackObj.pubSub.unsubscribe("trackDimensionsModified");
                        //     });
                        //   }
                        // });
                        geneAnnotationTrackObj.pubSub.subscribe("trackDimensionsModified", (msg) => { 
                          const newGeneAnnotationTrackObjTrackHeight = parseInt(geneAnnotationTrackObj.trackHeight);
                          if (newGeneAnnotationTrackObjTrackHeight !== self.state.geneAnnotationTrackHeight && newGeneAnnotationTrackObjTrackHeight !== 0) {
                            self.setState({
                              geneAnnotationTrackHeight: parseInt(geneAnnotationTrackObj.trackHeight) + 10,
                            }, () => {
                              // console.log(`geneAnnotationTrackObj trackDimensionsModified event sent ${self.state.geneAnnotationTrackHeight}px`);
                              self.updateViewportDimensions();
                              geneAnnotationTrackObj.pubSub.unsubscribe("trackDimensionsModified");
                            });
                          }
                        });
                      }, 500);

                    })
                  })
                }

                if (chromInfoCacheExists) {
                  updateViewerStateForPdModeAndChromInfo(this.chromInfoCache[newGenome], this);
                }
                else {
                  let chromSizesURL = this.getChromSizesURL(newGenome);
                  ChromosomeInfo(chromSizesURL)
                    .then((chromInfo) => {
                      this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                      updateViewerStateForPdModeAndChromInfo(chromInfo, this);
                    })
                    .catch((err) => {
                      let msg = this.errorMessage(err, `Could not retrieve chromosome information`, chromSizesURL);
                      this.setState({
                        overlayMessage: msg,
                        mainHgViewconf: {}
                      }, () => {
                        this.fadeInOverlay();
                      });
                    });
                }
              
              })
              .catch((err) => {
                //console.log(err.response);
                let msg = this.errorMessage(err, `[pd] Could not retrieve view configuration`, newHgViewconfURL);
                this.setState({
                  overlayMessage: msg,
                  mainHgViewconf: {}
                }, () => {
                  this.fadeInOverlay();
                });
              });
          })
          .catch((err) => {
            throw new Error(`Could not retrieve UUIDs for all specified components - ${JSON.stringify(err)}`);
            // console.log("Could not retrieve UUIDs for all specified components");
          });
      }

      //
      // per-component (multivec) mode, destacked bar style - SuperIndex
      //
      else if (newMode === "pds") {
        // get per-component multivec item
        let samplesToRenderAsTracks = [];
        let samplePromises = [];

        // Object.keys(Constants.allComponent).forEach((d) => {
        //   if (Constants.allComponent[d].active) {
        //     let o = Constants.allComponent[d];
        //     o['name'] = d;
        //     o['filename'] = `${d}.multires.mv5`
        //     samplesToRenderAsTracks.push(o);
        //     let endpoint = Constants.applicationHiGlassServerEndpointRootURL;
        //     samplePromises.push(uuidQueryPromise(o['filename'], endpoint, this));
        //   }
        // });

        const pdsSample = {
          "name" : "WM20240426_signal_track_per_component.hg38.version_sort.bed.multires.mv5",
          "filename" : "WM20240426_signal_track_per_component.hg38.version_sort.bed.multires.mv5",
          "componentIndex": 1,
          "samples": 1,
          "active": true,
          "longName": "All samples",
          "uuid": "Y1zBbNKLRNGzT8swsaU31w",
        };
        samplesToRenderAsTracks.push(pdsSample);
        let endpoint = Constants.applicationHiGlassServerEndpointRootURL;
        samplePromises.push(uuidQueryPromise(pdsSample["filename"], endpoint, this));
      
        //console.log("samplesToRenderAsTracks", samplesToRenderAsTracks);
        Promise.all(samplePromises).then((v) => {
            // console.log(`v ${JSON.stringify(v)}`);
            // let uuid = {};
            // v.forEach((d) => {
            //   uuid[d.fn] = d.uuid;
            // });
            // samplesToRenderAsTracks.forEach((d) => {
            //   d['uuid'] = uuid[d['filename']];
            // });
            // console.log(samplesToRenderAsTracks);
            let samplesToBeRendered = samplesToRenderAsTracks.reduce((d, c) => { return parseInt(d + c.samples) }, 0);
            // console.log("samplesToBeRendered", samplesToBeRendered);
            // console.log("newHgViewconfURL", newHgViewconfURL);
            axios.get(newHgViewconfURL)
              .then((res) => {
                if (!res.data) {
                  throw String("Error: New viewconf not returned from query to " + newHgViewconfURL);
                }
                
                // console.log("old res.data", res.data);
                
                // ensure the template is not editable
                res.data.editable = false;
                
                // update hgview parameters
                newHgViewParams.genome = newGenome;
                newHgViewParams.mode = newMode;
                
                let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
                let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
                let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
                let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
                //console.log("position: ", chrLeft, chrRight, start, stop);

                const updateViewerStateForPdsModeAndChromInfo = function(chromInfo, self) {
                  if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                    chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                    chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                    start = Constants.defaultApplicationPositions[newGenome].start;
                    stop = Constants.defaultApplicationPositions[newGenome].stop;
                  }
                  if (start > chromInfo.chromLengths[chrLeft]) {
                    start = chromInfo.chromLengths[chrLeft] - 10000;
                  }
                  if (stop > chromInfo.chromLengths[chrRight]) {
                    stop = chromInfo.chromLengths[chrRight] - 1000;
                  }
                  let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
                  let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
                  // console.log("chrLeft, start, absLeft", chrLeft, start, absLeft);
                  // console.log("chrRight, stop, absRight", chrRight, stop, absRight);
                  res.data.views[0].initialXDomain = [absLeft, absRight];
                  res.data.views[0].initialYDomain = [absLeft, absRight];
                  // update track heights -- requires preknowledge of track order from template
                  let windowInnerHeight = document.documentElement.clientHeight + "px";
                  // console.log("windowInnerHeight", windowInnerHeight);
                  // console.log("newHgViewTrackNumSamplesHeight", newHgViewTrackNumSamplesHeight);
                  //let availableWindowInnerHeight = parseInt(windowInnerHeight) - newHgViewTrackChromosomeHeight - newHgViewTrackIndexAnnotationsHeight - newHgViewTrackNumSamplesHeight - newHgViewTrackGeneAnnotationsHeight - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                  // let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackNumSamplesHeight) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackDHSComponentsHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                  // let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackNumSamplesHeight) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";

                  let availableWindowInnerHeight = parseInt(windowInnerHeight) 
                    - parseInt(newHgViewTrackChromosomeHeight) 
                    - parseInt(newHgViewTrackIndexDHSHeight) 
                    - parseInt(newHgViewTrackGeneAnnotationsHeight) 
                    - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) 
                    - parseInt(Constants.applicationViewerHgViewPaddingTop) 
                    - parseInt(Constants.applicationViewerHgViewPaddingBottom) + "px";
                  
                  // console.log(`----`);
                  // console.log(`availableWindowInnerHeight ${availableWindowInnerHeight}`);
                  // console.log(`newHgViewTrackChromosomeHeight ${newHgViewTrackChromosomeHeight}`);
                  // console.log(`newHgViewTrackIndexDHSHeight ${newHgViewTrackIndexDHSHeight}`);
                  // console.log(`newHgViewTrackGeneAnnotationsHeight ${newHgViewTrackGeneAnnotationsHeight}`);
                  // console.log(`----`);

                  let heightPerSample = parseFloat(parseInt(availableWindowInnerHeight) / samplesToBeRendered);
                  // console.log("heightPerSample", heightPerSample);
                  
                  // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
                  let topClone = update(res.data.views[0].tracks.top, {$push: []});
                  //console.log("topClone", topClone);

                  // build a new view from pieces of this clone
                  let newTop = [];
                  let newTopIdx = 0;

                  // if (this.state.hgViewParams.gmax && (this.state.hgViewParams.gmax !== Constants.defaultApplicationSignalGlobalMax)) {

                  samplesToRenderAsTracks.forEach((sampleToRender) => {
                    console.log("sampleToRender", sampleToRender);
                    newTop[newTopIdx] = update(topClone[0], {
                      server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      height: {$set: parseInt(sampleToRender.samples * heightPerSample)},
                      name: {$set: "29ComponentBasicMultipleBarChart"},
                      /* type: {$set: "horizontal-multivec"}, */
                      type: {$set: "basic-multiple-bar-chart"},
                      /* type: {$set: "horizontal-stacked-delta-bar"}, */
                      /* type: {$set: "horizontal-stacked-bar"}, */
                      // minHeight: {$set: 1},
                      // resolutions: {$set: Constants.componentResolutions},
                      options: {
                        minHeight: {$set: 0},
                        backgroundColor: {$set: "black"},
                        colorScale: {$set: Constants.systemColorPalettesAsHex['hg38']['29']["042924"]},
                        labelPosition: {$set: "topLeft"},
                        labelTextOpacity: {$set: 0.0},
                        labelBackgroundOpacity: {$set: 0.0},
                        labelColor: {$set: "white"},
                        valueScaleMin: {$set: 0.0},
                        valueScaleMax: {$set: parseFloat(self.state.hgViewParams.gmax)},
                        name: {$set: sampleToRender.longName},
                        valueScaling: {$set: "linear"},
                        // colorRange: {$set: Constants.componentColorMapGreyscale},
                        // colorRange: {$set: Constants.componentColorMapViridisV1},
                        showMousePosition: {$set: true},
                        colorbarPosition: {$set: null},
                        trackBorderWidth: {$set: 0},
                        trackBorderColor: {$set: "black"},
                        barBorder: {$set: false},
                        sortLargestOnTop: {$set: true},
                        // name: {$set: "24ComponentBasicMultipleBarChart"},
                        chromInfo: {$set: self.chromInfoCache[newGenome]},
                        binSize: {$set: 20},
                      },
                      tilesetUid: {$set: sampleToRender.uuid},
                      uid: {$set: uuid4()},
                      resolutions: {$set: [13107200, 6553600, 3276800, 1638400, 819200, 409600, 204800, 102400, 51200, 25600, 12800, 6400, 3200, 1600, 800, 400, 200]},
                    });
                    newTopIdx += 1;
                  })

                  // console.log("post-samples-to-render newTop", newTop);

                  //
                  // add chromosome track
                  //
                  newTop[newTopIdx] = update(topClone[1], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    height: {$set: newHgViewTrackChromosomeHeight + 10},
                    tilesetUid: {$set: newChromsizesUUID},
                    uid: {$set: uuid4()},
                    options: {
                      backgroundColor: {$set: "white"},
                      showMousePosition: {$set: true},
                    }
                  });
                  newTopIdx += 1;

                  // console.log("> post-chromosome-track newTop", newTop);

                  //
                  // add numSamples track
                  //
                  // let numSamplesUUID = Constants.viewerHgViewconfNumSamplesUUIDs[newNumSamples];
                  // let numSamplesServerEndpointRootURL = Constants.applicationHiGlassDevServerEndpointRootURL;
                  // console.log(`numSamplesUUID ${numSamplesUUID}`);
                  // console.log(`self.newHgViewParams ${JSON.stringify(self.newHgViewParams, null, 2)}`);

                  // if (self.state.hgViewParams.itt === Constants.applicationIttModes.ht) {
                    // numSamplesUUID = Constants.viewerHgViewconfFixedBinNumSamplesUUIDs[newNumSamples];
                    // numSamplesServerEndpointRootURL = Constants.applicationHiGlassServerEndpointRootURL;
                  // }

                  // let numSamplesTrackType = Constants.viewerHgViewconfNumSamplesTrackType;
                  // let numSamplesValueScaleMin = Constants.viewerHgViewconfNumSamplesValueScaleMin[newNumSamples];
                  // let numSamplesValueScaleMax = Constants.viewerHgViewconfNumSamplesValueScaleMax[newNumSamples];
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: numSamplesServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackNumSamplesHeight},
                  //   type: {$set: numSamplesTrackType},
                  //   tilesetUid: {$set: numSamplesUUID},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     minHeight: {$set: 0},
                  //     backgroundColor: {$set: "rgb(242,242,242)"},
                  //     axisLabelFormatting: {$set: "normal"},
                  //     axisPositionHorizontal: {$set: "outsideRight"},
                  //     barFillColor: {$set: "black"},
                  //     pointColor: {$set: "black"},
                  //     pointSize: {$set: 4},
                  //     zeroLineVisible: {$set: true},
                  //     zeroLineColor: {$set: "black"},
                  //     zeroLineOpacity: {$set: 0.0},
                  //     valueScaling: {$set: "log"},
                  //     valueScaleMin: {$set: numSamplesValueScaleMin},
                  //     valueScaleMax: {$set: numSamplesValueScaleMax},
                  //     valueFormat: {$set: "d"},
                  //     aggregationMode: {$set: "median"},
                  //     showTooltip: {$set: true},
                  //     showMousePosition: {$set: true}
                  //   }
                  // });
                  // newTopIdx += 1;

                  // console.log("post-num-samples newTop", newTop);

                  //
                  // add BED12 Index element
                  //
                  switch (self.state.hgViewParams.itt) {
                    case Constants.applicationIttModes.bb: {
                      // bigBed
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                        height: {$set: Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight},
                        type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                        tilesetUid: {$set: Constants.viewerHgViewconfTrackSuperIndexDHSUUID["042924"]},
                        uid: {$set: uuid4()},
                        options: {
                          minHeight: {$set: 0},
                          backgroundColor: {$set: "white"},
                          isBarPlotLike: {$set: true},
                          itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBSuperIndex042924Colormap},
                          showMousePosition: {$set: true},
                          biosampleCount: {$set: 4078},
                        }
                      });
                      break;
                    }
                    default:
                      throw new URIError(`Unknown Index DHS track type (itt) mode ${self.state.hgViewParams.itt}`);
                  }
                  newTopIdx += 1;

                  // console.warn(`Adding DHS index track... ${this.state.currentViewScale} vs ${this.state.hgViewParams.dtt}`);
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: Constants.applicationHiGlassDevServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackIndexDHSHeight},
                  //   type: {$set: Constants.viewerHgViewconfTrackIndexDHSType},
                  //   tilesetUid: {$set: Constants.viewerHgViewconfTrackIndexDHSUUID},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     minHeight: {$set: 0},
                  //     backgroundColor: {$set: "white"},
                  //     isBarPlotLike: {$set: true},
                  //     itemRGBMap: {$set: Constants.viewerHgViewconfBED12ItemRGBColormap},
                  //     showMousePosition: {$set: true},
                  //   }
                  // });
                  // newTopIdx += 1;
                  //
                  // add all-DHS Component track
                  //
                  //console.warn(`all-DHS track height ${newHgViewTrackDHSComponentsHeight}`);
                  // newTop[newTopIdx] = update(topClone[2], {
                  //   server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                  //   height: {$set: newHgViewTrackDHSComponentsHeight},
                  //   type: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['type']},
                  //   tilesetUid: {$set: Constants.viewerHgViewconfDTCCategoryUUIDs[self.state.hgViewParams.dtc]['uuid']},
                  //   uid: {$set: uuid4()},
                  //   options: {
                  //     minHeight: {$set: 0},
                  //     backgroundColor: {$set: "white"},
                  //     colorRange: {$set: Constants.dhsComponentColorsAsRgb},
                  //     colorLabels: {$set: Constants.dhsComponentLabels},
                  //     showMousePosition: {$set: true},
                  //     valueScaling: {$set: null},
                  //     valueScaleMin: {$set: 1},
                  //     valueScaleMax: {$set: 17},
                  //     valueScaleNaN: {$set: 17},
                  //     zeroValueColor: {$set: "white"},
                  //     heatmapValueScaling: {$set: "categorical"},
                  //     colorbarPosition: {$set: null}
                  //   }
                  // });
                  // newTopIdx += 1;

                  // console.log("post-dhs-component newTop", newTop);

                  //
                  // add gene annotation track
                  //
                  // console.log(`self.state.hgViewParams.gatt ${self.state.hgViewParams.gatt}`);
                  switch (self.state.hgViewParams.gatt) {
                    case Constants.applicationGattModes.cv: {
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: newHgViewTrackGeneAnnotationsHeight},
                        tilesetUid: {$set: newGenesUUID},
                        // tilesetUid: {$set: "UQPDVgG1TQi7Pw0P88RV-w"},
                        uid: {$set: uuid4()},
                        options: {
                          backgroundColor: {$set: "white"},
                          plusStrandColor: {$set: "black"},
                          minusStrandColor: {$set: "black"},
                          showMousePosition: {$set: true},
                        }
                      });
                      break;
                    }
                    case Constants.applicationGattModes.ht: {
                      const blockCalculateTranscriptCountsEnabled = Constants.applicationGacModes[self.state.hgViewParams.gac];
                      newTop[newTopIdx] = update(topClone[2], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: self.state.geneAnnotationTrackHeight},
                        type: {$set: "horizontal-transcripts"},
                        tilesetUid: {$set: Constants.viewerHgViewconfGenomeAnnotationUUIDs[self.state.hgViewParams.genome]["transcripts"]},
                        uid: {$set: uuid4()},
                        options: {
                          maxRows: {$set: Constants.defaultGeneAnnotationsHiglassTranscriptsDesktopTrackMaxRows},
                          maxTexts: {$set: 50},
                          labelFontSize: {$set: 10},
                          labelFontWeight: {$set: 500},
                          transcriptHeight: {$set: 14},
                          transcriptSpacing: {$set: 4},
                          // minHeight: {$set: self.state.geneAnnotationTrackHeight},
                          showMousePosition: {$set: true},
                          startCollapsed: {$set: false},
                          // blockStyle: {$set: "UCSC-like"},
                          blockStyle: {$set: "directional"},
                          // highlightTranscriptType: {$set: "longestIsoform"},
                          // highlightTranscriptTrackBackgroundColor: {$set: "#fdcfcf"},
                          // showToggleTranscriptsButton: {$set: true},
                          showToggleTranscriptsButton: {$set: false},
                          utrColor: {$set: "grey"},
                          plusStrandColor: {$set: "#111111"},
                          minusStrandColor: {$set: "#111111"},
                          blockCalculateTranscriptCounts: {$set: blockCalculateTranscriptCountsEnabled},
                          trackMargin: {$set: {top:10, bottom:10, left:0, right:0}},
                        }
                      });
                      break;
                    }
                    default:
                      throw new URIError(`Unknown gene annotation track type (gatt) mode ${self.state.hgViewParams.gatt}`);
                  }
                  newTopIdx += 1;

                  //
                  // 20px spacer
                  //
                  newTop[newTopIdx] = update(topClone[1], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    tilesetUid: {$set: ''},
                    type: {$set: 'empty'},
                    height: {$set: Constants.applicationViewerHgViewPaddingBottom},
                    uid: {$set: uuid4()},
                    options: {
                      backgroundColor: {$set: "white"},
                      showMousePosition: {$set: true},
                    }
                  });
                  newTopIdx += 1;

                  // console.log("post-gene-annotation newTop", newTop);

                  // replace the top view tracks with the newly build track layout
                  //console.log("updated newTop", JSON.stringify(newTop, null, 2));
                  res.data.views[0].tracks.top = newTop;
                  // confirm layout
                  //console.log("updated res.data", JSON.stringify(res.data, null, 2));

                  // get child view heights
                  const childViews = res.data.views[0].tracks.top;
                  let childViewHeightTotal = 0;
                  childViews.forEach((cv) => { childViewHeightTotal += cv.height });
                  childViewHeightTotal += 10;
                  let childViewHeightTotalPx = childViewHeightTotal + "px";
                  //console.log("childViewHeightTotalPx", childViewHeightTotalPx);
                  
                  // update Viewer state
                  //console.log("Updating mainHgViewconf from res.data");
                  self.setState({
                    hgViewParams: newHgViewParams,
                    hgViewHeight: childViewHeightTotalPx,
                    mainHgViewconf: res.data,
                    currentPositionKey: Math.random(),
                    currentPosition : {
                      chrLeft : chrLeft,
                      chrRight : chrRight,
                      startLeft : parseInt(start),
                      stopLeft : parseInt(stop),
                      startRight : parseInt(start),
                      stopRight : parseInt(stop)
                    },
                    // selectedProteinCodingsRowIdx: -1,
                    // selectedTranscriptionFactorsRowIdx: -1,
                    // selectedRoiRowIdx: -1,
                  }, () => {
                    self.setState({
                      hgViewKey: self.state.hgViewKey + 1,
                      drawerContentKey: self.state.drawerContentKey + 1,
                    }, () => {
                      // update browser history (address bar URL)
                      // console.log("calling [updateViewerURL] from [triggerUpdate]", self.state.hgViewParams.mode);
                      self.updateViewerURL(self.state.hgViewParams.mode,
                                           self.state.currentPosition.chrLeft,
                                           self.state.currentPosition.chrRight,
                                           self.state.currentPosition.startLeft,
                                           self.state.currentPosition.stopRight,
                                           true,
                                           "triggerUpdate (pd)");
                      // add location event handler
                      self.hgView.api.on("location", (event) => { 
                        self.updateViewerLocation(event);
                        // setTimeout(() => { self.updateScale(); }, 1500);
                      });
                      
                      setTimeout(() => {
                        // const indexDHSTrackObj = self.hgView.api.getComponent().getTrackObject(
                        //   self.state.mainHgViewconf.views[0].uid,
                        //   self.state.mainHgViewconf.views[0].tracks.top[3].uid,
                        // );
                        const geneAnnotationTrackObj = self.hgView.api.getComponent().getTrackObject(
                          self.state.mainHgViewconf.views[0].uid,
                          self.state.mainHgViewconf.views[0].tracks.top[3].uid,
                        );
                        // eslint-disable-next-line no-unused-vars
                        // indexDHSTrackObj.pubSub.subscribe("trackDimensionsModified", (msg) => { 
                        //   const newIndexDHSTrackObjTrackHeight = parseInt(indexDHSTrackObj.trackHeight);
                        //   if (newIndexDHSTrackObjTrackHeight !== self.state.indexDHSTrackHeight && newIndexDHSTrackObjTrackHeight !== 0) {
                        //     self.setState({
                        //       indexDHSTrackHeight: parseInt(indexDHSTrackObj.trackHeight),
                        //     }, () => {
                        //       // console.log(`indexDHSTrackObj trackDimensionsModified event sent ${self.state.indexDHSTrackHeight}px`);
                        //       self.updateViewportDimensions();
                        //       indexDHSTrackObj.pubSub.unsubscribe("trackDimensionsModified");
                        //     });
                        //   }
                        // });
                        geneAnnotationTrackObj.pubSub.subscribe("trackDimensionsModified", (msg) => { 
                          const newGeneAnnotationTrackObjTrackHeight = parseInt(geneAnnotationTrackObj.trackHeight);
                          if (newGeneAnnotationTrackObjTrackHeight !== self.state.geneAnnotationTrackHeight && newGeneAnnotationTrackObjTrackHeight !== 0) {
                            self.setState({
                              geneAnnotationTrackHeight: parseInt(geneAnnotationTrackObj.trackHeight) + 10,
                            }, () => {
                              // console.log(`geneAnnotationTrackObj trackDimensionsModified event sent ${self.state.geneAnnotationTrackHeight}px`);
                              self.updateViewportDimensions();
                              geneAnnotationTrackObj.pubSub.unsubscribe("trackDimensionsModified");
                            });
                          }
                        });
                      }, 500);

                    })
                  })
                }

                if (chromInfoCacheExists) {
                  updateViewerStateForPdsModeAndChromInfo(this.chromInfoCache[newGenome], this);
                }
                else {
                  let chromSizesURL = this.getChromSizesURL(newGenome);
                  ChromosomeInfo(chromSizesURL)
                    .then((chromInfo) => {
                      this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                      updateViewerStateForPdsModeAndChromInfo(chromInfo, this);
                    })
                    .catch((err) => {
                      let msg = this.errorMessage(err, `Could not retrieve chromosome information`, chromSizesURL);
                      this.setState({
                        overlayMessage: msg,
                        mainHgViewconf: {}
                      }, () => {
                        this.fadeInOverlay();
                      });
                    });
                }
              
              })
              .catch((err) => {
                //console.log(err.response);
                let msg = this.errorMessage(err, `[pds] Could not retrieve view configuration`, newHgViewconfURL);
                this.setState({
                  overlayMessage: msg,
                  mainHgViewconf: {}
                }, () => {
                  this.fadeInOverlay();
                });
              });
          })
          .catch((err) => {
            throw new Error(`Could not retrieve UUIDs for all specified components - ${JSON.stringify(err)}`);
            // console.log("Could not retrieve UUIDs for all specified components");
          });
      }

      //
      // per-component (multivec) mode, destacked bar style -- only
      //
      else if (newMode === "pdo") {
        // get per-component multivec item
        let samplesToRenderAsTracks = [];
        let samplePromises = [];

        // Object.keys(Constants.allComponent).forEach((d) => {
        //   if (Constants.allComponent[d].active) {
        //     let o = Constants.allComponent[d];
        //     o['name'] = d;
        //     o['filename'] = `${d}.multires.mv5`
        //     samplesToRenderAsTracks.push(o);
        //     let endpoint = Constants.applicationHiGlassServerEndpointRootURL;
        //     samplePromises.push(uuidQueryPromise(o['filename'], endpoint, this));
        //   }
        // });

        const pdSample = {
          "name" : "WM20181113_signal_track_per_component.hg38.multires.mv5",
          "filename" : "WM20181113_signal_track_per_component.hg38.multires.mv5",
          "componentIndex": 1,
          "samples": 1,
          "active": true,
          "longName": "All samples",
          "uuid": "HlXADoAsQt6tTm-NkrhWgw",
        };
        samplesToRenderAsTracks.push(pdSample);
        let endpoint = Constants.applicationHiGlassServerEndpointRootURL;
        samplePromises.push(uuidQueryPromise(pdSample["filename"], endpoint, this));
      
        //console.log("samplesToRenderAsTracks", samplesToRenderAsTracks);
        Promise.all(samplePromises).then(() => {
            // let uuid = {};
            // v.forEach((d) => {
            //   uuid[d.fn] = d.uuid;
            // });
            // samplesToRenderAsTracks.forEach((d) => {
            //   d['uuid'] = uuid[d['filename']];
            // });
            // console.log(samplesToRenderAsTracks);
            let samplesToBeRendered = samplesToRenderAsTracks.reduce((d, c) => { return parseInt(d + c.samples) }, 0);
            // console.log("samplesToBeRendered", samplesToBeRendered);
            // console.log("newHgViewconfURL", newHgViewconfURL);
            axios.get(newHgViewconfURL)
              .then((res) => {
                if (!res.data) {
                  throw String("Error: New viewconf not returned from query to " + newHgViewconfURL);
                }
                
                // console.log("old res.data", res.data);
                
                // ensure the template is not editable
                res.data.editable = false;
                
                // update hgview parameters
                newHgViewParams.genome = newGenome;
                newHgViewParams.mode = newMode;
                
                let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
                let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
                let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
                let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
                //console.log("position: ", chrLeft, chrRight, start, stop);

                const updateViewerStateForPdModeAndChromInfo = function(chromInfo, self) {
                  if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                    chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                    chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                    start = Constants.defaultApplicationPositions[newGenome].start;
                    stop = Constants.defaultApplicationPositions[newGenome].stop;
                  }
                  if (start > chromInfo.chromLengths[chrLeft]) {
                    start = chromInfo.chromLengths[chrLeft] - 10000;
                  }
                  if (stop > chromInfo.chromLengths[chrRight]) {
                    stop = chromInfo.chromLengths[chrRight] - 1000;
                  }
                  let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
                  let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
                  // console.log("chrLeft, start, absLeft", chrLeft, start, absLeft);
                  // console.log("chrRight, stop, absRight", chrRight, stop, absRight);
                  res.data.views[0].initialXDomain = [absLeft, absRight];
                  res.data.views[0].initialYDomain = [absLeft, absRight];
                  // update track heights -- requires preknowledge of track order from template
                  let windowInnerHeight = document.documentElement.clientHeight + "px";

                  let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";

                  let heightPerSample = parseFloat(parseInt(availableWindowInnerHeight) / samplesToBeRendered);
                  // console.log("heightPerSample", heightPerSample);
                  
                  // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
                  let topClone = update(res.data.views[0].tracks.top, {$push: []});
                  //console.log("topClone", topClone);

                  // build a new view from pieces of this clone
                  let newTop = [];
                  let newTopIdx = 0;

                  samplesToRenderAsTracks.forEach((sampleToRender) => {
                    // console.log("sampleToRender", sampleToRender);
                    newTop[newTopIdx] = update(topClone[0], {
                      server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                      height: {$set: parseInt(sampleToRender.samples * heightPerSample)},
                      name: {$set: "16ComponentBasicMultipleBarChart"},
                      /* type: {$set: "horizontal-multivec"}, */
                      type: {$set: "basic-multiple-bar-chart"},
                      /* type: {$set: "horizontal-stacked-delta-bar"}, */
                      /* type: {$set: "horizontal-stacked-bar"}, */
                      // minHeight: {$set: 1},
                      // resolutions: {$set: Constants.componentResolutions},
                      options: {
                        minHeight: {$set: 0},
                        backgroundColor: {$set: "black"},
                        colorScale: {$set: Constants.systemColorPalettesAsHex['hg38']['16']},
                        labelPosition: {$set: "topLeft"},
                        labelTextOpacity: {$set: 0.0},
                        labelBackgroundOpacity: {$set: 0.0},
                        labelColor: {$set: "white"},
                        valueScaleMin: {$set: 0.0},
                        valueScaleMax: {$set: parseFloat(self.state.hgViewParams.gmax)},
                        name: {$set: sampleToRender.longName},
                        valueScaling: {$set: "linear"},
                        // colorRange: {$set: Constants.componentColorMapGreyscale},
                        colorRange: {$set: Constants.componentColorMapViridisV1},
                        showMousePosition: {$set: true},
                        colorbarPosition: {$set: null},
                        trackBorderWidth: {$set: 0},
                        trackBorderColor: {$set: "black"},
                        barBorder: {$set: false},
                        sortLargestOnTop: {$set: true},
                        // name: {$set: "16ComponentBasicMultipleBarChart"},
                        chromInfo: {$set: self.chromInfoCache[newGenome]},
                        binSize: {$set: 20},
                      },
                      tilesetUid: {$set: sampleToRender.uuid},
                      uid: {$set: uuid4()},
                      resolutions: {$set: [13107200, 6553600, 3276800, 1638400, 819200, 409600, 204800, 102400, 51200, 25600, 12800, 6400, 3200, 1600, 800, 400, 200]},
                    });
                    newTopIdx += 1;
                  })

                  // console.log("post-samples-to-render newTop", newTop);

                  //
                  // add chromosome track
                  //
                  newTop[newTopIdx] = update(topClone[1], {
                    server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                    height: {$set: newHgViewTrackChromosomeHeight + 10},
                    tilesetUid: {$set: newChromsizesUUID},
                    uid: {$set: uuid4()},
                    options: {
                      backgroundColor: {$set: "white"},
                      showMousePosition: {$set: true},
                    }
                  });
                  // newTopIdx += 1;

                  // console.log("post-gene-annotation newTop", newTop);

                  // replace the top view tracks with the newly build track layout
                  //console.log("updated newTop", JSON.stringify(newTop, null, 2));
                  res.data.views[0].tracks.top = newTop;
                  // confirm layout
                  console.log("updated res.data", JSON.stringify(res.data, null, 2));

                  // get child view heights
                  const childViews = res.data.views[0].tracks.top;
                  let childViewHeightTotal = 0;
                  childViews.forEach((cv) => { childViewHeightTotal += cv.height });
                  childViewHeightTotal += 10;
                  let childViewHeightTotalPx = childViewHeightTotal + "px";
                  //console.log("childViewHeightTotalPx", childViewHeightTotalPx);
                  
                  // update Viewer state
                  //console.log("Updating mainHgViewconf from res.data");
                  self.setState({
                    hgViewParams: newHgViewParams,
                    hgViewHeight: childViewHeightTotalPx,
                    mainHgViewconf: res.data,
                    currentPositionKey: Math.random(),
                    currentPosition : {
                      chrLeft : chrLeft,
                      chrRight : chrRight,
                      startLeft : parseInt(start),
                      stopLeft : parseInt(stop),
                      startRight : parseInt(start),
                      stopRight : parseInt(stop)
                    },
                    // selectedProteinCodingsRowIdx: -1,
                    // selectedTranscriptionFactorsRowIdx: -1,
                    // selectedRoiRowIdx: -1,
                  }, () => {
                    self.setState({
                      hgViewKey: self.state.hgViewKey + 1,
                      drawerContentKey: self.state.drawerContentKey + 1,
                    }, () => {
                      // update browser history (address bar URL)
                      console.log("calling [updateViewerURL] from [triggerUpdate]", self.state.hgViewParams.mode);
                      self.updateViewerURL(self.state.hgViewParams.mode,
                                           self.state.currentPosition.chrLeft,
                                           self.state.currentPosition.chrRight,
                                           self.state.currentPosition.startLeft,
                                           self.state.currentPosition.stopRight,
                                           true,
                                           "triggerUpdate (pd)",);
                      // add location event handler
                      self.hgView.api.on("location", (event) => { 
                        self.updateViewerLocation(event);
                      });

                    })
                  })
                }

                if (chromInfoCacheExists) {
                  updateViewerStateForPdModeAndChromInfo(this.chromInfoCache[newGenome], this);
                }
                else {
                  let chromSizesURL = this.getChromSizesURL(newGenome);
                  ChromosomeInfo(chromSizesURL)
                    .then((chromInfo) => {
                      this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                      updateViewerStateForPdModeAndChromInfo(chromInfo, this);
                    })
                    .catch((err) => {
                      let msg = this.errorMessage(err, `Could not retrieve chromosome information`, chromSizesURL);
                      this.setState({
                        overlayMessage: msg,
                        mainHgViewconf: {}
                      }, () => {
                        this.fadeInOverlay();
                      });
                    });
                }
              
              })
              .catch((err) => {
                //console.log(err.response);
                let msg = this.errorMessage(err, `[pdo] Could not retrieve view configuration`, newHgViewconfURL);
                this.setState({
                  overlayMessage: msg,
                  mainHgViewconf: {}
                }, () => {
                  this.fadeInOverlay();
                });
              });
          })
          .catch((err) => {
            throw new Error(`Could not retrieve UUIDs for all specified components - ${JSON.stringify(err)}`);
            // console.log("Could not retrieve UUIDs for all specified components");
          });
      }

      //
      // query-target mode
      //
      else if (newMode === "qt") {
        this.setState({
          selectedExemplarRowIdx: Constants.defaultApplicationSerIdx,
          selectedRoiRowIdx: Constants.defaultApplicationSrrIdx,
        });
      }

      //
      // fiber-seq test mode
      //
      else if (newMode === "ft") {
        // get all-component item
        let samplesToRenderAsTracks = [];
        let samplePromises = [];
        Object.keys(Constants.allComponent).forEach((d) => {
          if (Constants.allComponent[d].active) {
            let o = Constants.allComponent[d];
            o['name'] = d;
            o['filename'] = `${d}.multires.mv5`
            samplesToRenderAsTracks.push(o);
            let endpoint = Constants.applicationHiGlassServerEndpointRootURL;
            samplePromises.push(uuidQueryPromise(o['filename'], endpoint, this));
          }
        });

        Promise.all(samplePromises).then((v) => {
            let uuid = {};
            v.forEach((d) => {
              uuid[d.fn] = d.uuid;
            });
            samplesToRenderAsTracks.forEach((d) => {
              d['uuid'] = uuid[d['filename']];
            });
            // console.log(samplesToRenderAsTracks);
            let samplesToBeRendered = samplesToRenderAsTracks.reduce((d, c) => { return parseInt(d + c.samples) }, 0);
            // console.log("samplesToBeRendered", samplesToBeRendered);
            // console.log("newHgViewconfURL", newHgViewconfURL);
            axios.get(newHgViewconfURL)
              .then((res) => {
                if (!res.data) {
                  throw String("Error: New viewconf not returned from query to " + newHgViewconfURL);
                }
                
                //console.log("old res.data", res.data);
                
                // ensure the template is not editable
                res.data.editable = false;
                
                // update hgview parameters
                newHgViewParams.genome = newGenome;
                newHgViewParams.mode = newMode;
                
                let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
                let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
                let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
                let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
                //console.log("position: ", chrLeft, chrRight, start, stop);

                function updateViewerStateForAcModeAndChromInfo(chromInfo, self) {
                  //console.log(`updateViewerStateForAcModeAndChromInfo`);
                  if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
                    chrLeft = Constants.defaultApplicationPositions[newGenome].chr;
                    chrRight = Constants.defaultApplicationPositions[newGenome].chr;
                    start = Constants.defaultApplicationPositions[newGenome].start;
                    stop = Constants.defaultApplicationPositions[newGenome].stop;
                  }
                  if (start > chromInfo.chromLengths[chrLeft]) {
                    start = chromInfo.chromLengths[chrLeft] - 10000;
                  }
                  if (stop > chromInfo.chromLengths[chrRight]) {
                    stop = chromInfo.chromLengths[chrRight] - 1000;
                  }
                  let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
                  let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
                  //console.log(chrLeft, start, absLeft);
                  //console.log(chrRight, stop, absRight);
                  res.data.views[0].initialXDomain = [absLeft, absRight];
                  res.data.views[0].initialYDomain = [absLeft, absRight];
                  // update track heights -- requires preknowledge of track order from template
                  let windowInnerHeight = document.documentElement.clientHeight + "px";
                  //console.log("windowInnerHeight", windowInnerHeight);
                  //console.log("newHgViewTrackNumSamplesHeight", newHgViewTrackNumSamplesHeight);
                  //let availableWindowInnerHeight = parseInt(windowInnerHeight) - newHgViewTrackChromosomeHeight - newHgViewTrackIndexAnnotationsHeight - newHgViewTrackNumSamplesHeight - newHgViewTrackGeneAnnotationsHeight - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                  // let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackNumSamplesHeight) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackDHSComponentsHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                  // let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(newHgViewTrackChromosomeHeight) - parseInt(newHgViewTrackIndexDHSHeight) - parseInt(newHgViewTrackDHSComponentsHeight) - parseInt(newHgViewTrackGeneAnnotationsHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";
                  
                  let availableWindowInnerHeight = parseInt(windowInnerHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight) + "px";

                  //console.log("availableWindowInnerHeight", availableWindowInnerHeight);
                  const testMultiplier = 6;
                  let heightPerSample = parseFloat(parseInt(availableWindowInnerHeight) / (samplesToBeRendered * testMultiplier));
                  //console.log("heightPerSample", heightPerSample);
                  // clone the tracks from the top of the view (ref. https://github.com/kolodny/immutability-helper)
                  let topClone = update(res.data.views[0].tracks.top, {$push: []});
                  //console.log("topClone", topClone);
                  // build a new view from pieces of this clone
                  let newTop = [];
                  let newTopIdx = 0;
                  samplesToRenderAsTracks.forEach((sampleToRender) => {
                    //console.log("sampleToRender", sampleToRender);
                    for (let i = 0; i < testMultiplier; ++i) {
                      newTop[newTopIdx] = update(topClone[0], {
                        server: {$set: Constants.applicationHiGlassServerEndpointRootURL},
                        height: {$set: parseInt(sampleToRender.samples * heightPerSample)},
                        name: {$set: sampleToRender.longName},
                        type: {$set: "horizontal-multivec"},
                        minHeight: {$set: 1},
                        resolutions: {$set: Constants.componentResolutions},
                        options: {
                          labelPosition: {$set: "topLeft"},
                          labelTextOpacity: {$set: 0.0},
                          labelBackgroundOpacity: {$set: 0.0},
                          labelColor: {$set: "white"},
                          valueScaleMin: {$set: 0.0},
                          valueScaleMax: {$set: (self.state.hgViewParams.gmax > 0) ? self.state.hgViewParams.gmax : 2.0},
                          name: {$set: sampleToRender.longName},
                          valueScaling: {$set: "linear"},
                          // colorRange: {$set: Constants.componentColorMapGreyscale},
                          colorRange: {$set: Constants.componentColorMapViridisV1},
                          showMousePosition: {$set: true},
                          is2d: {$set: true},
                          colorbarPosition: {$set: null},
                          heatmapType: {$set: "genericIndexDHS"},
                          heatmapComponents: {$set: Constants.components},
                          chromInfo: {$set: self.chromInfoCache[newGenome]},
                          binSize: {$set: 20},
                        },
                        tilesetUid: {$set: sampleToRender.uuid},
                        uid: {$set: uuid4()},
                      });
                      newTopIdx += 1;
                    }
                  });
                  //console.log(`added samplesToRenderAsTracks`);

                  //console.log(`added gene annotation track`);
                  // replace the top view tracks with the newly build track layout
                  //console.log("updated newTop", JSON.stringify(newTop, null, 2));
                  res.data.views[0].tracks.top = newTop;
                  // confirm layout
                  // console.log("updated res.data", JSON.stringify(res.data, null, 2));
                  // get child view heights
                  const childViews = res.data.views[0].tracks.top;
                  let childViewHeightTotal = 0;
                  childViews.forEach((cv) => { childViewHeightTotal += cv.height });
                  childViewHeightTotal += 10;
                  let childViewHeightTotalPx = childViewHeightTotal + "px";
                  //console.log("childViewHeightTotalPx", childViewHeightTotalPx);
                  
                  // update Viewer state
                  //console.log("Updating mainHgViewconf from res.data");
                  self.setState({
                    hgViewParams: newHgViewParams,
                    hgViewHeight: childViewHeightTotalPx,
                    mainHgViewconf: res.data,
                    currentPositionKey: Math.random(),
                    currentPosition : {
                      chrLeft : chrLeft,
                      chrRight : chrRight,
                      startLeft : parseInt(start),
                      stopLeft : parseInt(stop),
                      startRight : parseInt(start),
                      stopRight : parseInt(stop)
                    },
                    // selectedProteinCodingsRowIdx: -1,
                    // selectedTranscriptionFactorsRowIdx: -1,
                    // selectedRoiRowIdx: -1,
                  }, () => {
                    self.setState({
                      hgViewKey: self.state.hgViewKey + 1,
                      drawerContentKey: self.state.drawerContentKey + 1,
                    }, () => {
                      // update browser history (address bar URL)
                      console.log("calling [updateViewerURL] from [triggerUpdate]", self.state.hgViewParams.mode);
                      self.updateViewerURL(self.state.hgViewParams.mode,
                                           self.state.currentPosition.chrLeft,
                                           self.state.currentPosition.chrRight,
                                           self.state.currentPosition.startLeft,
                                           self.state.currentPosition.stopRight,
                                           true,
                                           "triggerUpdate (ac)",);
                      // add location event handler
                      self.hgView.api.on("location", (event) => { 
                        self.updateViewerLocation(event);
                      });
                    })
                  })
                }

                // console.log(`chromInfoCacheExists ${chromInfoCacheExists}`);
                if (chromInfoCacheExists) {
                  updateViewerStateForAcModeAndChromInfo(this.chromInfoCache[newGenome], this);
                }
                else {
                  let chromSizesURL = this.getChromSizesURL(newGenome);
                  ChromosomeInfo(chromSizesURL)
                    .then((chromInfo) => {
                      this.chromInfoCache[newGenome] = Object.assign({}, chromInfo);
                      updateViewerStateForAcModeAndChromInfo(chromInfo, this);
                    })
                    .catch((err) => {
                      let msg = this.errorMessage(err, `Could not retrieve chromosome information`, chromSizesURL);
                      this.setState({
                        overlayMessage: msg,
                        mainHgViewconf: {}
                      }, () => {
                        this.fadeInOverlay();
                      });
                    });
                }
              
              })
              .catch((err) => {
                // console.log(err.response);
                let msg = this.errorMessage(err, `[ft] Could not retrieve view configuration`, newHgViewconfURL);
                this.setState({
                  overlayMessage: msg,
                  mainHgViewconf: {}
                }, () => {
                  this.fadeInOverlay();
                });
              });
          })
          .catch((err) => {
            throw new Error(`Could not retrieve UUIDs for all specified components - ${JSON.stringify(err)}`);
            //console.log("Could not retrieve UUIDs for all specified components");
          });
      }
    }
  }
  
  openViewerAtChrPosition = (pos, upstreamPadding, downstreamPadding, regionType, rowIndex, strand) => {
    // console.log(`openViewerAtChrPosition > ${pos} | ${upstreamPadding} | ${downstreamPadding} | ${regionType} | ${rowIndex} | ${strand}`);
    let chrLeft = pos[0];
    let chrRight = pos[0];
    let posnInt = parseInt(pos[1]);
    let start = posnInt;
    let stop = posnInt;
    let unpaddedStart = start;
    let unpaddedStop = stop;

    switch (regionType) {
      case Constants.applicationRegionTypes.proteinCodings:
      case Constants.applicationRegionTypes.transcriptionFactors:
        // const exemplarPaddingFraction = Constants.defaultApplicationRoiPaddingFraction;
        stop = parseInt(pos[2]);
        unpaddedStop = stop;
/*
        let exemplarPadding = parseInt(exemplarPaddingFraction * (stop - start))
        start -= exemplarPadding;
        stop += exemplarPadding;
*/
        if (strand === "+") {
          start -= upstreamPadding;
          stop += downstreamPadding;
        }
        else if (strand === "-") {
          start -= downstreamPadding;
          stop += upstreamPadding;
        }
        else {
          start -= upstreamPadding;
          stop += downstreamPadding;
        }
        break;
      case Constants.applicationRegionTypes.roi:
        if (this.state.roiMode === "default") {
          const queryObj = Helpers.getJsonFromUrl();
          const roiPaddingFraction = (queryObj.roiPaddingFractional) ? parseFloat(queryObj.roiPaddingFractional) : Constants.defaultApplicationRoiPaddingFraction;
          const roiPaddingAbsolute = (queryObj.roiPaddingAbsolute) ? parseInt(queryObj.roiPaddingAbsolute) : Constants.defaultApplicationRoiPaddingAbsolute;
          stop = parseInt(pos[2]);
          unpaddedStop = stop;
          let roiPadding = (queryObj.roiPaddingFractional) ? parseInt(roiPaddingFraction * (stop - start)) : roiPaddingAbsolute;
          start -= roiPadding;
          stop += roiPadding;
        }
        else if (this.state.roiMode === "midpoint") {
          stop = parseInt(pos[2]);
          unpaddedStop = stop;
          let roiMidpoint = parseInt(start + ((stop - start) / 2));
          start = roiMidpoint - parseInt(this.state.roiPaddingAbsolute);
          stop = roiMidpoint + parseInt(this.state.roiPaddingAbsolute);
        }
        else {
          throw new URIError("Unknown ROI mode");
        }
        break;
      default:
        break;
    }
    
    // console.log(`hgViewUpdatePosition > ${this.state.hgViewParams.genome} | ${chrLeft} | ${start} | ${stop} | ${chrRight} | ${start} | ${stop} | ${regionType} | ${rowIndex}`);
    
    this.hgViewUpdatePosition(this.state.hgViewParams.genome, chrLeft, start, stop, chrRight, start, stop);
    
    if (rowIndex < 0) return;
    
    setTimeout(() => {
      switch (regionType) {
        case Constants.applicationRegionTypes.proteinCodings: {
          this.setState({
            selectedProteinCodingsBeingUpdated: true,
            selectedProteinCodingsRowIdx: parseInt(rowIndex),
            selectedProteinCodingsChrLeft: chrLeft,
            selectedProteinCodingsChrRight: chrRight,
            selectedProteinCodingsStart: parseInt(start),
            selectedProteinCodingsStop: parseInt(stop)
          }, () => {
            console.log("calling [updateViewerURL] from [openViewerAtChrPosition] for [proteinCodings]");
            this.updateViewerURL(this.state.hgViewParams.mode,
                                 chrLeft,
                                 chrRight,
                                 start,
                                 stop,
                                 true,
                                 "openViewerAtChrPosition",);
            // this.updateScale();
            if (this.state.selectedProteinCodingsRowIdx !== -1) {
              this.fadeOutIntervalDrop();
              // this.fadeInVerticalDrop();
              this.fadeOutVerticalDrop();
              this.fadeInIntervalDrop(chrLeft, chrRight, unpaddedStart, unpaddedStop, start, stop);
            }
          });
          const proteinCodingEl = document.getElementById(`protein_coding_idx_${(rowIndex - 1)}`);
          if (proteinCodingEl) proteinCodingEl.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" }); 
          break;
        }
        case Constants.applicationRegionTypes.transcriptionFactors: {
          this.setState({
            selectedTranscriptionFactorsBeingUpdated: true,
            selectedTranscriptionFactorsRowIdx: parseInt(rowIndex),
            selectedTranscriptionFactorsChrLeft: chrLeft,
            selectedTranscriptionFactorsChrRight: chrRight,
            selectedTranscriptionFactorsStart: parseInt(start),
            selectedTranscriptionFactorsStop: parseInt(stop)
          }, () => {
            console.log("calling [updateViewerURL] from [openViewerAtChrPosition] for [transcriptionFactors]");
            this.updateViewerURL(this.state.hgViewParams.mode,
                                 chrLeft,
                                 chrRight,
                                 start,
                                 stop,
                                 true,
                                 "openViewerAtChrPosition (B)");
            // this.updateScale();
            if (this.state.selectedTranscriptionFactorsRowIdx !== -1) {
              this.fadeOutIntervalDrop();
              //this.fadeInVerticalDrop();
              this.fadeOutVerticalDrop();
              this.fadeInIntervalDrop(chrLeft, chrRight, unpaddedStart, unpaddedStop, start, stop);
            }
          });
          const transcriptionFactorEl = document.getElementById(`transcription_factor_idx_${(rowIndex - 1)}`);
          if (transcriptionFactorEl) transcriptionFactorEl.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
          break;
        }
        case Constants.applicationRegionTypes.roi: {
          this.setState({
            selectedRoiBeingUpdated: true,
            selectedRoiRowIdx: parseInt(rowIndex),
            selectedRoiChrLeft: chrLeft,
            selectedRoiChrRight: chrRight,
            selectedRoiStart: parseInt(start),
            selectedRoiStop: parseInt(stop),
            // drawerContentKey: this.state.drawerContentKey + 1
          }, () => {
            // console.log("calling [updateViewerURL] from [openViewerAtChrPosition] for [roi]");
            // console.log("this.state.selectedRoiRowIdx", this.state.selectedRoiRowIdx);
            this.updateViewerURL(this.state.hgViewParams.mode,
                                 chrLeft,
                                 chrRight,
                                 start,
                                 stop,
                                 true,
                                 "openViewerAtChrPosition (C)");
            // this.updateScale();
            if (this.state.selectedRoiRowIdx !== -1) {
              // this.fadeInVerticalDrop();
              // this.fadeInIntervalDrop(chrLeft, chrRight, unpaddedStart, unpaddedStop, start, stop);
              switch (this.state.roiMode) {
                case "default":
                  this.fadeOutVerticalDrop();
                  this.fadeInIntervalDrop(chrLeft, chrRight, unpaddedStart, unpaddedStop, start, stop);
                  break;
                case "midpoint":
                  this.fadeOutIntervalDrop();
                  this.fadeInVerticalDrop();
                  break;
                default:
                  throw new URIError("Unknown ROI mode");
                  // break;
              }
            }
            // obtain ROI table row element from sort order, not from raw element id
            const roiTdIdxNeeded = this.state.roiTableDataIdxBySort.indexOf(rowIndex);
            const roiTableEl = document.getElementById('drawer-content-roi-table');
            const roiTbodyEl = roiTableEl.getElementsByTagName('tbody')[0];
            const roiTrEls = roiTbodyEl.getElementsByTagName('tr');
            const roiTrEl = roiTrEls[roiTdIdxNeeded];
            const roiTdEls = roiTrEl.cells;
            roiTdEls[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
            // const roiEl = document.getElementById(`roi_idx_${(rowIndex - 1)}`);
            // if (roiEl) roiEl.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" }); 
            this.setState({
              selectedRoiBeingUpdated: false,
            });
          });
          break;
        }          
        default:
          break;
      } 
    }, 1000);
  }
  
  openViewerAtChrRange = (range, applyPadding) => {
    // console.log(`openViewerAtChrRange ${JSON.stringify(range)} | ${JSON.stringify(applyPadding)}`);
    let chrLeft = range[0];
    let chrRight = range[0];
    let start = parseInt(range[1]);
    let stop = parseInt(range[2]);
    if (applyPadding) {
      const padding = parseInt(Constants.defaultHgViewGenePaddingFraction * (stop - start));
      const ub = Constants.assemblyBounds[this.state.hgViewParams.genome][chrRight]['ub'];
      start = ((start - padding) > 0) ? (start - padding) : 0;
      stop = ((stop + padding) < ub) ? (stop + padding) : ub;
    }
    // this.hgViewUpdatePosition(this.state.hgViewParams.genome, chrLeft, start, stop, chrRight, start, stop, 0);
    this.hgViewUpdatePosition(this.state.hgViewParams.genome, chrLeft, start, stop, chrRight, start, stop);
    console.log("calling [updateViewerURL] from [openViewerAtChrRange]");
    this.updateViewerURL(this.state.hgViewParams.mode,
                         chrLeft,
                         chrRight,
                         start,
                         stop,
                         true,
                         "openViewerAtChrRange",);
    // this.updateScale();
  }
  
  roiRawURL = (param) => {
    return decodeURIComponent(param);
  }

  roiRegionsUpdate = (data, cb, self) => {
    // console.log("roiRegionsUpdate", data);
    // regions represent raw lines from the incoming data
    // table data represent processed lines from regions, organized into fields
    const dataRegions = data.split(/\r\n|\r|\n/);
    // we set up a template object to hold a row of BED6 data (with placeholders)
    const roiTableRow = {
      'idx' : 0,
      'chrom' : '.',
      'chromStart' : 0,
      'chromEnd' : 0,
      'name' : '.',
      'score' : 0.0,
      'strand' : '.',
      'element' : {
        'position' : '.',
        'paddedPosition' : '.'
      }
    };
    let roiTableRows = [];
    let roiTableRowsCopy = [];
    let roiTableRowsIdxBySort = [];
    //
    // input should contain chromosomes that match the selected genome
    //
    const validChroms = Object.keys(Constants.assemblyBounds[this.state.hgViewParams.genome]);
    //
    // it is possible that the lines will not contain BED data, or will not have all fields
    // we validate input to try to ensure that the ROI drawer content will not contain garbage regions
    //
    function isNormalInteger(str) {
      return /^\+?(0|[1-9]\d*)$/.test(str);
    }    
    let newRoiMaxColumns = this.state.roiMaxColumns;
    let newRoiTableDataLongestNameLength = this.state.roiTableDataLongestNameLength;
    let lineCount = 0;
    //
    // parse data
    //
    for (const line of dataRegions) {
      // console.log("line", line);
      if (line.length === 0) { continue; }
      lineCount += 1;
      // we only add up to maximum number of elements
      if (lineCount > Constants.defaultApplicationRoiLineLimit) break;
      const elems = line.split(/\t/);
      let columns = elems.length;
      //      
      // not enough columns to make up a minimal BED file
      //
      if (columns < 3) {
        // console.log(`Input regions are missing columns (line ${lineCount})`);
        const err = {
          "response" : {
            "status" : 400,
            "statusText" : "Malformed input"
          }
        };
        const msg = this.errorMessage(err, `Input regions are missing columns (line ${lineCount})`, this.state.roiRawURL);
        this.setState({
          overlayMessage: msg
        }, () => {
          this.fadeInOverlay();
        });      
        return;
      }
      //
      // if the line does not have start or stop coordinates, then we send an error and return early
      //
      if (!isNormalInteger(elems[1]) || !isNormalInteger(elems[2])) {
        // console.log(`Input regions have non-coordinate data (line ${lineCount})`);
        const err = {
          "response" : {
            "status" : 400,
            "statusText" : "Malformed input"
          }
        };
        const msg = this.errorMessage(err, `Input regions have non-coordinate data (line ${lineCount})`, this.state.roiRawURL);
        this.setState({
          overlayMessage: msg
        }, () => {
          this.fadeInOverlay();
        });       
        return;
      }
      //
      // if the first element in elems is not a valid chromosome name from selected genome, report error and return early
      //
      if (!validChroms.includes(elems[0])) {
        //console.log(`Input regions have bad chromosome names (line ${lineCount})`);
        const err = {
          "response" : {
            "status" : 400,
            "statusText" : "Malformed input"
          }
        };
        const msg = this.errorMessage(err, `Input regions have bad chromosome names (line ${lineCount})`, this.state.roiRawURL);
        this.setState({
          overlayMessage: msg
        }, () => {
          this.fadeInOverlay();
        }); 
        return;
      }
      //
      // update maximum number of columns
      //
      if (columns > newRoiMaxColumns) {
        newRoiMaxColumns = columns;
      }
      //
      // clone a row object from template
      //
      const row = Object.assign({}, roiTableRow);
      //
      // populate row with content from elems
      //
      row.idx = lineCount;
      row.chrom = elems[0];
      row.chromStart = parseInt(elems[1]);
      row.chromEnd = parseInt(elems[2]);
      row.position = row.chrom + ':' + row.chromStart + '-' + row.chromEnd;
      //
      let paddedPosition = Helpers.zeroPad(row.chrom.replace(/chr/, ''), 3) + ':' + Helpers.zeroPad(row.chromStart, 12) + '-' + Helpers.zeroPad(row.chromEnd, 12);
      if (isNaN(row.chrom.replace(/chr/, ''))) {
        paddedPosition = row.chrom.replace(/chr/, '') + ':' + Helpers.zeroPad(row.chromStart, 12) + '-' + Helpers.zeroPad(row.chromEnd, 12);
      }
      //
      row.paddedPosition = paddedPosition;
      row.element = {
        "position" : row.position.slice(),
        "paddedPosition" : row.paddedPosition.slice()
      };
      row.name = (columns > 3) ? elems[3] : '.';
      row.score = (columns > 4) ? elems[4] : 0.0;
      row.strand = (columns > 5) ? elems[5] : '.';
      // console.log("row", row);
      //
      // add row object to table data array
      //
      roiTableRows.push(row);     
      roiTableRowsCopy.push(row);
      roiTableRowsIdxBySort.push(row.idx);
      //
      //
      //
      if (row.name.length > newRoiTableDataLongestNameLength) newRoiTableDataLongestNameLength = row.name.length; 
    }
    //
    // update state
    //
    //console.log("roiTableRows", roiTableRows);
    if (self) {
      self.state.roiEnabled = true;
      self.state.roiRegions = dataRegions;
      self.state.roiTableData = roiTableRows;
      self.state.roiTableDataCopy = roiTableRowsCopy;
      self.state.roiTableDataIdxBySort = roiTableRowsIdxBySort;
      self.state.roiMaxColumns = newRoiMaxColumns;
      self.state.roiTableDataLongestNameLength = newRoiTableDataLongestNameLength;
      const queryObj = Helpers.getJsonFromUrl();
      const activeTab = (queryObj.activeTab) ? queryObj.activeTab : "roi";
      // const firstRoi = roiTableRows[(self.state.selectedRoiRowIdxOnLoad !== Constants.defaultApplicationSrrIdx) ? self.state.selectedRoiRowIdxOnLoad - 1 : 0];
      // const region = firstRoi.position;
      // const regionType = Constants.applicationRegionTypes.roi;
      const rowIndex = (self.state.selectedRoiRowIdxOnLoad !== Constants.defaultApplicationSrrIdx) ? self.state.selectedRoiRowIdxOnLoad : 1;
      // const strand = firstRoi.strand;
      self.state.drawerActiveTabOnOpen = activeTab;
      self.state.selectedRoiRowIdx = parseInt(rowIndex);
      // this.jumpToRegion(region, regionType, rowIndex, strand);
      // this.updateViewportDimensions();
      // self.state.drawerIsOpen = true;
      setTimeout(() => {
        if (cb) {
          cb(self);
/*
          setTimeout(() => { 
            self.jumpToRegion(region, regionType, rowIndex, strand);
          }, 1000);
*/
        }
      }, 1000);
    }
    else {
      this.setState({
        roiEnabled: true,
        roiRegions: dataRegions,
        roiTableData: roiTableRows,
        roiTableDataCopy: roiTableRowsCopy,
        roiTableDataIdxBySort: roiTableRowsIdxBySort,
        roiMaxColumns: newRoiMaxColumns,
        roiTableDataLongestNameLength: newRoiTableDataLongestNameLength,
      }, () => {
        //
        // let the callback know that ROI data is available
        //
        if (cb) {
          cb(this);
        }
        //console.log("this.state.roiTableData", this.state.roiTableData);
        //console.log("this.state.roiTableDataIdxBySort", this.state.roiTableDataIdxBySort);
  /*
        if (document.getElementById("drawer-content-roi-table")) {
          console.log("drawer-content-roi-table", parseInt(document.getElementById("drawer-content-roi-table")));
        }
  */
        const queryObj = Helpers.getJsonFromUrl();
        //console.log("queryObj", JSON.stringify(queryObj));
        const activeTab = (queryObj.activeTab) ? queryObj.activeTab : "roi";
        setTimeout(() => {
          const firstRoi = roiTableRows[(this.state.selectedRoiRowIdxOnLoad !== Constants.defaultApplicationSrrIdx) ? this.state.selectedRoiRowIdxOnLoad - 1 : 0];
          try {
            const region = firstRoi.position;
            const regionType = Constants.applicationRegionTypes.roi;
            const rowIndex = (this.state.selectedRoiRowIdxOnLoad !== Constants.defaultApplicationSrrIdx) ? this.state.selectedRoiRowIdxOnLoad : 1;
            //console.log("rowIndex", rowIndex);
            const strand = firstRoi.strand;
            this.setState({
              //drawerIsOpen: true,
              drawerActiveTabOnOpen: activeTab,
              drawerContentKey: this.state.drawerContentKey + 1,
              selectedRoiRowIdx: parseInt(rowIndex)
            }, () => {
              //console.log("state", JSON.stringify(this.state));
              setTimeout(() => {
                this.jumpToRegion(region, regionType, rowIndex, strand);
              }, 0);
              this.updateViewportDimensions();
/*
              setTimeout(() => {
                this.setState({
                  drawerIsOpen: true
                });
              }, 1000); 
*/           
            });
          }
          catch (e) {
            if (e instanceof TypeError) {
              throw new Error(`ROI parsing error ${JSON.stringify(roiTableRows)}`)
            }
          }
        }, 2000);
      });
    }
  }  

  updateRois = (roiEncodedURL, cb) => {
    // decode to test validity, re-encode to submit to proxy
    let roiRawURL = this.roiRawURL(roiEncodedURL);
    // console.log(`roiRawURL ${roiRawURL}`);
    if (validator.isURL(roiRawURL)) {
      let reencodedRoiURL = encodeURIComponent(roiRawURL);
      this.setState({
        roiEncodedURL: reencodedRoiURL,
        roiRawURL: roiRawURL
      }, () => {
        //console.log("this.state.roiEncodedURL", this.state.roiEncodedURL);
        let proxyRoiURL = `${Constants.urlProxyURL}/${this.state.roiEncodedURL}`;
        //console.log("proxyRoiURL", proxyRoiURL);
        axios.get(proxyRoiURL)
          .then((res) => {
            if (res.data) { 
              this.roiRegionsUpdate(res.data, cb);
            }
            
          })
          .catch((err) => {
            // console.log(`err ${JSON.stringify(err)}`);
            const msg = this.errorMessage(err, `Regions-of-interest URL is invalid`, roiRawURL);
            this.setState({
              roiEnabled: false,
              overlayMessage: msg
            }, () => {
              this.fadeInOverlay();
            });
            return;
          });
      })
    }
    else {
      const err = {
        "response" : {
          "status" : 400,
          "statusText" : "Malformed URL"
        }
      };
      // console.log(`err ${JSON.stringify(err)}`);
      const msg = this.errorMessage(err, `Regions-of-interest URL is invalid`, roiRawURL);
      this.setState({
        roiEnabled: false,
        overlayMessage: msg
      }, () => {
        this.fadeInOverlay();
      });
      return;
    }
  }
  
  proteinCodingsDownloadURL = (genome) => {
    let downloadURL = Helpers.stripQueryStringAndHashFromPath(document.location.href) + `/assets/exemplars/${genome}/proteinCodings/top5PerSystem.txt`;
    //console.log("proteinCodingsDownloadURL", downloadURL);
    return downloadURL;
  }
  
  updateProteinCodings = (genome) => {
    // read exemplars into memory
    let proteinCodingsURL = this.proteinCodingsDownloadURL(genome);
    
    if (proteinCodingsURL) {
      axios.get(proteinCodingsURL)
        .then((res) => {
          if (!res.data) {
            throw String("Error: Protein-coding genes not returned from query to " + proteinCodingsURL);
          }
          this.setState({
            proteinCodingsJumpActive: true,
            proteinCodingsRegions: res.data.split('\n')
          }, () => { 
            //console.log("exemplarRegions", this.state.exemplarRegions); 
            let data = [];
            let dataCopy = [];
            let dataIdxBySort = [];
            let systems = {};
            this.state.proteinCodingsRegions.forEach((val, idx) => {
              let elem = val.split('\t');
              let chrom = elem[0];
              let start = elem[1];
              let stop = elem[2];
              let gene = elem[3];
              let system = elem[4];
              let rank = elem[5];
              let strand = elem[6];
              if (!chrom) return;
              //console.log("chrom, start, stop, state", chrom, start, stop, state);
              let paddedPosition = Helpers.zeroPad(chrom.replace(/chr/, ''), 3) + ':' + Helpers.zeroPad(parseInt(start), 12) + '-' + Helpers.zeroPad(parseInt(stop), 12);
              if (isNaN(chrom.replace(/chr/, ''))) {
                paddedPosition = chrom.replace(/chr/, '') + ':' + Helpers.zeroPad(parseInt(start), 12) + '-' + Helpers.zeroPad(parseInt(stop), 12);
              }
              let paddedNumerical = Helpers.zeroPad(parseInt(rank), 3);
              data.push({ 
                'idx' : idx + 1,
                'position' : chrom + ':' + start + '-' + stop,
                'gene' : gene,
                'system' : system,
                'rank' : {
                  'numerical' : rank,
                  'paddedNumerical' : paddedNumerical
                },
                'element' : {
                  'paddedPosition' : paddedPosition,
                  'position' : chrom + ':' + start + '-' + stop,
                  'system' : system,
                  'rank' : rank,
                  'strand' : strand
                }
              });
              dataCopy.push({
                'idx' : idx + 1,
                'element' : paddedPosition,
                'gene' : gene,
                'system' : system,
                'rank' : paddedNumerical,
                'strand' : strand
              });
              dataIdxBySort.push(idx + 1);
              systems[system] = 0;
            });
            this.setState({
              proteinCodingsTableData: data,
              proteinCodingsTableDataCopy: dataCopy,
              proteinCodingsTableDataIdxBySort: dataIdxBySort,
              proteinCodingsSystems: Object.keys(systems)
            }, () => {
              //console.log("this.state.transcriptionFactorsTableData", this.state.transcriptionFactorsTableData);
              //console.log("this.state.transcriptionFactorsTableDataCopy", this.state.transcriptionFactorsTableDataCopy);
              //console.log("this.state.transcriptionFactorsTableDataIdxBySort", this.state.transcriptionFactorsTableDataIdxBySort);
              //console.log("this.state.transcriptionFactorsSystems", this.state.transcriptionFactorsSystems);
            });
          });
        })
        .catch((err) => {
          //console.log(err.response);
          let msg = this.errorMessage(err, `Could not retrieve protein-coding gene data`, proteinCodingsURL);
          this.setState({
            overlayMessage: msg
          }, () => {
            this.fadeInOverlay();
          });
        });
    }
  }
  
  transcriptionFactorsDownloadURL = (genome) => {
    let downloadURL = Helpers.stripQueryStringAndHashFromPath(document.location.href) + `/assets/exemplars/${genome}/transcriptionFactors/top5PerSystem.txt`;
    //console.log("transcriptionFactorsDownloadURL", downloadURL);
    return downloadURL;
  }
  
  updateTranscriptionFactors = (genome) => {
    // read exemplars into memory
    let transcriptionFactorsURL = this.transcriptionFactorsDownloadURL(genome);
    
    if (transcriptionFactorsURL) {
      axios.get(transcriptionFactorsURL)
        .then((res) => {
          if (!res.data) {
            throw String("Error: TFs not returned from query to " + transcriptionFactorsURL);
          }
          this.setState({
            transcriptionFactorsJumpActive: true,
            transcriptionFactorsRegions: res.data.split('\n')
          }, () => { 
            //console.log("exemplarRegions", this.state.exemplarRegions); 
            let data = [];
            let dataCopy = [];
            let dataIdxBySort = [];
            let systems = {};
            this.state.transcriptionFactorsRegions.forEach((val, idx) => {
              let elem = val.split('\t');
              let chrom = elem[0];
              let start = elem[1];
              let stop = elem[2];
              let gene = elem[3];
              let system = elem[4];
              let rank = elem[5];
              let strand = elem[6];
              if (!chrom) return;
              //console.log("chrom, start, stop, state", chrom, start, stop, state);
              let paddedPosition = Helpers.zeroPad(chrom.replace(/chr/, ''), 3) + ':' + Helpers.zeroPad(parseInt(start), 12) + '-' + Helpers.zeroPad(parseInt(stop), 12);
              if (isNaN(chrom.replace(/chr/, ''))) {
                paddedPosition = chrom.replace(/chr/, '') + ':' + Helpers.zeroPad(parseInt(start), 12) + '-' + Helpers.zeroPad(parseInt(stop), 12);
              }
              let paddedNumerical = Helpers.zeroPad(parseInt(rank), 3);
              data.push({ 
                'idx' : idx + 1,
                'position' : chrom + ':' + start + '-' + stop,
                'gene' : gene,
                'system' : system,
                'rank' : {
                  'numerical' : rank,
                  'paddedNumerical' : paddedNumerical
                },
                'element' : {
                  'paddedPosition' : paddedPosition,
                  'position' : chrom + ':' + start + '-' + stop,
                  'system' : system,
                  'rank' : rank,
                  'strand' : strand
                }
              });
              dataCopy.push({
                'idx' : idx + 1,
                'element' : paddedPosition,
                'gene' : gene,
                'system' : system,
                'rank' : paddedNumerical,
                'strand' : strand
              });
              dataIdxBySort.push(idx + 1);
              systems[system] = 0;
            });
            this.setState({
              transcriptionFactorsTableData: data,
              transcriptionFactorsTableDataCopy: dataCopy,
              transcriptionFactorsTableDataIdxBySort: dataIdxBySort,
              transcriptionFactorsSystems: Object.keys(systems)
            }, () => {
              //console.log("this.state.transcriptionFactorsTableData", this.state.transcriptionFactorsTableData);
              //console.log("this.state.transcriptionFactorsTableDataCopy", this.state.transcriptionFactorsTableDataCopy);
              //console.log("this.state.transcriptionFactorsTableDataIdxBySort", this.state.transcriptionFactorsTableDataIdxBySort);
              //console.log("this.state.transcriptionFactorsSystems", this.state.transcriptionFactorsSystems);
            });
          });
        })
        .catch((err) => {
          //console.log(err.response);
          let msg = this.errorMessage(err, `Could not retrieve TF data`, transcriptionFactorsURL);
          this.setState({
            overlayMessage: msg
          }, () => {
            this.fadeInOverlay();
          });
        });
    }
  }
  
  onMouseEnterDownload = () => {
    //console.log("onMouseEnterDownload()");
  }
  
  onMouseClickDownload = () => {
    //console.log("onMouseClickDownload()");
    // get dimensions of download button (incl. padding and margin)
    let downloadButtonBoundingRect = document.getElementById('navigation-summary-download').getBoundingClientRect();
    let downloadPopupBoundingRect = document.getElementById('navigation-summary-download-popup').getBoundingClientRect();
    this.setState({
      downloadButtonBoundingRect: downloadButtonBoundingRect,
      downloadPopupBoundingRect: downloadPopupBoundingRect
    }, () => {
      this.setState({
        downloadIsVisible: !this.state.downloadIsVisible
      })
    });
  }
  
  onMouseLeaveDownload = () => {
    // console.log("onMouseLeaveDownload()");
    this.setState({
      downloadIsVisible: false
    });
  }
  
  fadeInVerticalDrop = (leftOffsetPx, cb) => {
    // console.log("fadeInVerticalDrop");
    // if (this.state.isMobile) return;
    // this.epilogosViewerContainerVerticalDrop.style.opacity = 1;
    // this.epilogosViewerContainerVerticalDrop.style.display = "contents";
    // this.epilogosViewerContainerVerticalDrop.style.left = leftOffsetPx;
    // this.epilogosViewerContainerOverlay.style.transition = "opacity 1s 1s";
    setTimeout(() => {
      if (cb) { cb(); }
      //console.log("setting selected[ProteinCodings|TranscriptionFactors|Roi]BeingUpdated to false");
      // this.setState({
      //   selectedProteinCodingsBeingUpdated: false,
      //   selectedTranscriptionFactorsBeingUpdated: false,
      //   selectedRoiBeingUpdated: false
      // })
    }, 500);
  }
  
  updateVerticalDrop = (cb) => {
    // console.log("updateVerticalDrop");
    setTimeout(() => {
      if (cb) { cb(); }
    }, 500);
  }
  
  fadeOutVerticalDrop = (cb) => {
    // console.log("fadeOutVerticalDrop");
    // this.epilogosViewerContainerVerticalDrop.style.opacity = 0;
    // this.epilogosViewerContainerVerticalDrop.style.display = "none";
    // this.epilogosViewerContainerOverlay.style.transition = "opacity 1s 1s";
    setTimeout(() => {
      if (cb) { cb(); }
    }, 500);
  }
  
  fadeInIntervalDrop = (chrLeft, chrRight, unpaddedStart, unpaddedStop, paddedStart, paddedStop, cb) => {
    // if (this.state.isMobile) return;
    // console.log("fadeInIntervalDrop", chrLeft, chrRight, unpaddedStart, unpaddedStop, paddedStart, paddedStop);
    
    // let genome = this.state.hgViewParams.genome;

    // const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, genome);
    // //let chromInfoCacheExists = this.chromInfoCache.hasOwnProperty(genome);

    // function updateViewerStateForChromInfo(chromInfo, self) {
    //   const windowInnerWidth = document.documentElement.clientWidth + "px";
    //   const rescale = (min, max, x) => (x - min) / (max - min);
    //   let chrUnpaddedStartPos = chromInfo.chrToAbs([chrLeft, unpaddedStart]);
    //   let chrUnpaddedStopPos = chromInfo.chrToAbs([chrRight, unpaddedStop]);
    //   let chrPaddedStartPos = chromInfo.chrToAbs([chrLeft, paddedStart]);
    //   let chrPaddedStopPos = chromInfo.chrToAbs([chrRight, paddedStop]);
      
    //   self.epilogosViewerContainerIntervalDropLeftTop.style.left = parseInt(rescale(chrPaddedStartPos, chrPaddedStopPos, chrUnpaddedStartPos) * parseInt(windowInnerWidth)) + "px";
    //   self.epilogosViewerContainerIntervalDropLeftBottom.style.left = self.epilogosViewerContainerIntervalDropLeftTop.style.left;
    //   self.epilogosViewerContainerIntervalDropRightTop.style.left = parseInt(rescale(chrPaddedStartPos, chrPaddedStopPos, chrUnpaddedStopPos) * parseInt(windowInnerWidth)) + "px";
    //   self.epilogosViewerContainerIntervalDropRightBottom.style.left = self.epilogosViewerContainerIntervalDropRightTop.style.left;
      
    //   self.epilogosViewerContainerIntervalDropRegionIntervalIndicator.style.width = parseInt(self.epilogosViewerContainerIntervalDropRightTop.style.left) - parseInt(self.epilogosViewerContainerIntervalDropLeftTop.style.left) + "px";
    //   self.epilogosViewerContainerIntervalDropRegionIntervalIndicator.style.left = parseInt(self.epilogosViewerContainerIntervalDropLeftTop.style.left) + "px";
      
    //   self.epilogosViewerContainerIntervalDrop.style.opacity = 1;
      
    //   self.setState({
    //     regionIndicatorOuterWidth: parseInt(self.epilogosViewerContainerIntervalDropRegionIntervalIndicator.style.width)
    //   });

    //   setTimeout(() => {
    //     if (cb) { cb(); }
    //     //console.log("setting selectedRoiBeingUpdated to false");
    //     self.setState({
    //       selectedProteinCodingsBeingUpdated: false,
    //       selectedTranscriptionFactorsBeingUpdated: false,
    //       selectedRoiBeingUpdated: false
    //     })
    //   }, 1000);
    // }

    // if (chromInfoCacheExists) {
    //   updateViewerStateForChromInfo(this.chromInfoCache[genome], this);
    // }
    // else {
    //   let chromSizesURL = this.getChromSizesURL(genome);
    //   ChromosomeInfo(chromSizesURL)
    //     .then((chromInfo) => {
    //       this.chromInfoCache[genome] = Object.assign({}, chromInfo);
    //       updateViewerStateForChromInfo(chromInfo, this);
    //     })
    //     .catch((err) => {
    //       throw new Error(`Error - [fadeInIntervalDrop] failed to fetch chromosome information - ${JSON.stringify(err)}`);
    //     });
    // }
  }
  
  fadeOutIntervalDrop = (cb) => {
    // this.epilogosViewerContainerIntervalDrop.style.opacity = 0;
    setTimeout(() => {
      if (cb) { cb(); }
    }, 500);
  }
  
  fadeInContainerOverlay = (cb) => {
    this.setState({
      tabixDataDownloadCommandCopied: false
    }, () => {
      this.epilogosViewerContainerOverlay.style.opacity = 1;
      this.epilogosViewerContainerOverlay.style.transition = "opacity 0.5s 0.5s";
      this.epilogosViewerContainerOverlay.style.pointerEvents = "auto";
      setTimeout(() => {
        if (cb) { cb(); }
      }, 500);
    })
  }
  
  fadeOutContainerOverlay = (cb) => {
    this.epilogosViewerContainerOverlay.style.opacity = 0;
    this.epilogosViewerContainerOverlay.style.transition = "opacity 0.5s 0.5s";
    setTimeout(() => {
      this.epilogosViewerContainerOverlay.style.pointerEvents = "none";
      if (cb) { cb(); }
    }, 500);
  }
  
  onClickDownloadItemSelect = (name) => {
    let coord = this.state.currentPosition;
    let params = this.state.hgViewParams;
    let genome = params.genome;
    let tabixURL = `${Constants.applicationTabixRootURL}/masterlist_DHSs_733samples_WM20180608_all.gz`;
    let tabixRange = "";
    let chrs = [];
    let posns = {};
    let addChr = false;
    let startChr = false;
    let endChr = false;
    switch (name) {
      case "tabix-service-index": {
        if (coord.chrLeft === coord.chrRight) {
          tabixRange = `${coord.chrLeft}:${coord.startLeft}-${coord.stopRight}`;
        }
        else {
          Object.keys(Constants.assemblyBounds[genome]).forEach((chr) => {
            if (chr === coord.chrLeft) { addChr = true; startChr = true; }
            if (chr === coord.chrRight) { endChr = true; }
            if (addChr) { chrs.push(chr); }
            if (startChr) { 
              // tabix is 1-indexed
              posns[chr] = {'start': (parseInt(coord.startLeft) + 1), 'stop': parseInt(Constants.assemblyBounds[genome][chr].ub) };
              startChr = false; 
            }
            else if (endChr) {
              posns[chr] = {'start': 1, 'stop': parseInt(coord.stopRight) };
              endChr = false;
            }
            else {
              posns[chr] = {'start': 1, 'stop': parseInt(Constants.assemblyBounds[genome][chr].ub) };
            }
            if (chr === coord.chrRight) { addChr = false; }
          })
          chrs.sort();
          chrs.forEach((chr) => {
            tabixRange += `${chr}:${posns[chr].start}-${posns[chr].stop} `;
          });
        }
/*
        console.log(`tabixRange ${tabixRange}`);
        console.log(`tabixURL ${tabixURL}`);
        let tabixServiceURL = `${Constants.tabixServiceURL}/?dataset=${encodeURIComponent(tabixURL)}&range=${encodeURIComponent(tabixRange)}`;
        console.log(`tabixServiceURL ${tabixServiceURL}`);
*/
        axios.get(Constants.tabixServiceURL, {
          params: {
            dataset: encodeURIComponent(tabixURL),
            range: encodeURIComponent(tabixRange)
          },
        }).then((res) => {
          // column names derived from viewerDataNotice() table content
          const bedData = `Seqname\tStart\tEnd\tIdentifier\tMean_signal\tSummit\tComponent\n${res.data}`;
          const bedUrl = window.URL.createObjectURL(new Blob([bedData]));
          const bedLink = document.createElement('a');
          bedLink.href = bedUrl;
          bedLink.setAttribute('download', ["index", params.genome, coord.chrLeft + '_' + coord.startLeft + '-' + coord.chrRight + '_' + coord.stopRight, "txt"].join("."));
          document.body.appendChild(bedLink);
          bedLink.click();
        })
        .catch(function(err) {
          throw Error(err);
        })
        .finally(function() {
          //console.log("Tabix service export attempt is complete");
        });
        break;
      }
      case "tabix-index": {
        // if chrs are equal, we can spit out a range directly
        if (coord.chrLeft === coord.chrRight) {
          tabixRange = `${coord.chrLeft}:${coord.startLeft}-${coord.stopRight}`;
        }
        else {
          Object.keys(Constants.assemblyBounds[genome]).forEach((chr) => {
            if (chr === coord.chrLeft) { addChr = true; startChr = true; }
            if (chr === coord.chrRight) { endChr = true; }
            if (addChr) { chrs.push(chr); }
            if (startChr) { 
              // tabix is 1-indexed
              posns[chr] = {'start': (parseInt(coord.startLeft) + 1), 'stop': parseInt(Constants.assemblyBounds[genome][chr].ub) };
              startChr = false; 
            }
            else if (endChr) {
              posns[chr] = {'start': 1, 'stop': parseInt(coord.stopRight) };
              endChr = false;
            }
            else {
              posns[chr] = {'start': 1, 'stop': parseInt(Constants.assemblyBounds[genome][chr].ub) };
            }
            if (chr === coord.chrRight) { addChr = false; }
          })
          chrs.sort();
          chrs.forEach((chr) => {
            tabixRange += `${chr}:${posns[chr].start}-${posns[chr].stop} `;
          });
        }
        let tabixDataDownloadCommand = `tabix ${tabixURL} ${tabixRange}`;
        this.setState({
          tabixDataDownloadCommand: tabixDataDownloadCommand
        }, () => {
          // fade in container overlay
          this.fadeInContainerOverlay(() => { 
            //console.log("faded in!"); 
            this.setState({
              tabixDataDownloadCommandVisible: true
            });
          });
        })
        break;
      }
      case "png": 
      case "svg": {
        // 
        // some unresolved issue with the multivec heatmap requires the view 
        // configuration to be reloaded in browser memory, before exporting PNG or SVG
        // which causes an annoying "blink" 
        //
        let newHgViewconf = this.state.mainHgViewconf;
        const queryObj = Helpers.getJsonFromUrl();
        let chrLeft = queryObj.chrLeft || this.state.currentPosition.chrLeft;
        let chrRight = queryObj.chrRight || this.state.currentPosition.chrRight;
        let start = parseInt(queryObj.start || this.state.currentPosition.startLeft);
        let stop = parseInt(queryObj.stop || this.state.currentPosition.stopRight);
        let currentGenome = queryObj.genome || this.state.hgViewParams.genome;

        //let chromInfoCacheExists = this.chromInfoCache.hasOwnProperty(currentGenome);
        const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, currentGenome);

        const exportImageForChromInfo = function(chromInfo, self) {
          if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
            chrLeft = Constants.defaultApplicationPositions[currentGenome].chr;
            chrRight = Constants.defaultApplicationPositions[currentGenome].chr;
            start = Constants.defaultApplicationPositions[currentGenome].start;
            stop = Constants.defaultApplicationPositions[currentGenome].stop;
          }
          if (start > chromInfo.chromLengths[chrLeft]) {
            start = chromInfo.chromLengths[chrLeft] - 10000;
          }
          if (stop > chromInfo.chromLengths[chrRight]) {
            stop = chromInfo.chromLengths[chrRight] - 1000;
          }
          let absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
          let absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
          newHgViewconf.views[0].initialXDomain = [absLeft, absRight];
          newHgViewconf.views[0].initialYDomain = [absLeft, absRight];
          self.setState({
            mainHgViewconf: newHgViewconf,
          }, () => {
            self.setState({
              hgViewKey: self.state.hgViewKey + 1
            }, () => {
              setTimeout(() => {
                if (name === "svg") {
                  let svgStr = self.hgView.api.exportAsSvg();
                  // cf. https://github.com/higlass/higlass/issues/651
                  let fixedSvgStr = svgStr.replace('xmlns="http://www.w3.org/1999/xhtml"', '');
                  //let fixedSvgStr = svgStr;
                  let svgFile = new File([fixedSvgStr], ["index", params.genome, coord.chrLeft + '_' + coord.startLeft + '-' + coord.chrRight + '_' + coord.stopRight, "svg"].join("."), {type: "image/svg+xml;charset=utf-8"});
                  saveAs(svgFile);  
                }
                else if (name === "png") {
                  let pngPromise = self.hgView.api.exportAsPngBlobPromise();
                  pngPromise
                    .then((blob) => {
                      //console.log(blob);
                      let reader = new FileReader(); 
                      reader.addEventListener("loadend", function() {
                        let array = new Uint8Array(reader.result);
                        //console.log(new TextDecoder("iso-8859-2").decode(array));
                        let pngBlob = new Blob([array], {type: "image/png"});
                        saveAs(pngBlob, ["index", params.genome, coord.chrLeft + '_' + coord.startLeft + '-' + coord.chrRight + '_' + coord.stopRight, "png"].join("."));
                      }); 
                      reader.readAsArrayBuffer(blob);
                    })
                    .catch(function(err) {
                      throw Error(err);
                    })
                    .finally(function() {
                      //console.log("PNG export attempt is complete");
                    });
                }                  
              }, (queryObj.mode === "ac") ? 5000 : 2500);
            });
          });
        }

        if (chromInfoCacheExists) {
          //console.log(`this.state ${JSON.stringify(this.state)}`);
          exportImageForChromInfo(this.chromInfoCache[currentGenome], this);
        }
        else {
          let chromSizesURL = this.getChromSizesURL(currentGenome);
          ChromosomeInfo(chromSizesURL)
            .then((chromInfo) => {
              this.chromInfoCache[currentGenome] = Object.assign({}, chromInfo);
              exportImageForChromInfo(chromInfo, this);
            })
            .catch((err) => {
              let msg = this.errorMessage(err, `Could not retrieve chromosome information`, chromSizesURL);
              this.setState({
                overlayMessage: msg,
                mainHgViewconf: {}
              }, () => {
                this.fadeInOverlay();
              });
            });
        }      
        break;
      }
/*
      case "png":
        let pngPromise = this.hgView.api.exportAsPngBlobPromise();
        pngPromise
          .then((blob) => {
            //console.log(blob);
            let reader = new FileReader(); 
            reader.addEventListener("loadend", function() {
              let array = new Uint8Array(reader.result);
              //console.log(new TextDecoder("iso-8859-2").decode(array));
              let pngBlob = new Blob([array], {type: "image/png"});
              saveAs(pngBlob, ["epilogos", params.genome, params.model, Constants.complexitiesForDataExport[params.complexity], params.group, coord.chrLeft + '_' + coord.startLeft + '-' + coord.chrRight + '_' + coord.stopRight, "png"].join("."));
            }); 
            reader.readAsArrayBuffer(blob);
          })
          .catch(function(err) {
            throw Error(err);
          })
          .finally(function() {
            //console.log("PNG export attempt is complete");
          });
        break;
      case "svg":
        let svgStr = this.hgView.api.exportAsSvg();
        
        // cf. https://github.com/higlass/higlass/issues/651
        let fixedSvgStr = svgStr.replace('xmlns="http://www.w3.org/1999/xhtml"', '');
        //let fixedSvgStr = svgStr;
        
        let svgFile = new File([fixedSvgStr], ["epilogos", params.genome, params.model, Constants.complexitiesForDataExport[params.complexity], params.group, coord.chrLeft + '_' + coord.startLeft + '-' + coord.chrRight + '_' + coord.stopRight, "svg"].join("."), {type: "image/svg+xml;charset=utf-8"});
        saveAs(svgFile);
        break;
*/
      default:
        break;
    }
    this.setState({
      downloadIsVisible: false
    });
  }

  onClickDownloadDataCommand = (evt) => {
    if (evt) {
      this.setState({
        tabixDataDownloadCommandCopied: true
      }, () => {
        document.activeElement.blur();
      });
    }
  }

  onClickCopyRegionCommand = (evt) => {
    if (evt) {
      // console.log(`evt.clientX ${evt.clientX} | evt.clientY ${evt.clientY}`);
      this.setState({
        mousePosition: {x: evt.clientX, y: evt.clientY},
      }, () => {
        // console.log(`mousePosition ${JSON.stringify(this.state.mousePosition)}`);
        setTimeout(() => {
          this.setState({
            showingClipboardCopiedAlert: true,
          }, () => {
            //document.activeElement.blur();
            setTimeout(() => {
              this.setState({
                showingClipboardCopiedAlert: false,
              }, () => {
              });
            }, Constants.hideClipboardCopiedAlertTime);
          });
        }, 500);
      })
    }
  }
  
  parameterSummaryAsTitle = () => {
    let genome = this.state.hgViewParams.genome;
    let genomeText = Constants.genomes[genome];
    let group = this.state.hgViewParams.group;
    let groupText = Constants.groupsByGenome[genome][group].text;
    let model = this.state.hgViewParams.model;
    let modelText = Constants.models[model];
    let complexity = this.state.hgViewParams.complexity;
    let complexityText = Constants.complexitiesForDataExport[complexity];
    return `${genomeText} | ${modelText} | ${groupText} | ${complexityText}`;
  }
  
  parameterSummaryAsElement = () => {
    let genome = this.state.hgViewParams.genome;
    let genomeText = Constants.genomes[genome];
    let group = this.state.hgViewParams.group;
    let groupText = Constants.groupsByGenome[genome][group].text;
    let model = this.state.hgViewParams.model;
    let modelText = Constants.models[model];
    let complexity = this.state.hgViewParams.complexity;
    let complexityText = Constants.complexities[complexity];
    let divider = <div style={{paddingLeft:'5px',paddingRight:'5px'}}>|</div>;
    if (parseInt(this.state.width)<1124) {
      if (parseInt(this.state.width)<900) {
        if (parseInt(this.state.width)>=850) {
          return <div ref={(component) => this.epilogosViewerParameterSummary = component} id="navigation-summary-parameters" style={(this.state.isMobile)?{"display":"none","width":"0px","height":"0px"}:((parseInt(this.state.width)<1180)?{"display":"inline-flex","letterSpacing":"0.005em"}:{"display":"inline-flex"})} className="navigation-summary-parameters">{genomeText}{divider}{modelText}</div>;
        }
        else {
          return <div ref={(component) => this.epilogosViewerParameterSummary = component} id="navigation-summary-parameters" className="navigation-summary-parameters" />
        }
      }
      else {
        return <div ref={(component) => this.epilogosViewerParameterSummary = component} id="navigation-summary-parameters" style={(this.state.isMobile)?{"display":"none","width":"0px","height":"0px"}:((parseInt(this.state.width)<1180)?{"display":"inline-flex","letterSpacing":"0.005em"}:{"display":"inline-flex"})} className="navigation-summary-parameters">{genomeText}{divider}{modelText}{divider}<span dangerouslySetInnerHTML={{ __html: complexityText }} /></div>;
      }
    }
    else {
      return <div ref={(component) => this.epilogosViewerParameterSummary = component} id="navigation-summary-parameters" style={(this.state.isMobile)?{"display":"none","width":"0px","height":"0px"}:((parseInt(this.state.width)<1180)?{"display":"inline-flex","letterSpacing":"0.005em"}:{"display":"inline-flex"})} className="navigation-summary-parameters">{genomeText}{divider}{modelText}{divider}{groupText}{divider}<span dangerouslySetInnerHTML={{ __html: complexityText }} /></div>;
    }
  }
  
  positionSummaryElement = (showClipboard) => {
    // console.log(`currentPosition ${JSON.stringify(this.state.currentPosition)}`);
    // console.log(`        chrLeft ${this.state.currentPosition.chrLeft}`);
    // console.log(`       chrRight ${this.state.currentPosition.chrRight}`);
    // console.log(`      startLeft ${this.state.currentPosition.startLeft}`);
    // console.log(`      stopRight ${this.state.currentPosition.stopRight}`)
    if (showClipboard === null) showClipboard = true;
    if ((typeof this.state.currentPosition === "undefined") || (typeof this.state.currentPosition.chrLeft === "undefined") || (typeof this.state.currentPosition.chrRight === "undefined") || (typeof this.state.currentPosition.startLeft === "undefined") || (typeof this.state.currentPosition.stopRight === "undefined")) {
      return <div />
    }
    let positionSummary = (this.state.currentPosition.chrLeft === this.state.currentPosition.chrRight) ? `${this.state.currentPosition.chrLeft}:${this.state.currentPosition.startLeft}-${this.state.currentPosition.stopLeft}` : `${this.state.currentPosition.chrLeft}:${this.state.currentPosition.startLeft} - ${this.state.currentPosition.chrRight}:${this.state.currentPosition.stopRight}`;
    // this.updateScale();
    let scaleSummary = (this.state.chromsAreIdentical) ? this.state.currentViewScaleAsString : "";
    if (showClipboard) {
      // console.log("showClipboard", showClipboard);
      if (parseInt(this.state.width)>750) {
        // return <div style={(parseInt(this.state.width)<1180)?{"letterSpacing":"0.005em"}:{}}><span title={"Viewer genomic position"}>{positionSummary}</span> <CopyToClipboard text={positionSummary}><span className="navigation-summary-position-clipboard-parent" title={"Copy genomic position to clipboard"}><FaClipboard className="navigation-summary-position-clipboard" /></span></CopyToClipboard></div>
        return (
          <div style={(parseInt(this.state.width)<1300)?{"letterSpacing":"0.005em"}:{}}>
            <CopyToClipboard text={positionSummary} onMouseDown={(e) => {this.onClickCopyRegionCommand(e) }}><span title={"Copy genomic position to clipboard"} className="navigation-summary-position-clipboard-text">{positionSummary}</span></CopyToClipboard>
            &nbsp;
            <span key={this.state.scaleSummaryKey}>
              {scaleSummary}
            </span>
            &nbsp;
            <CopyToClipboard text={positionSummary} onMouseDown={(e) => {this.onClickCopyRegionCommand(e) }}>
              <span className="navigation-summary-position-clipboard-parent" title={"Copy genomic position to clipboard"}>
                <FaClipboard className="navigation-summary-position-clipboard" />
              </span>
            </CopyToClipboard>
          </div>
        );
      }
      else {
        return <div />
      }
    }
    else {
      // console.log("showClipboard", showClipboard);
      return <div className="navigation-summary-position-mobile-landscape"><span title={"Viewer genomic position and assembly"}>{positionSummary} | {this.state.hgViewParams.genome}</span></div>
    }
  }
  
  trackLabels = () => {
    let genome = this.state.hgViewParams.genome;
    let annotationText = Constants.annotations[genome];
    let mode = this.state.hgViewParams.mode;
    let viewconf = this.state.mainHgViewconf;
    if (!viewconf || !viewconf.views) return;
    const childViews = viewconf.views[0].tracks.top;
    let childViewHeights = [];
    childViews.forEach((cv, i) => { childViewHeights[i] = cv.height; });
    const add = (a, b) => a + b;
    const pdPerTrackHeight = childViewHeights[0] / (Object.keys(Constants.components).length - 1);
    let componentIndexOffset = Object.keys(Constants.components).length - 1;
    let results = [];
    switch (mode) {
      case "ac": {
        results.push(<div key="ac-label-dnase" className="epilogos-viewer-container-track-label epilogos-viewer-container-track-label-inverse" style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight + 15)+'px',right:'15px',marginBottom:"0px",paddingBottom:"0px",textAlign:"right"}}>DNase I normalized density<br/><span style={{fontSize:"0.8em",marginTop:"0px",paddingTop:"0px"}}>All 733 biosamples</span></div>);
        let acCumulativeHeight = childViewHeights[0];
        let acComponentIndexOffset = 1;
        acCumulativeHeight += childViewHeights[acComponentIndexOffset];
        acComponentIndexOffset++;
        results.push(<div key="ac-label-index" className="epilogos-viewer-container-track-label" style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight + acCumulativeHeight + (Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight / 2 - 8))+'px', right:'15px'}}>Index DHS</div>);
        acCumulativeHeight += childViewHeights[acComponentIndexOffset];
        acComponentIndexOffset++;
        acCumulativeHeight += childViewHeights[acComponentIndexOffset];
        let acAnnotationBottomOffset = 0;
        switch (this.state.hgViewParams.gatt) {
          case "cv":
            acAnnotationBottomOffset = this.state.hgViewParams.hgViewTrackGeneAnnotationsHeight / 2 - 15;
            break;
          case "ht":
            acAnnotationBottomOffset = this.state.geneAnnotationTrackHeight / 2 - 15;
            break;
          default:
            break;
        }
        results.push(<div key="ac-label-annotation" className="epilogos-viewer-container-track-label" style={{bottom:parseInt(acAnnotationBottomOffset)+'px',right:'15px'}}>{annotationText}</div>);
        break;
      }
      case "pc": {
        if (!(this.state.isMobile && (this.state.isPortrait === false))) {
          results.push(<div key="pc-label-numsamples" className="epilogos-viewer-container-track-label" style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight + childViewHeights[0] + childViewHeights[1] + 8)+'px',right:'15px'}}>Biosamples with DHS</div>);
          results.push(<div key="pc-label-index" className="epilogos-viewer-container-track-label" style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight + childViewHeights[0] + childViewHeights[1] + childViewHeights[2] + 33)+'px',right:'15px'}}>{((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? "Index DHS" : "DHS Component"}</div>);
          results.push(<div key="pc-label-annotation" className="epilogos-viewer-container-track-label" style={{top:parseInt(childViewHeights.reduce(add))+'px',right:'15px'}}>{annotationText}</div>);
        }
        break;
      }
      case "pw": 
      case "pd": {
        let cumulativeHeight = 0;
        let pwVerticalOffset = (this.state.isMobile && (this.state.isPortrait === false)) ? -2 : 6;
        // let pdVerticalOffset = (mode === "pd") ? Constants.applicationViewerHgViewPaddingTop : 0;
        let pdVerticalOffset = 0;
        if (window.devicePixelRatio >= 3) pwVerticalOffset -= 7; // zoom level adjustment
        Object.keys(Constants.components).forEach((c, ci) => {
          if (ci >= 15) return;
          //console.log(`Constants.components[c].longName ${Constants.components[c].longName} ci ${ci}`);
          results.push(
            <div 
              key={`pw-label-samples-${ci}`} 
              className="epilogos-viewer-container-track-label-small epilogos-viewer-container-track-label-inverse" 
              style={
                  { 
                    top: `${parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight + cumulativeHeight + pwVerticalOffset + pdVerticalOffset)}px`,
                    right: '45px'
                  }}
            >
              {Constants.components[c].longName}
            </div>
          );
          if (mode === "pw") {
            cumulativeHeight += childViewHeights[ci];
          }
          else if (mode === "pd") {
            cumulativeHeight += pdPerTrackHeight; 
          }
        });
        if (mode === "pd") {
          componentIndexOffset = 1;
        }
        // if ((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) {
          // cumulativeHeight += childViewHeights[componentIndexOffset];
          // componentIndexOffset++;
          // results.push(<div key="pd-label-numsamples" className="epilogos-viewer-container-track-label" style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight + cumulativeHeight + 7)+'px',right:'15px'}}>Biosamples with DHS</div>);
        // }
        cumulativeHeight += childViewHeights[componentIndexOffset];
        componentIndexOffset++;
        if (mode === "pw") {
          results.push(<div key="pw-label-index" className="epilogos-viewer-container-track-label" style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight + cumulativeHeight + (((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? 33 : 20))+'px',right:'15px'}}>{((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? "Index DHS" : "DHS Component"}</div>);
        }
        else if (mode === "pd") {
          // results.push(<div key="pd-label-index" className="epilogos-viewer-container-track-label" style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight + cumulativeHeight + (((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? ((this.state.indexDHSTrackHeight / 2) - 13) : 20))+'px',right:'15px'}}>{((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? "Index DHS" : "DHS Component"}</div>);
          // results.push(<div key="pd-label-index" className="epilogos-viewer-container-track-label" style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight + cumulativeHeight + (this.isProductionSite ? ((this.state.indexDHSTrackHeight / 2) - 13) : 20) + 'px'),right:'15px'}}>Index DHS</div>);
          results.push(
            <div 
              key="pd-label-index" 
              className="epilogos-viewer-container-track-label" 
              style={
                {
                  top: parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight + cumulativeHeight + pdVerticalOffset + (Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight / 2) - 7),
                  right: '45px',
                }
              }
            >
              Index DHS
            </div>
          );
        }
        cumulativeHeight += childViewHeights[componentIndexOffset];
        componentIndexOffset++;
        if (mode === "pw") {
          results.push(<div key="pw-label-annotation" className="epilogos-viewer-container-track-label" style={{top:parseInt(childViewHeights.reduce(add) - (((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? 15 : 15))+'px',right:'15px'}}>{annotationText}</div>);
        }
        else if (mode === "pd") {
          const pdWindowInnerHeight = parseInt(document.documentElement.clientHeight) - parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight);
          let pdAnnotationBottomOffset = 0;
          switch (this.state.hgViewParams.gatt) {
            case "cv":
              pdAnnotationBottomOffset = this.state.hgViewParams.hgViewTrackGeneAnnotationsHeight / 2 - 15;
              break;
            case "ht":
              pdAnnotationBottomOffset = this.state.geneAnnotationTrackHeight / 2 - 15;
              break;
            default:
              break;
          }
          // results.push(<div key="pd-label-annotation" className="epilogos-viewer-container-track-label" style={{top:parseInt(pdWindowInnerHeight - (((this.state.currentViewScale <= this.state.hgViewParams.dtt) || (this.isProductionSite)) ? 15 : 15))+'px',right:'15px'}}>{annotationText}</div>);
          results.push(
            <div 
              key="pd-label-annotation" 
              className="epilogos-viewer-container-track-label" 
              style={
                {
                  bottom: parseInt(pdAnnotationBottomOffset + Constants.applicationViewerHgViewPaddingBottom)+'px',
                  right: '45px',
                }
              }
            >
              {annotationText}
            </div>
          );
        }
        break;
      }
      case "pds": {
        const pdsPerTrackHeight = childViewHeights[0] / (Object.keys(Constants.componentsEncode2024_042924).length);
        let pdsMobileVerticalOffset = (this.state.isMobile && (this.state.isPortrait === false)) ? -2 : 10;
        let pdsVerticalOffset = 0;
        if (window.devicePixelRatio >= 3) pdsVerticalOffset -= 7;
        let cumulativeHeight = 0;
        Object.keys(Constants.componentsEncode2024_042924).forEach((c, ci) => {
          results.push(
            <div 
              key={`pds-label-samples-${ci}`} 
              className="epilogos-viewer-container-track-label-small epilogos-viewer-container-track-label-inverse" 
              style={
                  { 
                    top: `${parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight + cumulativeHeight + pdsVerticalOffset + pdsMobileVerticalOffset)}px`,
                    right: '25px'
                  }}
            >
              {Constants.componentsEncode2024_042924[c].longName}
            </div>
          );
          cumulativeHeight += pdsPerTrackHeight;
        });
        componentIndexOffset = 1;
        cumulativeHeight += childViewHeights[componentIndexOffset];
        componentIndexOffset++;
        results.push(
          <div 
            key="pds-label-index" 
            className="epilogos-viewer-container-track-label" 
            style={
              {
                top: parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight + cumulativeHeight + pdsVerticalOffset + pdsMobileVerticalOffset + (Constants.viewerHgViewParameters.hgViewTrackIndexDHSHeight / 2) - 7),
                right: '25px',
              }
            }
          >
            Index DHS
          </div>
        );
        cumulativeHeight += childViewHeights[componentIndexOffset];
        componentIndexOffset++;
        let pdsAnnotationBottomOffset = 0;
        switch (this.state.hgViewParams.gatt) {
          case "cv":
            pdsAnnotationBottomOffset = this.state.hgViewParams.hgViewTrackGeneAnnotationsHeight / 2 - 15;
            break;
          case "ht":
            pdsAnnotationBottomOffset = this.state.geneAnnotationTrackHeight / 2 - 15;
            break;
          default:
            break;
        }
        results.push(
          <div 
            key="pds-label-annotation" 
            className="epilogos-viewer-container-track-label" 
            style={
              {
                bottom: parseInt(pdsAnnotationBottomOffset + Constants.applicationViewerHgViewPaddingBottom)+'px',
                right: '25px',
              }
            }
          >
            {annotationText}
          </div>
        );
        break;
      }
      default:
        break;
    }
    return results;
  }
  
  viewerOverlayNotice = () => {
    return <div>{this.state.overlayMessage}</div>
  }
  
  fadeOutOverlay = (cb) => {
    this.epilogosViewerContainerErrorOverlay.style.opacity = 0;
    this.epilogosViewerContainerErrorOverlay.style.transition = "opacity 0.5s 0.5s";
    setTimeout(() => {
      this.epilogosViewerContainerErrorOverlay.style.pointerEvents = "none";
      if (cb) { cb(); }
    }, 500);
  }
  
  fadeInOverlay = (cb) => {
    this.epilogosViewerContainerErrorOverlay.style.opacity = 1;
    this.epilogosViewerContainerErrorOverlay.style.transition = "opacity 0.5s 0.5s";
    this.epilogosViewerContainerErrorOverlay.style.pointerEvents = "auto";
    setTimeout(() => {
      if (cb) { cb(); }
    }, 500);
  }
  
  fadeInParameterSummary = (cb) => {
    if (!this.epilogosViewerParameterSummary.current) return;
    this.epilogosViewerParameterSummary.style.opacity = 1;
    this.epilogosViewerParameterSummary.style.transition = "opacity 0s 0s";
    this.epilogosViewerParameterSummary.style.pointerEvents = "auto";
    setTimeout(() => {
      if (cb) { cb(); }
    }, 500);
  }
  
  downloadRouteElement = () => {
    const queryObj = Helpers.getJsonFromUrl();
    let dtt = parseInt(this.state.hgViewParams.dtt || queryObj.dtt);
    let scale = this.state.currentViewScale;
    if (scale === -1) return;
    //console.log(`dtt ${dtt}`);
    //console.log(`scale ${scale}`);
    let result = [];
    if (scale > dtt) {
      result.push(
        <span key="download-route-link-tabix-index" className="download-route-link" name="tabix-index" onClick={() => this.onClickDownloadItemSelect("tabix-index")}>INDEX DHS</span>
      );
    }
    else {
      result.push(
        <span key="download-route-link-tabix-service-index" className="download-route-link" name="tabix-service-index" onClick={() => this.onClickDownloadItemSelect("tabix-service-index")}>INDEX DHS</span>
      );
    }
    result.push(
      <span key="download-route-link-spacer-A">{"\u00a0"}|{"\u00a0"}</span>
    );
    result.push(
      <span key="download-route-link-png" className="download-route-link" name="png" onClick={() => this.onClickDownloadItemSelect("png")}>PNG</span>
    );
    result.push(
      <span key="download-route-link-spacer-B">{"\u00a0"}|{"\u00a0"}</span>
    );
    result.push(
      <span key="download-route-link-svg" className="download-route-link" name="svg" onClick={() => this.onClickDownloadItemSelect("svg")}>SVG</span>
    );
    return result;
  }

  modeToggleAsElement() {
    return (this.state.hgViewParams.mode === "qt" ?
      <div key="mode-bg-0" className="drawer-settings-mode">
        <span className={"drawer-settings-mode-label drawer-settings-mode-label-left drawer-settings-mode-label-not-active drawer-settings-mode-label-disabled"} title={"733 Biosample DNase I density heatmap"}>
          733 biosamples
        </span>
        <FaToggleOn className={(this.state.hgViewParams.mode === "ac") ? "fa-toggle fa-toggle-ac fa-toggle-disabled" : "fa-toggle fa-toggle-pd fa-toggle-disabled"} />
        <span className={"drawer-settings-mode-label drawer-settings-mode-label-right drawer-settings-mode-label-not-active drawer-settings-mode-label-disabled"} title={"Per-component DNase I density tracks"}>
          16 components
        </span>
      </div>
    :
      <div key="mode-bg-0" className="drawer-settings-mode">
        <span onClick={()=>{this.onClickModeToggle("ac");}} className={(this.state.hgViewParams.mode === "ac") ? "drawer-settings-mode-label drawer-settings-mode-label-left drawer-settings-mode-label-active" : "drawer-settings-mode-label drawer-settings-mode-label-left drawer-settings-mode-label-not-active"} title={"733 Biosample DNase I density heatmap"}>
          733 biosamples
        </span>
        <FaToggleOn className={(this.state.hgViewParams.mode === "ac") ? "fa-toggle fa-toggle-ac" : "fa-toggle fa-toggle-pd"} onClick={()=>{this.onClickModeToggle((this.state.hgViewParams.mode === "ac") ? "pd" : "ac")}} />
        <span onClick={()=>{this.onClickModeToggle("pd");}} className={(this.state.hgViewParams.mode === "pd") ? "drawer-settings-mode-label drawer-settings-mode-label-right drawer-settings-mode-label-active" : "drawer-settings-mode-label drawer-settings-mode-label-right drawer-settings-mode-label-not-active"} title={"Per-component DNase I density tracks"}>
          16 components
        </span>
      </div>
    );
    // return (
    //   <div key="mode-bg-0" className="drawer-settings-mode">
    //     <span onClick={()=>{this.onClickModeToggle("ac");}} className={(this.state.hgViewParams.mode === "ac") ? "drawer-settings-mode-label drawer-settings-mode-label-left drawer-settings-mode-label-active" : "drawer-settings-mode-label drawer-settings-mode-label-left drawer-settings-mode-label-not-active"} title={"733 Biosample DNase I density heatmap"}>
    //       733 biosamples
    //     </span>
    //     <FaToggleOn className={(this.state.hgViewParams.mode === "ac") ? "fa-toggle fa-toggle-ac" : "fa-toggle fa-toggle-pd"} onClick={()=>{this.onClickModeToggle((this.state.hgViewParams.mode === "ac") ? "pd" : "ac")}} />
    //     <span onClick={()=>{this.onClickModeToggle("pd");}} className={(this.state.hgViewParams.mode === "pd") ? "drawer-settings-mode-label drawer-settings-mode-label-right drawer-settings-mode-label-active" : "drawer-settings-mode-label drawer-settings-mode-label-right drawer-settings-mode-label-not-active"} title={"Per-component DNase I density tracks"}>
    //       16 components
    //     </span>
    //   </div>
    // );
  }

  setGeneAnnotationTrackType = (newType) => {
    // console.log(`setGeneAnnotationTrackType | ${newType}`);
    let newHgViewParams = {...this.state.hgViewParams};
    if ((newType !== "cv") && (newType !== "ht")) return;
    newHgViewParams.gatt = newType;
    this.setState({
      hgViewParams: newHgViewParams,
    }, () => {
      this.updateViewerURLAtCurrentState(() => {
        this.triggerUpdate("update");
      });
    });
  }

  simSearchQuery = Helpers.debounce((chrom, start, stop) => {
    const queryChr = chrom;
    const queryStart = start;
    const queryEnd = stop;
    const queryScale = Helpers.calculateScale(queryChr, queryChr, queryStart, queryEnd, this, false);
    const queryWindowSize = parseInt(parseInt(queryScale.diff) / 1000); // kb
    const simSearchQueryPromise = Helpers.simSearchQueryPromise(queryChr, queryStart, queryEnd, queryWindowSize, this, true);
    simSearchQueryPromise.then((res) => {
      if (!res.query) {
        console.log(`res ${JSON.stringify(res)}`);
      }
      if (res && res.hits && res.hits.length > 0) {
        const newSimSearchQueryHits = (res.hits[0] !== "") ? res.hits[0].split('\n') : [];
        // console.log(`newSimSearchQueryHits ${JSON.stringify(newSimSearchQueryHits, null, 2)}`);
        const newSimSearchQueryCount = newSimSearchQueryHits.length;
        const newSimSearchQueryCountIsVisible = (newSimSearchQueryCount > 0 && this.state.hgViewParams.mode !== 'qt');
        const newSimSearchQueryCountIsEnabled = (newSimSearchQueryCount > 0 && this.state.hgViewParams.mode !== 'qt');
        this.setState({
          simSearchQueryCount: newSimSearchQueryCount,
          simSearchQueryCountIsVisible: newSimSearchQueryCountIsVisible,
          simSearchQueryCountIsEnabled: newSimSearchQueryCountIsEnabled,
        })
      }
    })
    // eslint-disable-next-line no-unused-vars
    .catch((err) => {
      console.error(`Could not query simsearch service`);
      this.setState({
        simSearchQueryCount: -1,
        simSearchQueryCountIsVisible: false,
        simSearchQueryCountIsEnabled: false,
      });
    });
  }, 250);

  recommenderV3SearchCanBeEnabled = () => {
    // console.log(`recommenderV3SearchCanBeEnabled | currentViewScale ${this.state.currentViewScale}`);
    // let params = this.state.tempHgViewParams;
    let params = this.state.hgViewParams;
    let test = true;
    if ((this.isProductionSite) || (this.isProductionProxySite)) test = false;
    // else if (params.sampleSet === "vE") test = false;
    else if ((this.state.currentViewScale <= 0) || (this.state.currentViewScale > Constants.defaultApplicationRecommenderV3ButtonHideShowThreshold)) test = false;
    else if (params.mode === "qt") test = false;
    // console.log(`params.mode ${params.mode}`);
    return test;
  }

  recommenderV3ExpandCanBeEnabled = () => {
    return this.recommenderV3SearchCanBeEnabled() && !this.state.recommenderV3SearchInProgress;
  }

  recommenderV3SearchCanBeVisible = () => {
    return true;
  }

  recommenderV3ManageAnimation = (canAnimate, hasFinished, cb) => {
    // console.log(`Viewer: recommenderV3ManageAnimation | canAnimate ${canAnimate} | hasFinished ${hasFinished}`);
    this.setState({
      recommenderV3CanAnimate: canAnimate
    }, () => {
      setTimeout(() => {
        this.setState({
          recommenderV3AnimationHasFinished: hasFinished,
        }, () => {
          if (!this.state.recommenderV3AnimationHasFinished) {
            this.recommenderV3ManageAnimation(false, true);
          }
          if (cb) cb();
        });
      }, 100);
    });
  }

  // recommenderV3SearchOnClick = () => {
  //   console.log(`recommenderV3SearchOnClick() - ${this.state.drawerWidth}`);
  //   if (this.state.recommenderV3SearchInProgress || !this.state.recommenderV3SearchIsEnabled) return;

  //   let windowInnerWidth = document.documentElement.clientWidth + "px";
  //   let navbarRighthalfDiv = document.getElementsByClassName("navbar-righthalf")[0];
  //   let navbarRighthalfDivStyle = navbarRighthalfDiv.currentStyle || window.getComputedStyle(navbarRighthalfDiv);
  //   let navbarRighthalfDivWidth = parseInt(navbarRighthalfDiv.clientWidth);
  //   let navbarRighthalfDivMarginLeft = parseInt(navbarRighthalfDivStyle.marginLeft);
  //   let epilogosViewerHeaderNavbarRighthalfWidth = parseInt(navbarRighthalfDivWidth + navbarRighthalfDivMarginLeft + 15) + "px";
  //   let epilogosViewerHeaderNavbarLefthalfWidth = 0;
  //   if (document.getElementById("navigation-summary-parameters")) {
  //     epilogosViewerHeaderNavbarLefthalfWidth = parseInt(parseInt(windowInnerWidth) - parseInt(epilogosViewerHeaderNavbarRighthalfWidth) - parseInt(document.getElementById("navigation-summary-parameters").offsetWidth) - parseInt(document.getElementById("navigation-mode-toggle").offsetWidth)) + "px";  
  //   }
  //   else {
  //     epilogosViewerHeaderNavbarLefthalfWidth = parseInt(parseInt(windowInnerWidth) - parseInt(epilogosViewerHeaderNavbarRighthalfWidth) - parseInt(document.getElementById("navigation-mode-toggle").offsetWidth)) + "px";  
  //   }

  //   this.updateActiveTab(Constants.defaultDrawerTabOnOpen);
  //   this.fadeOutIntervalDrop();
  //   this.setState({
  //     drawerContentKey: this.state.drawerContentKey + 1,
  //     drawerWidth: parseInt(epilogosViewerHeaderNavbarLefthalfWidth) - 140,
  //   }, () => {
  //     this.closeDrawer();
  //     this.setState({
  //       recommenderVersion: "v3",
  //       selectedExemplarRowIdx: Constants.defaultApplicationSerIdx,
  //       recommenderV3SearchInProgress: true,
  //       recommenderV3SearchButtonLabel: RecommenderSearchButtonInProgressLabel,
  //       recommenderV3SearchLinkLabel: RecommenderSearchLinkInProgressLabel,
  //       recommenderV3ExpandIsEnabled: false,
  //       recommenderV3ExpandLinkLabel: RecommenderExpandLinkDefaultLabel,
  //     }, () => {
  //       function updateWithRoisInMemory(self) {
  //         // console.log("[recommenderV3SearchOnClick] queryRegionIndicatorData", JSON.stringify(self.state.queryRegionIndicatorData, null, 2));
  //         const firstROI = self.state.roiTableData[0];
  //         // console.log("[recommenderV3SearchOnClick] firstROI", JSON.stringify(firstROI, null, 2));

  //         const queryObj = Helpers.getJsonFromUrl();
  //         const currentMode = self.state.hgViewParams.mode || queryObj.mode;
  //         // console.log(`currentMode ${currentMode}`);

  //         let newCurrentPosition = self.state.currentPosition;
  //         newCurrentPosition.chrLeft = self.state.queryRegionIndicatorData.chromosome;
  //         newCurrentPosition.chrRight = self.state.queryRegionIndicatorData.chromosome;
  //         newCurrentPosition.startLeft = self.state.queryRegionIndicatorData.start;
  //         newCurrentPosition.stopLeft = self.state.queryRegionIndicatorData.stop;
  //         newCurrentPosition.startRight = self.state.queryRegionIndicatorData.start;
  //         newCurrentPosition.stopRight = self.state.queryRegionIndicatorData.stop;
  //         let newTempHgViewParams = self.state.tempHgViewParams;
  //         newTempHgViewParams.mode = "qt";
  //         newTempHgViewParams.chrLeft = self.state.queryRegionIndicatorData.chromosome;
  //         newTempHgViewParams.chrRight = self.state.queryRegionIndicatorData.chromosome;
  //         newTempHgViewParams.start = self.state.queryRegionIndicatorData.start;
  //         newTempHgViewParams.stop = self.state.queryRegionIndicatorData.stop;
  //         const queryTargetQueryRegionLabel = self.state.queryRegionIndicatorData.regionLabel;
  //         const queryTargetQueryRegion = {
  //           'left' : {
  //             'chr' : self.state.queryRegionIndicatorData.chromosome,
  //             'start' : self.state.queryRegionIndicatorData.start,
  //             'stop' : self.state.queryRegionIndicatorData.stop,
  //           },
  //           'right' : {
  //             'chr' : self.state.queryRegionIndicatorData.chromosome,
  //             'start' : self.state.queryRegionIndicatorData.start,
  //             'stop' : self.state.queryRegionIndicatorData.stop,
  //           },
  //         };
  //         const queryTargetTargetRegionLabel = firstROI.position;
  //         const queryTargetTargetRegion = {
  //           'left' : {
  //             'chr' : firstROI.chrom,
  //             'start' : firstROI.chromStart,
  //             'stop' : firstROI.chromEnd,
  //           },
  //           'right' : {
  //             'chr' : firstROI.chrom,
  //             'start' : firstROI.chromStart,
  //             'stop' : firstROI.chromEnd,
  //           },
  //         };
          
  //         self.setState({
  //           hgViewParams: newTempHgViewParams,
  //           tempHgViewParams: newTempHgViewParams,
  //           currentPosition: newCurrentPosition,
  //           queryTargetQueryRegionLabel: queryTargetQueryRegionLabel,
  //           queryTargetQueryRegion: queryTargetQueryRegion,
  //           queryTargetTargetRegionLabel: queryTargetTargetRegionLabel,
  //           queryTargetTargetRegion: queryTargetTargetRegion,
  //           recommenderV3SearchIsVisible: self.recommenderV3SearchCanBeVisible(),
  //           recommenderV3SearchIsEnabled: self.recommenderV3SearchCanBeEnabled(),
  //           recommenderV3ExpandIsEnabled: self.recommenderV3ExpandCanBeEnabled(),
  //           exemplarsEnabled: false,
  //           roiEnabled: false,
  //           genomeSelectIsActive: false,
  //           autocompleteInputDisabled: true,
  //         }, () => {
  //           if (currentMode === "qt") {
  //             // self.queryTargetHgView.updateForNewRecommendations();
  //           }
  //           else {
  //             self.triggerUpdate("update");
  //           }
  //           self.setState({
  //             recommenderV3SearchInProgress: false,
  //             recommenderV3SearchIsVisible: self.recommenderV3SearchCanBeVisible(),
  //             recommenderV3SearchIsEnabled: self.recommenderV3SearchCanBeEnabled(),
  //             recommenderV3SearchButtonLabel: RecommenderV3SearchButtonDefaultLabel,
  //             recommenderV3SearchLinkLabel: RecommenderSearchLinkDefaultLabel,
  //             recommenderV3ExpandIsEnabled: self.recommenderV3ExpandCanBeEnabled(),
  //             recommenderV3ExpandLinkLabel: RecommenderExpandLinkDefaultLabel,
  //           }, () => {
  //             console.log("calling [updateViewerURL] from [recommenderV3SearchOnClick]");
  //             self.updateViewerURL(
  //               self.state.hgViewParams.mode,
  //               self.state.queryRegionIndicatorData.chromosome,
  //               self.state.queryRegionIndicatorData.chromosome,
  //               self.state.queryRegionIndicatorData.start,
  //               self.state.queryRegionIndicatorData.stop
  //             );
  //           });
  //         });
  //       }

  //       const queryObj = Helpers.getJsonFromUrl();
  //       let chrLeft = this.state.currentPosition.chrLeft || queryObj.chrLeft;
  //       let chrRight = this.state.currentPosition.chrRight || queryObj.chrRight;
  //       let start = parseInt(this.state.currentPosition.startLeft || queryObj.start);
  //       let stop = parseInt(this.state.currentPosition.stopRight || queryObj.stop);
  //       const currentGenome = this.state.hgViewParams.genome || queryObj.genome;
  //       const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, currentGenome);

  //       function recommenderV3SearchOnClickForChromInfo(chromInfo, self) {
  //         if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
  //           // console.log("[recommenderV3SearchOnClickForChromInfo] chromosome not in chromLengths");
  //           // throw new TypeError();
  //           chrLeft = Constants.defaultApplicationPositions[currentGenome].chr;
  //           chrRight = Constants.defaultApplicationPositions[currentGenome].chr;
  //           start = Constants.defaultApplicationPositions[currentGenome].start;
  //           stop = Constants.defaultApplicationPositions[currentGenome].stop;
  //         }
  //         if (start > chromInfo.chromLengths[chrLeft]) {
  //           start = chromInfo.chromLengths[chrLeft] - 10000;
  //         }
  //         if (stop > chromInfo.chromLengths[chrRight]) {
  //           stop = chromInfo.chromLengths[chrRight] - 1000;
  //         }
  //         // const absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
  //         // const absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
  //         const queryChr = chrLeft;
  //         const queryStart = start;
  //         const queryEnd = stop;
  //         // const queryWindowSize = parseInt(parseInt(self.state.currentViewScale) / 1000); // kb
  //         const queryWindowSize = parseInt(self.state.currentViewScale); // nt
  //         console.log(`[recommenderV3SearchOnClick] starting region: ${queryChr} | ${queryStart} | ${queryEnd} | ${queryWindowSize}`);
  //         let newRecommenderV3Query = Helpers.recommenderV3QueryPromise(queryChr, queryStart, queryEnd, queryWindowSize, self);
  //         newRecommenderV3Query.then((res) => {
  //           if (!res.query) {
  //             console.log(`res ${JSON.stringify(res)}`);
  //           }
  //           let queryRegionIndicatorData = {
  //             chromosome: res.query.chromosome,
  //             start: res.query.start,
  //             stop: res.query.end,
  //             midpoint: res.query.midpoint,
  //             sizeKey: res.query.sizeKey,
  //             regionLabel: `${res.query.chromosome}:${res.query.start}-${res.query.end}`,
  //             hitCount: res.query.hitCount,
  //             hitDistance: res.query.hitDistance,
  //             hitFirstInterval: res.query.hitFirstInterval,
  //           };
  //           // console.log(`queryRegionIndicatorData ${JSON.stringify(queryRegionIndicatorData)}`);
  //           // console.log(`res.hits[0] ${JSON.stringify(res.hits[0])}`);
  //           self.setState({
  //             queryRegionIndicatorData: queryRegionIndicatorData
  //           }, () => {
  //             self.roiRegionsUpdate(res.hits[0], updateWithRoisInMemory, self);
  //           });
  //         })
  //         .catch((err) => {
  //           // throw new Error(`Error - [recommenderV3SearchOnClick] could not retrieve recommender response for region query - ${JSON.stringify(err)}`);
  //           if (!err || !err.response || !err.response.status || !err.response.statusText) {
  //             err = {
  //               response : {
  //                 status : 404,
  //                 statusText : "No results found",
  //                 title : "Please try again",
  //               }
  //             };
  //           }
  //           err.response.title = "Please try again";
  //           let msg = self.errorMessage(err, "Could not retrieve recommendations for region query. Please try another region.");
  //           self.setState({
  //             overlayMessage: msg,
  //           }, () => {
  //             self.fadeInOverlay();
  //             self.setState({
  //               recommenderV3SearchInProgress: false,
  //               recommenderV3SearchIsVisible: self.recommenderV3SearchCanBeVisible(),
  //               recommenderV3SearchIsEnabled: self.recommenderV3SearchCanBeEnabled(),
  //               recommenderV3SearchButtonLabel: RecommenderV3SearchButtonDefaultLabel,
  //               recommenderV3SearchLinkLabel: RecommenderSearchLinkDefaultLabel,
  //               recommenderV3ExpandIsEnabled: self.recommenderV3ExpandCanBeEnabled(),
  //               recommenderV3ExpandLinkLabel: RecommenderExpandLinkDefaultLabel,
  //               autocompleteInputDisabled: false,
  //             });
  //           });
  //         });
  //       }

  //       if (chromInfoCacheExists) {
  //         recommenderV3SearchOnClickForChromInfo(this.chromInfoCache[currentGenome], this);
  //       }
  //       else {
  //         let chromSizesURL = this.getChromSizesURL(currentGenome);
  //         ChromosomeInfo(chromSizesURL)
  //           .then((chromInfo) => {
  //             this.chromInfoCache[currentGenome] = Object.assign({}, chromInfo);
  //             recommenderV3SearchOnClickForChromInfo(chromInfo, this);
  //           })
  //           .catch((err) => {
  //             throw new Error(`Error - [recommenderV3SearchOnClick] could not retrieve chromosome information - ${JSON.stringify(err)}`);
  //             // console.log("Error - [recommenderV3SearchOnClick] could not retrieve chromosome information - ", err);
  //           });
  //       }
  //     }); // end of close-drawer block
  //   }); // end of post-drawer-content-key block
  // }

  roiProcessData = (data) => {
    if (!data) {
      const msg = this.errorMessage({'response': {'status': 404, 'statusText': (this.state.roiRawURL) ? this.state.roiRawURL : null}}, `ROI data is empty or missing`, (this.state.roiRawURL) ? this.state.roiRawURL : null);
      this.setState({
        overlayMessage: msg,
        recommenderV3SearchInProgress: false,
        recommenderV3SearchIsVisible: this.recommenderV3SearchCanBeVisible(),
        recommenderV3SearchIsEnabled: this.recommenderV3SearchCanBeEnabled(),
        recommenderV3SearchButtonLabel: RecommenderV3SearchButtonDefaultLabel,
        recommenderV3SearchLinkLabel: RecommenderSearchLinkDefaultLabel,
        recommenderV3ExpandIsEnabled: this.recommenderV3ExpandCanBeEnabled(),
        recommenderV3ExpandLinkLabel: RecommenderExpandLinkDefaultLabel,
      }, () => {
        this.fadeInOverlay();
      });      
      return;
    }
    // regions represent raw lines from the incoming data
    // table data represent processed lines from regions, organized into fields
    // console.log(`data ${JSON.stringify(data)}`);
    const dataRegions = data.split(/\r\n|\r|\n/);
    // we set up a template object to hold a row of BED6 data (with placeholders)
    const roiTableRow = {
      'idx' : 0,
      'chrom' : '.',
      'chromStart' : 0,
      'chromEnd' : 0,
      'name' : '.',
      'score' : 0.0,
      'strand' : '.',
      'element' : {
        'position' : '.',
        'paddedPosition' : '.'
      }
    };
    let roiTableRows = [];
    let roiTableRowsCopy = [];
    let roiTableRowsIdxBySort = [];
    //
    // input should contain chromosomes that match the selected genome
    //
    const validChroms = Object.keys(Constants.assemblyBounds[this.state.hgViewParams.genome]);
    //
    // it is possible that the lines will not contain BED data, or will not have all fields
    // we validate input to try to ensure that the ROI drawer content will not contain garbage regions
    //
    function isNormalInteger(str) {
      return /^\+?(0|[1-9]\d*)$/.test(str);
    }    
    let newRoiMaxColumns = this.state.roiMaxColumns;
    let newRoiTableDataLongestNameLength = this.state.roiTableDataLongestNameLength;
    let lineCount = 0;
    //
    // parse data
    //
    for (const line of dataRegions) {
      //console.log("[roiRegionsUpdate] line", line);
      if (line.length === 0) { continue; }
      lineCount += 1;
      // we only add up to maximum number of elements
      if (lineCount > Constants.defaultApplicationRoiLineLimit) break;
      const elems = line.split(/\t/);
      let columns = elems.length;
      //      
      // not enough columns to make up a minimal BED file
      //
      if (columns < 3) {
        //console.log(`[roiRegionsUpdate] input regions are missing columns (line ${lineCount})`);
        const err = {
          "response" : {
            "status" : 400,
            "statusText" : "Malformed input"
          }
        };
        const msg = this.errorMessage(err, `Input regions are missing columns (line ${lineCount})`, this.state.roiRawURL);
        this.setState({
          overlayMessage: msg
        }, () => {
          this.fadeInOverlay();
        });      
        return;
      }
      //
      // if the line does not have start or stop coordinates, then we send an error and return early
      //
      if (!isNormalInteger(elems[1]) || !isNormalInteger(elems[2])) {
        //console.log(`[roiRegionsUpdate] input regions have non-coordinate data (line ${lineCount})`);
        const err = {
          "response" : {
            "status" : 400,
            "statusText" : "Malformed input"
          }
        };
        const msg = this.errorMessage(err, `Input regions have non-coordinate data (line ${lineCount})`, this.state.roiRawURL);
        this.setState({
          overlayMessage: msg
        }, () => {
          this.fadeInOverlay();
        });       
        return;
      }
      //
      // if the first element in elems is not a valid chromosome name from selected genome, report error and return early
      //
      if (!validChroms.includes(elems[0])) {
        //console.log(`[roiRegionsUpdate] input regions have bad chromosome names (line ${lineCount})`);
        const err = {
          "response" : {
            "status" : 400,
            "statusText" : "Malformed input"
          }
        };
        const msg = this.errorMessage(err, `Input regions have bad chromosome names (line ${lineCount})`, this.state.roiRawURL);
        this.setState({
          overlayMessage: msg
        }, () => {
          this.fadeInOverlay();
        }); 
        return;
      }
      //
      // update maximum number of columns
      //
      if (columns > newRoiMaxColumns) {
        newRoiMaxColumns = columns;
      }
      
      //
      // clone a row object from template
      //
      const row = Object.assign({}, roiTableRow);
      //
      // populate row with content from elems
      //
      row.idx = lineCount;
      row.chrom = elems[0];
      row.chromStart = parseInt(elems[1]);
      row.chromEnd = parseInt(elems[2]);
      row.position = row.chrom + ':' + row.chromStart + '-' + row.chromEnd;
      //
      let paddedPosition = Helpers.zeroPad(row.chrom.replace(/chr/, ''), 3) + ':' + Helpers.zeroPad(row.chromStart, 12) + '-' + Helpers.zeroPad(row.chromEnd, 12);
      if (isNaN(row.chrom.replace(/chr/, ''))) {
        paddedPosition = row.chrom.replace(/chr/, '') + ':' + Helpers.zeroPad(row.chromStart, 12) + '-' + Helpers.zeroPad(row.chromEnd, 12);
      }
      //
      row.paddedPosition = paddedPosition;
      row.element = {
        "position" : row.position.slice(),
        "paddedPosition" : row.paddedPosition.slice()
      };
      row.name = (columns > 3) ? elems[3] : '.';
      row.score = (columns > 4) ? elems[4] : 0.0;
      row.strand = (columns > 5) ? elems[5] : '.';
      //console.log("row", row);
      //
      // add row object to table data array
      //
      roiTableRows.push(row);     
      roiTableRowsCopy.push(row);
      roiTableRowsIdxBySort.push(row.idx);
      //
      //
      //
      if (row.name.length > newRoiTableDataLongestNameLength) newRoiTableDataLongestNameLength = row.name.length; 
    }

    return [roiTableRows, roiTableRowsCopy, roiTableRowsIdxBySort, dataRegions, newRoiMaxColumns, newRoiTableDataLongestNameLength];
  }

  recommenderV3SearchOnClick = () => {
    // console.log(`recommenderV3SearchOnClick()`);

    if (this.state.recommenderV3SearchInProgress || !this.state.recommenderV3SearchIsEnabled) return;

    // if (this.state.simSearchQueryCount <= 0 && !this.state.suggestionTableIsVisible) {
    //   this.suggestionTableMakeVisible();
    //   return;
    // }

    // this.updateActiveTab(Constants.defaultDrawerTabOnOpen);
    // this.fadeOutIntervalDrop();
    // this.fadeOutSuggestionIntervalDrop();
    this.setState({
      drawerIsEnabled: false,
      drawerContentKey: this.state.drawerContentKey + 1,
    }, () => {
      this.closeDrawer();
      this.setState({
        recommenderVersion: "v3",
        // selectedExemplarRowIdx: Constants.defaultApplicationSerIdx,
        // selectedRoiRowIdx: Constants.defaultApplicationSrrIdx,
        // selectedSimSearchRowIdx: Constants.defaultApplicationSsrIdx,
        recommenderV3SearchInProgress: true,
        recommenderV3SearchButtonLabel: RecommenderSearchButtonInProgressLabel,
        recommenderV3SearchLinkLabel: RecommenderSearchLinkInProgressLabel,
        recommenderV3ExpandIsEnabled: false,
        recommenderV3ExpandLinkLabel: RecommenderExpandLinkDefaultLabel,
      }, () => {
        // this.queryTargetHgView.updateSelectedHitIdx(1);

        function updateWithSimSearchRegionsInMemory(self) {
          // console.log("[recommenderV3SearchOnClick] queryRegionIndicatorData", JSON.stringify(self.state.queryRegionIndicatorData, null, 2));
          const firstSimSearchRegion = self.state.simSearchTableData[0];
          console.log("[recommenderV3SearchOnClick] firstSimSearchRegion", JSON.stringify(firstSimSearchRegion, null, 2));

          const queryObj = Helpers.getJsonFromUrl();
          const currentMode = self.state.hgViewParams.mode || queryObj.mode;
          // console.log(`currentMode ${currentMode}`);

          let newCurrentPosition = self.state.currentPosition;
          newCurrentPosition.chrLeft = self.state.queryRegionIndicatorData.chromosome;
          newCurrentPosition.chrRight = self.state.queryRegionIndicatorData.chromosome;
          newCurrentPosition.startLeft = self.state.queryRegionIndicatorData.start;
          newCurrentPosition.stopLeft = self.state.queryRegionIndicatorData.stop;
          newCurrentPosition.startRight = self.state.queryRegionIndicatorData.start;
          newCurrentPosition.stopRight = self.state.queryRegionIndicatorData.stop;
          let newTempHgViewParams = self.state.tempHgViewParams;
          newTempHgViewParams.mode = "qt";
          newTempHgViewParams.chrLeft = self.state.queryRegionIndicatorData.chromosome;
          newTempHgViewParams.chrRight = self.state.queryRegionIndicatorData.chromosome;
          newTempHgViewParams.start = self.state.queryRegionIndicatorData.start;
          newTempHgViewParams.stop = self.state.queryRegionIndicatorData.stop;
          const queryTargetQueryRegionLabel = self.state.queryRegionIndicatorData.regionLabel;
          const queryTargetQueryRegion = {
            'left' : {
              'chr' : self.state.queryRegionIndicatorData.chromosome,
              'start' : self.state.queryRegionIndicatorData.start,
              'stop' : self.state.queryRegionIndicatorData.stop,
            },
            'right' : {
              'chr' : self.state.queryRegionIndicatorData.chromosome,
              'start' : self.state.queryRegionIndicatorData.start,
              'stop' : self.state.queryRegionIndicatorData.stop,
            },
          };
          const queryTargetTargetRegionLabel = firstSimSearchRegion.position;
          const queryTargetTargetRegion = {
            'left' : {
              'chr' : firstSimSearchRegion.chrom,
              'start' : firstSimSearchRegion.chromStart,
              'stop' : firstSimSearchRegion.chromEnd,
            },
            'right' : {
              'chr' : firstSimSearchRegion.chrom,
              'start' : firstSimSearchRegion.chromStart,
              'stop' : firstSimSearchRegion.chromEnd,
            },
          };
          self.setState({
            hgViewParams: newTempHgViewParams,
            tempHgViewParams: newTempHgViewParams,
            currentPosition: newCurrentPosition,
            queryTargetQueryRegionLabel: queryTargetQueryRegionLabel,
            queryTargetQueryRegion: queryTargetQueryRegion,
            queryTargetTargetRegionLabel: queryTargetTargetRegionLabel,
            queryTargetTargetRegion: queryTargetTargetRegion,
            recommenderV3SearchIsVisible: self.recommenderV3SearchCanBeVisible(),
            recommenderV3SearchIsEnabled: self.recommenderV3SearchCanBeEnabled(),
            recommenderV3ExpandIsEnabled: self.recommenderV3ExpandCanBeEnabled(),
            exemplarsEnabled: false,
            roiEnabled: false,
            roiButtonIsEnabled: false,
            genomeSelectIsActive: false,
            autocompleteInputDisabled: true,
          }, () => {
            if (currentMode === "qt") {
              // self.queryTargetHgView.updateForNewRecommendations();
            }
            else {
              if (self.state.suggestionTableIsVisible) {
                self.suggestionTableMakeInvisible();
                self.fadeOutSuggestionIntervalDrop();
              }
              if (self.state.roiTableIsVisible) {
                self.roiTableMakeInvisible();
                self.fadeOutRoiIntervalDrop();
              }
              self.triggerUpdate("update", "recommenderV3SearchOnClick");
            }
            self.setState({
              recommenderV3SearchInProgress: false,
              recommenderV3SearchIsVisible: self.recommenderV3SearchCanBeVisible(),
              recommenderV3SearchIsEnabled: self.recommenderV3SearchCanBeEnabled(),
              recommenderV3SearchButtonLabel: RecommenderV3SearchButtonDefaultLabel,
              recommenderV3SearchLinkLabel: RecommenderSearchLinkDefaultLabel,
              recommenderV3ExpandIsEnabled: self.recommenderV3ExpandCanBeEnabled(),
              recommenderV3ExpandLinkLabel: RecommenderExpandLinkDefaultLabel,
            }, () => {
              const keepSuggestionInterval = true; // false;
              self.updateViewerURL(
                self.state.hgViewParams.mode,
                self.state.queryRegionIndicatorData.chromosome,
                self.state.queryRegionIndicatorData.chromosome,
                self.state.queryRegionIndicatorData.start,
                self.state.queryRegionIndicatorData.stop,
                keepSuggestionInterval,
                "recommenderV3SearchOnClick",
              );
            });
          });
        }

        const queryObj = Helpers.getJsonFromUrl();
        let chrLeft = this.state.currentPosition.chrLeft || queryObj.chrLeft;
        let chrRight = this.state.currentPosition.chrRight || queryObj.chrRight;
        let start = parseInt(this.state.currentPosition.startLeft || queryObj.start);
        let stop = parseInt(this.state.currentPosition.stopRight || queryObj.stop);
        const currentGenome = this.state.hgViewParams.genome || queryObj.genome;
        const chromInfoCacheExists = Object.prototype.hasOwnProperty.call(this.chromInfoCache, currentGenome);

        function recommenderV3SearchOnClickForChromInfo(chromInfo, self) {
          if (!(chrLeft in chromInfo.chromLengths) || !(chrRight in chromInfo.chromLengths)) {
            // console.log("[recommenderV3SearchOnClickForChromInfo] chromosome not in chromLengths");
            // throw new TypeError();
            chrLeft = Constants.defaultApplicationPositions[currentGenome].chr;
            chrRight = Constants.defaultApplicationPositions[currentGenome].chr;
            start = Constants.defaultApplicationPositions[currentGenome].start;
            stop = Constants.defaultApplicationPositions[currentGenome].stop;
          }
          if (start > chromInfo.chromLengths[chrLeft]) {
            start = chromInfo.chromLengths[chrLeft] - 10000;
          }
          if (stop > chromInfo.chromLengths[chrRight]) {
            stop = chromInfo.chromLengths[chrRight] - 1000;
          }
          // const absLeft = chromInfo.chrToAbs([chrLeft, parseInt(start)]);
          // const absRight = chromInfo.chrToAbs([chrRight, parseInt(stop)]);
          const queryChr = chrLeft;
          const queryStart = start;
          const queryEnd = stop;
          const queryWindowSize = parseInt(parseInt(self.state.currentViewScale) / 1000); // kb
          
          // console.log(`[recommenderV3SearchOnClick] starting region: ${queryChr} | ${queryStart} | ${queryEnd} | ${queryWindowSize}`);

          let newRecommenderV3Query = Helpers.recommenderV3QueryPromise(queryChr, queryStart, queryEnd, queryWindowSize, self);
          newRecommenderV3Query.then((res) => {
            if (!res.query) {
              console.log(`res ${JSON.stringify(res)}`);
            }
            let queryRegionIndicatorData = {
              chromosome: res.query.chromosome,
              start: res.query.start,
              stop: res.query.end,
              midpoint: res.query.midpoint,
              sizeKey: res.query.sizeKey,
              regionLabel: `${res.query.chromosome}:${res.query.start}-${res.query.end}`,
              hitCount: res.query.hitCount,
              hitDistance: res.query.hitDistance,
              hitFirstInterval: res.query.hitFirstInterval,
              hitStartDiff: res.query.hitStartDiff,
              hitEndDiff: res.query.hitEndDiff,
            };
            // console.log(`queryRegionIndicatorData ${JSON.stringify(queryRegionIndicatorData)}`);
            // console.log(`res.hits[0] ${JSON.stringify(res.hits[0])}`);
            self.setState({
              queryRegionIndicatorData: queryRegionIndicatorData
            }, () => {
              self.simSearchRegionsUpdate(res.hits[0], updateWithSimSearchRegionsInMemory, self);
            });
          })
          .catch((err) => {
            // throw new Error(`Error - [recommenderV3SearchOnClick] could not retrieve recommender response for region query - ${JSON.stringify(err)}`);
            if (!err || !err.response || !err.response.status || !err.response.statusText) {
              err = {
                response : {
                  status : 404,
                  statusText : "No results found",
                  title : "Please try again",
                }
              };
            }
            err.response.title = "Please try again";
            let msg = self.errorMessage(err, "Could not retrieve recommendations for region query. Please try another region.");
            self.setState({
              drawerIsEnabled: true,
              overlayMessage: msg,
            }, () => {
              self.fadeInOverlay();
              self.setState({
                recommenderV3SearchInProgress: false,
                recommenderV3SearchIsVisible: self.recommenderV3SearchCanBeVisible(),
                recommenderV3SearchIsEnabled: self.recommenderV3SearchCanBeEnabled(),
                recommenderV3SearchButtonLabel: RecommenderV3SearchButtonDefaultLabel,
                recommenderV3SearchLinkLabel: RecommenderSearchLinkDefaultLabel,
                recommenderV3ExpandIsEnabled: self.recommenderV3ExpandCanBeEnabled(),
                recommenderV3ExpandLinkLabel: RecommenderExpandLinkDefaultLabel,
                genomeSelectIsActive: true,
                autocompleteInputDisabled: false,
              });
            });
          });
        }

        if (chromInfoCacheExists) {
          recommenderV3SearchOnClickForChromInfo(this.chromInfoCache[currentGenome], this);
        }
        else {
          let chromSizesURL = this.getChromSizesURL(currentGenome);
          ChromosomeInfo(chromSizesURL)
            .then((chromInfo) => {
              this.chromInfoCache[currentGenome] = Object.assign({}, chromInfo);
              recommenderV3SearchOnClickForChromInfo(chromInfo, this);
            })
            .catch((err) => {
              throw new Error(`Error - [recommenderV3SearchOnClick] could not retrieve chromosome information - ${JSON.stringify(err)}`);
              // console.log("Error - [recommenderV3SearchOnClick] could not retrieve chromosome information - ", err);
            });
        }
      });
    });
  }
  
  render() {
    
    const epilogosViewerHeaderNavbarHeight = "55px";
    
    const hgEpilogosContentHeight = ((this.state.epilogosContentHeight) ? parseInt(this.state.epilogosContentHeight) + parseInt(epilogosViewerHeaderNavbarHeight) : 0) + "px";
    //console.log("hgEpilogosContentHeight", hgEpilogosContentHeight);
    
    //const hgNonEpilogosContentHeight = (parseInt(this.state.hgViewParams.hgViewTrackChromosomeHeight) + parseInt(this.state.hgViewParams.hgViewTrackGeneAnnotationsHeight) - 1) + "px";
    
    const windowInnerHeight = document.documentElement.clientHeight + "px";
    const windowInnerWidth = document.documentElement.clientWidth + "px";
    
    const hgNonEpilogosContentHeight = parseInt(windowInnerHeight) - parseInt(hgEpilogosContentHeight) + "px";
    
    //const hgNonEpilogosContentHeight = document.documentElement.clientHeight - parseInt(epilogosViewerHeaderNavbarHeight);
    
    const hgNonEpilogosContentTop = (document.documentElement.clientHeight - parseInt(hgNonEpilogosContentHeight) - 1) + "px";
    
    // const hgEpilogosMidpoint = parseInt((document.documentElement.clientHeight)/2.0 + parseInt(epilogosViewerHeaderNavbarHeight)) + "px";
    // let hgVerticalDropLabelShift = ((document.getElementById("epilogos-viewer-container-vertical-drop-label")) ? parseInt(document.getElementById("epilogos-viewer-container-vertical-drop-label").clientWidth/2.0) : 0) + "px";
    
    let hgVerticalDropTopBorderLeft = (this.state.hgViewParams.mode === "pd") ? "rgba(183, 183, 183, 0.75) 1px dashed" : "rgba(45, 45, 45, 0.75) 1px dashed";
    
    // let hgVerticalDropLabelClassNames = (this.state.hgViewParams.mode === "pw") ? "epilogos-viewer-container-vertical-drop-label" : "epilogos-viewer-container-vertical-drop-label-inverse";
    // let hgIntervalDropLabelClassNames = (this.state.hgViewParams.mode === "pw") ? "epilogos-viewer-container-interval-drop-label epilogos-viewer-container-track-label epilogos-viewer-container-track-label-small epilogos-viewer-container-interval-drop-label-inverse epilogos-viewer-container-track-label-inverse epilogos-viewer-container-track-label-small-inverse" : "epilogos-viewer-container-interval-drop-label epilogos-viewer-container-track-label epilogos-viewer-container-track-label-small";
    
    const queryHgViewOptions = { 
      bounded: true,
      pixelPreciseMarginPadding: false,
      containerPaddingX: 0,
      containerPaddingY: 0,
      viewMarginTop: 0,
      viewMarginBottom: 0,
      viewMarginLeft: 0,
      viewMarginRight: 0,
      viewPaddingTop: Constants.defaultApplicationQueryViewPaddingTop,
      viewPaddingBottom: 0,
      viewPaddingLeft: 0,
      viewPaddingRight: 0
    };

    const mainHgViewOptions = { 
      bounded: true,
      pixelPreciseMarginPadding: false,
      containerPaddingX: 0,
      containerPaddingY: 0,
      viewMarginTop: 0,
      viewMarginBottom: 0,
      viewMarginLeft: 0,
      viewMarginRight: 0,
      viewPaddingTop: 0,
      viewPaddingBottom: 0,
      viewPaddingLeft: 0,
      viewPaddingRight: 0 
    };

    const queryTargetHgViewOptions = mainHgViewOptions;

    return (
      <div id="epilogos-viewer-container-parent" ref={(component) => this.epilogosViewerContainerParent = component}>
      
        {/* <div id="epilogos-viewer-container-interval-drop" className="epilogos-viewer-container-interval-drop epilogos-viewer-container-track-label-parent" ref={(component) => this.epilogosViewerContainerIntervalDrop = component}>
          <div id="epilogos-viewer-container-interval-drop-region-indicator" ref={(component) => this.epilogosViewerContainerIntervalDropRegionIntervalIndicator = component} style={{top:`10px`, position:"absolute", zIndex:"0", pointerEvents:"none", left:"-300px"}}>
            <RegionIntervalIndicator 
              data={this.state.regionIndicatorData}
              outerWidth={this.state.regionIndicatorOuterWidth}
              width={(this.state.regionIndicatorOuterWidth > 300) ? `${this.state.regionIndicatorOuterWidth}px` : "300px"}
              height="100px"
              radius="3px"
              textColorRGBA="0, 0, 0, 1"
              fillRGB="183, 183, 183"
              fillOpacity="1"
              strokeRGB="183, 183, 183"
              strokeOpacity="0.75"
              strokeWidth="1"
              strokeDasharray="1,1" />
          </div>
          {/*<div id="epilogos-viewer-container-interval-drop-label" className={hgIntervalDropLabelClassNames} ref={(component) => this.epilogosViewerContainerIntervalDropLabel = component} style={{top:parseInt(Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight + 7)+'px'}}>
            {this.state.verticalDropLabel}
          </div>*
          <div id="epilogos-viewer-container-interval-drop-left-top" className="epilogos-viewer-container-interval-drop-left-top" ref={(component) => this.epilogosViewerContainerIntervalDropLeftTop = component} style={{ height: `${hgEpilogosContentHeight}`, top: "0px" , borderLeft: `${hgVerticalDropTopBorderLeft}` }} />
          <div id="epilogos-viewer-container-interval-drop-left-bottom" className="epilogos-viewer-container-interval-drop-left-bottom" ref={(component) => this.epilogosViewerContainerIntervalDropLeftBottom = component} style={{height:`${hgNonEpilogosContentHeight}`,top:`${hgNonEpilogosContentTop}`}} />
          <div id="epilogos-viewer-container-interval-drop-right-top" className="epilogos-viewer-container-interval-drop-right-top" ref={(component) => this.epilogosViewerContainerIntervalDropRightTop = component} style={{ height: `${hgEpilogosContentHeight}`, top: "0px" , borderLeft: `${hgVerticalDropTopBorderLeft}` }} />
          <div id="epilogos-viewer-container-interval-drop-right-bottom" className="epilogos-viewer-container-interval-drop-right-bottom" ref={(component) => this.epilogosViewerContainerIntervalDropRightBottom = component} style={{height:`${hgNonEpilogosContentHeight}`,top:`${hgNonEpilogosContentTop}`}} />
        </div> */}
        
        
        {/* <div id="epilogos-viewer-container-vertical-drop" className="epilogos-viewer-container-vertical-drop" ref={(component) => this.epilogosViewerContainerVerticalDrop = component}>
          <div id="epilogos-viewer-container-vertical-drop-region-indicator" ref={(component) => this.epilogosViewerContainerVerticalDropRegionMidpointIndicator = component} style={{top:`10px`, left:"calc(50vw - 2px)", position:"absolute", zIndex:"10000", pointerEvents:"all"}}>
            <RegionMidpointIndicator 
              data={this.state.regionIndicatorData}
              width="300px"
              height="100px"
              radius="3px"
              textColorRGBA="0, 0, 0, 1"
              fillRGB="183, 183, 183"
              fillOpacity="1"
              strokeRGB="183, 183, 183"
              strokeOpacity="0.75"
              strokeWidth="1"
              strokeDasharray="1,1" />
          </div>
          {/*<div id="epilogos-viewer-container-vertical-drop-label" className={hgVerticalDropLabelClassNames} ref={(component) => this.epilogosViewerContainerVerticalDropLabel = component} style={{top:`calc(100vh - ${hgEpilogosMidpoint} + ${hgVerticalDropLabelShift})`, left:"calc(50vw)"}}>
            {this.state.verticalDropLabel}
          </div>*
          <div id="epilogos-viewer-container-vertical-drop-top" className="epilogos-viewer-container-vertical-drop-top" ref={(component) => this.epilogosViewerContainerVerticalDropTop = component} style={{ height: `${hgEpilogosContentHeight}`, top: "0px", left:"calc(50vw)", borderLeft: `${hgVerticalDropTopBorderLeft}` }} />
          <div id="epilogos-viewer-container-vertical-drop-bottom" className="epilogos-viewer-container-vertical-drop-bottom" ref={(component) => this.epilogosViewerContainerVerticalDropBottom = component} style={{height:`${hgNonEpilogosContentHeight}`,top:`${hgNonEpilogosContentTop}`, left:"calc(50vw)"}} />
        </div> */}
      
        
      
        <div id="epilogos-viewer-container-error-overlay" className="epilogos-viewer-container-overlay" ref={(component) => this.epilogosViewerContainerErrorOverlay = component} onClick={() => {this.fadeOutOverlay(() => { /*console.log("faded out!");*/ this.setState({ overlayVisible: false }); })}}>
        
          <div ref={(component) => this.epilogosViewerContainerErrorOverlayNotice = component} id="epilogos-viewer-overlay-error-notice" className="epilogos-viewer-overlay-notice-parent" style={{position: 'absolute', top: '35%', zIndex:10001, textAlign:'center', width: '100%', backfaceVisibility: 'visible', transform: 'translateZ(0) scale(1.0, 1.0)'}} onClick={(e)=>{ e.stopPropagation() }}>
            <Collapse isOpen={this.state.showOverlayNotice}>
              <div className="epilogos-viewer-overlay-notice-child">
                {this.viewerOverlayNotice()}
              </div>
            </Collapse>
          </div>
          
        </div>
        
      
        <div id="epilogos-viewer-container-overlay" className="epilogos-viewer-container-overlay" ref={(component) => this.epilogosViewerContainerOverlay = component} onClick={() => {this.fadeOutContainerOverlay(() => { /*console.log("faded out!");*/ this.setState({ tabixDataDownloadCommandVisible: false }); })}}>
        
          <div ref={(component) => this.epilogosViewerDataNotice = component} id="epilogos-viewer-data-notice" className="epilogos-viewer-data-notice-parent" style={{position: 'absolute', top: '30%', zIndex:10001, textAlign:'center', width: '100%', backfaceVisibility: 'visible', transform: 'translateZ(0) scale(1.0, 1.0)'}} onClick={(e)=>{ e.stopPropagation() }}>
            <Collapse isOpen={this.state.showDataNotice}>
              <div className="epilogos-viewer-data-notice-child">
                {this.viewerDataNotice()}
              </div>
            </Collapse>
          </div>
          
        </div>
        
        <div ref={(component) => this.epilogosViewerTrackLabelParent = component} id="epilogos-viewer-container-track-label-parent" className="epilogos-viewer-container-track-label-parent">
          {this.trackLabels()}
        </div>
      
        <div id="epilogos-viewer-drawer-parent">
          <Drawer 
            disableOverlayClick={true}
            noOverlay={this.state.hideDrawerOverlay}
            className="epilogos-viewer-drawer" 
            width={this.state.drawerWidth}
            pageWrapId={"epilogos-viewer-container"} 
            outerContainerId={"epilogos-viewer-container-parent"}
            isOpen={this.state.drawerIsOpen}
            onStateChange={(state) => this.handleDrawerStateChange(state)}>
            <div>
              <DrawerContent 
                key={this.state.drawerContentKey}
                activeTab={this.state.drawerActiveTabOnOpen}
                type={this.state.drawerSelection} 
                title={this.state.drawerTitle}
                viewParams={this.state.hgViewParams}
                currentCoordIdx={0}
                drawerWidth={this.state.drawerWidth}
                drawerHeight={this.state.drawerHeight}
                orientationIsPortrait={false}
                changeViewParams={this.changeViewParams}
                updateActiveTab={this.updateActiveTab}
                proteinCodingsEnabled={this.state.proteinCodingsEnabled}
                proteinCodingsTableData={this.state.proteinCodingsTableData}
                proteinCodingsSystems={this.state.proteinCodingsSystems}
                selectedProteinCodingsRowIdx={this.state.selectedProteinCodingsRowIdx}
                onProteinCodingsColumnSort={this.updateSortOrderOfProteinCodingsTableDataIndices}
                transcriptionFactorsEnabled={this.state.transcriptionFactorsEnabled}
                transcriptionFactorsTableData={this.state.transcriptionFactorsTableData}
                transcriptionFactorsSystems={this.state.transcriptionFactorsSystems}
                selectedTranscriptionFactorsRowIdx={this.state.selectedTranscriptionFactorsRowIdx}
                onTranscriptionFactorsColumnSort={this.updateSortOrderOfTranscriptionFactorsTableDataIndices}
                roiEnabled={this.state.roiEnabled}
                roiTableData={this.state.roiTableData}
                roiTableDataLongestNameLength={this.state.roiTableDataLongestNameLength}
                roiTableDataLongestAllowedNameLength={this.state.roiTableDataLongestAllowedNameLength}
                roiMaxColumns={this.state.roiMaxColumns}
                selectedRoiRowIdx={this.state.selectedRoiRowIdx}
                onRoiColumnSort={this.updateSortOrderOfRoiTableDataIndices}
                jumpToRegion={this.jumpToRegion}
                />
            </div>
          </Drawer>
        </div>
        
        <div ref={(component) => this.epilogosViewerUpdateNotice = component} id="epilogos-viewer-update-notice" className="epilogos-viewer-update-notice-parent" style={{position: 'absolute', top: '45%', zIndex:1000, textAlign:'center', width: '100%', backfaceVisibility: 'hidden', transform: 'translateZ(0) scale(1.0, 1.0)'}}>
          <Collapse isOpen={this.state.showUpdateNotice}>
            <div className="epilogos-viewer-update-notice-child" style={(this.isMobile && this.isPortrait)?{maxWidth:"320px"}:{}}>
              {this.viewerUpdateNotice()}
            </div>
          </Collapse>
        </div>
      
        <div ref={(ref) => this.indexViewer = ref} id="epilogos-viewer-container" className="epilogos-viewer-container">
         
          <Navbar id="epilogos-viewer-container-navbar" color="#000000" expand="md" className="navbar-top navbar-top-custom justify-content-start" style={{backgroundColor:"#000000", cursor:"pointer"}}>
            
            <div className="epilogos-viewer-left">

              <NavItem>
                <div title={(this.state.drawerIsOpen)?"Close drawer":"Component-labeled protein-coding and transcription factor genes"} id="epilogos-viewer-hamburger-button" ref={(component) => this.epilogosViewerHamburgerButtonParent = component} className="epilogos-viewer-hamburger-button">
                  <div className="hamburger-button" ref={(component) => this.epilogosViewerHamburgerButton = component} onClick={() => this.toggleDrawer("proteinCodingGenes")}>
                    {!this.state.drawerIsOpen ? <FaBars size="1.2em" /> : <FaTimes size="1.2em" />}
                  </div>
                </div>
              </NavItem>
              
              <NavbarBrand className="brand-container navbar-brand-custom"> 
                <div className="brand" title={"Return to portal"}>
                  <div className="brand-content brand-content-viewer">
                    <div className="brand-content-header-viewer brand-content-text-viewer" onClick={this.onClick} data-id={Helpers.stripQueryStringAndHashFromPath(document.location.href)} data-target="_self">
                      index{'\u00A0'}
                    </div>
                  </div>
                </div>
              </NavbarBrand>

              <NavItem id="navigation-mode-toggle" className="navbar-nav">
                { (this.state.hgViewParams.mode === "pd" || this.state.hgViewParams.mode === "ac") ? this.modeToggleAsElement() : "" }
              </NavItem>

            </div>
            
            <Nav className="ml-auto navbar-righthalf" navbar style={((this.state.isMobile)?{"display":"none","width":"0px","height":"0px"}:((parseInt(this.state.width)<1180)?((parseInt(this.state.width)>820)?{"display":"block"}:{"display":"none"}):{"display":"block"}))}>
              <div className="navigation-summary" ref={(component) => this.epilogosViewerNavbarRighthalf = component} id="navbar-righthalf" key={this.state.currentPositionKey} style={this.state.currentPosition ? { display: 'flex' } : { display: 'none' }}>
                <div key={this.state.positionSummaryElementKey} className="navigation-summary-position" style={((this.state.isMobile)?{"display":"none","width":"0px","height":"0px"}:((parseInt(this.state.width)<1180)?((parseInt(this.state.width)>1024)?{"display":"block"}:{"display":"none"}):{"display":"block"}))}>{this.positionSummaryElement(true)}</div>
                <div id="epilogos-viewer-recommender-input-parent">
                {((this.isProductionSite) || (this.isProductionProxySite) || (this.state.hgViewParams.mode === "pds")) ? 
                  <span /> 
                  :
                  <SimsearchPill 
                    ref={(component) => this.indexDHSViewerSuggestionPill = component}
                    onClick={this.recommenderV3SearchOnClick}
                    count={this.state.simSearchQueryCount}
                    isVisible={this.state.simSearchQueryCountIsVisible}
                    isEnabled={this.state.simSearchQueryCountIsEnabled}
                    inProgress={this.state.recommenderV3SearchInProgress}
                    />
                  // <RecommenderSearchButton
                  //   ref={(component) => this.epilogosViewerRecommenderV3Button = component}
                  //   onClick={this.recommenderV3SearchOnClick}
                  //   inProgress={this.state.recommenderV3SearchInProgress}
                  //   isVisible={this.state.recommenderV3SearchIsVisible}
                  //   isEnabled={this.state.recommenderV3SearchIsEnabled}
                  //   label={this.state.recommenderV3SearchButtonLabel}
                  //   activeClass={"epilogos-recommender-element"}
                  //   manageAnimation={this.recommenderV3ManageAnimation}
                  //   canAnimate={this.state.recommenderV3CanAnimate}
                  //   hasFinishedAnimating={this.state.recommenderV3AnimationHasFinished}
                  //   enabledColor={"rgb(255,215,0)"}
                  //   disabledColor={"rgb(120,120,120)"}
                  //   size={18}
                  //   loopAnimation={false}
                  //   /> 
                }
                </div>
                <div id="epilogos-viewer-search-input-parent" className="epilogos-viewer-search-input-parent">
                  <Autocomplete
                    ref={(ref) => { this.autocompleteInputRef = ref; }}
                    className={"epilogos-viewer-search-input"}
                    placeholder={Constants.defaultSingleGroupSearchInputPlaceholder}
                    annotationScheme={Constants.annotationScheme}
                    annotationHost={Constants.annotationHost}
                    annotationPort={Constants.annotationPort}
                    annotationAssemblyRaw={this.state.hgViewParams.genome}
                    // annotationAssembly={`${this.state.hgViewParams.genome}_GENCODE_v38`}
                    annotationAssembly={`${this.state.hgViewParams.genome}_GENCODE_v38_v121823`}
                    mapIndexDHSScheme={Constants.mapIndexDHSScheme}
                    mapIndexDHSHost={Constants.mapIndexDHSHost}
                    mapIndexDHSPort={Constants.mapIndexDHSPort}
                    mapIndexDHSSetName={Constants.mapIndexDHSSetName}
                    onChangeLocation={this.onChangeSearchInputLocation}
                    onChangeInput={this.onChangeSearchInput}
                    onFocus={this.onFocusSearchInput}
                    onPostFocus={this.closeDrawer}
                    title={"Search for a gene of interest or jump to a genomic interval"}
                    suggestionsClassName={"suggestions viewer-suggestions"}
                    isMobile={false}
                  />
                </div>
                {/* <div id="epilogos-viewer-navigation-summary-assembly" title={"Viewer genomic assembly"} className="navigation-summary-assembly" style={(parseInt(this.state.width)<1250)?{}:{"letterSpacing":"0.005em"}}>
                  <GenomeSelectButton
                    ref={(ref) => { this.genomeSelectButtonRef = ref; }}
                    onClick={this.onClickGenomeSelect}
                    onMouseover={this.onMouseoverGenomeSelect}
                    enabled={this.state.genomeSelectIsEnabled}
                    visible={this.state.genomeSelectIsVisible}
                    active={this.state.genomeSelectIsActive}
                    label={this.state.hgViewParams.genome}
                    assembly={this.state.hgViewParams.genome}
                    category={Constants.genomes[this.state.hgViewParams.genome]}
                    disable={this.disableGenomeSelect}
                    switchToGenome={this.onSwitchGenomeSelect}
                    geneAnnotationTrackType={this.state.hgViewParams.gatt}
                    setGeneAnnotationTrackType={this.setGeneAnnotationTrackType}
                    />
                </div> */}
                {/*<div title={"Viewer genomic assembly"} className="navigation-summary-assembly" style={(parseInt(this.state.width)<1180)?{"letterSpacing":"0.005em"}:{}}>{this.state.hgViewParams.genome}</div>*/}
                <div title="Export viewer data" className={'navigation-summary-download ' + (this.state.downloadIsVisible?'navigation-summary-download-hover':'')} style={this.state.downloadWidgetIsVisible?{ visibility: 'visible' } : { visibility: 'hidden', width: '0px', marginLeft: '0px', paddingLeft: '0px' }} id="navigation-summary-download" onClick={this.onMouseClickDownload}><div className="navigation-summary-download-inner" style={(parseInt(this.state.width)<1180)?{"letterSpacing":"0.005em"}:{}}><FaArrowAltCircleDown /></div></div>
              </div>
            </Nav>
            
          </Navbar>
          
          <div style={((this.state.isMobile&&this.state.isPortrait)?{"visibility":"hidden","width":"0px","height":"0px"}:((this.state.isMobile&&!this.state.isPortrait)?{"visibility":"visible", "position":"absolute", "zIndex":"10000", "top":"15px", "right":"15px", "maxWidth":"320px", "whiteSpace":"no-wrap"}:{"visibility":"hidden","width":"0px","height":"0px"}))}>
            {this.positionSummaryElement(false)}
          </div>

          {(["pd", "ac", "co", "cf", "ft", "pds"].includes(this.state.hgViewParams.mode)) ? 
            
            <div className="higlass-content" style={{
              "height": this.state.hgViewHeight, 
              "position": "absolute", 
              // "top": `${Constants.applicationViewerHgViewPaddingTop + Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight}px`,
              "top": `${Constants.viewerHgViewParameters.epilogosHeaderNavbarHeight}px`,
              }}>
              <HiGlassComponent
                key={this.state.hgViewKey}
                ref={(component) => this.hgView = component}
                options={{ 
                  bounded: true,
                  pixelPreciseMarginPadding: false,
                  containerPaddingX: 0,
                  containerPaddingY: 0,
                  viewMarginTop: 0,
                  viewMarginBottom: 0,
                  viewMarginLeft: 0,
                  viewMarginRight: 0,
                  viewPaddingTop: 0,
                  viewPaddingBottom: 0,
                  viewPaddingLeft: 0,
                  viewPaddingRight: 0 
                }}
                viewConfig={this.state.mainHgViewconf}
                />
            </div>

            :

            <div className="higlass-content higlass-target-content" style={{"height": this.state.hgViewHeight, "top": Constants.applicationViewerHgViewPaddingTop}}>
              <QueryTargetViewer
                key={this.state.queryTargetHgViewKey}
                ref={(component) => this.queryTargetHgView = component}
                hgViewOptions={queryTargetHgViewOptions}
                hgViewParams={this.state.hgViewParams}
                hgViewconf={this.state.mainHgViewconf}
                navbarHeight={parseInt(Constants.defaultApplicationNavbarHeight)}
                contentHeight={parseInt(windowInnerHeight)}
                contentWidth={parseInt(windowInnerWidth)}
                queryHeaderLabel={'Query'}
                queryRegionLabel={this.state.queryTargetQueryRegionLabel}
                queryRegion={this.state.queryTargetQueryRegion}
                targetHeaderLabel={Constants.queryTargetViewerHitLabel}
                targetRegionLabel={this.state.queryTargetTargetRegionLabel}
                targetRegion={this.state.queryTargetTargetRegion}
                labelMinWidth={220}
                chromInfoCache={this.chromInfoCache}
                drawerWidth={300}
                hitsHeaderLabel={Constants.queryTargetViewerHitsHeaderLabel}
                hits={this.state.simSearchTableData}
                currentSelectedHitIdx={this.state.selectedSimSearchRowIdx}
                onHitSelect={this.updateSimSearchHitSelection}
                onHitsColumnSort={this.updateSortOrderOfSimSearchTableDataIndices}
                hitsIdxBySort={this.state.simSearchTableDataIdxBySort}
                errorMessage={this.errorMessage}
                updateParentViewerURL={this.updateViewerURLForQueryTargetMode}
                updateParentViewerAutocompleteState={this.updateViewerAutocompleteState}
                updateParentViewerHamburgerMenuState={this.updateViewerHamburgerMenuState}
                updateParentViewerDownloadState={this.updateViewerDownloadState}
                updateParentViewerOverlay={this.updateViewerOverlay}
                updateParentViewerState={this.updateViewerState}
                updateParentViewerRegions={this.updateRegionsForQueryTargetView}
                expandParentViewerToRegion={this.expandViewerToRegion}
                isQueryTargetViewLocked={this.state.queryTargetLockFlag}
                toggleQueryTargetViewLock={this.toggleQueryTargetViewLock}
                queryRegionIndicatorData={this.state.queryRegionIndicatorData}
                willRequireFullExpand={this.state.queryTargetModeWillRequireFullExpand}
                globalMinMax={this.state.queryTargetGlobalMinMax}
                copyClipboardText={this.onClickCopyRegionCommand}
                />
                {/* <QueryTargetViewer
                  key={this.state.queryTargetHgViewKey}
                  ref={(component) => this.queryTargetHgView = component}
                  hgViewOptions={queryTargetHgViewOptions}
                  navbarHeight={parseInt(Constants.defaultApplicationNavbarHeight)}
                  contentHeight={parseInt(windowInnerHeight)}
                  contentWidth={parseInt(windowInnerWidth)}
                  queryHeaderLabel={'Query'}
                  queryRegionLabel={this.state.queryTargetQueryRegionLabel}
                  queryRegion={this.state.queryTargetQueryRegion}
                  targetHeaderLabel={'Search hit'}
                  targetRegionLabel={this.state.queryTargetTargetRegionLabel}
                  targetRegion={this.state.queryTargetTargetRegion}
                  labelMinWidth={220}
                  chromInfoCache={this.chromInfoCache}
                  hgViewParams={this.state.hgViewParams}
                  hgViewconf={this.state.mainHgViewconf}
                  drawerWidth={320}
                  hitsHeaderLabel={'Recommendations'}
                  hits={this.state.roiTableData}
                  currentSelectedHitIdx={1}
                  onHitsColumnSort={this.updateSortOrderOfRoiTableDataIndices}
                  hitsIdxBySort={this.state.roiTableDataIdxBySort}
                  errorMessage={this.errorMessage}
                  updateParentViewerURL={this.updateViewerURLForQueryTargetMode}
                  updateParentViewerAutocompleteState={this.updateViewerAutocompleteState}
                  updateParentViewerHamburgerMenuState={this.updateViewerHamburgerMenuState}
                  updateParentViewerDownloadState={this.updateViewerDownloadState}
                  updateParentViewerOverlay={this.updateViewerOverlay}
                  updateParentViewerState={this.updateViewerState}
                  updateParentViewerRois={this.updateRegionsForQueryTargetView}
                  expandParentViewerToRegion={this.expandViewerToRegion}
                  queryTargetViewIsLocked={this.state.queryTargetLockFlag}
                  toggleQueryTargetViewLock={this.toggleQueryTargetViewLock}
                  queryRegionIndicatorData={this.state.queryRegionIndicatorData}
                  /> */}
              </div>
        }
          
          <div className={'navigation-summary-download-popup'} id="navigation-summary-download-popup" onMouseEnter={this.onMouseEnterDownload} onMouseLeave={this.onMouseLeaveDownload} style={{visibility:((this.state.downloadIsVisible)?"visible":"hidden"), position:"absolute", top:this.state.downloadButtonBoundingRect.bottom, left:(this.state.downloadButtonBoundingRect.right - this.state.downloadPopupBoundingRect.width)}}>
            <div>
              <div className="download-route-label">download</div>
              <div>
                {this.downloadRouteElement()}
              </div>
            </div>
          </div>

          <div style={{top:`${(this.state.mousePosition.y) - 12}px`,left:`${(this.state.mousePosition.x) - 50}px`}} className={`clipboard-alert ${this.state.showingClipboardCopiedAlert ? 'clipboard-alert-shown' : 'clipboard-alert-hidden'}`}>
            <Badge color="success" pill>Region copied!</Badge>
          </div>
          
        </div>
        
      </div>
    );
  }
}

export default Viewer;

Viewer.propTypes = { 
  height: PropTypes.string,
  hgViewParams: PropTypes.object,
  width: PropTypes.string,
};