import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';

import { 
  TabContent, 
  TabPane, 
  Nav, 
  NavItem, 
  NavLink,
} from 'reactstrap';
import classnames from 'classnames';

// Application constants
import * as Constants from '../Constants.js'; 

import { FaChevronCircleDown, FaChevronCircleUp } from 'react-icons/fa'

// Pretty-checkbox (pure CSS radio buttons)
import 'pretty-checkbox/dist/pretty-checkbox.css';

// ref. https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook
// ref. https://github.com/react-bootstrap-table/react-bootstrap-table2/tree/master/docs
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';

// Tooltip (for state and other mouseover help)
import ReactTooltip from 'react-tooltip';

// Query JSON objects (to build dropdowns and other inputs)
// cf. https://www.npmjs.com/package/jsonpath-lite
export const jp = require("jsonpath");

// Compare JSON objects for equality
export const equal = require("deep-equal");

class DrawerContent extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      drawerParametersHeight: 87,
      currentRoiMouseoverRow: -1,
      currentTranscriptionFactorsMouseoverRow: -1,
      currentProteinCodingsMouseoverRow: -1,
      activeTab: this.props.activeTab,
      enteredSettingsButtonName: null,
      enteredSettingsButtonValue: null,
      hideshow: {
        genome: true,
        model: true,
        complexity: true,
        mode: true,
        samples: true,
        preferredSamples: true,
        advancedOptions: this.props.advancedOptionsVisible
      },
      hideshowWidgetIsVisible: {
        genome: false,
        model: false,
        complexity: false,
        mode: false,
        samples: false,
        preferredSamples: false,
        advancedOptions: true
      },
      tabs: {
        proteinCodings: true,
        transcriptionFactors: true,
        roi: true
      },
      viewParams: {...this.props.viewParams},
      newViewParamsAreEqual: true
    };
    
    this.drawerParameters = React.createRef();
  }
  
  componentDidMount() {
    let drawerParameters = document.getElementById("drawer-parameters");
    setTimeout(() => {
      if (drawerParameters && drawerParameters.offsetHeight) {
        this.setState({
          drawerParametersHeight: drawerParameters.offsetHeight
        })
      }
    }, 1000);
  }
  
  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      }, () => {
        this.props.updateActiveTab(tab);
      });
    }
  }
  
  systemProteinCodingsToColorBox = (system) => {
    let backgroundColor = ((Constants.systemColorPalettes[this.props.viewParams.genome][system]) || "white");
    return <span className="state-color-box" style={{"backgroundColor":`rgb(${backgroundColor})`, "display":"inline-block"}}></span>
  }
  
  systemTranscriptionFactorsToColorBox = (system) => {
    let backgroundColor = ((Constants.systemColorPalettes[this.props.viewParams.genome][system]) || "white");
    return <span className="state-color-box" style={{"backgroundColor":`rgb(${backgroundColor})`, "display":"inline-block"}}></span>
  }
  
  toggleSettings = (category) => {
    //console.log("toggleSettings - old", this.state.hideshow);
    let newHideshow = this.state.hideshow;
    newHideshow[category] = !newHideshow[category];
    //console.log("toggleSettings - new", newHideshow);
    this.setState({
      hideshow : newHideshow
    });
  }
  
  onMouseEnterSettingsButton = (event) => {
    if (!event.target.disabled) {
      this.setState({
        enteredSettingsButtonName: event.target.name,
        enteredSettingsButtonValue: event.target.value
      });
    }
  }
  
  onMouseLeaveSettingsButton = (event) => {
    if (!event.target.disabled) {
      this.setState({
        enteredSettingsButtonName: null,
        enteredSettingsButtonValue: null
      });
    }
  }
  
  compareViewParams = (a, b) => {
    return equal(a, b);
  }
  
  render() {
    let self = this;
    function contentByType(type) {
      switch (type) {
        case "roi": {
          let roiResult = "";
          roiResult = <BootstrapTable 
                        id="drawer-content-roi-table"
                        keyField='idx' 
                        data={self.props.roiTableData}
                        columns={roiColumns} 
                        bootstrap4={true} 
                        bordered={false}
                        classes="elementTable"
                        rowStyle={customRoiRowStyle}
                        rowEvents={customRoiRowEvents}
                        />
          return <div style={{"height":self.props.drawerHeight,"overflowY":"auto"}} >{roiResult}</div>;
        }
        case "proteinCodings": {
          let proteinCodingsResult = "";
          let proteinCodingsTooltips = [];
          const kProteinCodingsTooltipPrefix = 'tooltip-proteinCodings-';
          let kProteinCodingsTooltipIdx = 0;
          //self.state.chromatinStates.forEach((val, idx) => {
          self.props.proteinCodingsSystems.forEach((val) => {
            let proteinCodingsId = "proteinCodings-system-" + val;
            //console.log("val", val);
            //console.log("self.props.viewParams.genome", self.props.viewParams.genome);
            //console.log("self.props.viewParams.model", self.props.viewParams.model);
            //console.log("Constants.systemColorPalettes[self.props.viewParams.genome][val]", Constants.systemColorPalettes[self.props.viewParams.genome][val]);
            let proteinCodingsSystemName = ((Constants.systemHumanReadableNames[self.props.viewParams.genome][val]) || "Undefined");
            const kProteinCodingsTooltipKey = kProteinCodingsTooltipPrefix + kProteinCodingsTooltipIdx;
            proteinCodingsTooltips.push(<ReactTooltip key={kProteinCodingsTooltipKey} id={proteinCodingsId} aria-haspopup='true' place="right" type="dark" effect="float">{proteinCodingsSystemName}</ReactTooltip>);
            kProteinCodingsTooltipIdx++;
          });
          proteinCodingsResult = <BootstrapTable 
                                    keyField='idx' 
                                    data={self.props.proteinCodingsTableData}
                                    columns={proteinCodingsColumns} 
                                    bootstrap4={true} 
                                    bordered={false}
                                    classes="elementTable"
                                    rowStyle={customProteinCodingsRowStyle}
                                    rowEvents={customProteinCodingsRowEvents}
                                    />
          return <div style={{"height":self.props.drawerHeight,"overflowY":"auto"}} >{proteinCodingsResult}{proteinCodingsTooltips}</div>;
        }
        case "transcriptionFactors": {
          let transcriptionFactorsResult = "";
          let transcriptionFactorsTooltips = [];
          const kTranscriptionFactorsTooltipPrefix = 'tooltip-transcriptionFactors-';
          let kTranscriptionFactorsTooltipIdx = 0;
          //self.state.chromatinStates.forEach((val, idx) => {
          self.props.transcriptionFactorsSystems.forEach((val) => {
            let transcriptionFactorsId = "transcriptionFactors-system-" + val;
            //console.log("val", val);
            //console.log("self.props.viewParams.genome", self.props.viewParams.genome);
            //console.log("self.props.viewParams.model", self.props.viewParams.model);
            //console.log("Constants.systemColorPalettes[self.props.viewParams.genome][val]", Constants.systemColorPalettes[self.props.viewParams.genome][val]);
            let transcriptionFactorsSystemName = ((Constants.systemHumanReadableNames[self.props.viewParams.genome][val]) || "Undefined");
            const kTranscriptionFactorsTooltipKey = kTranscriptionFactorsTooltipPrefix + kTranscriptionFactorsTooltipIdx;
            transcriptionFactorsTooltips.push(<ReactTooltip key={kTranscriptionFactorsTooltipKey} id={transcriptionFactorsId} aria-haspopup='true' place="right" type="dark" effect="float">{transcriptionFactorsSystemName}</ReactTooltip>);
            kTranscriptionFactorsTooltipIdx++;
          });
          transcriptionFactorsResult = <BootstrapTable 
                                          keyField='idx' 
                                          data={self.props.transcriptionFactorsTableData}
                                          columns={transcriptionFactorsColumns} 
                                          bootstrap4={true} 
                                          bordered={false}
                                          classes="elementTable"
                                          rowStyle={customTranscriptionFactorsRowStyle}
                                          rowEvents={customTranscriptionFactorsRowEvents}
                                          />
          return <div style={{"height":self.props.drawerHeight,"overflowY":"auto"}} >{transcriptionFactorsResult}{transcriptionFactorsTooltips}</div>;
        }
        default:
          return <div></div>;
      }
    }
    
    let roiColumns = [
      {
        attrs: idxRoiAttrs,
        dataField: 'idx',
        text: '',
        headerStyle: {
          fontSize: '0.7em',
          width: '24px',
          borderBottom: '1px solid #b5b5b5',
          textAlign: 'center',
        },
        style: {
          fontSize: '0.7em',
          outlineWidth: '0px',
          marginLeft: '4px',
          paddingTop: '4px',
          paddingBottom: '2px',
          textAlign: 'center',
        },
        sort: true,
        onSort: (field, order) => { this.props.onRoiColumnSort(field, order); },
        // eslint-disable-next-line no-unused-vars
        sortCaret: (order, column) => {
          switch (order) {
            case "asc":
              return <div><ReactTooltip key="roi-column-sort-idx-asc" id="roi-column-sort-idx-asc" aria-haspopup="true" place="right" type="dark" effect="float">Sort indices in descending order</ReactTooltip><div data-tip data-for={"roi-column-sort-idx-asc"}><FaChevronCircleDown className="column-sort-defined" /></div></div>
            case "desc":
              return <div><ReactTooltip key="roi-column-sort-idx-desc" id="roi-column-sort-idx-desc" aria-haspopup="true" place="right" type="dark" effect="float">Sort indices in ascending order</ReactTooltip><div data-tip data-for={"roi-column-sort-idx-desc"}><FaChevronCircleUp className="column-sort-defined" /></div></div>
            case "undefined":
            default:
              return <div><ReactTooltip key="roi-column-sort-idx-undefined" id="roi-column-sort-idx-undefined" aria-haspopup="true" place="right" type="dark" effect="float">Sort indices</ReactTooltip><div data-tip data-for={"roi-column-sort-idx-undefined"}><FaChevronCircleDown className="column-sort-undefined" /></div></div>
          }
        }
      },
      {
        dataField: 'element',
        text: '',
        formatter: elementRoiFormatter,
        headerStyle: {
          fontSize: '0.7em',
          width: '200px',
          maxWidth: '200px',
          borderBottom: '1px solid #b5b5b5',
        },
        style: {
          fontFamily: 'Source Code Pro',
          fontWeight: 'normal',
          fontSize: '0.675em',
          outlineWidth: '0px',
          paddingTop: '4px',
          paddingBottom: '3px',
          paddingRight: '2px',
        },
        sort: true,
        // eslint-disable-next-line no-unused-vars
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          //console.log(a.paddedPosition, b.paddedPosition, order, dataField);
          if (order === 'asc') {
            return b.paddedPosition.localeCompare(a.paddedPosition);
          }
          else {
            return a.paddedPosition.localeCompare(b.paddedPosition); // desc
          }          
        },
        onSort: (field, order) => { this.props.onRoiColumnSort(field, order); },
        // eslint-disable-next-line no-unused-vars
        sortCaret: (order, column) => {
          switch (order) {
            case "asc":
              return <div><ReactTooltip key="roi-column-sort-element-asc" id="roi-column-sort-element-asc" aria-haspopup="true" place="right" type="dark" effect="float">Sort intervals in ascending order</ReactTooltip><div data-tip data-for={"roi-column-sort-element-asc"}><FaChevronCircleDown className="column-sort-defined" /></div></div>
            case "desc":
              return <div><ReactTooltip key="roi-column-sort-element-desc" id="roi-column-sort-element-desc" aria-haspopup="true" place="right" type="dark" effect="float">Sort intervals in descending order</ReactTooltip><div data-tip data-for={"roi-column-sort-element-desc"}><FaChevronCircleUp className="column-sort-defined" /></div></div>
            case "undefined":
            default:
              return <div><ReactTooltip key="roi-column-sort-element-undefined" id="roi-column-sort-element-undefined" aria-haspopup="true" place="right" type="dark" effect="float">Sort by interval</ReactTooltip><div data-tip data-for={"column-sort-element-undefined"}><FaChevronCircleDown className="column-sort-undefined" /></div></div>
          }
        }
      }
    ];
    
    // add 'name' column to ROI, if present
    if (this.props.roiMaxColumns > 3) {
      roiColumns.push({
        dataField: 'name',
        text: '',
        formatter: nameRoiFormatter,
        headerStyle: {
          fontSize: '0.7em',
          //width: `${(((this.props.roiTableDataLongestAllowedNameLength < this.props.roiTableDataLongestNameLength) ? this.props.roiTableDataLongestAllowedNameLength : this.props.roiTableDataLongestNameLength) * 8)}px`,
          width: '155px',
          maxWidth: '155px',
          borderBottom: '1px solid #b5b5b5',
        },
        style: {
          fontWeight: 'normal',
          fontSize: '0.7em',
          outlineWidth: '0px',
          paddingTop: '4px',
          paddingBottom: '2px',
          paddingRight: '3px',
        },
        sort: true,
        onSort: (field, order) => { this.props.onRoiColumnSort(field, order); },
        // eslint-disable-next-line no-unused-vars
        sortCaret: (order, column) => {
          switch (order) {
            case "asc":
              return <div><ReactTooltip key="roi-column-sort-name-asc" id="roi-column-sort-name-asc" aria-haspopup="true" place="right" type="dark" effect="float">Sort names in descending order</ReactTooltip><div data-tip data-for={"roi-column-sort-name-asc"}><FaChevronCircleDown className="column-sort-defined" /></div></div>
            case "desc":
              return <div><ReactTooltip key="roi-column-sort-name-desc" id="roi-column-sort-name-desc" aria-haspopup="true" place="right" type="dark" effect="float">Sort names in ascending order</ReactTooltip><div data-tip data-for={"roi-column-sort-name-desc"}><FaChevronCircleUp className="column-sort-defined" /></div></div>
            case "undefined":
            default:
              return <div><ReactTooltip key="roi-column-sort-name-undefined" id="roi-column-sort-name-undefined" aria-haspopup="true" place="right" type="dark" effect="float">Sort by name</ReactTooltip><div data-tip data-for={"column-sort-name-undefined"}><FaChevronCircleDown className="column-sort-undefined" /></div></div>
          }
        }
      })
    }
    
    // add 'score' column to ROI, if present
    if (this.props.roiMaxColumns > 4 && !this.props.orientationIsPortrait) {
      roiColumns.push({
        dataField: 'score',
        text: '',
        formatter: scoreRoiFormatter,
        headerStyle: {
          fontSize: '0.7em',
          width: '85px',
          borderBottom: '1px solid #b5b5b5',
        },
        style: {
          fontFamily: 'Source Code Pro',
          fontWeight: 'normal',
          fontSize: '0.7em',
          outlineWidth: '0px',
          paddingTop: '4px',
          paddingBottom: '2px',
          paddingRight: '2px',
        },
        sort: true,
        onSort: (field, order) => { this.props.onRoiColumnSort(field, order); },
        // eslint-disable-next-line no-unused-vars
        sortCaret: (order, column) => {
          switch (order) {
            case "asc":
              return <div><ReactTooltip key="roi-column-sort-score-asc" id="roi-column-sort-score-asc" aria-haspopup="true" place="right" type="dark" effect="float">Sort scores in ascending order</ReactTooltip><div data-tip data-for={"roi-column-sort-score-asc"}><FaChevronCircleDown className="column-sort-defined" /></div></div>
            case "desc":
              return <div><ReactTooltip key="roi-column-sort-score-desc" id="roi-column-sort-score-desc" aria-haspopup="true" place="right" type="dark" effect="float">Sort scores in descending order</ReactTooltip><div data-tip data-for={"roi-column-sort-score-desc"}><FaChevronCircleUp className="column-sort-defined" /></div></div>
            case "undefined":
            default:
              return <div><ReactTooltip key="roi-column-sort-score-undefined" id="roi-column-sort-score-undefined" aria-haspopup="true" place="right" type="dark" effect="float">Sort by score</ReactTooltip><div data-tip data-for={"column-sort-score-undefined"}><FaChevronCircleDown className="column-sort-undefined" /></div></div>
          }
        },
        // eslint-disable-next-line no-unused-vars
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === 'asc') {
            return b - a;
          }
          return a - b; // desc
        }
      })
    }
    
    // add 'strand' column to ROI, if present
    if (this.props.roiMaxColumns > 5 && !this.props.orientationIsPortrait) {
      roiColumns.push({
        dataField: 'strand',
        text: '',
        formatter: strandRoiFormatter,
        headerStyle: {
          fontSize: '0.7em',
          width: '85px',
          maxWidth: '85px',
          borderBottom: '1px solid #b5b5b5',
        },
        style: {
          fontFamily: 'Source Code Pro',
          fontWeight: 'normal',
          fontSize: '0.7em',
          outlineWidth: '0px',
          paddingTop: '4px',
          paddingBottom: '2px',
          paddingRight: '0px',
        },
        sort: true,
        onSort: (field, order) => { this.props.onRoiColumnSort(field, order); },
        // eslint-disable-next-line no-unused-vars
        sortCaret: (order, column) => {
          switch (order) {
            case "asc":
              return <div><ReactTooltip key="roi-column-sort-strand-asc" id="roi-column-sort-strand-asc" aria-haspopup="true" place="right" type="dark" effect="float">Sort strands in opposite order</ReactTooltip><div data-tip data-for={"roi-column-sort-strand-asc"}><FaChevronCircleDown className="column-sort-defined" /></div></div>
            case "desc":
              return <div><ReactTooltip key="roi-column-sort-strand-desc" id="roi-column-sort-strand-desc" aria-haspopup="true" place="right" type="dark" effect="float">Sort strands in opposite order</ReactTooltip><div data-tip data-for={"roi-column-sort-strand-desc"}><FaChevronCircleUp className="column-sort-defined" /></div></div>
            case "undefined":
            default:
              return <div><ReactTooltip key="roi-column-sort-strand-undefined" id="roi-column-sort-strand-undefined" aria-haspopup="true" place="right" type="dark" effect="float">Sort by strand</ReactTooltip><div data-tip data-for={"column-sort-score-undefined"}><FaChevronCircleDown className="column-sort-undefined" /></div></div>
          }
        }
      })
    }
    
    const proteinCodingsColumns = [
      {
        attrs: idxProteinCodingAttrs,
        dataField: 'idx',
        text: '',
        headerStyle: {
          fontSize: '0.8em',
          width: '30px',
          borderBottom: '1px solid #b5b5b5',
          textAlign: 'center',
        },
        style: {
          fontSize: '0.8em',
          outlineWidth: '0px',
          marginLeft: '4px',
          paddingTop: '4px',
          paddingBottom: '2px',
          textAlign: 'center',
        },
        sort: true,
        onSort: (field, order) => { this.props.onProteinCodingsColumnSort(field, order); },
        // eslint-disable-next-line no-unused-vars
        sortCaret: (order, column) => {
          switch (order) {
            case "asc":
              return <div><ReactTooltip key="proteinCodings-column-sort-idx-asc" id="proteinCodings-column-sort-idx-asc" aria-haspopup="true" place="right" type="dark" effect="float">Sort indices in descending order</ReactTooltip><div data-tip data-for={"proteinCodings-column-sort-idx-asc"}><FaChevronCircleDown className="column-sort-defined" /></div></div>
            case "desc":
              return <div><ReactTooltip key="proteinCodings-column-sort-idx-desc" id="proteinCodings-column-sort-idx-desc" aria-haspopup="true" place="right" type="dark" effect="float">Sort indices in ascending order</ReactTooltip><div data-tip data-for={"proteinCodings-column-sort-idx-desc"}><FaChevronCircleUp className="column-sort-defined" /></div></div>
            case "undefined":
            default:
              return <div><ReactTooltip key="proteinCodings-column-sort-idx-undefined" id="proteinCodings-column-sort-idx-undefined" aria-haspopup="true" place="right" type="dark" effect="float">Sort indices</ReactTooltip><div data-tip data-for={"proteinCodings-column-sort-idx-undefined"}><FaChevronCircleDown className="column-sort-undefined" /></div></div>
          }
        }
      },
      {
        dataField: 'system',
        text: '',
        formatter: systemProteinCodingsFormatter,
        headerStyle: {
          fontSize: '0.8em',
          width: '30px',
          borderBottom: '1px solid #b5b5b5',
        },
        style: {
          fontSize: '0.8em',
          outlineWidth: '0px',
          paddingTop: '4px',
          paddingBottom: '2px',
          textAlign: 'left'
        },
        sort: true,
        // eslint-disable-next-line no-unused-vars
        sortFunc: (a, b, order, dataField) => {
          if (order === 'asc') {
            return b.localeCompare(a);
          }
          else {
            return a.localeCompare(b); // desc
          }          
        },
        onSort: (field, order) => { this.props.onProteinCodingsColumnSort(field, order); },
        // eslint-disable-next-line no-unused-vars
        sortCaret: (order, column) => {
          switch (order) {
            case "asc":
              return <div><ReactTooltip key="column-sort-state-asc" id="column-sort-state-asc" aria-haspopup="true" place="right" type="dark" effect="float">Sort systems in descending order</ReactTooltip><div data-tip data-for={"column-sort-state-asc"}><FaChevronCircleDown className="column-sort-defined" /></div></div>
            case "desc":
              return <div><ReactTooltip key="column-sort-state-desc" id="column-sort-state-desc" aria-haspopup="true" place="right" type="dark" effect="float">Sort systems in ascending order</ReactTooltip><div data-tip data-for={"column-sort-state-desc"}><FaChevronCircleUp className="column-sort-defined" /></div></div>
            case "undefined":
            default:
              return <div><ReactTooltip key="column-sort-state-undefined" id="column-sort-state-undefined" aria-haspopup="true" place="right" type="dark" effect="float">Sort by system</ReactTooltip><div data-tip data-for={"column-sort-state-undefined"}><FaChevronCircleDown className="column-sort-undefined" /></div></div>
          }
        }
      },
      {
        dataField: 'gene',
        text: '',
        formatter: geneProteinCodingsFormatter,
        headerStyle: {
          fontSize: '0.8em',
          width: '230px',
          borderBottom: '1px solid #b5b5b5',
        },
        style: {
          fontWeight: 'normal',
          fontSize: '0.8em',
          outlineWidth: '0px',
          paddingTop: '4px',
          paddingBottom: '2px',
          paddingRight: '6px',
        },
        sort: true,
        // eslint-disable-next-line no-unused-vars
        sortFunc: (a, b, order, dataField) => {
          //console.log(a, b, order, dataField);
          if (order === 'asc') {
            return b.localeCompare(a);
          }
          else {
            return a.localeCompare(b); // desc
          }          
        },
        onSort: (field, order) => { this.props.onProteinCodingsColumnSort(field, order); },
        // eslint-disable-next-line no-unused-vars
        sortCaret: (order, column) => {
          switch (order) {
            case "asc":
              return <div><ReactTooltip key="column-sort-element-asc" id="column-sort-element-asc" aria-haspopup="true" place="right" type="dark" effect="float">Sort gene names in ascending order</ReactTooltip><div data-tip data-for={"column-sort-element-asc"}><FaChevronCircleDown className="column-sort-defined" /></div></div>
            case "desc":
              return <div><ReactTooltip key="column-sort-element-desc" id="column-sort-element-desc" aria-haspopup="true" place="right" type="dark" effect="float">Sort gene names in descending order</ReactTooltip><div data-tip data-for={"column-sort-element-desc"}><FaChevronCircleUp className="column-sort-defined" /></div></div>
            case "undefined":
            default:
              return <div><ReactTooltip key="column-sort-element-undefined" id="column-sort-element-undefined" aria-haspopup="true" place="right" type="dark" effect="float">Sort genes by name</ReactTooltip><div data-tip data-for={"column-sort-element-undefined"}><FaChevronCircleDown className="column-sort-undefined" /></div></div>
          }
        }
      }, 
    ];
    
    const transcriptionFactorsColumns = [
      {
        attrs: idxTranscriptionFactorAttrs,
        dataField: 'idx',
        text: '',
        headerStyle: {
          fontSize: '0.8em',
          width: '30px',
          borderBottom: '1px solid #b5b5b5',
          textAlign: 'center',
        },
        style: {
          fontSize: '0.8em',
          outlineWidth: '0px',
          marginLeft: '4px',
          paddingTop: '4px',
          paddingBottom: '2px',
          textAlign: 'center',
        },
        sort: true,
        onSort: (field, order) => { this.props.onTranscriptionFactorsColumnSort(field, order); },
        // eslint-disable-next-line no-unused-vars
        sortCaret: (order, column) => {
          switch (order) {
            case "asc":
              return <div><ReactTooltip key="transcriptionFactors-column-sort-idx-asc" id="transcriptionFactors-column-sort-idx-asc" aria-haspopup="true" place="right" type="dark" effect="float">Sort indices in descending order</ReactTooltip><div data-tip data-for={"transcriptionFactors-column-sort-idx-asc"}><FaChevronCircleDown className="column-sort-defined" /></div></div>
            case "desc":
              return <div><ReactTooltip key="transcriptionFactors-column-sort-idx-desc" id="transcriptionFactors-column-sort-idx-desc" aria-haspopup="true" place="right" type="dark" effect="float">Sort indices in ascending order</ReactTooltip><div data-tip data-for={"transcriptionFactors-column-sort-idx-desc"}><FaChevronCircleUp className="column-sort-defined" /></div></div>
            case "undefined":
            default:
              return <div><ReactTooltip key="transcriptionFactors-column-sort-idx-undefined" id="transcriptionFactors-column-sort-idx-undefined" aria-haspopup="true" place="right" type="dark" effect="float">Sort indices</ReactTooltip><div data-tip data-for={"transcriptionFactors-column-sort-idx-undefined"}><FaChevronCircleDown className="column-sort-undefined" /></div></div>
          }
        }
      },
      {
        dataField: 'system',
        text: '',
        formatter: systemTranscriptionFactorsFormatter,
        headerStyle: {
          fontSize: '0.8em',
          width: '30px',
          borderBottom: '1px solid #b5b5b5',
        },
        style: {
          fontSize: '0.8em',
          outlineWidth: '0px',
          paddingTop: '4px',
          paddingBottom: '2px',
          textAlign: 'left'
        },
        sort: true,
        // eslint-disable-next-line no-unused-vars
        sortFunc: (a, b, order, dataField) => {
          if (order === 'asc') {
            return b.localeCompare(a);
          }
          else {
            return a.localeCompare(b); // desc
          }          
        },
        onSort: (field, order) => { this.props.onTranscriptionFactorsColumnSort(field, order); },
        // eslint-disable-next-line no-unused-vars
        sortCaret: (order, column) => {
          switch (order) {
            case "asc":
              return <div><ReactTooltip key="column-sort-state-asc" id="column-sort-state-asc" aria-haspopup="true" place="right" type="dark" effect="float">Sort systems in descending order</ReactTooltip><div data-tip data-for={"column-sort-state-asc"}><FaChevronCircleDown className="column-sort-defined" /></div></div>
            case "desc":
              return <div><ReactTooltip key="column-sort-state-desc" id="column-sort-state-desc" aria-haspopup="true" place="right" type="dark" effect="float">Sort systems in ascending order</ReactTooltip><div data-tip data-for={"column-sort-state-desc"}><FaChevronCircleUp className="column-sort-defined" /></div></div>
            case "undefined":
            default:
              return <div><ReactTooltip key="column-sort-state-undefined" id="column-sort-state-undefined" aria-haspopup="true" place="right" type="dark" effect="float">Sort by system</ReactTooltip><div data-tip data-for={"column-sort-state-undefined"}><FaChevronCircleDown className="column-sort-undefined" /></div></div>
          }
        }
      },
      {
        dataField: 'gene',
        text: '',
        formatter: geneTranscriptionFactorsFormatter,
        headerStyle: {
          fontSize: '0.8em',
          width: '230px',
          borderBottom: '1px solid #b5b5b5',
        },
        style: {
          fontWeight: 'normal',
          fontSize: '0.8em',
          outlineWidth: '0px',
          paddingTop: '4px',
          paddingBottom: '2px',
          paddingRight: '6px',
        },
        sort: true,
        // eslint-disable-next-line no-unused-vars
        sortFunc: (a, b, order, dataField) => {
          //console.log(a, b, order, dataField);
          if (order === 'asc') {
            return b.localeCompare(a);
          }
          else {
            return a.localeCompare(b); // desc
          }          
        },
        onSort: (field, order) => { this.props.onTranscriptionFactorsColumnSort(field, order); },
        // eslint-disable-next-line no-unused-vars
        sortCaret: (order, column) => {
          switch (order) {
            case "asc":
              return <div><ReactTooltip key="column-sort-element-asc" id="column-sort-element-asc" aria-haspopup="true" place="right" type="dark" effect="float">Sort gene names in ascending order</ReactTooltip><div data-tip data-for={"column-sort-element-asc"}><FaChevronCircleDown className="column-sort-defined" /></div></div>
            case "desc":
              return <div><ReactTooltip key="column-sort-element-desc" id="column-sort-element-desc" aria-haspopup="true" place="right" type="dark" effect="float">Sort gene names in descending order</ReactTooltip><div data-tip data-for={"column-sort-element-desc"}><FaChevronCircleUp className="column-sort-defined" /></div></div>
            case "undefined":
            default:
              return <div><ReactTooltip key="column-sort-element-undefined" id="column-sort-element-undefined" aria-haspopup="true" place="right" type="dark" effect="float">Sort genes by name</ReactTooltip><div data-tip data-for={"column-sort-element-undefined"}><FaChevronCircleDown className="column-sort-undefined" /></div></div>
          }
        }
      }, 
    ];
    
    // eslint-disable-next-line no-unused-vars
    function idxRoiAttrs(cell, row, rowIndex, colIndex) {
      return { id : `roi_idx_${rowIndex}` };
    }
    
    // eslint-disable-next-line no-unused-vars
    function elementRoiFormatter(cell, row) {
      return <div><span>{ row.position }</span></div>
    }
    
    // eslint-disable-next-line no-unused-vars
    function nameRoiFormatter(cell, row) {
      const name = row.name;
      return (name.length >= self.props.roiTableDataLongestAllowedNameLength) ? (
        <div>
          <span title={name}>{name.substring(0, self.props.roiTableDataLongestAllowedNameLength)}&#8230;</span>
        </div>
      ) : (
        <div>
          <span>{name}</span>
        </div>
      );
    }
    
    // eslint-disable-next-line no-unused-vars
    function scoreRoiFormatter(cell, row) {
/*
      function getNumberParts(x, b) {
        let exp = 0;
        let sgn = 0;
        if (x === 0) return { sign: 0, mantissa: 0, exponent: 0 };
        if (x < 0) { sgn=1; x=-x; }
        while (x > b) { x /= b; exp++; }
        while (x < 1) { x *= b; exp--; }
        return { sign: sgn, mantissa: x, exponent: exp };
      }
      const exponentialScore = Number.parseFloat(row.score).toExponential(3);
      const scoreParts = getNumberParts(exponentialScore, 10);
      const formattedScore = (scoreParts.exponent === 0) ? ((scoreParts.mantissa !== 0.0) ? Number.parseFloat(row.score).toPrecision(4) : 0) : exponentialScore;
*/
      const formattedScore = (parseFloat(row.score) !== 0.0) ? Number.parseFloat(row.score).toExponential(3) : 0;
      return <div><span>{ formattedScore }</span></div>
    }
    
    // eslint-disable-next-line no-unused-vars
    function strandRoiFormatter(cell, row) {
      if (typeof row.strand === "number" || !isNaN(Number(row.strand))) {
        const formattedScore = (parseFloat(row.strand) !== 0.0) ? Number.parseFloat(row.strand).toExponential(3) : 0;
        return <div><span>{ formattedScore }</span></div>
      }
      return <div><span>string: { row.strand }</span></div>
    }
    
    // eslint-disable-next-line no-unused-vars
    function idxProteinCodingAttrs(cell, row, rowIndex, colIndex) {
      return { id : `protein_coding_idx_${rowIndex}` };
    }
    
    // eslint-disable-next-line no-unused-vars
    function geneProteinCodingsFormatter(cell, row) {
      return <div><span>{ row.gene }</span></div>
    }
    
    // eslint-disable-next-line no-unused-vars
    function systemProteinCodingsFormatter(cell, row) {
      return (
        <div data-tip data-for={`proteinCodings-system-${row.system}`}>
          { self.systemProteinCodingsToColorBox(row.system) }
        </div>
      );
    }
    
    // eslint-disable-next-line no-unused-vars
    function idxTranscriptionFactorAttrs(cell, row, rowIndex, colIndex) {
      return { id : `transcription_factor_idx_${rowIndex}` };
    }
    
    // eslint-disable-next-line no-unused-vars
    function geneTranscriptionFactorsFormatter(cell, row) {
      return <div><span>{ row.gene }</span></div>
    }
    
    // eslint-disable-next-line no-unused-vars
    function systemTranscriptionFactorsFormatter(cell, row) {
      return (
        <div data-tip data-for={`transcriptionFactors-system-${row.system}`}>
          { self.systemTranscriptionFactorsToColorBox(row.system) }
        </div>
      );
    }
    
    // eslint-disable-next-line no-unused-vars
    const customRoiRowStyle = (row, rowIndex) => {
      const style = {};
      if (row.idx === this.props.selectedRoiRowIdx) {
        style.backgroundColor = '#2631ad';
        style.color = '#fff';
      }
      else if (row.idx === this.state.currentRoiMouseoverRow) {
        style.backgroundColor = '#173365';
        style.color = '#fff';
      }
      return style;
    };
    
    // eslint-disable-next-line no-unused-vars
    const customProteinCodingsRowStyle = (row, rowIndex) => {
      const style = {};
      if (row.idx === this.props.selectedProteinCodingsRowIdx) {
        style.backgroundColor = '#2631ad';
        style.color = '#fff';
      }
      else if (row.idx === this.state.currentProteinCodingsMouseoverRow) {
        style.backgroundColor = '#173365';
        style.color = '#fff';
      }
      return style;
    };
    
    // eslint-disable-next-line no-unused-vars
    const customTranscriptionFactorsRowStyle = (row, rowIndex) => {
      const style = {};
      if (row.idx === this.props.selectedTranscriptionFactorsRowIdx) {
        style.backgroundColor = '#2631ad';
        style.color = '#fff';
      }
      else if (row.idx === this.state.currentTranscriptionFactorsMouseoverRow) {
        style.backgroundColor = '#173365';
        style.color = '#fff';
      }
      return style;
    };
    
    const customRoiRowEvents = {
      // eslint-disable-next-line no-unused-vars
      onClick: (e, row, rowIndex) => {
        this.props.jumpToRegion(row.position, Constants.applicationRegionTypes.roi, row.idx, row.element.strand);
      },
      // eslint-disable-next-line no-unused-vars
      onMouseEnter: (e, row, rowIndex) => {
        this.setState({
          currentRoiMouseoverRow: row.idx
        });
      },
      // eslint-disable-next-line no-unused-vars
      onMouseLeave: (e, row, rowIndex) => {
        this.setState({
          currentRoiMouseoverRow: -1
        });
      }
    };
    
    const customProteinCodingsRowEvents = {
      // eslint-disable-next-line no-unused-vars
      onClick: (e, row, rowIndex) => {
        //console.log("row, Constants.applicationRegionTypes.proteinCodings, rowIndex, strand", row, Constants.applicationRegionTypes.proteinCodings, rowIndex, row.strand);
        this.props.jumpToRegion(row.position, Constants.applicationRegionTypes.proteinCodings, row.idx, row.element.strand);
      },
      // eslint-disable-next-line no-unused-vars
      onMouseEnter: (e, row, rowIndex) => {
        this.setState({
          currentProteinCodingsMouseoverRow: row.idx
        });
      },
      // eslint-disable-next-line no-unused-vars
      onMouseLeave: (e, row, rowIndex) => {
        this.setState({
          currentProteinCodingsMouseoverRow: -1
        });
      }
    };
    
    const customTranscriptionFactorsRowEvents = {
      // eslint-disable-next-line no-unused-vars
      onClick: (e, row, rowIndex) => {
        //console.log("row, Constants.applicationRegionTypes.transcriptionFactors, rowIndex, strand", row, Constants.applicationRegionTypes.transcriptionFactors, rowIndex, row.strand);
        this.props.jumpToRegion(row.position, Constants.applicationRegionTypes.transcriptionFactors, row.idx, row.element.strand);
      },
      // eslint-disable-next-line no-unused-vars
      onMouseEnter: (e, row, rowIndex) => {
        this.setState({
          currentTranscriptionFactorsMouseoverRow: row.idx
        });
      },
      // eslint-disable-next-line no-unused-vars
      onMouseLeave: (e, row, rowIndex) => {
        this.setState({
          currentTranscriptionFactorsMouseoverRow: -1
        });
      }
    };
    
    function tabContent() {
      return (
        <div>
          <Nav tabs>
            <NavItem disabled={!self.state.tabs.settings}>
              <NavLink
                className={classnames({ active: self.state.activeTab === 'proteinCodings' })}
                onClick={() => { self.toggle('proteinCodings'); }}
                disabled={!self.state.tabs.proteinCodings}
              >
                { !self.props.roiEnabled ? "protein-coding genes" : "protein-coding" }
              </NavLink>
            </NavItem>
            <NavItem disabled={!self.state.tabs.transcriptionFactors}>
              <NavLink
                className={classnames({ active: self.state.activeTab === 'transcriptionFactors' })}
                onClick={() => { self.toggle('transcriptionFactors'); }}
                disabled={!self.state.tabs.transcriptionFactors}
              >
                TF<span style={{fontSize:'smaller'}}>s</span>
              </NavLink>
            </NavItem>
            {(self.props && self.props.roiEnabled) ?
              <NavItem disabled={!self.state.tabs.roi}>
                <NavLink
                  className={classnames({ active: self.state.activeTab === 'roi' })}
                  onClick={() => { self.toggle('roi'); }}
                  disabled={!self.state.tabs.roi}
                >
                  roi
                </NavLink>
              </NavItem> : ""}
          </Nav>
          <TabContent activeTab={self.state.activeTab} className="drawer-tab-content">
              <TabPane tabId="proteinCodings">
                { contentByType("proteinCodings") }
              </TabPane>
              <TabPane tabId="transcriptionFactors">
                { contentByType("transcriptionFactors") }
              </TabPane>
              {(self.props && self.props.roiEnabled) ? 
                <TabPane tabId="roi">
                  { contentByType("roi") }
                </TabPane> : ""}
          </TabContent>
        </div>
      )
    }

    return (
      <Fragment>
        {tabContent()}
      </Fragment>
    )
  }
}

export default DrawerContent;

DrawerContent.propTypes = {
  activeTab: PropTypes.string,
  advancedOptionsVisible: PropTypes.bool,
  jumpToRegion: PropTypes.func,
  onProteinCodingsColumnSort: PropTypes.func,
  onRoiColumnSort: PropTypes.func,
  onTranscriptionFactorsColumnSort: PropTypes.func,
  orientationIsPortrait: PropTypes.bool,
  roiMaxColumns: PropTypes.number,
  roiTableDataLongestAllowedNameLength: PropTypes.number,
  roiTableDataLongestNameLength: PropTypes.number,
  selectedProteinCodingsRowIdx: PropTypes.number,
  selectedRoiRowIdx: PropTypes.number,
  selectedTranscriptionFactorsRowIdx: PropTypes.number,
  updateActiveTab: PropTypes.func,
  viewParams: PropTypes.object
};